import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadContext } from '../../store/actions/Contexto';
import { isAfterDataFimInscricaoEDataFimPagamento } from '../../helper/ContextHelper';

import Grid from '@material-ui/core/Grid';

import Table from '../../components/Table/Table';

import { percentMask } from '../../helper/MaskHelper';

export class DadosInscricoesEdicao extends Component {
    constructor (props) {
        super(props)
        props.parentRef(this);
        this.state = {
            edicoes: [],
        }
    }

    render () {
        const columns = [
            { label: 'Edicão', name: 'edicao', func: null, key: false, width: '9', },
            { label: 'Escolas', name: 'escolas', func: null, key: false, width: '9', },
            { label: 'Escolas Inscritas', name: 'escolasInscritas', func: null, key: false, width: '9', },
            { label: 'Escolas Aguardando Pagamento', name: 'escolasAguardandoPagamento', func: null, key: false, width: '9', hidden: isAfterDataFimInscricaoEDataFimPagamento(this.props.contexts), },
            { label: '% de Escolas Inscritas', name: 'escolasInscritasPorcentagem', func: percentMask, key: false, width: '9', },
            { label: 'Municipios', name: 'municipios', func: null, key: false, width: '9', },
            { label: 'Municipios Inscritos', name: 'municipiosInscritos', func: null, key: false, width: '9', },
            { label: '% de Municipios Inscritos', name: 'municipiosInscritosPorcentagem', func: percentMask, key: false, width: '9', },
            { label: 'Mirim 1 - Alunos Previstos', name: 'alunosPrevistosMirim1', func: null, key: false, width: '9', },
            { label: 'Mirim 2 - Alunos Previstos', name: 'alunosPrevistosMirim2', func: null, key: false, width: '9', },
            { label: 'Total de Alunos Previstos', name: 'alunosPrevistos', func: null, key: false, width: '9', },
        ];

        return (
            <Grid container spacing={3} style={{ marginTop: this.props.marginTop ?? 8 }}>
                <Grid item xs={12}>
                    <Table              
                        columns={columns}
                        tableData={this.state.edicoes}
                        actions={[]}
                        page={null}
                        rowsPerPage={null}
                        sort={null}
                        fixedHeader={true}
                    />
                </Grid>
            </Grid>
        )
    }
}

const mapStateToProps = state => ({ contexts: state.contexts });
const mapDispatchToProps = dispatch => (bindActionCreators({ loadContext }, dispatch));

export default connect(mapStateToProps, mapDispatchToProps)(DadosInscricoesEdicao);