import React from 'react';

import Typography from '@material-ui/core/Typography';

import Title from '../../components/Title/Title';

import useStyles from './Style';

const MaterialDigitalFase2 = () => {
    const classes = useStyles();
    return (
        <>
            <Title id="anchor-13" className={classes.title}>
                13. DA DISPONIBILIZAÇÃO DO MATERIAL DE PROVA DA 2ª FASE 
            </Title>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>13.1</span> O IMPA é responsável pela elaboração, impressão e pelo envio do Material de Prova da 2ª Fase para as escolas inscritas na Olimpíada Mirim – OBMEP.
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>13.2</span> As provas e o material de provas da 2ª Fase da 3ª Olimpíada Mirim – OBMEP serão enviados para o endereço declarado, na ficha de inscrição, pela escola 
                ou secretaria de educação. É de responsabilidade da escola buscar o material de aplicação das provas no Centro de Distribuição de Encomendas dos Correios - ECT, 
                caso o endereço indicado pela escola no ato da inscrição seja eventualmente classificado como área de restrição de entrega pelos Correios. Neste caso, a retirada 
                do material dependerá da apresentação da identidade do representante da escola e de comprovante do respectivo vínculo com a escola.
            </Typography>
            
            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>13.3</span> É de responsabilidade da escola ou da secretaria de educação informar corretamente o endereço completo da escola para o qual o material será enviado.
            </Typography>

            <Typography className={classes.text} component={'div'} variant="body2">
                <span className={classes.item}>13.4</span> O Material de Prova é composto de:

                <ul className={classes.list}>
                    <li><span className={classes.item}>{"a)"}</span> Manual de Instruções para Aplicação da Prova da 2ª Fase;</li>
                    <li><span className={classes.item}>{"b)"}</span> Provas da 2ª Fase da Olimpíada Mirim - OBMEP, disponíveis de acordo com o(s) nível(is) dos(as) alunos(as) que a escola inscreveu;</li>
                    <li><span className={classes.item}>{"c)"}</span> Máscara de Correção;</li>
                    <li><span className={classes.item}>{"d)"}</span> Soluções da Prova;</li>
                    <li><span className={classes.item}>{"e)"}</span> Relação de Material – Check List.</li>
                </ul>
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>13.5</span> Ao receber a caixa com as provas e o material de provas, conforme disposto no item 13.4 deste Regulamento, a escola deverá verificar se todo o material 
                listado na Relação de Material (Check list) foi recebido, atentando para as quantidades e para o tipo de material listado. Caso alguma parte do material não tenha 
                sido enviada, a escola deverá fazer contato com a Central da Olimpíada Mirim por telefone (21 2529-5084) ou por e-mail (<span className={classes.underline}>olimpiadamirim@obmep.org.br</span>), imediatamente.
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>13.6</span> O pacote de provas deverá ser aberto somente no dia da aplicação da prova.
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>13.7</span> As regras deste Regulamento e as instruções do Manual de Instruções para Aplicação da Prova da 2ª Fase deverão ser rigorosamente seguidas pelas escolas.
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>13.8</span> As escolas inscritas ou as secretarias de educação são responsáveis pela manutenção do sigilo da prova e do gabarito durante todo o processo, bem como 
                por instruir neste sentido quaisquer funcionários e/ou colaboradores eventualmente envolvidos no processo.
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>13.9</span> O IMPA se isenta de qualquer responsabilidade em caso de divulgação indevida das provas.
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>13.10</span> A escola inscrita ou secretaria de educação que quebrar o sigilo das questões das provas e de seus respectivos gabaritos poderá ser punida, bem como 
                poderá ser desclassificada, inexistindo responsabilidade do IMPA.
            </Typography>
        </>
    )
}

export default MaterialDigitalFase2;