import { format, parse } from 'date-fns'

import brLocale from "date-fns/locale/pt-BR";

export const toDate = date => date ? format(new Date(date), 'dd/MM/yyyy', { locale: brLocale }) : null;

export const toDateTime = date => date ? format(new Date(date), "dd/MM/yyyy HH:mm:ss", { locale: brLocale }) : null;

export const toDateTimeWithoutSeconds = date => date ? format(new Date(date), "dd/MM/yyyy HH:mm", { locale: brLocale }) : null;

export const toDateTimeForCompare = date => date ? new Date(date) : null;

export const formatInTimeZone = date => format(new Date(date), 'yyyy-MM-dd HH:mm:ssXX', { locale: brLocale });

export const formatInDate = date => format(new Date(date), 'yyyy-MM-dd', { locale: brLocale });

export const parseDate = date => date ? parse(date, "dd/MM/yyyy", new Date()) : null;

export const parseDateTime = date => date ? parse(date, "dd/MM/yyyy HH:mm:ss", new Date()) : null;

export const currentDateTime = () => format(new Date(), "dd/MM/yyyy HH:mm", { locale: brLocale });