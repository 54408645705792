import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';

import SelectOption from '../../components/Inputs/Select/Select';

import { 
    optionsOrigem, 
    optionsStatus
} from '../../variables/Enums/Escola';

import {
    isBlank,
    isBlankHelperText,
} from '../../helper/ValidationHelper';

import { __ESCOLA_ADICIONAR_OPCOES_AVANCADAS } from '../../security/RoleConfiguration';
import AuthorizedElement from '../../security/AuthorizedElement';
import AuthorizedFunction from '../../security/AuthorizedFunction';

export class EscolaStatus extends Component {
    constructor(props) {
        super(props);
        props.parentRef(this);
        this.state = {
            origem: "",
            status: "",
            errors: {
                origem: false,
                status: false,
            },
            helpers: {
                origem: null,
                status: null,
            },
            disabled: {
                origem: false,
                status: false,
            }
        }
        this.isValid = this.isValid.bind(this);
    }

    // INPUTS
    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
            errors: {...this.state.errors, [e.target.name]: false },
            helpers: {...this.state.helpers, [e.target.name]: null }
        })
    }

    // VALIDAÇÕES
    isValid = () => {
        if (AuthorizedFunction([__ESCOLA_ADICIONAR_OPCOES_AVANCADAS]) && !this.props.escola && (isBlank(this.state.status) || isBlank(this.state.origem))) {
            this.setState({
                errors: {
                    origem: AuthorizedFunction([__ESCOLA_ADICIONAR_OPCOES_AVANCADAS]) && !this.props.escola && isBlank(this.state.origem) ? true : false,
                    status: AuthorizedFunction([__ESCOLA_ADICIONAR_OPCOES_AVANCADAS]) && !this.props.escola && isBlank(this.state.status) ? true : false,
                },
                helpers: {
                    origem: AuthorizedFunction([__ESCOLA_ADICIONAR_OPCOES_AVANCADAS]) && !this.props.escola && isBlank(this.state.origem) ? isBlankHelperText() : null,
                    status: AuthorizedFunction([__ESCOLA_ADICIONAR_OPCOES_AVANCADAS]) && !this.props.escola && isBlank(this.state.status) ? isBlankHelperText() : null,
                },
            });
            return false;
        }
        return true;
    }   

    handleChangeCheckbox = e => {
        this.setState({
            [e.target.name]: e.target.checked,
        });
    }

    render () {
        return (
            <AuthorizedElement roles={[__ESCOLA_ADICIONAR_OPCOES_AVANCADAS]} >
                <Grid container spacing={3} >
                    <Grid item container spacing={3}>
                        <Grid item sm={12} lg={2}>
                            <SelectOption
                                required
                                disabled={this.state.disabled.status}
                                label="Status"
                                name='status'
                                value={this.state.status}
                                onChange={(e) => this.handleChange(e)}
                                options={optionsStatus}
                                hiddenblank="true"
                                error={this.state.errors.status}
                                helperText={this.state.helpers.status}
                            />
                        </Grid>
                        <Grid item sm={12} lg={3}>
                            <SelectOption
                                required
                                disabled={this.state.disabled.origem || this.props.escola}
                                label="Origem"
                                name='origem'
                                value={this.state.origem}
                                onChange={(e) => this.handleChange(e)}
                                options={optionsOrigem}
                                hiddenblank="true"
                                error={this.state.errors.origem}
                                helperText={this.state.helpers.origem}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </AuthorizedElement>
        )
    }
}

export default EscolaStatus;