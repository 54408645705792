import Form from '../../views/Banner/Form';
import { __BANNER_ADICIONAR, __BANNER_EDITAR } from '../../security/RoleConfiguration';

export const path = '/admin/banners';

export const listRoutes = [
    {
        path: path + '/adicionar',
        component: Form,
        roles: [ __BANNER_ADICIONAR ],
    },
    {
        path: path + '/:id/editar',
        component: Form,
        roles: [ __BANNER_EDITAR ],
    }
];

export default listRoutes;