import React, { useState, Component, useEffect } from 'react';
import { Link, withRouter } from "react-router-dom";

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadContext } from '../../store/actions/Contexto';
import { isAfterInicioConsumoServicosImpressaoGrafica } from '../../helper/ContextHelper';

import Grid from '@material-ui/core/Grid';
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import SchoolOutlinedIcon from "@material-ui/icons/SchoolOutlined";
import { amber } from "@material-ui/core/colors";
import GridOffOutlinedIcon from '@material-ui/icons/GridOffOutlined';

import EscolaService from '../../services/Escola';
import { errors } from '../../services/API';

import {patternLabelDepAdmin, patternSchools} from '../../variables/Enums/Escola';

import ButtonPrimary from "../../components/Button/Button";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Title from '../../components/Title/Title';
import Table from '../../components/Table/Table';
import Toast from '../../components/Toast/Toast';
import {
  PAGE,
  ASC,
  TOTAL_PAGES, verifyPagingAndSorting,
} from '../../components/Table/Utils';

import { DialogConfirm as DialogHabilitarEscola }  from '../../forms/Dialog/DialogConfirm';
import EscolaMateriaisFilter from "../../forms/Escola/EscolaMateriaisFilter";
import ButtonExport from "../../forms/Buttons/ButtonsExport";

import { removePagingAndSorting } from '../../helper/PaginationHelper';
import { filterPersistence } from '../../helper/ParseStateHelper';

import { Layout } from '../../layouts/private/Private';

import AuthorizedElement from "../../security/AuthorizedElement";
import {__ESCOLA_EXPORTAR} from "../../security/RoleConfiguration";

import DialogIgnorarEscola from "./DialogIgnorarEscola";

export const ButtonIgnorarMaterial = ({ linkToBack }) => {
  const [ quantidadeEscolasIgnoradasParaImpressao, setQuantidadeEscolasIgnoradasParaImpressao ] = useState(0);

  useEffect(() => {
    EscolaService.filters([{ field: "enviarGrafica", value: false }])
        .then(res => setQuantidadeEscolasIgnoradasParaImpressao(res.data.totalElements));
  }, []);

  return (
    <Grid container spacing={2} style={{ marginTop: '16px' }}>
      <Grid item sm={12} lg={4}>
        <ButtonPrimary
            to={{
              pathname: `/admin/materiais/escolas-ignorar`,
              state: {
                history: {
                  path: linkToBack,
                }
              }
            }}
            component={Link}
            name={`Escolas Ignoradas para Ações de Impressão de Materiais (${quantidadeEscolasIgnoradasParaImpressao})`}
            startIcon={<SchoolOutlinedIcon />}
            style={{ backgroundColor: amber[800] }}
        />
      </Grid>
    </Grid>
  )
}

export class IgnorarMaterial extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: PAGE,
      size: 25,
      totalElements: 25,
      totalPages: TOTAL_PAGES,
      sort: [{ orderBy: 'cdMecEscola', order: ASC }],
      defaultOrderBy: 'cdMecEscola',
      schools: [],
      filters: [],
      filtered: false,
      loading: true,
      history: {
        path: null,
        state: null
      },
    }
  }

  // DECLARAÇÃO DE REFERÊNCIA DOS COMPONENTES
  setToast = t => this.Toast = t;
  setDialogHabilitarEscola = h => this.DialogHabilitarEscola = h;
  setDialogIgnorarEscola = h => this.DialogIgnorarEscola = h;
  setEscolaMateriaisFilter = f => this.EscolaMateriaisFilter = f;

  labelHabilitarEscola = id => !this.disabledHabilitarEscola(id) ? "Remover da Listagem de Escolas para Ignorar em Ações de Impressão" : "O Consumo do Serviço de Materiais para Impressão Já Foi Iniciado pela Gráfica";
  disabledHabilitarEscola = id => isAfterInicioConsumoServicosImpressaoGrafica(this.props.contexts);

  handleHabilitarEscola = id => {
    const school = this.state.schools.find(s => s.cdMecEscola === id);
    this.DialogHabilitarEscola.setState({
      dialog: {
        open: true,
        title: `Deseja habilitar a Escola ${school.nmEscola} - ${school.cdMecEscola} em ações de Impressão de Materiais ?`,
        text: `A ação deixará a escola apta para ser computada numa próximo Cálculo e Ordenação de Materiais, fazendo-se necessário que as ações sejam refeitas, caso já tenham sido realizadas.`,
        id: id,
        loading: false,
        showConfirm: true,
      }
    });
  }

  handleHabilitarEscolaConfirm = (id) => {
    this.DialogHabilitarEscola.loading();

    EscolaService.ignorarParaMateriais(id, false)
        .then(res => {
          this.Toast.setState({
            message: {
              message: "A Escola foi Habilitada com Sucesso.",
              type: 'success',
              open: true
            }
          })

          this.loadData();
        })
        .catch(error => {
          const e = errors(error);
          this.Toast.setState({
            message: {
              message: e.message,
              type: e.type,
              open: true
            }
          })
        })
        .finally(() => {
          this.DialogHabilitarEscola.close();
        });
  }

  handleOpenModalIgnorarEscola = id => this.DialogIgnorarEscola.setState({ open: true });

  handleExportPlan = () => {
    this.EscolaMateriaisFilter.setState({ filter: true, exportPlan: true, }, () => this.EscolaMateriaisFilter.handleFilterApply());

    this.Toast.setState({
      message: {
        message: "A Planilha está sendo gerada para Exportação.",
        type: 'info',
        open: true,
        loading: true,
        autoHide: false,
      }
    })
  }

  loadData = async () => {
    this.props.loadContext();

    this.setState({ history: filterPersistence(this.props.location) });

    let filters = verifyPagingAndSorting(this.state);
    this.setState({
      filters: [...filters],
      filtered: true,
      loading: true
    });

    await EscolaService.filters([...filters, { field: "enviarGrafica", value: false }])
      .then(res => {
        if (res.data.content) {
          const schools = patternSchools(res.data.content);
          this.setState({
            schools: [...schools],
            page: res.data.number,
            size: res.data.size,
            totalElements: res.data.totalElements,
            totalPages: res.data.totalPages,
            loading: false,
          });
        } else {
          this.setState({
            schools: [],
            page: PAGE,
            size: 25,
            totalElements: 25,
            totalPages: TOTAL_PAGES,
            loading: false,
          });
        }
      })
      .catch(error => {
        const e = errors(error);
        this.Toast.setState({
          message: {
            message: e.message,
            type: e.type,
            open: true
          }
        })

        this.setState({ loading: false });
      })
  }

  // FILTROS
  filterData = (filters, isActive = true) => this.setState({ filtered: isActive, filters: [...removePagingAndSorting(filters)] }, () => this.loadData());

  // PÁGINA
  handlePage = page => this.setState({ page: page }, () => this.filterData(this.state.filters));

  // LINHAS POR PÁGINA
  handleRowsPerPage = (size, page) => this.setState({ size: size, page: page }, () => this.filterData(this.state.filters));

  // ORDENAÇÃO
  handleSort = (orderBy, order) => {
    if (this.state.sort.find(s => s.orderBy === orderBy)) {
        let newSort = this.state.sort.filter(s => s.orderBy !== orderBy);
        newSort.splice(this.state.sort.indexOf(this.state.sort.find(s => s.orderBy === orderBy)), 0, { orderBy: orderBy, order: order })
        this.setState({...this.state, sort: [...newSort], filtered: true });
    } else {
        this.setState({ ...this.state, sort: [...this.state.sort.filter(s => s.orderBy !== orderBy), { orderBy: orderBy, order: order }], filtered: true });
    }
  }
  handleClearSort = (orderBy) => {
      this.setState({ ...this.state, filtered: true, sort: orderBy ? [...this.state.sort.filter(s => s.orderBy !== orderBy)] : [{ orderBy: this.state.defaultOrderBy, order: ASC }] });
  };

  // TOTAL DE PAGINAS
  handleTotalPages = () => this.state.totalPages;

  // TOTAL DE ELEMENTOS
  handleTotalElements = () => this.state.totalElements;

  // VERIFICAR PERSISTENCIA NO FORMULARIO DE FILTROS
  handleFilterPersistence = () => this.EscolaMateriaisFilter ? this.EscolaMateriaisFilter.state ? this.EscolaMateriaisFilter.handleFilterPersistence(this.state.filters) : null : null;

  componentDidMount() { this.loadData(); }
  
  render() {
    const pageName = 'Escolas Ignoradas para Ações de Impressão';
    const links = [
      {
        path: null,
        name: 'Configurações',
      },
      {
        path: null,
        name: 'Materiais',
      },
      {
        path: this.state.history && this.state.history.path ? `${this.state.history.path}` : '/admin/materiais/calculo',
        name: this.state.history && this.state.history.path && this.state.history.path.includes("ordem-impressao") ? 'Ordem de Impressão de Escolas' : 'Cálculo de Materiais de Escolas',
      },
    ];

    const columns = [
      { label: 'INEP', name: 'cdMecEscola', func: null, key: true },
      { label: 'Escola', name: 'nmEscola', func: null, key: false },
      { label: 'Dep. Adm.', name: 'nmDependenciaAdministrativa', func: patternLabelDepAdmin, key: false },
      { label: 'UF', name: 'nmUf', func: null, key: false, ordering: false },
      { label: 'Município', name: 'nmMunicipio', func: null, key: false, ordering: false },
    ];

    const actions = [
      {
        name: this.labelHabilitarEscola,
        func: this.handleHabilitarEscola,
        icon: <GridOffOutlinedIcon />,
        disabled: this.disabledHabilitarEscola,
        color: "secondary",
      },
    ]

    return (
      <Layout>
        <Toast parentRef={this.setToast} />
        <Grid container >
          <Grid item xs={12}>
            <Breadcrumbs links={links} active={pageName} />
          </Grid>
        </Grid>
        <Title>{pageName}</Title>

        <Grid container >
          <Grid item lg={12}>
            <Paper style={{ marginTop: '8px', marginBottom: '16px', padding: '24px' }} elevation={1}>
              <Grid container spacing={3}>
                <Grid item sm={12} lg={12} >
                  <Typography component="div" variant="body2">
                    <b>Informações:</b>

                    <ul style={{ marginTop: 16, paddingLeft: 36 }}>
                      <li>As Escolas Marcadas como <b>Ignoradas</b>, não serão consideradas para <b>Cálculo de Materiais</b> e <b>Ordenação para Impressão</b>, e portanto não serão processadas nestas funcionalidades.</li>
                      <li><b>Adicionar ou Remover</b> Escolas como Ignoradas, <b>NÃO</b> tem efeito imediato nas ações de Cálculo e Ordenação que já foram realizados, e sim somente nas próximas às ações realizadas.</li>
                      <li>As Escolas nesta listagem, <b>NÃO</b> serão acrescentadas nos serviços disponibilizados para a Gráfica, que remetem a ações referentes a Impressão e Distribuição de Materiais.</li>
                      <li>Após a <b>Data para Início do Consumo de Informações de Impressão</b> por parte da gráfica, as Escolas <b>NÃO</b> poderão ser alteradas, pois isso impacta diretamente na logística de impressão, distribuição e envio de materiais.</li>
                    </ul>
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>

        <EscolaMateriaisFilter
            parentRef={this.setEscolaMateriaisFilter}
            handleFilterChange={this.filterData}
        />

        <Grid container spacing={2} style={{ marginTop: '16px', marginBottom: '16px' }}>
          <Grid item sm={12} lg={3}>
            <ButtonPrimary
                onClick={this.handleOpenModalIgnorarEscola}
                name="Adicionar Escolas para Ignorar"
                startIcon={<SchoolOutlinedIcon />}
                style={isAfterInicioConsumoServicosImpressaoGrafica(this.props.contexts) ? {} : { backgroundColor: amber[800] }}
                disabled={isAfterInicioConsumoServicosImpressaoGrafica(this.props.contexts)}
            />
          </Grid>

          <Grid item sm={12} lg={12}>
            <AuthorizedElement roles={[__ESCOLA_EXPORTAR]}>
              <ButtonExport
                  title="Exportar Planilha"
                  onClick={this.handleExportPlan}
              />
            </AuthorizedElement>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Table
              columns={columns}
              tableData={this.state.schools}
              actions={actions}
              page={this.state.page}
              handlePage={this.handlePage}
              handleTotalPages={this.handleTotalPages}
              handleTotalElements={this.handleTotalElements}
              rowsPerPage={this.state.size}
              handleRowsPerPage={this.handleRowsPerPage}
              sort={this.state.sort}
              handleSort={this.handleSort}
              handleClearSort={this.handleClearSort}
              loading={this.state.loading}
            />
          </Grid>
        </Grid>

        {!isAfterInicioConsumoServicosImpressaoGrafica(this.props.contexts) &&
          <>
            <DialogHabilitarEscola
                parentRef={this.setDialogHabilitarEscola}
                handleConfirm={this.handleHabilitarEscolaConfirm}
            />

            <DialogIgnorarEscola
                parentRef={this.setDialogIgnorarEscola}
                handleClose={this.loadData}
            />
          </>
        }
      </Layout>
    )
  }
}

const mapStateToProps = state => ({ contexts: state.contexts });
const mapDispatchToProps = dispatch => (bindActionCreators({ loadContext }, dispatch));

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(IgnorarMaterial));