import React, { Component } from 'react'

import Dialog from '../../components/Dialog/Dialog'
import Toast from '../../components/Toast/Toast'

import { ButtonCancel } from '../../forms/Buttons/ButtonsForm'
import Ocorrencia from '../../views/Escola/Ocorrencia'

export class DialogOcorrencias extends Component {
    constructor(props) {
        super(props);
        props.parentRef(this);
        this.state = {
            open: false,
            escola: null,
            loading: false,
        }
    }

    // DECLARAÇÕES DE REFERENCIA DOS COMPONENTES
    setToast = t => this.Toast = t;

    // AÇÕES MODAL
    buttons = () =>
        <>
            <ButtonCancel 
                onBack={this.handleClose}
                nameOnBack="Fechar"
            />
        </>

    // FECHAR MODAL
    handleClose = () => {
        this.props.handleClose();
        this.setState({
            open: false,
            escola: null,
            loading: false,
        });
    }

    render() {
        return (
            <>  
                <Toast parentRef={this.setToast} />
                <Dialog
                    maxWidth={"xl"}
                    fullWidth={true}
                    closeDialog={this.handleClose}
                    openDialog={this.state.open}
                    title={`Ocorrências ${this.state.escola ? `- ${this.state.escola.nmEscola} (${this.state.escola.cdMecEscola})` : ""}`}
                    actions={this.buttons()}
                >
                    {this.state.escola && <Ocorrencia escola={this.state.escola} />}
                </Dialog>
            </>
        )
    }
}

export default DialogOcorrencias;