import { makeStyles } from '@material-ui/core/styles';
import { blueGrey } from '@material-ui/core/colors';

const useStyles = makeStyles(
    (theme) => (
        {
            container: {
                marginTop: theme.spacing(12),
                marginBottom: theme.spacing(8),
            },
            title: {
                marginTop: theme.spacing(6),
                fontWeight: '600',
            },
            file: {
                marginBottom: theme.spacing(2),
                display: 'flex',
                alignItems: 'center',
                color: blueGrey[900],
            },
        }
    )
);

export default useStyles;