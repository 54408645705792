import React, { Component } from 'react';

import { Grid } from '@material-ui/core';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import CircularProgress from '@material-ui/core/CircularProgress';

import Dialog from '../../components/Dialog/Dialog';
import { ButtonDefault, ButtonPrimary } from '../../components/Button/Button';
import Toast from "../../components/Toast/Toast";
import Text from '../../components/Inputs/Text/Text';

import AlunoService from '../../services/Aluno';
import { errors } from '../../services/API';

import {
    isBlank,
    isBlankHelperText,
    isNotaFase2Invalida,
    isNotaFase2HelperText,
} from '../../helper/ValidationHelper';
import { numberMask } from '../../helper/MaskHelper';

export class Nota extends Component {
    constructor(props) {
        super(props);
        props.parentRef(this);
        this.state = {
            dialog: {
                open: false,
                aluno: {
                    id: null,
                    nome: null,
                    nota: null
                },
                loading: false,
            },
            aluno: {
                nota: null,
            },
            errors: {
                nota: false,
            },
            helpers: {
                nota: null,
            },
        }
    }

    setToast = t => this.Toast = t;

    handleChange = (e) => {
        this.setState({
            dialog: {
                ...this.state.dialog,
                aluno: {
                    ...this.state.dialog.aluno,
                    [e.target.name]: numberMask(e.target.value),
                },
            },
            errors: { ...this.state.errors, [e.target.name]: false },
            helpers: { ...this.state.helpers, [e.target.name]: null },
        });
    }

    // AÇÕES MODAL
    actions = () => {
        return (
            <>
                <ButtonPrimary 
                    onClick={this.handleSubmit}
                    startIcon={this.state.dialog.loading ? <CircularProgress size={16} /> : <CheckOutlinedIcon />}
                    disabled={this.state.dialog.loading}
                    style={{ margin: 4 }}
                    name="Salvar"
                />
                <ButtonDefault
                    onClick={this.close}
                    startIcon={<ClearOutlinedIcon />}
                    style={{ margin: 4 }}
                    name="Fechar"
                />
            </>
        )
    }

    openModal(aluno) {
        this.setState({
            dialog: {
                ...this.state.dialog,
                open: true,
                aluno: {
                    id: aluno.cdAluno,
                    nome: aluno.nmAluno,
                    nota: aluno.notaFase2,
                },
            },
        });
    }

    // FECHAR MODAL
    close = () => {
        this.props.handleClose();
        this.setState({
            dialog: {
                open: false,
                aluno: {
                    id: null,
                    nome: null,
                    nota: null,
                },
                loading: false,
            },
        })
    }

    isValid = () => {
        if (isBlank(this.state.dialog.aluno.nota) || isNotaFase2Invalida(this.state.dialog.aluno.nota)) {
            this.setState({
                errors: {
                    nota: isBlank(this.state.dialog.aluno.nota) || isNotaFase2Invalida(this.state.dialog.aluno.nota) ? true : false,
                },
                helpers: {
                    nota: isBlank(this.state.dialog.aluno.nota) ? isBlankHelperText() : isNotaFase2Invalida(this.state.dialog.aluno.nota) ? isNotaFase2HelperText(this.state.dialog.aluno.nota) : null,
                },
            });
            return false;
        }
        return true;
    }

    handleSubmit = () => {
        this.setState({ dialog: { ...this.state.dialog, loading: true } });

        if (!this.isValid()) {
            this.setState({ dialog: { ...this.state.dialog, loading: false } });
            return
        }

        AlunoService.atualizarNotaFase2(this.state.dialog.aluno.id, this.state.dialog.aluno.nota)
            .then(res => {
                this.Toast.setState({
                    message: {
                        message: "Nota da Fase 2 Salva com Sucesso",
                        type: "success",
                        open: true
                    }
                });
                
                this.setState({ dialog: { ...this.state.dialog, loading: false } })
                setTimeout(() => this.close(), 1000);
            })
            .catch(error => {
                const e = errors(error);
                this.Toast.setState({
                    message: {
                        message: e.message,
                        type: e.type,
                        open: true
                    }
                })
            })
        .finally(() => this.setState({ dialog: { ...this.state.dialog, loading: false } }));
    }

    render() {
        return (
            <>
                <Toast parentRef={this.setToast} />
                <Dialog
                    openDialog={this.state.dialog.open}
                    title={`Nota na Fase 2 - Aluno ${this.state.dialog.aluno.nome}`}
                    closeDialog={this.close}
                    actions={this.actions()}
                    fullWidth={true}
                    maxWidth={"sm"}
                >
                    <Grid container spacing={3}>
                        <Grid item lg={12}>
                            <Text
                                required
                                label="Nota da Fase 2"
                                name="nota"
                                value={this.state.dialog.aluno.nota}
                                error={this.state.errors.nota}
                                onChange={this.handleChange}
                                helperText={this.state.helpers.nota}
                                inputProps={{
                                    maxLength: 2,
                                }}
                            />
                        </Grid>
                    </Grid>
                </Dialog>
            </>
        )
    }
}

export default Nota;