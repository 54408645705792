import React from 'react';
import clsx from 'clsx';

import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';

import Title from '../../components/Title/Title';

import useStyles from './Style';

const Taxas = () => {
    const classes = useStyles();
    return (
        <>
            <span id="pgto"></span>

            <Title id="anchor-04" className={classes.title}>
                4. DAS TAXAS DE INSCRIÇÃO 
            </Title>

            <Typography className={classes.text} component={'div'} variant="body2">
                <span className={classes.item}>4.1</span> O valor da taxa de inscrição está diretamente relacionado à MODALIDADE DE INSCRIÇÃO e à dependência administrativa da escola:

                <Grid 
                    container 
                    align='center' 
                    direction="row" 
                    justify="center"
                    alignItems="center"
                    className={classes.wrapper}
                >
                    <Grid item xs={12} sm={8} lg={6} xl={6} >
                        <TableContainer component="div">
                            <Table className={classes.table} size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.bolder} align="center">Dependência</TableCell>
                                        <TableCell className={classes.bolder} align="center">Modalidade</TableCell>
                                        <TableCell className={classes.bolder} align="center">Inscrição</TableCell>
                                        <TableCell className={classes.bolder} align="center">Medalha</TableCell>
                                        <TableCell className={classes.bolder} align="center">Pagamento</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell className={classes.bolder} align="center">Pública</TableCell>
                                        <TableCell className={classes.bolder} align="center">1</TableCell>
                                        <TableCell align="center">Gratuita</TableCell>
                                        <TableCell align="center">Não</TableCell>
                                        <TableCell align="center">Isenta</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className={classes.bolder} align="center">Pública</TableCell>
                                        <TableCell className={classes.bolder} align="center">2</TableCell>
                                        <TableCell align="center">Gratuita</TableCell>
                                        <TableCell align="center">Sim</TableCell>
                                        <TableCell align="center">Valores Tabela C</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className={classes.bolder} align="center">Privada</TableCell>
                                        <TableCell className={classes.bolder} align="center">3</TableCell>
                                        <TableCell align="center">Paga</TableCell>
                                        <TableCell align="center">Não</TableCell>
                                        <TableCell align="center">R$ 150,00</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className={classes.bolder} align="center">Privada</TableCell>
                                        <TableCell className={classes.bolder} align="center">4</TableCell>
                                        <TableCell align="center">Paga</TableCell>
                                        <TableCell align="center">Sim</TableCell>
                                        <TableCell align="center">R$ 150,00 + Valores Tabela F</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                            <small style={{ fontSize: '0.85em' }}>Tabela G – Modalidades de Inscrição</small>
                        </TableContainer>
                    </Grid>
                </Grid>
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>4.2</span> A inscrição da <span className={classes.bolder}>ESCOLA PÚBLICA</span> na <span className={classes.bolder}>MODALIDADE 1</span> (INSCRIÇÃO SEM PREMIAÇÃO) de inscrição, <span className={classes.bolder}>NÃO</span> envolve processo de pagamento e não gera taxa de inscrição. Para realizar a inscrição, 
                basta que a escola ou secretaria de educação, dentro do “PERÍODO PARA INSCRIÇÕES” estipulado no Calendário Oficial da 3ª Olimpíada Mirim – OBMEP, preencha por completo, na página da 
                3ª Olimpíada Mirim – OBMEP, <span className={classes.underline}>www.olimpiadamirim.obmep.org.br</span>, o formulário de inscrição.
            </Typography>
            
            <Typography className={classes.text} component={'div'} variant="body2">
                <span className={classes.item}>4.3</span> O valor da taxa de inscrição da <span className={classes.bolder}>ESCOLA PÚBLICA</span> na <span className={classes.bolder}>MODALIDADE 2</span> (INSCRIÇÃO COM PREMIAÇÃO), varia de acordo com quantidade de alunos(as) inscritos(as) por nível, conforme indicado 
                na Tabela H, abaixo:<br /><br />

                <Grid 
                    container 
                    align='center' 
                    direction="row" 
                    justify="center"
                    alignItems="center"
                    className={classes.wrapper}
                >
                    <Grid item sm={10} lg={10}>
                        <TableContainer component="div">
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                    <TableCell align="center" style={{ backgroundColor: '#CACACA' }}>Nível</TableCell>
                                        <TableCell align="center" className={classes.bolder} style={{ backgroundColor: '#CACACA' }}>Quantidade de Alunos Inscritos</TableCell>
                                        <TableCell align="center" className={classes.bolder} style={{ backgroundColor: '#B68D4A', color: '#FFFFFF' }}>Medalha - OURO</TableCell>
                                        <TableCell align="center" className={classes.bolder} style={{ backgroundColor: '#A3A2A0', color: '#FFFFFF' }}>Medalha - PRATA</TableCell>
                                        <TableCell align="center" className={classes.bolder} style={{ backgroundColor: '#A46628', color: '#FFFFFF' }}>Medalha - BRONZE</TableCell>
                                        <TableCell align="center" style={{ backgroundColor: '#CACACA' }}>Valor da inscrição <span className={classes.bolder}>POR NÍVEL</span></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow style={{ border: 1 }}>
                                    <TableCell align="center" component="th" scope="row" rowSpan={4} style={{ backgroundColor: '#FFE699' }}>MIRIM 1</TableCell>
                                        <TableCell align="center" className={classes.bolder}>1 a 60</TableCell>
                                        <TableCell align="center">1</TableCell>
                                        <TableCell align="center">3</TableCell>
                                        <TableCell align="center">6</TableCell>
                                        <TableCell align="center" className={classes.bolder}>R$ 200,00</TableCell>
                                    </TableRow>
                                    <TableRow style={{ border: 1 }}>
                                        <TableCell align="center" className={classes.bolder}>61 a 120</TableCell>
                                        <TableCell align="center">2</TableCell>
                                        <TableCell align="center">6</TableCell>
                                        <TableCell align="center">12</TableCell>
                                        <TableCell align="center" className={classes.bolder}>R$ 400,00</TableCell>
                                    </TableRow>
                                    <TableRow style={{ border: 1 }}>
                                        <TableCell align="center" className={classes.bolder}>121 a 180</TableCell>
                                        <TableCell align="center">3</TableCell>
                                        <TableCell align="center">9</TableCell>
                                        <TableCell align="center">18</TableCell>
                                        <TableCell align="center" className={classes.bolder}>R$ 600,00</TableCell>
                                    </TableRow>
                                    <TableRow style={{ border: 1 }}>
                                        <TableCell align="center" className={classes.bolder}>181 ou +</TableCell>
                                        <TableCell align="center">4</TableCell>
                                        <TableCell align="center">12</TableCell>
                                        <TableCell align="center">24</TableCell>
                                        <TableCell align="center" className={classes.bolder}>R$ 800,00</TableCell>
                                    </TableRow>
                                    <TableRow style={{ border: 1 }}>
                                        <TableCell align="center" component="th" scope="row" rowSpan={4} style={{ backgroundColor: '#FFCCFF' }}>MIRIM 2</TableCell>
                                        <TableCell align="center" className={classes.bolder}>1 a 60</TableCell>
                                        <TableCell align="center">1</TableCell>
                                        <TableCell align="center">3</TableCell>
                                        <TableCell align="center">6</TableCell>
                                        <TableCell align="center" className={classes.bolder}>R$ 200,00</TableCell>
                                    </TableRow>
                                    <TableRow style={{ border: 1 }}>
                                        <TableCell align="center" className={classes.bolder}>61 a 120</TableCell>
                                        <TableCell align="center">2</TableCell>
                                        <TableCell align="center">6</TableCell>
                                        <TableCell align="center">12</TableCell>
                                        <TableCell align="center" className={classes.bolder}>R$ 400,00</TableCell>
                                    </TableRow>
                                    <TableRow style={{ border: 1 }}>
                                        <TableCell align="center" className={classes.bolder}>121 a 180</TableCell>
                                        <TableCell align="center">3</TableCell>
                                        <TableCell align="center">9</TableCell>
                                        <TableCell align="center">18</TableCell>
                                        <TableCell align="center" className={classes.bolder}>R$ 600,00</TableCell>
                                    </TableRow>
                                    <TableRow style={{ border: 1 }}>
                                        <TableCell align="center" className={classes.bolder}>181 ou +</TableCell>
                                        <TableCell align="center">4</TableCell>
                                        <TableCell align="center">12</TableCell>
                                        <TableCell align="center">24</TableCell>
                                        <TableCell align="center" className={classes.bolder}>R$ 800,00</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                            <small style={{ fontSize: '0.85em' }}>Tabela H - Escolas Públicas – MODALIDADE 2 (INSCRIÇÃO COM PREMIAÇÃO)</small>
                        </TableContainer>
                    </Grid>
                </Grid>

                EXEMPLOS de Inscrição de Escola Pública na MODALIDADE 2 (INSCRIÇÃO COM PREMIAÇÃO):

                <ul className={classes.list}>
                    <li><span className={classes.item}>{"a)"}</span> Se a <span className={classes.bolder}>ESCOLA PÚBLICA</span> optou pela <span className={classes.bolder}>MODALIDADE 2</span> e inscreveu 57 alunos no MIRIM 1 e 45 alunos no MIRIM 2, o valor da inscrição será de R$ 400,00 (R$ 200,00 pelo MIRIM 1 e R$ 200,00 pelo MIRIM 2);</li>
                    <li><span className={classes.item}>{"b)"}</span> Se a <span className={classes.bolder}>ESCOLA PÚBLICA</span> optou pela <span className={classes.bolder}>MODALIDADE 2</span> e inscreveu 49 alunos no MIRIM 1 e 87 alunos no MIRIM 2, o valor da inscrição será de R$ 600,00 (R$ 200,00 pelo MIRIM 1 e R$ 400,00 pelo MIRIM 2);</li>
                    <li><span className={classes.item}>{"c)"}</span> Se a <span className={classes.bolder}>ESCOLA PÚBLICA</span> optou pela <span className={classes.bolder}>MODALIDADE 2</span> e inscreveu 180 alunos no MIRIM 1 e 245 alunos no MIRIM 2, o valor da inscrição será de R$ 1400,00 (R$ 600,00 pelo MIRIM 1 e R$ 800,00 pelo MIRIM 2).</li>
                </ul>
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>4.4</span> O valor da taxa de inscrição da <span className={classes.bolder}>ESCOLA PRIVADA</span> na <span className={classes.bolder}>MODALIDADE 3</span> (INSCRIÇÃO SEM PREMIAÇÃO) e de R$ 150,00 (cento e cinquenta reais), independentemente da quantidade de alunos(as) 
                inscritos(as) pela escola por Nível (MIRIM 1 – 2º e 3º anos do Ensino Fundamental e 4º e 5º anos do Ensino Fundamental).
            </Typography>

            <Typography className={classes.text} component={'div'} variant="body2">
                <span className={classes.item}>4.5</span> O valor da taxa de inscrição da <span className={classes.bolder}>ESCOLA PRIVADA</span> na <span className={classes.bolder}>MODALIDADE 4</span> (INSCRIÇÃO COM PREMIAÇÃO) é de R$ 150,00 (cento e cinquenta reais), acrescido de valor que variará de acordo com 
                a quantidade de alunos(as) inscritos(as) por nível, conforme disposto na Tabela I, abaixo:<br /><br />

                <Grid 
                    container 
                    align='center' 
                    direction="row" 
                    justify="center"
                    alignItems="center"
                    className={classes.wrapper}
                >
                    <Grid item sm={10} lg={10}>
                        <TableContainer component="div">
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center" style={{ backgroundColor: '#CACACA' }}>Nível</TableCell>
                                        <TableCell align="center" style={{ backgroundColor: '#CACACA' }}>Quantidade de Alunos Inscritos</TableCell>
                                        <TableCell align="center" className={classes.bolder} style={{ backgroundColor: '#B68D4A', color: '#FFFFFF' }}>OURO</TableCell>
                                        <TableCell align="center" className={classes.bolder} style={{ backgroundColor: '#A3A2A0', color: '#FFFFFF' }}>PRATA</TableCell>
                                        <TableCell align="center" className={classes.bolder} style={{ backgroundColor: '#A46628', color: '#FFFFFF' }}>BRONZE</TableCell>
                                        <TableCell align="center" className={classes.bolder} style={{ backgroundColor: '#CACACA' }}>R$150,00 + </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow style={{ border: 1 }}>
                                        <TableCell align="center" component="th" scope="row" rowSpan={4} style={{ backgroundColor: '#FFE699' }}>MIRIM 1</TableCell>
                                        <TableCell align="center" className={classes.bolder}>1 a 60</TableCell>
                                        <TableCell align="center">1</TableCell>
                                        <TableCell align="center">3</TableCell>
                                        <TableCell align="center">6</TableCell>
                                        <TableCell align="center" className={classes.bolder}>R$ 200,00</TableCell>
                                    </TableRow>
                                    <TableRow style={{ border: 1 }}>
                                        <TableCell align="center" className={classes.bolder}>61 a 120</TableCell>
                                        <TableCell align="center">2</TableCell>
                                        <TableCell align="center">6</TableCell>
                                        <TableCell align="center">12</TableCell>
                                        <TableCell align="center" className={classes.bolder}>R$ 400,00</TableCell>
                                    </TableRow>
                                    <TableRow style={{ border: 1 }}>
                                        <TableCell align="center" className={classes.bolder}>121 a 180</TableCell>
                                        <TableCell align="center">3</TableCell>
                                        <TableCell align="center">9</TableCell>
                                        <TableCell align="center">18</TableCell>
                                        <TableCell align="center" className={classes.bolder}>R$ 600,00</TableCell>
                                    </TableRow>
                                    <TableRow style={{ border: 1 }}>
                                        <TableCell align="center" className={classes.bolder}>181 ou +</TableCell>
                                        <TableCell align="center">4</TableCell>
                                        <TableCell align="center">12</TableCell>
                                        <TableCell align="center">24</TableCell>
                                        <TableCell align="center" className={classes.bolder}>R$ 800,00</TableCell>
                                    </TableRow>
                                    <TableRow style={{ border: 1 }}>
                                        <TableCell align="center" component="th" scope="row" rowSpan={4} style={{ backgroundColor: '#FFCCFF' }}>MIRIM 2</TableCell>
                                        <TableCell align="center" className={classes.bolder}>1 a 60</TableCell>
                                        <TableCell align="center">1</TableCell>
                                        <TableCell align="center">3</TableCell>
                                        <TableCell align="center">6</TableCell>
                                        <TableCell align="center" className={classes.bolder}>R$ 200,00</TableCell>
                                    </TableRow>
                                    <TableRow style={{ border: 1 }}>
                                        <TableCell align="center" className={classes.bolder}>61 a 120</TableCell>
                                        <TableCell align="center">2</TableCell>
                                        <TableCell align="center">6</TableCell>
                                        <TableCell align="center">12</TableCell>
                                        <TableCell align="center" className={classes.bolder}>R$ 400,00</TableCell>
                                    </TableRow>
                                    <TableRow style={{ border: 1 }}>
                                        <TableCell align="center" className={classes.bolder}>121 a 180</TableCell>
                                        <TableCell align="center">3</TableCell>
                                        <TableCell align="center">9</TableCell>
                                        <TableCell align="center">18</TableCell>
                                        <TableCell align="center" className={classes.bolder}>R$ 600,00</TableCell>
                                    </TableRow>
                                    <TableRow style={{ border: 1 }}>
                                        <TableCell align="center" className={classes.bolder}>181 ou +</TableCell>
                                        <TableCell align="center">4</TableCell>
                                        <TableCell align="center">12</TableCell>
                                        <TableCell align="center">24</TableCell>
                                        <TableCell align="center" className={classes.bolder}>R$ 800,00</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                            <small style={{ fontSize: '0.85em' }}>Tabela I - Escolas Privadas – MODALIDADE 4 (INSCRIÇÃO COM PREMIAÇÃO)</small>
                        </TableContainer>
                    </Grid>
                </Grid>

                EXEMPLOS de Inscrição de Escola Privada na MODALIDADE 4 (INSCRIÇÃO COM PREMIAÇÃO):

                <ul className={classes.list}>
                    <li><span className={classes.item}>{"a)"}</span> Se a <span className={classes.bolder}>ESCOLA PRIVADA</span> optou pela MODALIDADE 4 e inscreveu 57 alunos no MIRIM 1 e 45 alunos no MIRIM 2, o valor da inscrição será de R$ 150,00 + R$ 400,00 (R$ 200,00 pelo MIRIM 1 e R$ 200,00 pelo MIRIM 2), logo, o valor da taxa de inscrição para esta escola será de R$ 550,00;</li>
                    <li><span className={classes.item}>{"b)"}</span> Se a <span className={classes.bolder}>ESCOLA PRIVADA</span> optou pela MODALIDADE 4 e inscreveu 49 alunos no MIRIM 1 e 87 alunos no MIRIM 2, o valor da inscrição será de R$ 150,00 + R$ 600,00 (R$ 200,00 pelo MIRIM 1 e R$ 400,00 pelo MIRIM 2), logo, o valor da taxa de inscrição para esta escola será de R$ 750,00;</li>
                    <li><span className={classes.item}>{"c)"}</span> Se a <span className={classes.bolder}>ESCOLA PRIVADA</span> optou pela MODALIDADE 4 e inscreveu 180 alunos no MIRIM 1 e 245 alunos no MIRIM 2, o valor da inscrição será de R$ 150,00 + R$ 1400,00 (R$ 600,00 pelo MIRIM 1 e R$ 800,00 pelo MIRIM 2), logo, o valor da taxa de inscrição para esta escola será de R$ 1550,00.</li>
                </ul>
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.bolder}>PARÁGRAFO ÚNICO:</span><br />
                A premiação das escolas públicas e privadas inscritas na 3ª Olimpíada Mirim – OBMEP está diretamente relacionada à quantidade de alunos(as) inscritos(as) pela escola em cada nível (Mirim 1 – Alunos de 2º e 3º anos e Mirim 2 – Alunos de 4º e 5º anos do ensino fundamental), 
                conforme indicado nas Tabelas B, C, E, F, H, I e J dos itens 3.7, 3.10, 3.14, 3.17, 4.3, 4.5 e 16.1 e no Anexo II, <span className={clsx(classes.bolder, classes.underline)}>EXCETO</span> quando a quantidade de alunos(as) classificados(as), por nível, for inferior à quantidade de premiados(as). Nestes casos, o número de 
                certificados/medalhas de bronze será reduzido até que se alcance a quantidade de classificados(as). Excluídos(as) todos(as) os certificados/medalhas de bronze, caso a quantidade de classificados(as) ainda permaneça maior do que a quantidade de premiados(as) 
                prevista, a quantidade de certificados/medalhas de prata será reduzida até que o número de classificados(as) seja igual ao número de premiados(as).
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>4.6</span> A <span className={classes.bolder}>ESCOLA PRIVADA</span> que optar pela <span className={classes.bolder}>MODALIDADE 3</span> (INSCRIÇÃO SEM PREMIAÇÃO) ou pela <span className={classes.bolder}>MODALIDADE 4</span> (INSCRIÇÃO COM PREMIAÇÃO) deverá acessar a página restrita da escola na 
                Olimpíada Mirim – OBMEP (<span className={classes.underline}>www.olimpiadamirim.obmep.org.br</span>) para obtenção do boleto bancário, único meio disponível para pagamento da taxa de inscrição. O boleto para pagamento da taxa 
                de inscrição <span className={classes.bolder}>somente ficará disponível para emissão e pagamento a partir de 22/06/2024</span>, data posterior ao encerramento do prazo das inscrições, de modo que, durante o período de inscrição, 
                <span className={classes.bolder}>o boleto não estará disponível</span>. O boleto para pagamento da taxa de inscrição deverá ser quitado impreterivelmente até o dia <span className={classes.bolder}>28/06/2024</span>, não sendo possível o pagamento do mesmo após o vencimento.
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>4.7</span> Compete às <span className={classes.bolder}>ESCOLAS PÚBLICAS</span> que optarem pela MODALIDADE 2 (INSCRIÇÃO COM PREMIAÇÃO) observar atentamente os prazos de inscrição, de emissão do boleto e de pagamento da taxa de 
                inscrição, conforme estabelecido neste Regulamento, sob pena de não validação da inscrição na MODALIDADE 2 e consequente readequação da inscrição para a <span className={classes.bolder}>MODALIDADE 1</span>.
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>4.8</span> As inscrições das <span className={classes.bolder}>ESCOLAS PRIVADAS</span>, independente da modalidade escolhida, somente serão confirmadas após o processamento do pagamento pelo sistema bancário e a confirmação do 
                crédito, dentro do prazo estabelecido no Calendário Oficial da Olimpíada Mirim - OBMEP (Anexo I).
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>4.9</span> Compete às <span className={classes.bolder}>ESCOLAS PRIVADAS</span> observar atentamente os prazos de inscrição, de emissão do boleto e de pagamento da taxa de inscrição, conforme estabelecido neste Regulamento, 
                sob pena de não validação da inscrição.
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>4.10</span> Não haverá, sob qualquer hipótese, a concessão de isenção da taxa de inscrição para as <span className={classes.bolder}>ESCOLAS PRIVADAS</span>, ainda que sejam instituições filantrópicas.
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>4.11</span> As <span className={classes.bolder}>ESCOLAS PRIVADAS</span> conveniadas à rede pública que atuem exclusivamente com alunos regularmente matriculados em escolas públicas serão consideradas como públicas 
                em todas as etapas do processo desde que, dentro do prazo, apresentem declaração que comprove a informação, devendo inscrever-se como tal.
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>4.12</span> As <span className={classes.bolder}>ESCOLAS PRIVADAS</span> conveniadas com a rede pública que tiverem composição mista, isto é, alunos da rede pública e privada, serão consideradas como privadas 
                em todas as etapas do processo, inclusive para a premiação de seus alunos, devendo inscrever-se como tal.
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>4.13</span> As <span className={classes.bolder}>ESCOLAS PÚBLICAS</span> optantes da <span className={classes.bolder}>MODALIDADE 2</span> (INSCRIÇÃO COM PREMIAÇÃO) e as <span className={classes.bolder}>ESCOLAS PRIVADAS</span> e a deverão conferir atentamente as informações constantes no boleto, 
                respeitando o prazo estabelecido, antes da realização do pagamento do mesmo, verificando se os dados foram devidamente preenchidos e não apresentam erros. Caso seja identificado 
                algum erro no boleto, a escola deverá entrar em contato com a Central da Olimpíada Mirim – OBMEP para solicitar o cancelamento e a liberação para emissão de um novo boleto. O cancelamento 
                e a emissão de um novo boleto só serão possíveis dentro do prazo para emissão e pagamento do boleto, <span className={classes.bolder}>entre 22 e 28 de junho de 2024</span>.
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>4.14</span> Não serão aceitos pagamentos de taxa de inscrição de forma parcelada ou por outros meios, tais como, mas não se limitando a, transferências bancárias, PIX, depósitos, 
                dentre outros, devendo a quitação ser realizada exclusivamente por meio do boleto bancário emitido na forma dos itens 4.2 ao 4.14 deste Regulamento.
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>4.15</span> Após a efetivação do pagamento da taxa de inscrição, o valor não será devolvido, inclusive no caso de desistência da escola, com exceção, na hipótese exclusiva de cancelamento 
                da 3ª Olimpíada Mirim - OBMEP por decisão do IMPA.
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>4.16</span> O IMPA não se responsabilizará por qualquer pagamento não recebido por motivos de ordem técnica dos computadores e/ou de conexão com a internet, falhas de qualquer natureza, 
                feriados regionais, intercorrências bancárias, congestionamento das linhas de comunicação, indisponibilidade ou erro de instituições financeiras e entidades conveniadas no que se refere ao 
                processamento do pagamento da taxa de inscrição, bem como em relação a quaisquer outros fatores que impossibilitem a finalização da inscrição.
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>4.17</span> As normas adicionais para pagamento da <span className={classes.bolder}>TAXA DE INSCRIÇÃO</span> se encontram disponíveis no Anexo II deste Regulamento, devendo ser lidas com atenção.
            </Typography>
        </>
    )
}

export default Taxas;