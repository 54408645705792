import React from 'react';

import Switch from '@material-ui/core/Switch';
import FormControlLabel from "@material-ui/core/FormControlLabel";

export const SwitchOption = (props) => {
    return (
        <FormControlLabel
            control={
                <Switch
                    {...props}
                    checked={props.checked}
                    onChange={props.onChange}
                    name={props.name}
                    color="primary"
                />
            }
            label={props.label}
        />
    );
}

export default SwitchOption;