import React from 'react';

import Grid from '@material-ui/core/Grid';

import DateTimePicker from '../Inputs/DateTimePicker/DateTimePicker';
import Text from '../Inputs/Text/Text';
import Boolean from '../Inputs/Boolean/Boolean';

export const ContextDispatcher = (props) => {
  const size = props.children ? 11 : 12;

  return (
    <Grid container item sm={12} lg={4} >
      <Grid item sm={size} lg={size} >
        { props.context.type === "DATA" ? <DateTimePicker {...props} />
          : props.context.type === "BOOLEAN" ? <Boolean hiddenblank={true} {...props} />
          : props.context.type === "TEXTO" ? <Text {...props} />
          : props.context.type === "LONG" ? <Text {...props} />
          : null
        }
      </Grid>

      {props.children &&
        <Grid item sm={1} lg={1} style={{ alignContent: "center", textAlign: "center" }} >
          {props.children}
        </Grid>
      }
    </Grid>
  );
}

export default ContextDispatcher;