import clsx from 'clsx';
import React from 'react';

import Typography from '@material-ui/core/Typography';

import Title from '../../components/Title/Title';

import useStyles from './Style';

const MedidasSeguranca = () => {
    const classes = useStyles();
    return (
        <>
            <Title id="anchor-6" variant="h6" className={classes.subtitle}>
                <span className={classes.item}>6.</span> DURAÇÃO DO TRATAMENTO E MEDIDAS DE SEGURANÇA
            </Title>

            <Typography className={classes.text} variant="body2">
                Ainda, os dados pessoais tratados pelo IMPA são mantidos apenas por período indispensável para alcance das finalidades apresentadas nesse aviso de privacidade e por eventual período adicional quando
                necessário para cumprimento de obrigações legais.
            </Typography>
            <Typography className={classes.text} variant="body2">
                Os dados pessoais são mantidos em ambiente seguro e controlado pelo IMPA. Mais detalhes sobre as medidas de segurança adotadas pelo IMPA podem ser encontrados na Política de Segurança da Informação disponível no link
                <a href="https://impa.br/wp-content/uploads/2020/09/PSI_IMPA_v1.2.pdf" target="_blank" rel="noopener noreferrer" className={clsx(classes.pointer, classes.bolder)}> https://impa.br/wp-content/uploads/2020/09/PSI_IMPA_v1.2.pdf</a>.
            </Typography>
        </>
    )
}

export default MedidasSeguranca;