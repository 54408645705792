import API from './API';

const path = `materiais-extras`;

const MaterialExtraService = {
    // FILTROS
    filters: async filters => await API.filters(path, filters),

    // EXPORTAR
    export: async filters => await API.export(path, filters),

    // LISTAR TODOS
    list: async () => await API.list(path),

    // POR DATA
    porData: async filters => await API.filters(`${path}/por-data`, filters), 
    porDataExport: async filters => await API.export(`${path}/por-data`, filters), 

    // POR STATUS
    porStatus: async filters => await API.filters(`${path}/por-status`, filters), 
    porStatusExport: async filters => await API.export(`${path}/por-status`, filters),
}

export default MaterialExtraService;