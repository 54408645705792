import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import Alert from '@material-ui/lab/Alert';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import EuroOutlinedIcon from '@material-ui/icons/EuroOutlined';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import InscricaoService from '../../services/Inscricao';
import { errors } from '../../services/API';

import Title from '../../components/Title/Title';
import Text from '../../components/Inputs/Text/Text';
import Toast from "../../components/Toast/Toast";
import Loading from '../../components/Loading/Loading';

import Endereco from '../../forms/Endereco/Endereco';

import { ButtonSecondary } from '../../components/Button/Button';
import { ButtonSave } from '../../forms/Buttons/ButtonsForm';

import {
    isBlank,
    isBlankHelperText,
    isNameCompoundInvalid,
    isNameCompoundHelperText,
    isCPFInvalid,
    isCPFHelperText,
    isCNPJInvalid,
    isCNPJHelperText,
    isMailInvalid,
    isMailHelperText,
} from '../../helper/ValidationHelper';
import { cpfMask, cnpjMask } from '../../helper/MaskHelper';
import { filterPersistence } from '../../helper/ParseStateHelper';

import { Layout } from '../../layouts/private/Private';

export class DadosFatura extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fatura: {
                mecCode: !isNaN(this.props.match.params.id) ? this.props.match.params.id : '',
                nomeEscola: '',
                cpfOuCnpj: "CNPJ",
                cpf: '',
                cnpj: '',
                nome: '',
                email: '',
            },
            errors: {
                cpf: false,
                cnpj: false,
                nome: false,
                email: false,
            },
            helpers: {
                cpf: null,
                cnpj: null,
                nome: null,
                email: null,
            },
            loadingButtonSave: false,
            loading: this.props.match.params.id ? true : false,
            history: {
                path: null,
                state: null
            }
        };
    }

    // INPUTS
    handleChange = async e => {
        this.setState({
            fatura: {
                ...this.state.fatura,
                [e.target.name]: e.target.name === 'cpf' ? cpfMask(e.target.value) : e.target.name === 'cnpj' ? cnpjMask(e.target.value) : e.target.value
            },
            errors: { ...this.state.errors, [e.target.name]: false },
            helpers: { ...this.state.helpers, [e.target.name]: false }
        });
    };

    resetaFormulario = async e => {
        this.setState({
            fatura: {
                mecCode: this.state.fatura.mecCode,
                nomeEscola: this.state.fatura.nomeEscola, 
                nome : '',
                email : "",
                cpf: "",
                cnpj: "",
                cpfOuCnpj : e.target.value
            },
            loading: false
        });
    };

    // DECLARAÇÃO DE REFERÊNCIA DOS COMPONENTES
    setToast = t => this.Toast = t;
    setEndereco = e => this.Endereco = e;

    // VALIDAÇÕES
    isValid = () => {
        if (((isBlank(this.state.fatura.cpf) || isCPFInvalid(this.state.fatura.cpf)) && this.state.fatura.cpfOuCnpj === "CPF") 
                || ((isBlank(this.state.fatura.cnpj) || isCNPJInvalid(this.state.fatura.cnpj)) && this.state.fatura.cpfOuCnpj === "CNPJ") 
                || isBlank(this.state.fatura.nome) || isNameCompoundInvalid(this.state.fatura.nome) || isBlank(this.state.fatura.email) || isMailInvalid(this.state.fatura.email)) {
            this.setState({
                errors: {
                    cpf: (isBlank(this.state.fatura.cpf) || isCPFInvalid(this.state.fatura.cpf)) && this.state.fatura.cpfOuCnpj === "CPF" ? true : false,
                    cnpj: (isBlank(this.state.fatura.cnpj) || isCNPJInvalid(this.state.fatura.cnpj)) && this.state.fatura.cpfOuCnpj === "CNPJ" ? true : false,
                    nome: isBlank(this.state.fatura.nome) || isNameCompoundInvalid(this.state.fatura.nome) ? true : false,
                    email: isBlank(this.state.fatura.email) || isMailInvalid(this.state.fatura.email) ? true : false,
                },
                helpers: {
                    cpf: this.state.fatura.cpfOuCnpj === "CPF" ? isBlank(this.state.fatura.cpf) ? isBlankHelperText() : isCPFInvalid(this.state.fatura.cpf) ? isCPFHelperText() : null : null,
                    cnpj: this.state.fatura.cpfOuCnpj === "CNPJ" ? isBlank(this.state.fatura.cnpj) ? isBlankHelperText() : isCNPJInvalid(this.state.fatura.cnpj) ? isCNPJHelperText() : null : null,
                    nome: isBlank(this.state.fatura.nome) ? isBlankHelperText() : isNameCompoundInvalid(this.state.fatura.nome) ? isNameCompoundHelperText() : null,
                    email: isBlank(this.state.fatura.email) ? isBlankHelperText() : isMailInvalid(this.state.fatura.email) ? isMailHelperText() : null,
                },
            });
            return false;
        }
        return true;
    }

    // SALVAR
    handleSubmit = async () => {
        this.setState({ loadingButtonSave: true });

        const formIsValid = this.isValid();
        const formEnderecoIsValid = this.Endereco.isValid();
        
        if (!formIsValid || !formEnderecoIsValid) {
            this.setState({ loadingButtonSave: false });
            return
        }

        const fatura = {
            cpf: this.state.fatura.cpfOuCnpj === "CPF" ? this.state.fatura.cpf : null,
            cnpj: this.state.fatura.cpfOuCnpj === "CNPJ" ? this.state.fatura.cnpj : null,
            nomeFaturador: this.state.fatura.nome.toUpperCase().trim().replaceAll(/\s+/g, " "),
            emailFaturador: this.state.fatura.email.trim().replaceAll(/\s+/g, " "),

            enderecoCep: this.Endereco.state.address.zipCode,
            enderecoLogradouro: this.Endereco.state.address.address.toUpperCase().trim(),
            enderecoNumero: this.Endereco.state.address.number,
            enderecoComplemento: this.Endereco.state.address.complement,
            enderecoBairro: this.Endereco.state.address.district.toUpperCase().trim(),
            enderecoMunicipioRegiao: this.Endereco.state.address.city,
        }

        InscricaoService.salvarDadosFatura(this.state.fatura.mecCode, fatura)
            .then(resultado => {
                window.location.href = resultado.data;
            })
            .catch(error => {
                const e = errors(error);
                this.Toast.setState({
                    message: {
                        message: e.message,
                        type: e.type,
                        open: true
                    }
                })
            })
            .finally(() => this.setState({ loadingButtonSave: false }))
    }

    async componentDidMount() {
        this.setState({ history: filterPersistence(this.props.location) });

        if (this.state.fatura.mecCode) {
            InscricaoService.buscarDadosFatura(this.state.fatura.mecCode)
                .then(res => {
                    this.setState({
                        fatura: {
                            mecCode: res.data.cdMecEscola,
                            nomeEscola: res.data.nomeEscola,
                            nome : res.data.nomeFaturador,
                            email : res.data.emailFaturador,
                            cpfOuCnpj: res.data.cpf ? "CPF" : "CNPJ",
                            cpf: res.data.cpf ? cpfMask(res.data.cpf) : '',
                            cnpj: res.data.cnpj ? cnpjMask(res.data.cnpj): '',
                        },
                        loading: false
                    });                    
                    
                    this.Endereco.findCities(res.data.enderecoEstado, res.data.enderecoMunicipioRegiao);

                    this.Endereco.setState({
                        address: {
                            zipCode: res.data.enderecoCep,
                            address: res.data.enderecoLogradouro,
                            number: res.data.enderecoNumero,
                            complement: res.data.enderecoComplemento,
                            district: res.data.enderecoBairro,
                            city: res.data.enderecoCidade,
                            UF: res.data.enderecoEstado,
                        }
                    })
                 
                })
                .catch(err => {
                    this.Toast.setState({
                        message: {
                            message: "Não Foi Possível Buscar os Dados de Pagamento.",
                            type: 'error',
                            open: true
                        }
                    })

                    this.setState({ loading: false })
                })
        }
    }

    handleBack = () => {
        this.props.history.push({
            pathname: `${this.state.history.path}`,
            state: {
                history: {
                    state: this.state.history.state
                }
            }
        });
    }

    render() {
        const page = 'Confirmação de Dados da Fatura (Nota Fiscal)';

        return (
            <Layout>
                <Toast parentRef={this.setToast} />

                <Title>{page}</Title>
                <Title variant="h6">{this.state.fatura.nomeEscola}</Title>

                {this.state.loading ?
                    <Loading />
                :
                    <>
                        <Grid container >
                            <Grid item sm={12} lg={5}>
                                <Alert severity="warning">
                                    Caso sua escola <b>NÃO</b> possua <b>CNPJ</b>, altere o campo de Tipo de Faturador para Pessoa Física e fature a partir de um <b>CPF</b>.
                                </Alert>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item sm={4} lg={4}>
                                <FormControl component="div" style={{ marginTop: 20, display: 'flex', flexDirection: 'row' }}>
                                    <FormLabel style={{ alignSelf: 'center', marginRight: 20 }} required component="legend" >Tipo de Faturador:</FormLabel>
                                    <RadioGroup
                                        row
                                        required
                                        aria-label="Tipo de Faturador"
                                        name="cpfOuCnpj"
                                        value={this.state.fatura.cpfOuCnpj}
                                        onChange={(e) => {this.handleChange(e); this.resetaFormulario(e);}}
                                    >
                                        <FormControlLabel style={{ marginRight: 30 }} value="CPF" control={<Radio />} label="Pessoa Física" />
                                        <FormControlLabel style={{ marginRight: 30 }} value="CNPJ" control={<Radio />} label="Pessoa Jurídica" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid container spacing={3}>
                            { this.state.fatura.cpfOuCnpj === "CPF" ?
                                <Grid item lg={2}>
                                    <Text
                                        required
                                        name="cpf"
                                        label="CPF"
                                        value={this.state.fatura.cpf}
                                        error={this.state.errors.cpf}
                                        onChange={this.handleChange}
                                        helperText={this.state.helpers.cpf}
                                    />
                                </Grid>
                            : null }
                            
                            { this.state.fatura.cpfOuCnpj === "CNPJ" ?
                                <Grid item lg={2}>
                                    <Text
                                        required
                                        name="cnpj"
                                        label="CNPJ"
                                        value={this.state.fatura.cnpj}
                                        error={this.state.errors.cnpj}
                                        onChange={this.handleChange}
                                        helperText={this.state.helpers.cnpj}
                                    />
                                </Grid> 
                            : null }
                    
                            <Grid item sm={5} lg={5}>
                                <Text
                                    required
                                    name="nome"
                                    label="Nome do Faturador"
                                    value={this.state.fatura.nome}
                                    error={this.state.errors.nome}
                                    onChange={this.handleChange}
                                    helperText={this.state.helpers.nome}
                                />
                            </Grid>

                            <Grid item sm={5} lg={5}>
                                <Text
                                    required
                                    name="email"
                                    label="E-mail do Faturador"
                                    value={this.state.fatura.email}
                                    error={this.state.errors.email}
                                    onChange={this.handleChange}
                                    helperText={this.state.helpers.email}
                                />
                            </Grid>
                        </Grid>

                        <Endereco parentRef={this.setEndereco} />
                        
                        <Grid container spacing={3} alignItems='flex-end' style={{ marginTop: 16 }}>
                            <Grid item sm={12} lg={12}>
                                <ButtonSave
                                    startIcon={<EuroOutlinedIcon />}
                                    onClick={this.handleSubmit}                        
                                    name="Ir para o Pagamento. ATENÇÃO: Confirme se os dados estão corretos, pois serão os mesmos da Nota Fiscal"
                                    loading={this.state.loadingButtonSave}
                                />
                            </Grid>
                            <Grid item sm={12} lg={12}>
                                <ButtonSecondary
                                    startIcon={<ArrowBackIcon />}
                                    style={{ margin: 4 }}
                                    onClick={this.props.history.goBack}
                                    name={"Voltar"}
                                />
                            </Grid>
                        </Grid>
                    </>
                }
            </Layout>
        )
    }
};

export default withRouter(DadosFatura);