import React, { useEffect, useState } from 'react';

import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import { Typography } from '@material-ui/core';

import DashboardService from '../../services/Dashboard';

export const Alertas = ({ mec }) => {
    const [alertas, setAlertas] = useState([]);

    useEffect(() => {
        const f = [{ field: 'id', value: mec }];
        
        DashboardService.getAlertas(f)
            .then(res => setAlertas([...res.data]));
    }, [mec]);

    return (
        <>
            { alertas.length > 0 &&
                <Grid item xs={12}>
                    { alertas.map(a =>
                        <Alert key={a.mensagem} severity={a.tipo} style={{ marginTop: '10px' }}>
                            <Typography variant="body1" dangerouslySetInnerHTML={{__html: a.mensagem}} />
                        </Alert>
                    )}
                </Grid>
            }
        </>              
    )
}

export default Alertas;