import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadContext } from '../../store/actions/Contexto';
import { isAfterInicioConsumoServicosImpressaoGrafica } from '../../helper/ContextHelper';

import Grid from '@material-ui/core/Grid';
import CollectionsBookmarkOutlinedIcon from '@material-ui/icons/CollectionsBookmarkOutlined';

import TipoDocumentoService from '../../services/TipoDocumento';
import { errors } from '../../services/API';

import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Title from '../../components/Title/Title';
import Table from '../../components/Table/Table';
import Toast from '../../components/Toast/Toast';

import { Layout } from '../../layouts/private/Private';

import { patternBooleanWithChip } from "../../variables/Enums/Range";

import { __TIPO_DOCUMENTO_EDITAR } from '../../security/RoleConfiguration';
import AuthorizedFunction from "../../security/AuthorizedFunction";
import AuthorizedElement from "../../security/AuthorizedElement";

import DialogTipoDocumento from "./DialogTipoDocumento";

export class TipoDocumento extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tipos: [],
            materialCalculado: true,
            loading: true,
        }
    }

    setToast = t => this.Toast = t;
    setDialogTipoDocumento = c => this.DialogTipoDocumento = c;

    labelSemPermissao = "Você não tem permissão para fazer isso";
    labelConsumoIniciado = "O Consumo do Serviço de Materiais para Impressão Já Foi Iniciado pela Gráfica";

    labelAlterarMaterial = id => !this.disabledAlterarMaterial(id) ? "Gerenciar Atribuições" : isAfterInicioConsumoServicosImpressaoGrafica(this.props.contexts) ? this.labelConsumoIniciado : this.labelSemPermissao;
    disabledAlterarMaterial = id => !AuthorizedFunction([__TIPO_DOCUMENTO_EDITAR]) || isAfterInicioConsumoServicosImpressaoGrafica(this.props.contexts);

    handleAlterarMaterial = async (id) => {
        const tipo = this.state.tipos.find(t => t.codigoDocumento === id);
        this.DialogTipoDocumento.setState({
            open: true,
            id: tipo.codigoDocumento,
            nome: tipo.nomeTipoDocumento,
            regular: tipo.materialRegular,
            extra: tipo.materialExtra,
            disabled: {
                regular: isAfterInicioConsumoServicosImpressaoGrafica(this.props.contexts),
                extra: isAfterInicioConsumoServicosImpressaoGrafica(this.props.contexts),
            },
            inicial : {
                regular: tipo.materialRegular,
                extra: tipo.materialExtra,
            },
        });
    }

    loadData = async () => {
        this.props.loadContext();

        this.setState({ loading: true });

        TipoDocumentoService.filters([])
            .then(res => {
                if (res.data) {
                    this.setState({ tipos: [...res.data], loading: false, });
                } else {
                    this.setState({ tipos: [], loading: false, });
                }
            })
            .catch(error => {
                const e = errors(error);
                this.Toast.setState({
                    message: {
                        message: e.message,
                        type: e.type,
                        open: true
                    }
                })

                this.setState({ loading: false });
            })
    }

    // COMPONENT DID MOUNT
    componentDidMount() { this.loadData(); }

    render () {
        const pageName = 'Tipos de Materiais';
        const links = [
            {
                path: null,
                name: 'Configurações'
            },
            {
                path: null,
                name: 'Materiais'
            },
        ];

        const columns = [
            { label: '#', name: 'codigoDocumento', func: null, key: true },
            { label: 'Nome do Material', name: 'nomeTipoDocumento', func: null, key: false },
            { label: 'Envelope', name: 'nomeEnvelope', func: null, key: false },
            { label: 'Material Regular', name: 'materialRegular', func: patternBooleanWithChip, key: false },
            { label: 'Material Extra', name: 'materialExtra', func: patternBooleanWithChip, key: false },
        ];

        const actions = [
            {
                name: this.labelAlterarMaterial,
                func: this.handleAlterarMaterial,
                icon: <CollectionsBookmarkOutlinedIcon />,
                disabled: this.disabledAlterarMaterial,
            },
        ];

        return (
            <Layout>
                <Toast parentRef={this.setToast} />
                <Grid container >
                    <Grid item xs={12}>
                        <Breadcrumbs links={links} active={pageName} />
                    </Grid>
                </Grid>
                <Title>{pageName}</Title>

                <Grid container >
                    <Grid item xs={12}>
                        <Table
                            columns={columns}
                            tableData={this.state.tipos}
                            actions={actions}
                            page={null}
                            sort={null}
                            rowsPerPage={null}
                            loading={this.state.loading}
                        />
                    </Grid>
                </Grid>

                {!isAfterInicioConsumoServicosImpressaoGrafica(this.props.contexts) &&
                    <AuthorizedElement roles={[__TIPO_DOCUMENTO_EDITAR]}>
                        <DialogTipoDocumento
                            parentRef={this.setDialogTipoDocumento}
                            handleClose={this.loadData}
                        />
                    </AuthorizedElement>
                }
            </Layout>
        )
    }
}

const mapStateToProps = state => ({ contexts: state.contexts });
const mapDispatchToProps = dispatch => (bindActionCreators({ loadContext }, dispatch));

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TipoDocumento));