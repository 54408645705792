import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadContext } from '../../store/actions/Contexto';
import { isBeforeDataInicioPagamento } from '../../helper/ContextHelper';

import Grid from '@material-ui/core/Grid';

import SelectOption from '../../components/Inputs/Select/Select';

import { optionsAdminDep, optionsAdminDepWithoutFederal } from '../../variables/Enums/DependenciaAdministrativa';
import { optionsConvenio, optionsTipoConvenio } from '../../variables/Enums/ConvenioEnum';

import { 
    isBlank, 
    isBlankHelperText 
} from '../../helper/ValidationHelper';
import { Alert } from '@material-ui/lab';
import { Typography } from '@material-ui/core';
import Boolean from '../../components/Inputs/Boolean/Boolean';
import Text from '../../components/Inputs/Text/Text';

import { cnpjMask } from '../../helper/MaskHelper';

export class DependenciaAdministrativa extends Component {
    constructor(props) {
        super(props);
        props.parentRef(this);
        this.state = {
            adminDep: '',
            possuiConvenio : false,
            depConvenio : '',
            tipoConvenio : '',
            cnpj : '',
            errors: {
                adminDep: false,
                possuiConvenio: false,
                depConvenio: false,
                tipoConvenio: false,
                cnpj: false,
            },
            helpers: {
                adminDep: null,
                possuiConvenio: null,
                depConvenio: null,
                tipoConvenio: null,
                cnpj: null
            },
            disabled: {
                adminDep: false,
                possuiConvenio: false,
                depConvenio: false,
                tipoConvenio: false,
                cnpj: false,
            }
        }
        this.isValid = this.isValid.bind(this);
    }

    // INPUTS
    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.name === 'cnpj' ? cnpjMask(e.target.value) : e.target.value,
            errors: {...this.state.errors, [e.target.name]: false },
            helpers: {...this.state.helpers, [e.target.name]: null },
            disabled: {...this.state.disabled }
        }, () => {
            if (this.props.onChange)
                this.props.onChange(this.state);
        });
    }  

    handleChangePossuiConvenio = e => {
        this.setState({
            possuiConvenio: e.target.value,
            depConvenio: '',
            tipoConvenio: '',
            errors: {...this.state.errors, [e.target.name]: false },
            helpers: {...this.state.helpers, [e.target.name]: null },
            disabled: {...this.state.disabled }
        }, () => {
            if (this.props.onChange)
                this.props.onChange(this.state);
        });
    }

    handleChangeDepConvenio = e => {
        this.setState({
            possuiConvenio: "true",
            depConvenio: e.target.value,
            tipoConvenio: '',
            errors: {...this.state.errors, [e.target.name]: false },
            helpers: {...this.state.helpers, [e.target.name]: null },
            disabled: {...this.state.disabled }
        }, () => {
            if (this.props.onChange)
                this.props.onChange(this.state);
        });
    }

    // VALIDAÇÕES
    isValid = () => {
        if ( isBlank(this.state.adminDep) 
                || (this.state.adminDep === "PRIVADA" && isBlank(this.state.possuiConvenio))  
                || (this.state.adminDep === "PRIVADA" && isBlank(this.state.cnpj))  
                || (this.state.adminDep === "PRIVADA" && this.state.possuiConvenio === true  &&  isBlank(this.state.depConvenio))  
                || (this.state.adminDep === "PRIVADA" && this.state.possuiConvenio === true  &&  isBlank(this.state.tipoConvenio))  
        ) {
            this.setState({ 
                errors: {
                    adminDep: isBlank(this.state.adminDep) ? true : false,
                    possuiConvenio: this.state.adminDep === "PRIVADA" && isBlank(this.state.possuiConvenio) ? true : false,
                    depConvenio: this.state.adminDep === "PRIVADA" && this.state.possuiConvenio === true && isBlank(this.state.depConvenio) ? true : false,
                    tipoConvenio: this.state.adminDep === "PRIVADA" && this.state.possuiConvenio === true && isBlank(this.state.tipoConvenio) ? true : false,
                    cnpj: this.state.adminDep === "PRIVADA" && isBlank(this.state.cnpj) ? true : false
                },
                helpers: {
                    adminDep: isBlank(this.state.adminDep) ? isBlankHelperText() : null,
                    possuiConvenio:  this.state.adminDep === "PRIVADA" && isBlank(this.state.possuiConvenio) ? isBlankHelperText() : null,
                    depConvenio: this.state.adminDep === "PRIVADA" && this.state.possuiConvenio === true && isBlank(this.state.depConvenio) ?  isBlankHelperText() : null,
                    tipoConvenio: this.state.adminDep === "PRIVADA" && this.state.possuiConvenio === true && isBlank(this.state.tipoConvenio) ?  isBlankHelperText() : null,
                    cnpj: this.state.adminDep === "PRIVADA" && isBlank(this.state.cnpj) ? isBlankHelperText() : null,
                },
                disabled: {...this.state.disabled }
            });
            return false;
        }
        return true;
    }

    async componentDidMount() {
        await this.props.loadContext();

        if (!this.props.create && !isBeforeDataInicioPagamento(this.props.contexts)) {
            this.setState({
                ...this.state,
                disabled: {
                    adminDep: true,
                    possuiConvenio: true,
                    depConvenio: true,
                    tipoConvenio: true,
                    cnpj: true,
                }
            })
        }
    }

    render () {
        return (
            <>
                <Grid item container spacing={3}>
                    <Grid item sm={4} lg={2}>
                        <SelectOption
                            required
                            label="Dep. Administrativa"
                            name='adminDep'
                            value={this.state.adminDep}
                            error={this.state.errors.adminDep}
                            onChange={(e) => this.handleChange(e)}
                            options={this.props.withFederal ? optionsAdminDep : optionsAdminDepWithoutFederal}
                            hiddenblank="true"
                            helperText={this.state.helpers.adminDep}
                            disabled={this.state.disabled.adminDep}
                        />
                    </Grid>
                    <Grid item sm={4} lg={2}>
                        <Text
                            required={this.state.adminDep === 'PRIVADA'}
                            label="CNPJ da Escola"
                            name='cnpj'
                            value={this.state.cnpj}
                            error={this.state.adminDep === 'PRIVADA' ? this.state.errors.cnpj : null}
                            onChange={(e) => this.handleChange(e)}
                            helperText={this.state.adminDep === 'PRIVADA' ? this.state.helpers.cnpj : null}
                            disabled={this.state.disabled.cnpj}
                        />
                    </Grid>
                </Grid>

                { this.state.adminDep === 'PRIVADA' && 
                    <>
                        <Grid item container spacing={3}>
                            <Grid item sm={3} lg={3}>
                                <Boolean
                                    label="Sua escola é convêniada?"
                                    name='possuiConvenio'
                                    value={this.state.possuiConvenio}
                                    error={this.state.errors.possuiConvenio}
                                    onChange={(e) => this.handleChangePossuiConvenio(e)}
                                    hiddenblank="true"
                                    helperText={this.state.helpers.possuiConvenio}
                                    disabled={this.state.disabled.possuiConvenio}
                                />
                            </Grid>
                            <Grid item sm={9} lg={9}>
                                <Alert severity="warning" style={{ marginTop: '10px' }}>
                                    <Typography variant="body1">
                                        Escolas conveniadas - são instituições/escolas privadas ou filantrópicas que se tornam parceiras do município/estado, por meio de convênio, com o intuito de complementar as vagas oferecidas no sistema público.
                                    </Typography>
                                </Alert> 
                            </Grid>
                        </Grid>
                        
                        { this.state.possuiConvenio === "true" && 
                            <Grid item container spacing={3}>
                                <Grid item sm={6} lg={6}>
                                    <SelectOption
                                        required
                                        label="Dependência de Convênio"
                                        name='depConvenio'
                                        value={this.state.depConvenio}
                                        error={this.state.errors.depConvenio}
                                        onChange={(e) => this.handleChangeDepConvenio(e)}
                                        options={optionsConvenio}
                                        hiddenblank="true"
                                        helperText={this.state.helpers.depConvenio}
                                        disabled={this.state.disabled.depConvenio}
                                    />
                                </Grid>

                                { (this.state.depConvenio === 'PARCIAL' || this.state.depConvenio === "TOTAL") && 
                                    <Grid item sm={6} lg={6}>
                                        <SelectOption
                                            required
                                            label="Informe a Dependência Administrativa dos alunos"
                                            name='tipoConvenio'
                                            value={this.state.tipoConvenio}
                                            error={this.state.errors.tipoConvenio}
                                            onChange={(e) => this.handleChange(e)}
                                            options={optionsTipoConvenio}
                                            hiddenblank="true"
                                            helperText={this.state.helpers.tipoConvenio}
                                            disabled={this.state.disabled.tipoConvenio}
                                        />
                                    </Grid> 
                                }
                            </Grid> 
                        }
                    </> 
                }
            </>
        )
    }
}

const mapStateToProps = state => ({ contexts: state.contexts });
const mapDispatchToProps = dispatch => (bindActionCreators({ loadContext }, dispatch));

export default connect(mapStateToProps, mapDispatchToProps)(DependenciaAdministrativa);