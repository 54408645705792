import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';

import Table, { DataTableLineTotals as Totals } from '../../components/Table/Table';

export class DadosInscricoes extends Component {
    constructor (props) {
        super(props)
        props.parentRef(this);
        this.state = {
            inscricoes: [],
        }
    }

    sumEscolas = () => this.state.inscricoes.reduce((total, i) => total + i.escolas, 0);
    sumAlunosPrevistosMirim1 = () => this.state.inscricoes.reduce((total, i) => total + i.mirim1, 0);
    sumAlunosPrevistosMirim2 = () => this.state.inscricoes.reduce((total, i) => total + i.mirim2, 0);
    sumAlunos = () => this.state.inscricoes.reduce((total, i) => total + i.alunos, 0);

    render () {
        const columns = [
            { label: 'Data de Inscrição', name: 'dataFormatada', func: null, key: false, width: '20', },
            { label: 'Total de Escolas', name: 'escolas', func: null, key: false, width: '20', },
            { label: 'Mirim 1 - Alunos', name: 'mirim1', func: null, key: false, width: '20', },
            { label: 'Mirim 2 - Alunos', name: 'mirim2', func: null, key: false, width: '20', },
            { label: 'Total de Alunos', name: 'alunos', func: null, key: false, width: '20', },
        ];
        
        const actions = [];

        const totals = [
            { column: 0, value: "Total", width: '20', },
            { column: 1, value: this.sumEscolas(), width: '20', },
            { column: 2, value: this.sumAlunosPrevistosMirim1(), width: '20', },
            { column: 3, value: this.sumAlunosPrevistosMirim2(), width: '20', },
            { column: 4, value: this.sumAlunos(), width: '20', },
        ];

        return (
            <Grid container spacing={3} style={{ marginTop: this.props.marginTop ?? 8 }}>
                <Grid item xs={12}>
                    <Table              
                        columns={columns}
                        tableData={this.state.inscricoes}
                        actions={actions}
                        page={null}
                        rowsPerPage={null}
                        sort={null}
                        fixedHeader={true}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Totals lineData={totals} columns={columns} />
                </Grid>
            </Grid>
        )
    }
}

export default DadosInscricoes;