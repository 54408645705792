export const PAGE = 0;
export const SIZE = 10;
export const ASC = 'asc';
export const DESC = 'desc';
export const TOTAL_ELEMENTS = 10;
export const TOTAL_PAGES = 5;

export const verifyPagingAndSorting = ({ filters, filtered = false, page = PAGE, size = SIZE, sort = [] }) => {
    filters.push({ field: 'page', value: filtered ? page : PAGE });
    filters.push({ field: 'size', value: size });
    sort.forEach(s => filters.push({ field: 'sort', value: `${s.orderBy},${s.order}` }));

    return filters;
}