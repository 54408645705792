import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadContext } from '../../store/actions/Contexto';
import { isAfterInicioConsumoServicosImpressaoGrafica } from '../../helper/ContextHelper';

import Grid from '@material-ui/core/Grid';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import InboxIcon from '@material-ui/icons/Inbox';
import VpnKeyOutlinedIcon from "@material-ui/icons/VpnKeyOutlined";
import Zoom from "@material-ui/core/Zoom";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import { amber } from "@material-ui/core/colors";

import CoordenadorService from '../../services/Coordenador';
import ImportacaoService from '../../services/Importacao';
import { errors } from '../../services/API';

import {removePagingAndSorting, verifyPersistence} from '../../helper/PaginationHelper';
import { filterPersistence } from '../../helper/ParseStateHelper';

import CoordenadorFilter from '../../forms/Coordenador/CoordenadorFilter';
import { ButtonExport } from '../../forms/Buttons/ButtonsExport';
import DialogChangePassword from "../../forms/Dialog/DialogChangePassword";
import DialogMaterialExtra from "./DialogMaterialExtra";

import Table from '../../components/Table/Table';
import Toast from '../../components/Toast/Toast';
import Switch from '../../components/Inputs/Switch/Switch';
import { ButtonPrimary } from "../../components/Button/Button";
import {
    verifyPagingAndSorting,
    PAGE,
    SIZE,
    ASC,
    TOTAL_ELEMENTS,
    TOTAL_PAGES,
} from '../../components/Table/Utils';

import {
    __COORDENADOR_EDITAR_MATERIAL_EXTRA,
    __COORDENADOR_EXPORTAR,
    __COORDENADOR_EDITAR_SENHA
} from '../../security/RoleConfiguration';
import AuthorizedElement from '../../security/AuthorizedElement';
import AuthorizedFunction from "../../security/AuthorizedFunction";

import { patternCoordenadores } from '../../variables/Enums/Coordenador';
import CircularProgress from "@material-ui/core/CircularProgress";

export class Coordenador extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: PAGE,
            size: SIZE,
            totalElements: TOTAL_ELEMENTS,
            totalPages: TOTAL_PAGES,
            sort: [{ orderBy: 'regiao.cdRegiao', order: ASC }],
            defaultOrderBy: 'regiao.cdRegiao',
            filtered: false,
            filters: [],
            coordenadores: [],
            checksCoords: [],
            mostrarMateriais: false,
            loading: true,
            loadingImport: false,
            history: {
                path: null,
                state: null
            }
        }

        this.state = verifyPersistence(this.state, this.props.location);
    }

    handleChangeCoordenadores = keys => {
        const coordenadores = this.state.coordenadores.filter( c => keys.find(k => k === c.id));
        this.setState({
            checksCoords: [...coordenadores]
        })
    }

    handleChangeSwitch = e => {
        this.setState({ ...this.state, [e.target.name]: e.target.checked });
    }

    // FECHAR TOAST
    setToast = t => this.Toast = t;
    setDialogChangePassword = f => this.DialogChangePassword = f;
    setDialogMaterialExtra = m => this.DialogMaterialExtra = m;
    setCoordenadorFilter = e => this.CoordenadorFilter = e;

    labelSemPermissao = "Você não tem permissão para fazer isso";
    labelConsumoIniciado = "O Consumo do Serviço de Materiais para Impressão Já Foi Iniciado pela Gráfica";

    // LABELS
    labelEditarMaterialExtra = id => !this.disabledEditarMaterialExtra(id) ? "Gerenciar Material Extra" : isAfterInicioConsumoServicosImpressaoGrafica(this.props.contexts) ? this.labelConsumoIniciado : this.labelSemPermissao;
    labelAtualizarSenha = id => !this.disabledAtualizarSenha(id) ? "Alterar Senha" : this.labelSemPermissao;

    // DISABLES
    disabledEditarMaterialExtra = id => !AuthorizedFunction([__COORDENADOR_EDITAR_MATERIAL_EXTRA]) || isAfterInicioConsumoServicosImpressaoGrafica(this.props.contexts);
    disabledAtualizarSenha = id => !AuthorizedFunction([__COORDENADOR_EDITAR_SENHA]);

    // ABRIR MODAL DE EDITAR MATERIAL EXTRA
    handleEditarMaterialExtra = id => {
        this.props.history.push({
            pathname: `/admin/coordenadores/materiaisExtras`,
            state: {
                coordenadores: id ? [ this.state.coordenadores.find(coord => coord.id === id) ] : this.state.checksCoords,
                history: {
                    path: `/admin/coordenadores`,
                    state: this.state,
                }
            }
        });
    }

    // ABRIR MODAL DE ALTERAR SENHA
    handleOpenAtualizarSenhaClick = id => {
        const coord = this.state.coordenadores.find(coord => coord.id === id);
        this.DialogChangePassword.setState({ open: true, id: coord.id, login: coord.login });
    }

    // ABRIR MODAL DE ATRIBUIR MATERIAL EXTRA
    openDialogMaterialExtra = () => {
        this.DialogMaterialExtra.setState({ open: true, coordenadores: this.state.checksCoords, tipos: this.props.tipos, });
    }

    // EXPORTAÇÂO
    handleExportPlan = () => {
        this.CoordenadorFilter.setState({ filter: true, exportPlan: true, }, () => this.CoordenadorFilter.handleFilterApply());

        this.Toast.setState({
            message: {
                message: "A Planilha está sendo gerada para Exportação.",
                type: 'info',
                open: true,
                loading: true,
                autohide: false,
            }
        })
    }

    handleImport = () => {
        this.setState({ loadingImport: true });

        ImportacaoService.importarCoordenador()
            .then(res => {
                this.Toast.setState({
                    message: {
                        message: "Importação de Coordenadores Realizada com Sucesso.",
                        type: "success",
                        open: true
                    }
                })

                this.loadData();
            })
            .catch(error => {
                const e = errors(error);
                this.Toast.setState({
                    message: {
                        message: e.message,
                        type: e.type,
                        open: true
                    }
                })
            })
            .finally(() => this.setState({ loadingImport: false }))
    }

    loadData = async () => {
        await this.props.loadContext();

        this.setState({ history: filterPersistence(this.props.location) });

        let f = verifyPagingAndSorting(this.state);

        this.setState({
            filters: [...f],
            filtered: true,
            loading: true
        });

        f = f.filter(f => f.field !== 'tipo');
        f.push({ field: 'tipo', value: "COORDENADOR" });

        CoordenadorService.filters(f)
            .then(res => {
                if (res.data.content) {
                    this.setState({
                        filters: f,
                        coordenadores: [...patternCoordenadores(res.data.content)],
                        page: res.data.number,
                        size: res.data.size,
                        totalElements: res.data.totalElements,
                        totalPages: res.data.totalPages,
                        loading: false,
                    });
                } else {
                    this.setState({
                        filters: f,
                        coordenadores: [],
                        page: res.data.number,
                        size: res.data.size,
                        totalElements: res.data.totalElements,
                        totalPages: res.data.totalPages,
                        loading: false,
                    });
                }
            })
            .catch(error => {
                const e = errors(error);
                this.Toast.setState({
                    message: {
                        message: e.message,
                        type: e.type,
                        open: true
                    }
                })
            })
            .finally(() => this.setState({ loading: false }))
    }

    // FILTROS
    filterData = (filters, isActive = true) => this.setState({ filtered: isActive, filters: [...removePagingAndSorting(filters)] }, () => this.loadData());

    // PÁGINA
    handlePage = page => this.setState({ page: page }, () => this.filterData(this.state.filters));

    // LINHAS POR PÁGINA
    handleRowsPerPage = (size, page) => this.setState({ size: size, page: page }, () => this.filterData(this.state.filters));

    // ORDENAÇÃO
    handleSort = (orderBy, order) => {
        if (this.state.sort.find(s => s.orderBy === orderBy)) {
            let newSort = this.state.sort.filter(s => s.orderBy !== orderBy);
            newSort.splice(this.state.sort.indexOf(this.state.sort.find(s => s.orderBy === orderBy)), 0, { orderBy: orderBy, order: order })
            this.setState({...this.state, sort: [...newSort], filtered: true });
        } else {
            this.setState({ ...this.state, sort: [...this.state.sort.filter(s => s.orderBy !== orderBy), { orderBy: orderBy, order: order }], filtered: true });
        }
    }
    handleClearSort = (orderBy) => {
        this.setState({ ...this.state, filtered: true, sort: orderBy ? [...this.state.sort.filter(s => s.orderBy !== orderBy)] : [{ orderBy: this.state.defaultOrderBy, order: ASC }] });
    };

    // TOTAL DE PAGINAS
    handleTotalPages = () => this.state.totalPages;

    // TOTAL DE ELEMENTOS
    handleTotalElements = () => this.state.totalElements;

    // VERIFICAR PERSISTENCIA NO FORMULARIO DE FILTROS
    handleFilterPersistence = () => this.CoordenadorFilter ? this.CoordenadorFilter.state ? this.CoordenadorFilter.handleFilterPersistence(this.state.filters) : null : null;

    // COMPONENT DID MOUNT
    componentDidMount() { this.loadData(); }

    labelMaterial = (nome) => {
        return (
            <Tooltip title={nome} TransitionComponent={Zoom}>
                <span style={{ cursor: 'pointer' }}>
                  <IconButton
                      size="small"
                      component="div"
                      color="primary"
                      aria-label={nome}
                  >
                    <DescriptionOutlinedIcon />
                  </IconButton>
                </span>
            </Tooltip>
        )
    }

    contagemMaterial = (contagem, nome) => {
        return (
            <Tooltip title={nome} TransitionComponent={Zoom}>
                {contagem && contagem !== 0 ?
                    <span style={{ fontWeight: "bold", cursor: "pointer" }}>{contagem}</span>
                :
                    <span style={{ fontWeight: "bold", cursor: "pointer", color: "red" }}>0</span>
                }
            </Tooltip>
        );
    }

    visaoMateriaisExtras = (columns) => {
        if (this.props.tipos.length > 0 && this.state.mostrarMateriais)
            this.props.tipos.forEach(t => {
                columns.push({
                    label: () => this.labelMaterial(`#${t.codigoDocumento} - ${t.nomeTipoDocumento}`),
                    name: "materiais",
                    func: (materiais) => {
                        const material = materiais.find(m =>  m.cdTipoDocumento === t.codigoDocumento);
                        return material ? this.contagemMaterial(material.qtdDocumento, t.nomeTipoDocumento) : this.contagemMaterial(0, t.nomeTipoDocumento);
                    },
                    key: false,
                    ordering: false,
                    align: "center",
                });
            });
    }

    switchMostrarMateriais = () => {
        return (
            <Switch
                checked={this.state.mostrarMateriais}
                onChange={this.handleChangeSwitch}
                name="mostrarMateriais"
                label="Mostrar Materiais Extras"
            />
        )
    }

    render() {
        const columns = [
            { label: '', name: 'id', func: null, key: false, item: 'checkbox' },
            { label: '#', name: 'id', func: null, key: true, hidden: true },
            { label: 'Login', name: 'login', func: null, key: false },
            { label: 'Nome', name: 'nome', func: null, key: false, filter: 'nmCoordenador' },
            { label: 'Região', name: 'regiao', func: null, key: false, filter: 'regiao.cdRegiao' },
        ];

        this.visaoMateriaisExtras(columns);

        const actions = [
            {
                name: this.labelEditarMaterialExtra,
                func: this.handleEditarMaterialExtra,
                icon: <AllInboxIcon />,
                disabled: this.disabledEditarMaterialExtra,
            }
        ];

        if (!this.state.mostrarMateriais)
            actions.splice(0, 0, {
                name: this.labelAtualizarSenha,
                func: this.handleOpenAtualizarSenhaClick,
                icon: <VpnKeyOutlinedIcon />,
                disabled: this.disabledAtualizarSenha,
            });

        this.handleFilterPersistence();

        return (
            <>
                <Toast parentRef={this.setToast} />
                <Grid container >
                    <Grid item xs={12}>
                        <CoordenadorFilter
                            parentRef={this.setCoordenadorFilter}
                            handleFilterChange={this.filterData}
                            export={CoordenadorService.export}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <AuthorizedElement roles={[__COORDENADOR_EXPORTAR]}>
                            <Grid container spacing={3}>
                                {this.state.filters.length > 3 &&
                                    <ButtonExport
                                        title="Exportar Planilha"
                                        onClick={this.handleExportPlan}
                                    />
                                }
                            </Grid>
                        </AuthorizedElement>

                        <Grid container spacing={3}>
                            <Grid item sm={12} lg={4}>
                                <ButtonPrimary
                                    name="Importar Coordenadores da OBMEP"
                                    startIcon={this.state.loadingImport ? <CircularProgress size={16} /> : <RecentActorsIcon />}
                                    style={this.state.loadingImport ? {} : { backgroundColor: amber[800] }}
                                    onClick={this.handleImport}
                                    disabled={this.state.loadingImport}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Table
                            topActions={this.switchMostrarMateriais}
                            columns={columns}
                            tableData={this.state.coordenadores}
                            actions={actions}
                            page={this.state.page}
                            handlePage={this.handlePage}
                            handleTotalPages={this.handleTotalPages}
                            handleTotalElements={this.handleTotalElements}
                            rowsPerPage={this.state.size}
                            handleRowsPerPage={this.handleRowsPerPage}
                            sort={this.state.sort}
                            handleSort={this.handleSort}
                            handleClearSort={this.handleClearSort}
                            loading={this.state.loading}
                            handleChecks={this.handleChangeCoordenadores}
                        />
                    </Grid>
                </Grid>

                <AuthorizedElement roles={[__COORDENADOR_EDITAR_MATERIAL_EXTRA]}>
                    <Grid container spacing={3}>
                        <Grid item sm={12} lg={6} >
                            <ButtonPrimary
                                startIcon={<InboxIcon />}
                                onClick={() => this.openDialogMaterialExtra()}
                                name="Atribuir Quantidade para Um Tipo de Material Extra aos Coordenadores Selecionados"
                                disabled={this.state.checksCoords.length === 0 || isAfterInicioConsumoServicosImpressaoGrafica(this.props.contexts)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item sm={12} lg={4} >
                            <ButtonPrimary
                                startIcon={<AllInboxIcon />}
                                onClick={() => this.handleEditarMaterialExtra()}
                                name="Gerenciar Material Extra de Coordenadores Selecionados"
                                disabled={this.state.checksCoords.length === 0 || isAfterInicioConsumoServicosImpressaoGrafica(this.props.contexts)}
                            />
                        </Grid>
                    </Grid>
                </AuthorizedElement>

                <AuthorizedElement roles={[__COORDENADOR_EDITAR_SENHA]}>
                    <DialogChangePassword
                        parentRef={this.setDialogChangePassword}
                        handleAtualizarSenha={CoordenadorService.atualizarSenha}
                    />
                </AuthorizedElement>

                {!isAfterInicioConsumoServicosImpressaoGrafica(this.props.contexts) &&
                    <AuthorizedElement roles={[__COORDENADOR_EDITAR_MATERIAL_EXTRA]}>
                        <DialogMaterialExtra
                            parentRef={this.setDialogMaterialExtra}
                            handleClose={this.loadData}
                        />
                    </AuthorizedElement>
                }
            </>
        )
    }
}

const mapStateToProps = state => ({ contexts: state.contexts });
const mapDispatchToProps = dispatch => (bindActionCreators({ loadContext }, dispatch));

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Coordenador));