import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import Toast from '../../components/Toast/Toast';
import { ButtonPrimary } from "../../components/Button/Button";

import TarefaService from '../../services/Tarefa';
import { errors } from '../../services/API';

import InformacoesUltimaExecucao from './InformacoesUltimaExecucao';
import BarraProgressoTarefa from './BarraProgressoTarefa';

import {
    __TAREFA_ATUALIZAR_STATUS_SEDEX_INICIAR,
    __TAREFA_ATUALIZAR_STATUS_SEDEX_ACOMPANHAR,
} from '../../security/RoleConfiguration';
import AuthorizedFunction from '../../security/AuthorizedFunction';

export class AtualizacaoStatusSedex extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tarefa: {
                id: null,
                inicio: null,
                termino: null,
                usuarioQueIniciou: '',
                tarefaExecutada: '',
                tipo: '',
                status: '',
                registrosParaProcessar: 0,
                registrosProcessados: 0,
                inicioProcessamento: null,
            },
            possuiTarefa: false,
            loadingAtualizarStatusSedex: false,
            atualizacaoStatusSedexJaIniciada: true,
        }
    }

    // FECHAR TOAST
    setToast = t => this.Toast = t;

    handleAtualizarStatusSedex = () => {
        this.setState({
            ...this.state,
            possuiTarefa: false,
            loadingAtualizarStatusSedex: true,
            atualizacaoStatusSedexJaIniciada: true,
        });

        TarefaService.iniciarTarefaAtualizacaoStatusSedex()
            .then(res => {
                this.setState({
                    ...this.state,
                    loadingAtualizarStatusSedex: false,
                    atualizacaoStatusSedexJaIniciada: true,
                });

                this.initObserverAcompanhamento();

                this.Toast.setState({
                    message: {
                        message: "Atualização Iniciada com Sucesso. Em breve aparecerão informações na tela, sobre o progresso desta Tarefa.",
                        type: "success",
                        open: true
                    }
                });
            })
            .catch(error => {
                this.setState({
                    ...this.state,
                    possuiTarefa: true,
                    loadingAtualizarStatusSedex: false,
                    atualizacaoStatusSedexJaIniciada: false,
                });

                const e = errors(error);
                this.Toast.setState({
                    message: {
                        message: "Não foi Possível Iniciar a Atualização de Status. " + e.message,
                        type: e.type,
                        open: true
                    }
                });
            });
    }

    initObserverAcompanhamento = () => setTimeout(() => this.loadData(true), 5000);

    loadData = (observer = false) => {
        TarefaService.acompanharTarefaAtualizacaoStatusSedex()
            .then(res => {
                if (res.status === 200) {
                    this.setState({
                        tarefa: {
                            id: res.data.id,
                            inicio: res.data.inicio,
                            termino: res.data.termino,
                            usuarioQueIniciou: res.data.usuarioQueIniciou,
                            tarefaExecutada: res.data.tarefaExecutada,
                            tipo: res.data.tipo,
                            status: res.data.status,
                            registrosParaProcessar: res.data.registrosParaProcessar,
                            registrosProcessados: res.data.registrosProcessados,
                            inicioProcessamento: res.data.inicioProcessamento,
                        },
                        possuiTarefa: true,
                        atualizacaoStatusSedexJaIniciada: res.data.status === "EM_ANDAMENTO" ? true : false,
                    });

                    if (res.data.status === "EM_ANDAMENTO")
                        this.initObserverAcompanhamento();

                } else if (res.status === 204) {
                    this.setState({
                        ...this.state,
                        possuiTarefa: false,
                        atualizacaoStatusSedexJaIniciada: false,
                    });
                } else {
                    this.setState({
                        ...this.state,
                        possuiTarefa: false,
                        atualizacaoStatusSedexJaIniciada: true,
                    });
                }
            })
            .catch(error => {
                if (!observer) {
                    const e = errors(error);
                    this.Toast.setState({
                        message: {
                            message: "Não foi Possível Realizar o Acompanhamento da Atualização de Status. " + e.message,
                            type: e.type,
                            open: true
                        }
                    });
                }
            })
    }

    // COMPONENT DID MOUNT
    componentDidMount() { this.loadData(); }

    render() {
        return (
            <>
                <Toast parentRef={this.setToast} />
                {(AuthorizedFunction([__TAREFA_ATUALIZAR_STATUS_SEDEX_INICIAR, __TAREFA_ATUALIZAR_STATUS_SEDEX_ACOMPANHAR])) &&
                    <Grid container spacing={2} style={{ marginTop: '16px', marginBottom: '16px' }}>
                        <Grid item sm={12} lg={12} style={{ marginBottom: '24px' }}>
                            <Typography variant="body2">
                                Entre o período de <b>{this.props.inicio}</b> à <b>{this.props.termino}</b>, 
                                o sistema atualizará o status Sedex dos objetos postais diariamente, nos seguintes horários: <b>8h</b>, <b>12h</b> e <b>21h</b>
                            </Typography>
                        </Grid>

                        {AuthorizedFunction([__TAREFA_ATUALIZAR_STATUS_SEDEX_ACOMPANHAR]) && this.state.possuiTarefa &&
                            <Grid item sm={12} lg={12}  style={{ marginBottom: '24px' }}>
                                <>
                                    <InformacoesUltimaExecucao
                                        inicio={this.state.tarefa.inicio}
                                        termino={this.state.tarefa.termino}
                                        usuario={this.state.tarefa.usuarioQueIniciou}
                                        status={this.state.tarefa.status}
                                        tipo={this.state.tarefa.tipo}
                                        paraProcessar={this.state.tarefa.registrosParaProcessar}
                                        processados={this.state.tarefa.registrosProcessados}
                                    />

                                    {this.state.atualizacaoStatusSedexJaIniciada &&
                                        <BarraProgressoTarefa
                                            inicioProcessamento={this.state.tarefa.inicioProcessamento}
                                            paraProcessar={this.state.tarefa.registrosParaProcessar}
                                            processados={this.state.tarefa.registrosProcessados}
                                        />
                                    }
                                </>
                            </Grid>
                        }

                        {AuthorizedFunction([__TAREFA_ATUALIZAR_STATUS_SEDEX_INICIAR]) &&
                            <Grid item sm={12} lg={4}>
                                <ButtonPrimary
                                    startIcon={this.state.loadingAtualizarStatusSedex ? <CircularProgress size={16} /> : <PlayCircleOutlineIcon />}
                                    onClick={this.handleAtualizarStatusSedex}
                                    name="Iniciar Manualmente Atualização de Status dos Objetos Postais"
                                    disabled={this.state.atualizacaoStatusSedexJaIniciada}
                                />
                            </Grid>
                        }
                    </Grid>
                }
            </>
        )
    }
}

export default withRouter(AtualizacaoStatusSedex);