import React from 'react';

import Header from '../../components/Header/Header';

import Layout from '../../layouts/public/Public';

import Banner from './Banner';

const Inicio = () => {
    return (
        <Layout>
            <Header />
            <Banner />
        </Layout>
    )
}

export default Inicio;