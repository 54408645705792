import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import ListIcon from '@material-ui/icons/List';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Popover from '@material-ui/core/Popover';
import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import EmojiEventsOutlinedIcon from '@material-ui/icons/EmojiEventsOutlined';
import AttachMoneyOutlinedIcon from '@material-ui/icons/AttachMoneyOutlined';
import AccountTreeOutlinedIcon from '@material-ui/icons/AccountTreeOutlined';
import ScheduleOutlinedIcon from '@material-ui/icons/ScheduleOutlined';
import Chip from '@material-ui/core/Chip';
import DoneIcon from '@material-ui/icons/Done';
import BlockIcon from '@material-ui/icons/Block';
import ErrorIcon from '@material-ui/icons/Error';

import { makeStyles } from '@material-ui/core/styles';
import { blue, green, orange, red } from '@material-ui/core/colors';

import ContextoService from '../../services/Contexto';
import { errors } from '../../services/API';

import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Title from '../../components/Title/Title';
import Toast from "../../components/Toast/Toast";
import { ButtonPrimary } from "../../components/Button/Button";
import { ContextDispatcher } from '../../components/Dispatcher/Dispatcher';
import Loading from '../../components/Loading/Loading';
import Categoria from '../../components/Categoria/Categoria';

import { DialogConfirm as DialogConfirmarMudanca } from '../../forms/Dialog/DialogConfirm';
import { ButtonAdd } from '../../forms/Buttons/ButtonsAdd';

import { patternContext, patternLabelGrupoExibicao } from '../../variables/Enums/Contexto';

import AuthorizedElement from '../../security/AuthorizedElement';
import AuthorizedFunction from '../../security/AuthorizedFunction';
import {
    __CONTEXTO_EDITAR_VALOR,
    __CONTEXTO_ADICIONAR,
    __CONTEXTO_VISUALIZAR,
} from '../../security/RoleConfiguration';

import {
    isBlank,
    isBlankHelperText,
    isNumber,
    isDateTimeHelperText,
    isDateTimeInvalid,
} from '../../helper/ValidationHelper';
import { formatInTimeZone } from '../../helper/DateHelper';

import { Layout } from '../../layouts/private/Private';

export const useStyles = makeStyles(theme => ({
    body: {
        margin: theme.spacing(3, 0),
    },
    legenda: {
        padding: "16px",
        "& ul": {
            paddingInlineStart: "16px",
        },
    },
    info: {
        backgroundColor: blue[400],
        color: "#fff",
    },
    success: {
        backgroundColor: green[400],
        color: "#fff",
    },
    warning: {
        backgroundColor: orange[400],
        color: "#fff",
    },
    error: {
        backgroundColor: red[400],
        color: "#fff",
    },
    white: {
        color: "#fff",
    },
    black: {
        color: "#000",
    }
}));

const ButtonList = () => {
    const classes = useStyles();
    return (
        <Grid item sm={12} lg={2} className={classes.body}>
            <ButtonPrimary
                to='/admin/contexto/listagem'
                component={Link}
                startIcon={<ListIcon />}
                name="Listagem de Variáveis"
            />
        </Grid>
    )
}

const PopUpLegenda = ({ open, ancora, texto, handleClose }) => {
    const classes = useStyles();    
    return (
        <Popover
            open={open}
            anchorEl={ancora}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >
            <Typography className={classes.legenda} variant="body2" dangerouslySetInnerHTML={{ __html: texto }}></Typography>
        </Popover>
    )
}

const ChipToken = ({ token, status = false }) => {
    const classes = useStyles();
    return (
        <>
            <Chip
                size="small"
                icon={status ? <DoneIcon className={classes.white} /> : !token ? <ErrorIcon className={classes.white} /> : <BlockIcon className={classes.black} />}
                label={<b>{status ? `Habilitado` : !token ? `Não Integrado` : `Desabilitado`}</b>}
                className={status ? classes.success : !token ? classes.error : null}
            />
        </>
    )
}

class Contexto extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contexts: [],
            errors: {},
            helpers: {},
            loading: true,
            legenda: {
                open: false,
                ancora: null,
                texto: null,
            },
            integracoes: [],
        }
    }

    // INPUTS
    handleChange = e => {
        this.setState({
            contexts: [...this.state.contexts.filter(c => {
                if (c.name === e.target.name)
                    if (c.type !== "LONG" || (c.type === "LONG" && !isNumber(e.target.value)))
                        c.value = e.target.value;
                return true
            })
            ],
            errors: { ...this.state.errors, [e.target.name]: false },
            helpers: { ...this.state.helpers, [e.target.name]: null }
        });
    };

    // INPUTS DATE TIME PICKER
    handleChangeDateTime = (e, name) => {
        this.setState({
            contexts: [...this.state.contexts.filter(c => {
                if (c.name === name) c.value = e;
                return true
            })
            ],
            errors: { ...this.state.errors, [name]: false },
            helpers: { ...this.state.helpers, [name]: null }
        });
    }

    // LEGENDA
    handleChangeOpenLegenda = (e, legenda) => {
        this.setState({
            legenda: {
                open: true,
                ancora: e.currentTarget,
                texto: legenda,
            },
        });
    };

    handleChangeCloseLegenda = () => {
        this.setState({
            legenda: {
                open: false,
                ancora: null,
                texto: null,
            },
        });
    };

    // DECLARAÇÃO DE REFERÊNCIA DOS COMPONENTES
    setToast = t => this.Toast = t;
    setDialogConfirmarMudanca = d => this.DialogConfirmarMudanca = d;

    // BOTAO LEGENDA
    buttonInfo = (legenda) => {
        return (
            <Tooltip title="Informações" TransitionComponent={Zoom}>
                <IconButton 
                    size="small"
                    color="default" 
                    aria-label="Informações"
                    onClick={(e) => this.handleChangeOpenLegenda(e, legenda)}
                >
                    <InfoOutlinedIcon />
                </IconButton>
            </Tooltip> 
        )
    }

    // MONTAR VARIAVEIS
    fieldsContext = (grupo = null, icone = null, expanded = false) => {
        const contextos = this.state.contexts.filter(ctx => ctx.grupoExibicao === grupo);

        if (contextos.length > 0) {
            return (
                <Categoria nome={patternLabelGrupoExibicao(grupo)} icone={icone} defaultExpanded={expanded}>
                    <Grid container spacing={3} >
                        {contextos.map((c) => {
                            return (
                                <ContextDispatcher
                                    context={c}
                                    key={c.id}
                                    required
                                    label={c.label}
                                    name={c.name}
                                    autoComplete="off"
                                    onChange={(e) => {
                                        c.type === "DATA" ? this.handleChangeDateTime(e, c.name) : this.handleChange(e)

                                        if (c.id === 35 || c.id === 36) {
                                            this.modalPeriodoCadastroAlunosCentral(c.id)
                                        } else if (c.id === 27) {
                                            this.modalAlertaPremiacaoIrreversivel(c.id)
                                        } else {
                                            this.handleBlur(c.id)
                                        }
                                    }}
                                    disabled={!AuthorizedFunction([__CONTEXTO_EDITAR_VALOR]) || c.grupoExibicao === "INTEGRACAO"}
                                    value={c.value}
                                    error={this.state.errors[c.name]}
                                    helperText={this.state.helpers[c.name]}
                                >
                                    { c.legenda && this.buttonInfo(c.legenda) }
                                </ContextDispatcher>
                            )
                        })}
                    </Grid>
                </Categoria>
            )
        }
    }

    fieldsContextToken = (grupo = null, icone = null, expanded = false) => {
        const contextos = this.state.contexts.filter(ctx => ctx.grupoExibicao === grupo);

        if (contextos.length > 0) {
            return (
                <Categoria nome={patternLabelGrupoExibicao(grupo)} icone={icone} defaultExpanded={expanded}>
                    <Grid container spacing={3} style={{ paddingTop: 2 }}>
                        {contextos.map((c) => 
                            <Grid item sm={12} lg={3}>
                                <Typography variant="body2" style={{ display: 'inline' }}>
                                    <b>{c.label}</b>:
                                </Typography> &nbsp;
                                
                                <ChipToken 
                                    token={c.value} 
                                    status={this.state.integracoes.find(i => i.cdVariavel === c.id)?.status} 
                                /> &nbsp;

                                { c.legenda && this.buttonInfo(c.legenda) }
                            </Grid>
                        )}
                    </Grid>
                </Categoria>
            )
        }
    }

    // VALIDAÇÃO
    isValid = c => {
        if (isBlank(c.value)) {
            this.setState({
                errors: { ...this.state.errors, [c.name]: true },
                helpers: { ...this.state.helpers, [c.name]: isBlankHelperText() }
            })
            return false
        } else if (isDateTimeInvalid(c.value) && c.type === "DATA") {
            this.setState({
                errors: { ...this.state.errors, [c.name]: true },
                helpers: { ...this.state.helpers, [c.name]: isDateTimeHelperText() }
            })
            return false
        }
        return true
    }

    modalPeriodoCadastroAlunosCentral = (id) => {
        this.DialogConfirmarMudanca.setState({
            dialog: {
                open: true,
                title: `ATENÇÃO`,
                text: `Para a Abertura deste Período é recomendado fortemente que a Variável de Contexto "Permitir Cadastro de Alunos Para Provas Especiais na 2ª Fase" seja alterada para o valor NÃO.`,
                id: id,
                loading: false,
                showConfirm: true,
            }
        });
    }

    modalAlertaPremiacaoIrreversivel = (id) => {
        this.DialogConfirmarMudanca.setState({
            dialog: {
                open: true,
                title: `ATENÇÃO`,
                text: `Caso a data "Fim de Lançamentos de Notas da Fase 2" já tenha sido ultrapassada uma vez independente de ter sido alterada para um período posterior, 
                        as Premiações de TODAS as Escolas já foram inicialmente executadas por uma rotina automatizada do sistema, de maneira que tais Premiações são irreversíveis, 
                        podendo apenas serem resetadas individualmente na Listagem de Escolas.`,
                id: id,
                loading: false,
                showConfirm: true,
            }
        });
    }

    // SALVAR CONTEXTO
    handleBlur = (id) => {
        const c = this.state.contexts.find(c => c.id === id);

        if (!this.isValid(c))
            return

        const context = {
            nmTipo: c.type,
            nmValor: c.type === "DATA" ? formatInTimeZone(c.value) : c.value,
            nmVariavel: c.label,
            nmOrdemExibicao: c.ordem,
            nmLegenda: c.legenda,
            nmAgrupamento: c.grupoExibicao,
        }

        ContextoService.edit(c.id, context)
            .then(res => {
                this.Toast.setState({
                    message: {
                        message: "Contexto Salvo com Sucesso",
                        type: "success",
                        open: true
                    }
                });
            })
            .catch(error => {
                const e = errors(error);
                this.Toast.setState({
                    message: {
                        message: e.message,
                        type: e.type,
                        open: true
                    }
                })
            })
            .finally(() => this.DialogConfirmarMudanca.close());
    }

    async componentDidMount() {
        let f = [];
        f.push({ field: 'sort', value: 'nmOrdemExibicao,asc' });

        ContextoService.filters(f)
            .then(res => {
                if (res.data.content) {
                    const contexts = patternContext(res.data.content);

                    let errors = {};
                    contexts.forEach(context => { errors[context.name] = false });

                    let helpers = {};
                    contexts.forEach(context => { helpers[context.name] = null });

                    this.setState({
                        contexts: [...this.state.contexts, ...contexts],
                        errors: errors,
                        helpers: helpers,
                        loading: false,
                    });
                } else {
                    this.setState({
                        contexts: [],
                        errors: {},
                        helpers: {},
                        loading: false,
                    });
                }
            })
            .catch(error => {
                const e = errors(error);
                this.Toast.setState({
                    message: {
                        message: e.message,
                        type: e.type,
                        open: true
                    }
                })

                this.setState({ loading: false, });
            })

        ContextoService.verificarIntegracoes()
            .then(res => {
                this.setState({
                    integracoes: [...res.data],
                })
            })
            .catch(error => {
                const e = errors(error);
                this.Toast.setState({
                    message: {
                        message: "Não foi Possível Buscar o Status das Integrações",
                        type: e.type,
                        open: true
                    }
                })
            })
    }

    render() {
        const page = 'Contexto';
        const links = [
            {
                path: null,
                name: 'Configurações'
            }
        ];

        return (
            <Layout>
                <Toast parentRef={this.setToast} />
                <Grid container >
                    <Grid item xs={12}>
                        <Breadcrumbs links={links} active={page} />
                    </Grid>
                </Grid>
                <Title>{page}</Title>

                <Grid container style={{ marginBottom: 16 }}>
                    <AuthorizedElement roles={[__CONTEXTO_VISUALIZAR]}>
                        <ButtonList />
                    </AuthorizedElement>
                    <AuthorizedElement roles={[__CONTEXTO_ADICIONAR]}>
                        <ButtonAdd
                            to={{
                                pathname: '/admin/contexto/adicionar',
                                state: {
                                    history: {
                                        path: `/admin/contexto`,
                                        state: this.state,
                                    }
                                }
                            }}
                            title="Adicionar Variável"
                        />
                    </AuthorizedElement>
                </Grid>

                {this.state.loading ? 
                    <Loading /> 
                : 
                    <>
                        {this.fieldsContext("GERAL", <SettingsOutlinedIcon />)}
                        {this.fieldsContext("INSCRICAO", <ScheduleOutlinedIcon />)}
                        {this.fieldsContext("PAGAMENTO", <AttachMoneyOutlinedIcon />)}
                        {this.fieldsContext("FASE_1", <DateRangeOutlinedIcon />)}
                        {this.fieldsContext("FASE_2", <DateRangeOutlinedIcon />)}
                        {this.fieldsContext("PREMIACAO", <EmojiEventsOutlinedIcon />)}

                        {this.fieldsContextToken("INTEGRACAO", <AccountTreeOutlinedIcon />)}

                        {this.fieldsContext()}
                    </>
                }

                <PopUpLegenda 
                    open={this.state.legenda.open}
                    ancora={this.state.legenda.ancora}
                    texto={this.state.legenda.texto}
                    handleClose={this.handleChangeCloseLegenda} 
                />

                <DialogConfirmarMudanca
                    parentRef={this.setDialogConfirmarMudanca}
                    handleConfirm={this.handleBlur}
                />
            </Layout >
        )
    }
}

export default Contexto;