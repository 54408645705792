import API from './API';

const path = `rest`;

const GraficaService = {

    tiposMateriais: async filters => await API.filters(`${path}/documentos/tipos`, filters),
    escolasInscritas: async filters => await API.filters(`${path}/escolas/inscritas`, filters),
    inscricoesPorUfs: async filters => await API.filters(`${path}/ufs/inscricoes`, filters),
    materiaisExtras: async filters => await API.filters(`${path}/coordenadores/materiais-extras`, filters),

}

export default GraficaService;