import React, { Component } from 'react';
import { withRouter } from "react-router-dom";

import Grid from '@material-ui/core/Grid';
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import StorageOutlinedIcon from '@material-ui/icons/StorageOutlined';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import SchoolOutlinedIcon from "@material-ui/icons/SchoolOutlined";
import MapOutlinedIcon from "@material-ui/icons/MapOutlined";
import CircularProgress from "@material-ui/core/CircularProgress";

import ButtonPrimary from "../../components/Button/Button";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Title from '../../components/Title/Title';
import Toast from '../../components/Toast/Toast';

import EscolaFilterEscolasIncritas from "../../forms/Escola/EscolaFilterFields";
import EnderecoFilterInscricoesPorUfs from "../../forms/Endereco/EnderecoFilter";
import EnderecoFilterEscolasIncritas from "../../forms/Endereco/EnderecoFilter";
import EnderecoFilterMaterialExtra from "../../forms/Endereco/EnderecoFilter";

import GraficaService from "../../services/Grafica";
import { errors } from "../../services/API";

import { json } from "../../helper/FileHelper";

import { Layout } from '../../layouts/private/Private';

const parseFilenameJSON = (name, filters) => filters.length > 0 ? name + "_" + filters.map(f => f.value).join("_") : name;

export class Servicos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingTiposMateriais: false,
      loadingInscricoesPorUfs: false,
      loadingEscolasInscritas: false,
      loadingMateriaisExtras: false,
    }
  }

  // DECLARAÇÃO DE REFERÊNCIA DOS COMPONENTES
  setToast = t => this.Toast = t;
  setEscolaFilterEscolasIncritas = form => this.EscolaFilterEscolasIncritas = form;
  setEnderecoFilterEscolasIncritas = form => this.EnderecoFilterEscolasIncritas = form;
  setEnderecoFilterInscricoesPorUfs = form => this.EnderecoFilterInscricoesPorUfs = form;
  setEnderecoFilterMaterialExtra = form => this.EnderecoFilterMaterialExtra = form;

  handleTiposMateriais = () => {
    this.setState({ loadingTiposMateriais: true });

    GraficaService.tiposMateriais([])
      .then(res => {
        json(res.data, "TiposMateriais");

        this.Toast.setState({
          message: {
            message: "Download de JSON de Tipos de Materiais Realizado com Sucesso",
            type: "success",
            open: true
          }
        });
      })
      .catch(error => {
        const e = errors(error);
        this.Toast.setState({
          message: {
            message: e.message,
            type: e.type,
            open: true
          }
        })
      })
      .finally(() => this.setState({ loadingTiposMateriais: false }));
  }

  handleEscolasInscritas = () => {
    this.setState({ loadingEscolasInscritas: true });

    const f = [];
    const filtersEscola = this.EscolaFilterEscolasIncritas.state.filters;
    const filtersEndereco = this.EnderecoFilterEscolasIncritas.state.filters;

    if (filtersEscola.inepSchool) f.push({ field: 'mec', value: filtersEscola.inepSchool })

    if (filtersEndereco.UF) f.push({ field: 'uf', value: filtersEndereco.UF })
    if (filtersEndereco.region) f.push({ field: 'regiao', value: filtersEndereco.region })

    GraficaService.escolasInscritas(f)
      .then(res => {
        const filename = parseFilenameJSON("EscolasInscritas", f)
        json(res.data, filename);

        this.Toast.setState({
          message: {
            message: "Download de JSON de Escolas Inscritas Realizado com Sucesso",
            type: "success",
            open: true
          }
        });
      })
      .catch(error => {
        const e = errors(error);
        this.Toast.setState({
          message: {
            message: e.message,
            type: e.type,
            open: true
          }
        })
      })
      .finally(() => this.setState({ loadingEscolasInscritas: false }));
  }

  handleInscricoesPorUfs = () => {
    this.setState({ loadingInscricoesPorUfs: true });

    const f = [];
    const filtersEndereco = this.EnderecoFilterInscricoesPorUfs.state.filters;

    if (filtersEndereco.UF) f.push({ field: 'uf', value: filtersEndereco.UF })
    if (filtersEndereco.region) f.push({ field: 'regiao', value: filtersEndereco.region })

    GraficaService.inscricoesPorUfs(f)
      .then(res => {
        const filename = parseFilenameJSON("InscricoesPorUfs", f);
        json(res.data, filename);

        this.Toast.setState({
          message: {
            message: "Download de JSON de Inscrições Por UF`s Realizado com Sucesso",
            type: "success",
            open: true
          }
        });
      })
      .catch(error => {
        const e = errors(error);
        this.Toast.setState({
          message: {
            message: e.message,
            type: e.type,
            open: true
          }
        })
      })
      .finally(() => this.setState({ loadingInscricoesPorUfs: false }));
  }

  handleMateriaisExtras = () => {
    this.setState({ loadingMateriaisExtras: true });

    const f = [];
    const filtersEndereco = this.EnderecoFilterMaterialExtra.state.filters;

    if (filtersEndereco.UF) f.push({ field: 'uf', value: filtersEndereco.UF })
    if (filtersEndereco.region) f.push({ field: 'regiao', value: filtersEndereco.region })

    GraficaService.materiaisExtras(f)
      .then(res => {
        const filename = parseFilenameJSON("MateriaisExtras", f);
        json(res.data, filename);

        this.Toast.setState({
          message: {
            message: "Download de JSON de Materiais Extras Realizado com Sucesso",
            type: "success",
            open: true
          }
        });
      })
      .catch(error => {
        const e = errors(error);
        this.Toast.setState({
          message: {
            message: e.message,
            type: e.type,
            open: true
          }
        })
      })
      .finally(() => this.setState({ loadingMateriaisExtras: false }));
  }

  render() {
    const pageName = 'Serviços para Impressão de Materiais';
    const links = [
      {
        path: null,
        name: 'Configurações',
      },
      {
        path: null,
        name: 'Materiais',
      },
    ];

    return (
      <Layout>
        <Toast parentRef={this.setToast} />
        <Grid container >
          <Grid item xs={12}>
            <Breadcrumbs links={links} active={pageName} />
          </Grid>
        </Grid>
        <Title>{pageName}</Title>

        <Grid container spacing={2} >
          <Grid item lg={4} >
            <Paper style={{ marginTop: '8px', marginBottom: '8px', padding: '24px' }} elevation={1} >
              <Typography variant="h6" display="block" style={{ marginBottom: '16px' }} >
                Tipos de Materiais
              </Typography>
              <ButtonPrimary
                onClick={this.handleTiposMateriais}
                name="Baixar JSON de Tipos de Materiais"
                startIcon={this.state.loadingTiposMateriais ? <CircularProgress size={16} /> : <StorageOutlinedIcon />}
                disabled={this.state.loadingTiposMateriais}
              />
            </Paper>
          </Grid>
        </Grid>

        <Grid container spacing={2} >
          <Grid item lg={9} >
            <Paper style={{ marginTop: '8px', marginBottom: '8px', padding: '24px' }} elevation={1} >
              <Typography variant="h6" display="block" >
                Escolas Inscritas
              </Typography>
              <Grid item container spacing={3} >
                <EscolaFilterEscolasIncritas
                  parentRef={this.setEscolaFilterEscolasIncritas}
                  disabledEmail={true}
                  disabledNameSchool={true}
                />
                <EnderecoFilterEscolasIncritas
                  parentRef={this.setEnderecoFilterEscolasIncritas}
                  withRegion={true}
                  disabledCities={true}
                />
              </Grid>
              <ButtonPrimary
                onClick={this.handleEscolasInscritas}
                name="Baixar JSON de Escolas Inscritas"
                startIcon={this.state.loadingEscolasInscritas ? <CircularProgress size={16} /> : <SchoolOutlinedIcon />}
                disabled={this.state.loadingEscolasInscritas}
              />
            </Paper>
          </Grid>
        </Grid>

        <Grid container spacing={2} >
          <Grid item lg={6} >
            <Paper style={{ marginTop: '8px', marginBottom: '8px', padding: '24px' }} elevation={1} >
              <Typography variant="h6" display="block" >
                Inscrições Por UF`s
              </Typography>
              <Grid item container spacing={3} >
                <EnderecoFilterInscricoesPorUfs
                  parentRef={this.setEnderecoFilterInscricoesPorUfs}
                  withRegion={true}
                  disabledCities={true}
                />
              </Grid>
              <ButtonPrimary
                onClick={this.handleInscricoesPorUfs}
                name="Baixar JSON de Inscrições Por UF`s"
                startIcon={this.state.loadingInscricoesPorUfs ? <CircularProgress size={16} /> : <MapOutlinedIcon />}
                disabled={this.state.loadingInscricoesPorUfs}
              />
            </Paper>
          </Grid>
          <Grid item lg={6} >
            <Paper style={{ marginTop: '8px', marginBottom: '8px', padding: '24px' }} elevation={1} >
              <Typography variant="h6" display="block" >
                Materiais Extras para Coordenadores
              </Typography>
              <Grid item container spacing={3} >
                <EnderecoFilterMaterialExtra
                  parentRef={this.setEnderecoFilterMaterialExtra}
                  withRegion={true}
                  withRegionRJ04={true}
                  disabledCities={true}
                />
              </Grid>
              <ButtonPrimary
                onClick={this.handleMateriaisExtras}
                name="Baixar JSON de Materiais Extras para Coordenadores"
                startIcon={this.state.loadingMateriaisExtras ? <CircularProgress size={16} /> : <AllInboxIcon />}
                disabled={this.state.loadingMateriaisExtras}
              />
            </Paper>
          </Grid>
        </Grid>
      </Layout>
    )
  }
}

export default withRouter(Servicos);