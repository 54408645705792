import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { isBlank, isBlankHelperText } from '../../helper/ValidationHelper';
import { Typography } from '@material-ui/core';

export class Modalidade extends Component {
    constructor(props) {
        super(props);
        props.parentRef(this);
        this.state = {
            adminDep: "",
            dependenciaConvenio: "",
            modalidade : "",
            errors: {
                modalidade: false,
            },
            helpers: {
                modalidade: null,
            },
            disabled: {
                modalidade: false,
            }
        }
        this.isValid = this.isValid.bind(this);
    }

    // INPUTS
    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
            errors: { 
                [e.target.name]: false
            },
            helpers: { 
                [e.target.name]: null 
            },
        }, () => {
            if (this.props.onChange)
                this.props.onChange(this.state);
        });
    }

    // VALIDAÇÕES
    isValid = () => {
        if (isBlank(this.state.modalidade)) {
            this.setState({
                errors: {
                    modalidade: isBlank(this.state.modalidade) ? true : false,
                },
                helpers: {
                    modalidade: isBlank(this.state.modalidade) ? isBlankHelperText() : null,
                },
            });
            return false;
        }
        return true;
    }

    changeDepAdmin = (state) => {
        this.setState({
            ...this.state,
            modalidade: this.state.dependenciaConvenio === state.depConvenio ? this.state.modalidade : "",
            dependenciaConvenio: state.depConvenio,
        });
    }
        
    optionsRadioPublicas = [
        { value: "MODALIDADE_UM", label: `Modalidade 1 – Inscrição sem premiação - A inscrição é gratuita e estão inclusos os certificados digitais.`},
        { value: "MODALIDADE_DOIS", label: `Modalidade 2 – Inscrição com premiação - A inscrição é gratuita e estão inclusos os certificados digitais e, <b><u>mediante a confirmação do pagamento de taxa</u></b>, há o envio de medalhas de ouro, prata e bronze, conforme quadro abaixo:`}
    ]
    
    optionsRadioPrivadas = [
        { value: "MODALIDADE_TRES", label: `Modalidade 3 – Inscrição sem premiação - A inscrição é confirmada após o pagamento da taxa de inscrição no valor de R$150,00 e inclui certificados digitais.` },
        { value: "MODALIDADE_QUATRO", label: `Modalidade 4 – Inscrição com premiação - Inclui certificados digitais e o envio de medalhas de ouro, prata e bronze, <b><u>mediante pagamento de taxa de inscrição no valor de R$ 150,00 + os valores por nível (conforme tabela abaixo)</u></b>:`}
    ];

    optionsRadio = (isPrivada) => {
        const options = isPrivada ? this.optionsRadioPrivadas : this.optionsRadioPublicas;
        return options.map(o => <FormControlLabel key={o.value} style={{ marginRight: 30 }} value={o.value} control={<Radio />} disabled={this.state.disabled.modalidade} label={<ul dangerouslySetInnerHTML={{__html:o.label}}></ul>} />);
    }
    
    render() {
        const isPrivada = this.state.adminDep === "PRIVADA" && this.state.dependenciaConvenio !== "TOTAL";

        return (
            <>
                <Grid container spacing={3} alignItems='center'>
                    <Grid item sm={12} lg={12}>
                        <FormControl component="div" error={this.state.errors.modalidade} style={{ marginTop: 20, display: 'flex', flexDirection: 'column' }}>
                            <FormLabel style={{ marginBottom: 15 }} required component="legend" >Modalidade:</FormLabel>
                            <RadioGroup
                                row
                                required
                                aria-label="Modalidade"
                                name="modalidade"
                                value={!this.state.modalidade ? isPrivada ? "MODALIDADE_TRES" : "MODALIDADE_UM" : this.state.modalidade }
                                onChange={(e) => this.handleChange(e)}
                            >
                                { this.optionsRadio(isPrivada) }
                            </RadioGroup>                            
                            <FormHelperText style={{ marginTop: 20, marginRight: 20 }} >{this.state.helpers.modalidade}</FormHelperText>
                        </FormControl>
                    </Grid>
                    
                    { this.state.modalidade === "MODALIDADE_DOIS" || this.state.modalidade === "MODALIDADE_QUATRO" ? 
                        isPrivada ? <GradeMedalhasValoresPrivada /> : <GradeMedalhasValoresPublica /> : <></> 
                    }

                    { (this.state.modalidade === "MODALIDADE_DOIS" || this.state.modalidade === "MODALIDADE_TRES" || this.state.modalidade === "MODALIDADE_QUATRO" || (!this.state.modalidade && isPrivada)) &&
                        <Grid item sm={12} lg={12}>
                            <Typography variant="overline" justify="center" style={{ fontSize: '1em', fontWeight: '900', color: 'darkred', display: 'flex' }}>
                                { this.state.modalidade === "MODALIDADE_DOIS" ?
                                    <b>ATENÇÃO! A inscrição é gratuita, pois caso a escola não efetue o pagamento dentro do prazo, a inscrição migrará automaticamente para Modalidade 1.</b>
                                : (this.state.modalidade === "MODALIDADE_TRES" || this.state.modalidade === "MODALIDADE_QUATRO" || (!this.state.modalidade && isPrivada)) &&
                                    <b>ATENÇÃO! Caso a escola não efetue o pagamento dentro do prazo, a inscrição não será validada.</b>
                                }
                            </Typography>
                        </Grid>
                    }                    
                </Grid>
            </>
        )
    }
}

export const GradeMedalhasValoresPrivada = () => {
    return (
        <>
            <Grid item sm={1} lg={1}></Grid>
            <Grid item sm={10} lg={10}>
                <TableContainer component="div">
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center" style={{ backgroundColor: '#CACACA' }}>Nível</TableCell>
                                <TableCell align="center" style={{ backgroundColor: '#CACACA' }}>Quantidade de Alunos Inscritos</TableCell>
                                <TableCell align="center" style={{ backgroundColor: '#B68D4A', color: '#FFFFFF' }}><b>OURO</b></TableCell>
                                <TableCell align="center" style={{ backgroundColor: '#A3A2A0', color: '#FFFFFF' }}><b>PRATA</b></TableCell>
                                <TableCell align="center" style={{ backgroundColor: '#A46628', color: '#FFFFFF' }}><b>BRONZE</b></TableCell>
                                <TableCell align="center" style={{ backgroundColor: '#CACACA' }}><b>R$150,00</b> + Valor Adicional <b>POR NÍVEL</b></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow style={{ border: 1 }}>
                                <TableCell align="center" component="th" scope="row" rowSpan={4} style={{ backgroundColor: '#FFE699' }}>MIRIM 1</TableCell>
                                <TableCell align="center"><b>1 a 60</b></TableCell>
                                <TableCell align="center">1</TableCell>
                                <TableCell align="center">3</TableCell>
                                <TableCell align="center">6</TableCell>
                                <TableCell align="center"><b>R$ 200,00</b></TableCell>
                            </TableRow>
                            <TableRow style={{ border: 1 }}>
                                <TableCell align="center"><b>61 a 120</b></TableCell>
                                <TableCell align="center">2</TableCell>
                                <TableCell align="center">6</TableCell>
                                <TableCell align="center">12</TableCell>
                                <TableCell align="center"><b>R$ 400,00</b></TableCell>
                            </TableRow>
                            <TableRow style={{ border: 1 }}>
                                <TableCell align="center"><b>121 a 180</b></TableCell>
                                <TableCell align="center">3</TableCell>
                                <TableCell align="center">9</TableCell>
                                <TableCell align="center">18</TableCell>
                                <TableCell align="center"><b>R$ 600,00</b></TableCell>
                            </TableRow>
                            <TableRow style={{ border: 1 }}>
                                <TableCell align="center"><b>181 ou +</b></TableCell>
                                <TableCell align="center">4</TableCell>
                                <TableCell align="center">12</TableCell>
                                <TableCell align="center">24</TableCell>
                                <TableCell align="center"><b>R$ 800,00</b></TableCell>
                            </TableRow>
                            <TableRow style={{ border: 1 }}>
                                <TableCell align="center" component="th" scope="row" rowSpan={4} style={{ backgroundColor: '#FFCCFF' }}>MIRIM 2</TableCell>
                                <TableCell align="center"><b>1 a 60</b></TableCell>
                                <TableCell align="center">1</TableCell>
                                <TableCell align="center">3</TableCell>
                                <TableCell align="center">6</TableCell>
                                <TableCell align="center"><b>R$ 200,00</b></TableCell>
                            </TableRow>
                            <TableRow style={{ border: 1 }}>
                                <TableCell align="center"><b>61 a 120</b></TableCell>
                                <TableCell align="center">2</TableCell>
                                <TableCell align="center">6</TableCell>
                                <TableCell align="center">12</TableCell>
                                <TableCell align="center"><b>R$ 400,00</b></TableCell>
                            </TableRow>
                            <TableRow style={{ border: 1 }}>
                                <TableCell align="center"><b>121 a 180</b></TableCell>
                                <TableCell align="center">3</TableCell>
                                <TableCell align="center">9</TableCell>
                                <TableCell align="center">18</TableCell>
                                <TableCell align="center"><b>R$ 600,00</b></TableCell>
                            </TableRow>
                            <TableRow style={{ border: 1 }}>
                                <TableCell align="center"><b>181 ou +</b></TableCell>
                                <TableCell align="center">4</TableCell>
                                <TableCell align="center">12</TableCell>
                                <TableCell align="center">24</TableCell>
                                <TableCell align="center"><b>R$ 800,00</b></TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </>
    )
}

export const GradeMedalhasValoresPublica = () => {
    return (
        <>
            <Grid item sm={1} lg={1}></Grid>
            <Grid item sm={10} lg={10}>
                <TableContainer component="div">
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                            <TableCell align="center" style={{ backgroundColor: '#CACACA' }}>Nível</TableCell>
                                <TableCell align="center" style={{ backgroundColor: '#CACACA' }}>Quantidade de Alunos Inscritos</TableCell>
                                <TableCell align="center" style={{ backgroundColor: '#B68D4A', color: '#FFFFFF' }}><b>OURO</b></TableCell>
                                <TableCell align="center" style={{ backgroundColor: '#A3A2A0', color: '#FFFFFF' }}><b>PRATA</b></TableCell>
                                <TableCell align="center" style={{ backgroundColor: '#A46628', color: '#FFFFFF' }}><b>BRONZE</b></TableCell>
                                <TableCell align="center" style={{ backgroundColor: '#CACACA' }}>Valor da inscrição <b>POR NÍVEL</b></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow style={{ border: 1 }}>
                            <TableCell align="center" component="th" scope="row" rowSpan={4} style={{ backgroundColor: '#FFE699' }}>MIRIM 1</TableCell>
                                <TableCell align="center"><b>1 a 60</b></TableCell>
                                <TableCell align="center">1</TableCell>
                                <TableCell align="center">3</TableCell>
                                <TableCell align="center">6</TableCell>
                                <TableCell align="center"><b>R$ 200,00</b></TableCell>
                            </TableRow>
                            <TableRow style={{ border: 1 }}>
                                <TableCell align="center"><b>61 a 120</b></TableCell>
                                <TableCell align="center">2</TableCell>
                                <TableCell align="center">6</TableCell>
                                <TableCell align="center">12</TableCell>
                                <TableCell align="center"><b>R$ 400,00</b></TableCell>
                            </TableRow>
                            <TableRow style={{ border: 1 }}>
                                <TableCell align="center"><b>121 a 180</b></TableCell>
                                <TableCell align="center">3</TableCell>
                                <TableCell align="center">9</TableCell>
                                <TableCell align="center">18</TableCell>
                                <TableCell align="center"><b>R$ 600,00</b></TableCell>
                            </TableRow>
                            <TableRow style={{ border: 1 }}>
                                <TableCell align="center"><b>181 ou +</b></TableCell>
                                <TableCell align="center">4</TableCell>
                                <TableCell align="center">12</TableCell>
                                <TableCell align="center">24</TableCell>
                                <TableCell align="center"><b>R$ 800,00</b></TableCell>
                            </TableRow>
                            <TableRow style={{ border: 1 }}>
                                <TableCell align="center" component="th" scope="row" rowSpan={4} style={{ backgroundColor: '#FFCCFF' }}>MIRIM 2</TableCell>
                                <TableCell align="center"><b>1 a 60</b></TableCell>
                                <TableCell align="center">1</TableCell>
                                <TableCell align="center">3</TableCell>
                                <TableCell align="center">6</TableCell>
                                <TableCell align="center"><b>R$ 200,00</b></TableCell>
                            </TableRow>
                            <TableRow style={{ border: 1 }}>
                                <TableCell align="center"><b>61 a 120</b></TableCell>
                                <TableCell align="center">2</TableCell>
                                <TableCell align="center">6</TableCell>
                                <TableCell align="center">12</TableCell>
                                <TableCell align="center"><b>R$ 400,00</b></TableCell>
                            </TableRow>
                            <TableRow style={{ border: 1 }}>
                                <TableCell align="center"><b>121 a 180</b></TableCell>
                                <TableCell align="center">3</TableCell>
                                <TableCell align="center">9</TableCell>
                                <TableCell align="center">18</TableCell>
                                <TableCell align="center"><b>R$ 600,00</b></TableCell>
                            </TableRow>
                            <TableRow style={{ border: 1 }}>
                                <TableCell align="center"><b>181 ou +</b></TableCell>
                                <TableCell align="center">4</TableCell>
                                <TableCell align="center">12</TableCell>
                                <TableCell align="center">24</TableCell>
                                <TableCell align="center"><b>R$ 800,00</b></TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </>
    )
}

export default Modalidade;