import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadContext } from '../../store/actions/Contexto';
import { isAfterDataProvaFase1, isAfterDataFimInscricaoEDataFimPagamento } from '../../helper/ContextHelper';

import Grid from '@material-ui/core/Grid';

import Table from '../../components/Table/Table';

import { evasaoLimiterMask } from '../../helper/MaskHelper';

export class DadosMunicipios extends Component {
    constructor (props) {
        super(props)
        props.parentRef(this);
        this.state = {
            municipios: [],
        }
    }

    componentDidMount () { this.props.loadContext() }

    sumMunicipios = () => this.state.municipios.length;

    sumEscolas = () => this.state.municipios.reduce((total, mun) => total + mun.qtdEscolas, 0);
    sumEscolasInscritas = () => this.state.municipios.reduce((total, mun) => total + mun.qtdEscolasInscritas, 0);
    sumEscolasAguardandoPagamento = () => this.state.municipios.reduce((total, mun) => total + mun.qtdEscolasAguardandoPagamento, 0);
    sumAlunosPrevistos = () => this.state.municipios.reduce((total, mun) => total + mun.qtdAlunosPrevistos, 0);
    sumAlunosParticipantes = () => this.state.municipios.reduce((total, mun) => total + mun.qtdAlunosParticipantes, 0);

    calcEvasao = () => 100 - ((this.sumAlunosParticipantes() * 100) / this.sumAlunosPrevistos());

    sumAlunosParticipantesFase2Mirim1 = () => this.state.municipios.reduce((total, mun) => total + mun.qtdAlunosParticipantesFase2Mirim1, 0);
    sumAlunosParticipantesFase2Mirim2 = () => this.state.municipios.reduce((total, mun) => total + mun.qtdAlunosParticipantesFase2Mirim2, 0);
    sumAlunosParticipantesFase2 = () => this.state.municipios.reduce((total, mun) => total + mun.qtdAlunosParticipantesFase2, 0);

    sumAlunosInscritosFase2Mirim1 = () => this.state.municipios.reduce((total, mun) => total + mun.qtdAlunosInscritosFase2Mirim1, 0);
    sumAlunosInscritosFase2Mirim2 = () => this.state.municipios.reduce((total, mun) => total + mun.qtdAlunosInscritosFase2Mirim2, 0);
    sumAlunosInscritosFase2 = () => this.state.municipios.reduce((total, mun) => total + mun.qtdAlunosInscritosFase2, 0);

    render () {
        const columns = [
            { label: 'UF', name: 'nmUf', func: null, key: true, witdh: '5', },
            { label: 'Municipio', name: 'nmMunicipio', func: null, key: false, width: '10', },			
            { label: 'Escolas', name: 'qtdEscolas', func: null, key: false, width: '7', },
            
			{ label: 'Escolas Inscritas', name: 'qtdEscolasInscritas', func: null, key: false, width: '7', },
            { label: 'Escolas Aguardando Pagamento', name: 'qtdEscolasAguardandoPagamento', func: null, key: false, width: '7', hidden: isAfterDataFimInscricaoEDataFimPagamento(this.props.contexts), },
            { label: 'Fase 1 - Alunos Previstos', name: 'qtdAlunosPrevistos', func: null, key: false, width: '7', },
            { label: 'Fase 1 - Alunos Participantes', name: 'qtdAlunosParticipantes', func: null, key: false, width: '7', },
            { label: 'Fase 1 - Evasão Total', name: 'evasaoTotal', func: isAfterDataProvaFase1(this.props.contexts) ? evasaoLimiterMask : () => "Prova Não Realizada", key: false, width: '7', },

            { label: 'Fase 2 - Mirim 1 - Alunos Inscritos', name: 'qtdAlunosInscritosFase2Mirim1', func: null, key: false, width: '7', },
            { label: 'Fase 2 - Mirim 2 - Alunos Inscritos', name: 'qtdAlunosInscritosFase2Mirim2', func: null, key: false, width: '7', },
            { label: 'Fase 2 - Alunos Inscritos', name: 'qtdAlunosInscritosFase2', func: null, key: false, width: '7', },

            { label: 'Fase 2 - Mirim 1 - Alunos Com Nota Lançada', name: 'qtdAlunosParticipantesFase2Mirim1', func: null, key: false, width: '7', },
            { label: 'Fase 2 - Mirim 2 - Alunos Com Nota Lançada', name: 'qtdAlunosParticipantesFase2Mirim2', func: null, key: false, width: '7', },
            { label: 'Fase 2 - Alunos Com Nota Lançada', name: 'qtdAlunosParticipantesFase2', func: null, key: false, width: '7', },
        ];
        
        const actions = [];
		
		let municipiosContent = [...this.state.municipios];

		municipiosContent.push({
			nmUf: "",
			nmMunicipio: this.sumMunicipios(),
			qtdEscolas: this.sumEscolas(),
			qtdEscolasInscritas: this.sumEscolasInscritas(),
            qtdEscolasAguardandoPagamento: this.sumEscolasAguardandoPagamento(),
			qtdAlunosPrevistos: this.sumAlunosPrevistos(),
			qtdAlunosParticipantes: this.sumAlunosParticipantes(),
			evasaoTotal: isAfterDataProvaFase1(this.props.contexts) ? evasaoLimiterMask(this.calcEvasao()) : () => "Prova Não Realizada",
            qtdAlunosInscritosFase2Mirim1: this.sumAlunosInscritosFase2Mirim1(),
            qtdAlunosInscritosFase2Mirim2: this.sumAlunosInscritosFase2Mirim2(),
            qtdAlunosInscritosFase2: this.sumAlunosInscritosFase2(),
            qtdAlunosParticipantesFase2Mirim1: this.sumAlunosParticipantesFase2Mirim1(),
            qtdAlunosParticipantesFase2Mirim2: this.sumAlunosParticipantesFase2Mirim2(),
            qtdAlunosParticipantesFase2: this.sumAlunosParticipantesFase2(),
		});
        
        return (
            <Grid container spacing={3} style={{ marginTop: this.props.marginTop ?? 8 }}>
                <Grid item xs={12}>
                    <Table              
                        columns={columns}
                        tableData={municipiosContent}
                        actions={actions}
                        page={null}
                        rowsPerPage={null}
                        sort={null}
                        fixedHeader={true}
                    />
                </Grid>
            </Grid>
        )
    }
}

const mapStateToProps = state => ({ contexts: state.contexts });
const mapDispatchToProps = dispatch => (bindActionCreators({ loadContext }, dispatch));

export default connect(mapStateToProps, mapDispatchToProps)(DadosMunicipios);