export const patternCity = options => {
    let array = [];

    options.forEach( option => {
        array.push({ value: option.cdMunicipioRegiao, label: option.nmMunicipio })        
    });

    return array;
}

export const patternRegion = (options, withRJ04 = false) => {
    let array = [];

    options.forEach( option => {
        array.push({ value: option.cdRegiao, label: option.cdRegiao })
    });

    if (withRJ04)
        array.push({ value: "RJ04", label: "RJ04" });

    array.sort((a, b) => a.label.localeCompare(b.label));
    return array;
}

export default patternCity;