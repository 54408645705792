import React from 'react';

import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';

import Title from '../../components/Title/Title';

import useStyles from './Style';

const Fases = () => {
    const classes = useStyles();
    return (
        <>
            <Title id="anchor-06" className={classes.title}>
                6. DAS FASES 
            </Title>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>6.1</span> A Olimpíada Mirim - OBMEP é composta de 2 (duas) Fases – 1ª Fase e 2ª Fase. Todos os alunos inscritos pela escola na Olimpíada Mirim - OBMEP farão a prova da 1ª Fase 
                e apenas os classificados realizarão a prova da 2ª Fase.
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>6.2</span> Cada escola deve indicar na Ficha de Inscrição apenas o número total de seus alunos inscritos em cada Nível (MIRIM 1 – 2º e 3º anos e MIRIM 2 – 4º e 5º anos) para 
                a 1ª Fase da Olimpíada Mirim - OBMEP, não sendo necessária a inscrição nominal dos(as) alunos(as).
            </Typography>
            
            <Typography className={classes.text} component={'div'} variant="body2">
                <span className={classes.item}>6.3</span> Os(as) alunos(as) participantes da Olimpíada Mirim - OBMEP, <span className={classes.bolder}>serão divididos em 2 (dois) níveis</span>, de acordo com o grau de escolaridade em que estiverem matriculados:

                <Grid 
                    container 
                    align='center' 
                    direction="row" 
                    justify="center"
                    alignItems="center"
                    className={classes.wrapper}
                >
                    <Grid item xs={12} sm={8} lg={6} xl={6} >
                        <TableContainer component="div">
                            <Table className={classes.table} size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.bolder} width="40%">NÍVEL</TableCell>
                                        <TableCell className={classes.bolder} align="right">GRAU DE ESCOLARIDADE</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell className={classes.bolder} width="40%" component="th" scope="row">Mirim 1</TableCell>
                                        <TableCell align="right">2º e 3º anos do ensino fundamental</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className={classes.bolder} width="40%" component="th" scope="row">Mirim 2</TableCell>
                                        <TableCell align="right">4º e 5º anos do ensino fundamental</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>6.4</span> Os(as) alunos(as) do modelo de ensino denominado Educação de Jovens e Adultos (EJA) também serão distribuídos conforme a regra acima.
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>6.5</span> Quando se tratar de modelo com outras formas de seriação, deverá ser utilizada a equivalência com as séries regulares, de acordo com as orientações do MEC.
            </Typography>
        </>
    )
}

export default Fases;