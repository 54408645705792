import React from 'react'

import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

import { patternAdminDep } from '../../variables/Enums/DependenciaAdministrativa'

import { 
    patternAdminDepConvenio, 
    patternAdminDepConvenioAlunos,
    patternPossuiConvenio
} from '../../variables/Enums/ConvenioEnum'

import { cnpjMask } from '../../helper/MaskHelper';

const DadosEscola = (props) => {
    return (
        <Paper elevation={3} style={{ padding: 24, marginTop: 24 }}>
            <Grid item sm={12} lg={12} style={{ marginBottom: 10 }}>
                <Typography variant="body2"><b>Dados da Escola</b></Typography>
            </Grid>

            <Grid item sm={12} lg={12} >
                <Typography variant="body2">
                    INEP: <b>{props.escola.cdEscola}</b> &nbsp;&nbsp;&nbsp; Nome da Escola: <b>{props.escola.nome}</b>
                </Typography>
                <Typography variant="body2">
                    Dependência Administrativa: <b>{patternAdminDep(props.escola.dependenciaAdministrativa)}</b>
                </Typography>
                {props.escola.cnpj &&
                    <Typography variant="body2">
                        CNPJ: <b>{cnpjMask(props.escola.cnpj)}</b>
                    </Typography>
                }
                {props.escola.dependenciaAdministrativa === 'PRIVADA' &&
                    <>
                        <Typography variant="body2">
                            Possiu Convênio: <b>{patternPossuiConvenio(props.escola.possuiConvenio)}</b>
                        </Typography>
                        {props.escola.possuiConvenio &&
                            <>
                                {props.escola.dependenciaConvenio && 
                                    <Typography variant="body2">
                                        Dependência de Convênio: <b>{patternAdminDepConvenio(props.escola.dependenciaConvenio)}</b>
                                    </Typography>
                                }
                                {props.escola.dependenciaAlunoConvenio &&
                                    <Typography variant="body2">
                                        Dependência Administrativa dos Alunos: <b>{patternAdminDepConvenioAlunos(props.escola.dependenciaAlunoConvenio)}</b>
                                    </Typography>
                                }
                            </>
                        }
                    </>
                }
                <Typography variant="body2">
                    Email: <b>{props.escola.email}</b>
                </Typography>
                <Typography variant="body2">
                    Telefones: <b>{props.escola.telefones ? props.escola.telefones : "Não Informado"}</b>
                </Typography>
            </Grid>
        </Paper>
    )
}

export default DadosEscola;