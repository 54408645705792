import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import ReportIcon from '@material-ui/icons/Report';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import Divider from '@material-ui/core/Divider';
import SearchIcon from '@material-ui/icons/Search';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import { amber, red, green, cyan } from '@material-ui/core/colors';

import { ButtonPrimary } from '../../components/Button/Button';
import SelectOption from '../../components/Inputs/Select/Select';
import Text from '../../components/Inputs/Text/Text';
import Toast from "../../components/Toast/Toast";
import Boolean from '../../components/Inputs/Boolean/Boolean';

import { 
    optionsStatusOcorrenciaConvenio,
    optionsStatusOcorrencia, 
    patternTipoOcorrencia, 
    patternSchool
} from '../../variables/Enums/Escola';
import { optionsConvenio, optionsTipoConvenio } from '../../variables/Enums/ConvenioEnum';

import {
    isBlank,
    isBlankHelperText,
} from '../../helper/ValidationHelper';
import { mecCodeMask } from '../../helper/MaskHelper';

import { __ESCOLA_ADICIONAR_OPCOES_AVANCADAS } from '../../security/RoleConfiguration';
import AuthorizedElement from '../../security/AuthorizedElement';

import EscolaService from '../../services/Escola';
import { errors } from '../../services/API';

import AlertaOcorrenciaEscolaNovaTrocandoINEP from './AlertaOcorrenciaEscolaNovaTrocandoINEP';

const LIMITE_CARACTERES_JUSTIFICATIVA = 400;
const __MECCODE_LENGTH = 8;

export class EscolaOcorrencia extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mecCode: props.escola.cdMecEscola,
            id: props.ocorrencia.id,
            status: props.ocorrencia.status,
            statusAtual: props.ocorrencia.status,
            tipo: props.ocorrencia.tipo,
            justificativa: props.ocorrencia.justificativa,
            dataTratamento: props.ocorrencia.dataTratamento,
            usuarioTratamento: props.ocorrencia.usuarioTratamento,
            possuiConvenio: props.escola.possuiConvenio,
            depConvenio: props.escola.depConvenio,
            tipoConvenio: props.escola.tipoConvenio,
            mecCodeEscolaCorreta: props.ocorrencia.escolaCorreta ? props.ocorrencia.escolaCorreta.cdMecEscola : "",
            escolaCorreta: props.ocorrencia.escolaCorreta ? patternSchool(props.ocorrencia.escolaCorreta) : null,
            errors: {
                status: false,
                justificativa: false,
                depConvenio : false,
                mecCodeEscolaCorreta: false,
            },
            helpers: {
                status: null,
                justificativa: null,
                depConvenio : null,
                mecCodeEscolaCorreta: null,
            },
            loading: false,
            loadingSearchEscolaCorreta: false,
        }
    }

    setToast = t => this.Toast = t;

    // INPUTS
    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.name === "mecCodeEscolaCorreta" ? mecCodeMask(e.target.value) : e.target.value,
            errors: {...this.state.errors, [e.target.name]: false },
            helpers: {...this.state.helpers, [e.target.name]: null }
        });
    }

    isOcorrenciaInvalidada = () => this.state.status === "INVALIDA";
    isTrocarConvenio = () => this.state.tipo === "CONVENIO_TOTAL" && this.state.status === "INVALIDA" && this.state.possuiConvenio === "true";
    isTrocarINEP = () => this.state.tipo === "ESCOLA_NOVA" && this.state.status === "INVALIDA_INFORMANDO_OUTRO_INEP";

    isTratamentoAberto = () => this.state.statusAtual === "EM_ANALISE" || this.state.statusAtual === "TRATAMENTO_INICIADO";

    handleSubmit = () => {
        this.setState({ loading: true });

        if (!this.isValid()) {
            this.setState({ loading: false });
            return;
        }

        const ocorrencia = {
            status: this.state.status,
            justificativa: this.isOcorrenciaInvalidada() ? this.state.justificativa : null,
            depConvenio: this.isTrocarConvenio() ? this.state.depConvenio : null,
            tipoConvenio: this.isTrocarConvenio() ? this.state.tipoConvenio : null,
            escolaCorreta: this.isTrocarINEP() ? this.state.mecCodeEscolaCorreta : null,
        }

        EscolaService.tratarOcorrencia(this.state.mecCode, this.state.id, ocorrencia)
            .then(res => {
                this.Toast.setState({
                    message: {
                        message: "Ocorrência Tratada",
                        type: "success",
                        open: true
                    }
                });

                this.setState({
                    ...this.state,
                    statusAtual: res.data.status,
                    dataTratamento: res.data.dataTratamento,
                    usuarioTratamento: res.data.usuarioTratamento,
                })
            })
            .catch(error => {
                const e = errors(error);
                this.Toast.setState({
                    message: {
                        message: e.message,
                        type: e.type,
                        open: true
                    }
                })
            })
            .finally(() => this.setState({ loading: false }));
    }

    // VALIDAÇÕES
    isValid = () => {
        if (this.state.status === "EM_ANALISE"
            || (this.isOcorrenciaInvalidada() && isBlank(this.state.justificativa))
            || (this.isTrocarConvenio() && this.state.depConvenio === 'TOTAL') 
            || (this.isTrocarINEP() && (isBlank(this.state.mecCodeEscolaCorreta) || this.state.escolaCorreta == null))) {
            this.setState({
                errors: {
                    status: this.state.status === "EM_ANALISE" ? true : false,
                    justificativa: this.isOcorrenciaInvalidada() && isBlank(this.state.justificativa) ? true : false,
                    depConvenio: this.isTrocarConvenio() && this.state.depConvenio === 'TOTAL' ? true : false,
                    mecCodeEscolaCorreta: this.isTrocarINEP() && (isBlank(this.state.mecCodeEscolaCorreta) || this.state.escolaCorreta == null) ? true : false,
                },
                helpers: {
                    status: this.state.status === "EM_ANALISE" ? "A Ocorrência não pode ser colocada como 'Em Análise'" : null,
                    justificativa: this.isOcorrenciaInvalidada() && isBlank(this.state.justificativa) ? isBlankHelperText() : null,
                    depConvenio: this.isTrocarConvenio() && this.state.depConvenio === 'TOTAL' ? "A Dependência do Convênio não pode ser 'Todos da Rede Pública' para Invalidar uma Ocorrência" : null,
                    mecCodeEscolaCorreta: this.isTrocarINEP() ? isBlank(this.state.mecCodeEscolaCorreta) ? isBlankHelperText() : this.state.escolaCorreta == null ? "Busque a Escola Antes de Realizar o Tratamento" : null : null,
                },
            });
            return false;
        }
        return true;
    }   

    handleChangeCheckbox = e => {
        this.setState({
            [e.target.name]: e.target.checked,
        });
    }

    handleSearchEscolaCorreta = () => {
        this.setState({ loadingSearchEscolaCorreta: true });

        if (this.state.mecCodeEscolaCorreta.length !== __MECCODE_LENGTH || this.state.mecCode === this.state.mecCodeEscolaCorreta ) {
            this.setState({
                errors: { mecCodeEscolaCorreta: true, },
                helpers: { 
                    mecCodeEscolaCorreta: this.state.mecCodeEscolaCorreta.length !== __MECCODE_LENGTH ? "Preencha o campo com um Código INEP de 8 Dígitos" :
                        this.state.mecCode === this.state.mecCodeEscolaCorreta ? "Preencha o campo com um Código INEP Diferente da Escola Nova que está sendo Tratada" : "", 
                },
                loadingSearchEscolaCorreta: false,
            });
            return
        }

        EscolaService.find(this.state.mecCodeEscolaCorreta)
            .then(res => {                
                if (res.status === 200) {
                    const school = res.data

                    if (school.inNova === true && school.nmStatus !== "VALIDA") {
                        this.setState({
                            escolaCorreta: null,
                            errors: { mecCodeEscolaCorreta: true, },
                            helpers: { mecCodeEscolaCorreta: "Para Selecionar uma Escola Nova, ela Precisa ser uma Escola Válida", },
                        });                        
                    } else {
                        this.setState({
                            escolaCorreta: patternSchool(school),
                            errors: { mecCodeEscolaCorreta: false, },
                            helpers: { mecCodeEscolaCorreta: null, },
                        });
                    }
                } else {
                    this.Toast.setState({
                        message: {
                            message: "Escola Não Encontrada",
                            type: "error",
                            open: true
                        }
                    });

                    this.setState({
                        errors: { mecCodeEscolaCorreta: true, },
                        helpers: { mecCodeEscolaCorreta: "Escola Não Encontrada", },
                        loadingSearchEscolaCorreta: false,
                    });
                }
            })
            .catch(error => {
                const e = errors(error);
                this.Toast.setState({
                    message: {
                        message: e.message,
                        type: e.type,
                        open: true
                    }
                })
            })
            .finally(() => this.setState({ loadingSearchEscolaCorreta: false }));
    }

    render () {
        return (
            <>
                <Toast parentRef={this.setToast} />
                <AuthorizedElement roles={[__ESCOLA_ADICIONAR_OPCOES_AVANCADAS]} >                
                    <Paper elevation={2} style={{ marginBottom: 10, padding: 24 }}>
                        <Grid item container sm={12} lg={12} spacing={3}>
                            <Grid item lg={3} sm={3}> 
                                <span style={{ display: "flex", alignItems: "center" }} >
                                    {this.state.tipo && 
                                        <>
                                            <Typography component="div" variant="body2" display="inline" >Tipo de Ocorrencia: </Typography> &nbsp;
                                            <Typography component="div" variant="overline" style={{ fontWeight: "bold", fontSize: 18, color: "#01306e" }}>{patternTipoOcorrencia(this.state.tipo)}</Typography> &nbsp;
                                        </> 
                                    }

                                    { this.state.statusAtual === "EM_ANALISE" ? 
                                        <Tooltip title="Ocorrência Precisa de Validação" TransitionComponent={Zoom}><ReportProblemIcon style={{ color: amber[700] }} /></Tooltip>
                                    : this.state.statusAtual === "TRATAMENTO_INICIADO" ? 
                                        <Tooltip title="Ocorrência em Tratamento" TransitionComponent={Zoom}><ReportProblemIcon style={{ color: cyan[700] }} /></Tooltip>
                                    : this.state.statusAtual === "INVALIDA" || this.state.statusAtual === "INVALIDA_INFORMANDO_OUTRO_INEP" ? 
                                        <Tooltip title="Ocorrência Inválida" TransitionComponent={Zoom}><ReportIcon style={{ color: red[700] }} /></Tooltip> 
                                    : this.state.statusAtual === "VALIDA" ? 
                                        <Tooltip title="Ocorrência Válida" TransitionComponent={Zoom}><CheckCircleIcon style={{ color: green[700] }} /></Tooltip>  
                                    : null }
                                </span>

                                <br />                            
                                {!this.isTratamentoAberto() &&
                                    <Typography variant="body2" >Tratado por <b>{this.state.usuarioTratamento}</b> em {this.state.dataTratamento}</Typography>
                                }
                            </Grid>
                            <Grid item sm={2} lg={2}>
                                <SelectOption
                                    required
                                    disabled={!this.isTratamentoAberto() ? true : false}
                                    label="Status"
                                    name='status'
                                    value={this.state.status}
                                    onChange={(e) => this.handleChange(e)}
                                    options={this.state.tipo === "CONVENIO_TOTAL" ? optionsStatusOcorrenciaConvenio : optionsStatusOcorrencia}
                                    hiddenblank="true"
                                    error={this.state.errors.status}
                                    helperText={this.state.helpers.status}
                                />
                            </Grid>
                            <Grid item lg={7} sm={7} alignContent='flex-end'>
                                {(this.isOcorrenciaInvalidada() || this.isTrocarINEP()) &&
                                    <>
                                        {this.isTrocarINEP() ?
                                            <Grid item container sm={12} lg={12} spacing={2}>
                                                {this.isTratamentoAberto() &&
                                                    <Grid item sm={12} lg={12}>
                                                        <Typography variant="body2">
                                                            O Tratamento de Escola Novas como <b>Inválida Informando Outro INEP</b>, deve ser realizado para as escolas que já são cadastradas  
                                                            no sistema e já estão na Lista de Escolas, porém se inscreveram, ou iniciaram uma inscrição, como uma Escola Nova com um INEP diferente do seu 
                                                            INEP registrado na Lista de Escolas. Abaixo, digite o INEP correto para esta Escola Nova e verifique as ações que serão feitas automáticamente 
                                                            pelo sistema para fazer este tratamento.
                                                        </Typography>
                                                    </Grid>
                                                }
                                                <Grid item sm={12} lg={12}>
                                                    <Text
                                                        required
                                                        disabled={this.state.escolaCorreta || !this.isTratamentoAberto()}
                                                        name="mecCodeEscolaCorreta"
                                                        label="INEP da Escola Correta"
                                                        value={this.state.escolaCorreta ? `${this.state.escolaCorreta.cdMecEscola} - ${this.state.escolaCorreta.nmEscola}` : this.state.mecCodeEscolaCorreta}
                                                        error={this.state.errors.mecCodeEscolaCorreta}
                                                        onChange={this.handleChange}
                                                        helperText={this.state.helpers.mecCodeEscolaCorreta}
                                                        inputProps={{
                                                            maxLength: __MECCODE_LENGTH,
                                                        }}
                                                        InputProps={{
                                                            endAdornment: 
                                                                <InputAdornment position="end">
                                                                    {this.state.escolaCorreta ?
                                                                        <>
                                                                            <Tooltip title="Limpar Escola" TransitionComponent={Zoom}>
                                                                                <IconButton 
                                                                                    size="small"
                                                                                    color="primary"
                                                                                    aria-label="Limpar Escola"
                                                                                    disabled={!this.isTratamentoAberto()}
                                                                                    onClick={() => this.setState({ mecCodeEscolaCorreta: '', escolaCorreta: null })}
                                                                                >
                                                                                    <ClearOutlinedIcon />
                                                                                </IconButton>
                                                                            </Tooltip>

                                                                            <Divider style={{ height: 25, margin: 4, }} orientation="vertical" />

                                                                            <Tooltip title="Ver Detalhes da Escola" TransitionComponent={Zoom}>
                                                                                <IconButton 
                                                                                    size="small"
                                                                                    color="primary" 
                                                                                    component="a"
                                                                                    href={`${process.env.REACT_APP_PUBLIC_URL}/admin/escola/${this.state.escolaCorreta.cdMecEscola}/editar`}
                                                                                    target="_blank"
                                                                                    aria-label="Ver Detalhes da Escola"
                                                                                >
                                                                                    <OpenInNewIcon/>
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        </>
                                                                    : this.state.loadingSearchEscolaCorreta ? <CircularProgress />
                                                                    : this.state.mecCodeEscolaCorreta &&
                                                                        <Tooltip title="Buscar Escola" TransitionComponent={Zoom}>
                                                                            <IconButton 
                                                                                size="small"
                                                                                color="primary" 
                                                                                aria-label="Buscar Escola"
                                                                                disabled={!this.isTratamentoAberto()}
                                                                                onClick={this.handleSearchEscolaCorreta}
                                                                            >
                                                                                <SearchIcon />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    }
                                                                </InputAdornment>,
                                                        }}
                                                    />
                                                </Grid>
                                                {(this.state.escolaCorreta && this.isTratamentoAberto()) &&
                                                    <Grid item sm={12} lg={12}>
                                                        <AlertaOcorrenciaEscolaNovaTrocandoINEP escolaNova={this.props.escola} escolaCorreta={this.state.escolaCorreta} />
                                                    </Grid>
                                                }
                                            </Grid>
                                        : (this.state.tipo === "CONVENIO_TOTAL" && this.state.status === "INVALIDA") &&
                                            <Grid item container sm={12} lg={12} spacing={2}>
                                                <Grid item sm={3} lg={3}>
                                                    <Boolean
                                                        disabled={this.state.statusAtual === "INVALIDA" ? true : false}
                                                        label="Sua escola é convêniada?"
                                                        name='possuiConvenio'
                                                        value={this.state.possuiConvenio}
                                                        onChange={(e) => this.handleChange(e)}
                                                        hiddenblank="true"
                                                    />
                                                </Grid>
                                                { this.state.possuiConvenio === "true" && 
                                                    <>
                                                        <Grid item sm={4} lg={4}>
                                                            <SelectOption
                                                                required
                                                                disabled={this.state.statusAtual === "INVALIDA" ? true : false}
                                                                label="Dependência de Convênio"
                                                                name='depConvenio'
                                                                value={this.state.depConvenio}
                                                                error={this.state.errors.depConvenio}
                                                                onChange={(e) => this.handleChange(e)}
                                                                options={optionsConvenio}
                                                                hiddenblank="true"
                                                                helperText={this.state.helpers.depConvenio}
                                                            />
                                                        </Grid>

                                                        { (this.state.depConvenio === 'PARCIAL' || this.state.depConvenio === "TOTAL") && 
                                                            <Grid item sm={5} lg={5}>
                                                                <SelectOption
                                                                    required
                                                                    disabled={this.state.statusAtual === "INVALIDA" ? true : false}
                                                                    label="Informe a Dependência Administrativa dos alunos"
                                                                    name='tipoConvenio'
                                                                    value={this.state.tipoConvenio}
                                                                    onChange={(e) => this.handleChange(e)}
                                                                    options={optionsTipoConvenio}
                                                                    hiddenblank="true"
                                                                />
                                                            </Grid> 
                                                        }
                                                    </>
                                                }
                                            </Grid>
                                        }

                                        {this.isOcorrenciaInvalidada() &&
                                            <Grid item container sm={12} lg={12} spacing={3}>
                                                <Text
                                                    style={{ marginLeft: '8px' }}
                                                    required
                                                    disabled={!this.isTratamentoAberto() ? true : false}
                                                    multiline={true}
                                                    rows={3}
                                                    name="justificativa"
                                                    label="Justificativa de Status"
                                                    value={this.state.justificativa ?? ""}
                                                    error={this.state.errors.justificativa}
                                                    onChange={this.handleChange}
                                                    helperText={ `${this.state.helpers.justificativa ?? ""} Caracteres: ${this.state.justificativa ? this.state.justificativa.length : 0}/${LIMITE_CARACTERES_JUSTIFICATIVA}`}
                                                    inputProps={{ maxLength: LIMITE_CARACTERES_JUSTIFICATIVA }}
                                                />
                                            </Grid>
                                        }
                                    </>
                                }

                                {this.isTratamentoAberto() &&
                                    <Grid item container sm={12} lg={12} spacing={2} justify='flex-end'>
                                        <ButtonPrimary
                                            startIcon={this.state.loading ? <CircularProgress size={16} /> : <CheckOutlinedIcon />}
                                            style={{ margin: 4 }}
                                            onClick={this.handleSubmit}
                                            name="Tratar Ocorrência"
                                            disabled={this.state.loading}
                                        />
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                    </Paper>
                </AuthorizedElement>
            </>
        )
    }
}

export default EscolaOcorrencia;