import React from 'react';

import Typography from '@material-ui/core/Typography';

import Title from '../../components/Title/Title';

import useStyles from './Style';

const ProvaFase1 = () => {
    const classes = useStyles();
    return (
        <>
            <Title id="anchor-07" className={classes.title}>
                7. DA PROVA DA 1ª FASE
            </Title>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>7.1</span> A Olimpíada Mirim - OBMEP é constituída por 2 (duas) Fases – 1ª Fase e 2ª Fase. A prova da 1ª Fase é composta de <span className={classes.bolder}>15 (quinze) questões objetivas</span> (múltipla escolha), valendo 1 
                (um) ponto cada, totalizando 15 (quinze) pontos, sendo certo que cada questão dispõe de 5 (cinco) opções de resposta (A, B, C, D e E), dentre as quais apenas uma delas é a correta. 
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>7.2</span> As questões propostas na prova da 1ª Fase apresentam conteúdos previstos na Base Nacional Comum Curricular (BNCC) para alunos(as) de 2º, 3º, 4º e 5º anos do Ensino Fundamental.
            </Typography>         
        </>
    )
}

export default ProvaFase1;