import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadContext } from '../../store/actions/Contexto';

import Grid from '@material-ui/core/Grid';
import VpnKeyOutlinedIcon from "@material-ui/icons/VpnKeyOutlined";
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import { amber } from "@material-ui/core/colors";

import CoordenadorService from '../../services/Coordenador';
import ImportacaoService from '../../services/Importacao';
import { errors } from '../../services/API';

import {removePagingAndSorting, verifyPersistence} from '../../helper/PaginationHelper';
import { filterPersistence } from '../../helper/ParseStateHelper';

import CoordenadorFilter from '../../forms/Coordenador/CoordenadorFilter';
import { ButtonExport } from '../../forms/Buttons/ButtonsExport';
import DialogChangePassword from "../../forms/Dialog/DialogChangePassword";

import Table from '../../components/Table/Table';
import Toast from '../../components/Toast/Toast';
import ButtonPrimary from "../../components/Button/Button";
import {
    verifyPagingAndSorting,
    PAGE,
    SIZE,
    ASC,
    TOTAL_ELEMENTS,
    TOTAL_PAGES,
} from '../../components/Table/Utils';

import {
    __COORDENADOR_EXPORTAR,
    __COORDENADOR_EDITAR_SENHA
} from '../../security/RoleConfiguration';
import AuthorizedElement from '../../security/AuthorizedElement';
import AuthorizedFunction from "../../security/AuthorizedFunction";

import { patternCoordenadores } from '../../variables/Enums/Coordenador';
import CircularProgress from "@material-ui/core/CircularProgress";

export class Coordenador extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: PAGE,
            size: SIZE,
            totalElements: TOTAL_ELEMENTS,
            totalPages: TOTAL_PAGES,
            sort: [{ orderBy: 'regiao.cdRegiao', order: ASC }],
            defaultOrderBy: 'regiao.cdRegiao',
            filtered: false,
            filters: [],
            coordenadores: [],
            loading: true,
            loadingImport: false,
            history: {
                path: null,
                state: null
            }
        }

        this.state = verifyPersistence(this.state, this.props.location);
    }

    // FECHAR TOAST
    setToast = t => this.Toast = t;
    setDialogChangePassword = f => this.DialogChangePassword = f;
    setCoordenadorFilter = e => this.CoordenadorFilter = e;

    // LABELS
    labelSemPermissao = "Você não tem permissão para fazer isso";
    labelAtualizarSenha = id => !this.disabledAtualizarSenha(id) ? "Alterar Senha" : this.labelSemPermissao;

    // DISABLES
    disabledAtualizarSenha = id => !AuthorizedFunction([__COORDENADOR_EDITAR_SENHA]);

    // ABRIR MODAL DE ALTERAR SENHA
    handleOpenAtualizarSenhaClick = id => {
        const coord = this.state.coordenadores.find(coord => coord.id === id);
        this.DialogChangePassword.setState({ open: true, id: coord.id, login: coord.login });
    }

    // EXPORTAÇÂO
    handleExportPlan = () => {
        this.CoordenadorFilter.setState({ filter: true, exportPlan: true, }, () => this.CoordenadorFilter.handleFilterApply());

        this.Toast.setState({
            message: {
                message: "A Planilha está sendo gerada para Exportação.",
                type: 'info',
                open: true,
                loading: true,
                autohide: false,
            }
        })
    }

    handleImport = () => {
        this.setState({ loadingImport: true });

        ImportacaoService.importarApoioSecretarial()
            .then(res => {
                this.Toast.setState({
                    message: {
                        message: "Importação de Apoio Secretarial Realizada com Sucesso.",
                        type: "success",
                        open: true
                    }
                });

                this.loadData();
            })
            .catch(error => {
                const e = errors(error);
                this.Toast.setState({
                    message: {
                        message: e.message,
                        type: e.type,
                        open: true
                    }
                })
            })
            .finally(() => this.setState({ loadingImport: false }))
    }

    loadData = async () => {
        await this.props.loadContext();

        this.setState({ history: filterPersistence(this.props.location) });

        let f = verifyPagingAndSorting(this.state);

        this.setState({
            filters: [...f],
            filtered: true,
            loading: true
        });

        f = f.filter(f => f.field !== 'tipo');
        f.push({ field: 'tipo', value: "APOIO_SECRETARIAL" });

        CoordenadorService.filters(f)
            .then(res => {
                if (res.data.content) {
                    this.setState({
                        filters: f,
                        coordenadores: [...patternCoordenadores(res.data.content)],
                        page: res.data.number,
                        size: res.data.size,
                        totalElements: res.data.totalElements,
                        totalPages: res.data.totalPages,
                        loading: false,
                    });
                } else {
                    this.setState({
                        filters: f,
                        coordenadores: [],
                        page: res.data.number,
                        size: res.data.size,
                        totalElements: res.data.totalElements,
                        totalPages: res.data.totalPages,
                        loading: false,
                    });
                }
            })
            .catch(error => {
                const e = errors(error);
                this.Toast.setState({
                    message: {
                        message: e.message,
                        type: e.type,
                        open: true
                    }
                })
            })
            .finally(() => this.setState({ loading: false }))
    }

    // FILTROS
    filterData = (filters, isActive = true) => this.setState({ filtered: isActive, filters: [...removePagingAndSorting(filters)] }, () => this.loadData());

    // PÁGINA
    handlePage = page => this.setState({ page: page }, () => this.filterData(this.state.filters));

    // LINHAS POR PÁGINA
    handleRowsPerPage = (size, page) => this.setState({ size: size, page: page }, () => this.filterData(this.state.filters));

    // ORDENAÇÃO
    handleSort = (orderBy, order) => {
        if (this.state.sort.find(s => s.orderBy === orderBy)) {
            let newSort = this.state.sort.filter(s => s.orderBy !== orderBy);
            newSort.splice(this.state.sort.indexOf(this.state.sort.find(s => s.orderBy === orderBy)), 0, { orderBy: orderBy, order: order })
            this.setState({...this.state, sort: [...newSort], filtered: true });
        } else {
            this.setState({ ...this.state, sort: [...this.state.sort.filter(s => s.orderBy !== orderBy), { orderBy: orderBy, order: order }], filtered: true });
        }
    }
    handleClearSort = (orderBy) => {
        this.setState({ ...this.state, filtered: true, sort: orderBy ? [...this.state.sort.filter(s => s.orderBy !== orderBy)] : [{ orderBy: this.state.defaultOrderBy, order: ASC }] });
    };

    // TOTAL DE PAGINAS
    handleTotalPages = () => this.state.totalPages;

    // TOTAL DE ELEMENTOS
    handleTotalElements = () => this.state.totalElements;

    // VERIFICAR PERSISTENCIA NO FORMULARIO DE FILTROS
    handleFilterPersistence = () => this.CoordenadorFilter ? this.CoordenadorFilter.state ? this.CoordenadorFilter.handleFilterPersistence(this.state.filters) : null : null;

    // COMPONENT DID MOUNT
    componentDidMount() { this.loadData(); }

    render() {
        const columns = [
            { label: '#', name: 'id', func: null, key: true, hidden: true },
            { label: 'Login', name: 'login', func: null, key: false },
            { label: 'Nome', name: 'nome', func: null, key: false, filter: 'nmCoordenador' },
            { label: 'Região', name: 'regiao', func: null, key: false, filter: 'regiao.cdRegiao' },
        ];

        const actions = [
            {
                name: this.labelAtualizarSenha,
                func: this.handleOpenAtualizarSenhaClick,
                icon: <VpnKeyOutlinedIcon />,
                disabled: this.disabledAtualizarSenha,
            }
        ];

        this.handleFilterPersistence();

        return (
            <>
                <Toast parentRef={this.setToast} />
                <Grid container >
                    <Grid item xs={12}>
                        <CoordenadorFilter
                            parentRef={this.setCoordenadorFilter}
                            handleFilterChange={this.filterData}
                            export={CoordenadorService.export}
                            disabledStatusMateriais={true}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <AuthorizedElement roles={[__COORDENADOR_EXPORTAR]}>
                            <Grid container spacing={3}>
                                {this.state.filters.length > 3 &&
                                    <ButtonExport
                                        title="Exportar Planilha"
                                        onClick={this.handleExportPlan}
                                    />
                                }
                            </Grid>
                        </AuthorizedElement>

                        <Grid container spacing={3}>
                            <Grid item sm={12} lg={4}>
                                <ButtonPrimary
                                    name="Importar Apoio Secretarial da OBMEP"
                                    startIcon={this.state.loadingImport ? <CircularProgress size={16} /> : <RecentActorsIcon />}
                                    style={this.state.loadingImport ? {} : { backgroundColor: amber[800] }}
                                    onClick={this.handleImport}
                                    disabled={this.state.loadingImport}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Table
                            columns={columns}
                            tableData={this.state.coordenadores}
                            actions={actions}
                            page={this.state.page}
                            handlePage={this.handlePage}
                            handleTotalPages={this.handleTotalPages}
                            handleTotalElements={this.handleTotalElements}
                            rowsPerPage={this.state.size}
                            handleRowsPerPage={this.handleRowsPerPage}
                            sort={this.state.sort}
                            handleSort={this.handleSort}
                            handleClearSort={this.handleClearSort}
                            loading={this.state.loading}
                        />
                    </Grid>
                </Grid>

                <AuthorizedElement roles={[__COORDENADOR_EDITAR_SENHA]}>
                    <DialogChangePassword
                        parentRef={this.setDialogChangePassword}
                        handleAtualizarSenha={CoordenadorService.atualizarSenha}
                    />
                </AuthorizedElement>
            </>
        )
    }
}

const mapStateToProps = state => ({ contexts: state.contexts });
const mapDispatchToProps = dispatch => (bindActionCreators({ loadContext }, dispatch));

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Coordenador));