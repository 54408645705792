import React from "react";

import Alert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';

export const AlertaOcorrenciaEscolaNovaTrocandoINEP = ({ escolaNova, escolaCorreta }) => {
    return (
        <Alert severity="warning" style={{ marginTop: '10px' }}>
            <Typography variant="overline"><b>ATENÇÃO</b></Typography>
            <Typography variant="body2">
                As ações que serão realizadas no Tratamento da Escola Nova como <b>Inválida Informando Outro INEP</b> para este caso, estão listadas abaixo, 
                e após Realizadas, estas ações <b>NÃO</b> poderão ser desfeitas. 
                
                <ul style={{ paddingTop: '12px', paddingLeft: '20px' }}>
                    {!escolaNova.isInscrita ?
                        <>
                            {escolaCorreta.nmStatus === "INVALIDA" ?
                                <li style={{ marginBottom: '4px' }}>
                                    A Escola selecionada <b>({escolaCorreta.cdMecEscola})</b> para vínculo com a Escola Nova <b>({escolaNova.cdMecEscola})</b>, está marcada como <b>INVÁLIDA</b>.
                                    Portanto o tratamento não terá efeito nenhum na Escola selecionada <b>{escolaCorreta.nmEscola} ({escolaCorreta.cdMecEscola})</b>{escolaCorreta.isInscrita && <>, nem em sua Inscrição</>}.
                                </li>
                            :
                                <li style={{ marginBottom: '4px' }}>
                                    A Escola selecionada <b>({escolaCorreta.cdMecEscola})</b> para vínculo com a Escola Nova <b>({escolaNova.cdMecEscola})</b>, 
                                    não sofrerá alterações{escolaCorreta.isInscrita && <>, pois já está <b>INSCRITA</b></>}.
                                </li>
                            }
                        </>                        
                    :
                        <>
                            {!escolaCorreta.isInscrita ?
                                <>
                                    {escolaCorreta.nmStatus === "INVALIDA" &&
                                        <li style={{ marginBottom: '4px' }}>
                                            A Escola selecionada <b>({escolaCorreta.cdMecEscola})</b> para vínculo com a Escola Nova <b>({escolaNova.cdMecEscola})</b>, está marcada como <b>INVÁLIDA</b>.
                                            Portanto o Tratamento fará a Modificação no Status da Escola selecionada <b>{escolaCorreta.nmEscola} ({escolaCorreta.cdMecEscola})</b> para <b>VÁLIDA</b>.
                                        </li>
                                    }

                                    <li style={{ marginBottom: '4px' }}> 
                                        O sistema irá <b>TRANSFERIR</b> os dados da Inscrição da Escola Nova <b>({escolaNova.cdMecEscola})</b> para a Escola selecionada <b>({escolaCorreta.cdMecEscola})</b>,  
                                        à saber, nos dados de <b>Responsável</b>, <b>Quantidade de Alunos</b> e <b>Endereço de Entrega</b>. Os demais dados permanecerão sendo os da Escola selecionada <b>({escolaCorreta.cdMecEscola})</b>.
                                    </li>
                                    <li style={{ marginBottom: '4px' }}> 
                                        A Escola Nova <b>{escolaNova.nmEscola} ({escolaNova.cdMecEscola})</b> deverá <b>Recuperar a Senha</b> utilizando o 
                                        INEP da Escola selecionada <b>({escolaCorreta.cdMecEscola})</b> para ter Acesso ao Sistema.
                                    </li>
                                </>
                            :
                                <>
                                    {escolaCorreta.nmStatus === "INVALIDA" ?
                                        <li style={{ marginBottom: '4px' }}>
                                            A Escola selecionada <b>({escolaCorreta.cdMecEscola})</b> para vínculo com a Escola Nova <b>({escolaNova.cdMecEscola})</b>, está marcada como <b>INVÁLIDA</b>.
                                            Portanto o tratamento não terá efeito nenhum na Escola selecionada <b>{escolaCorreta.nmEscola} ({escolaCorreta.cdMecEscola})</b>{escolaCorreta.isInscrita && <>, nem em sua Inscrição</>}.
                                        </li>
                                    :
                                        <li style={{ marginBottom: '4px' }}>
                                            A Escola selecionada <b>({escolaCorreta.cdMecEscola})</b> para vínculo com a Escola Nova <b>({escolaNova.cdMecEscola})</b>, 
                                            não sofrerá alterações{escolaCorreta.isInscrita && <>, pois já está <b>INSCRITA</b></>}.
                                        </li>
                                    }
                                </>
                            }     
                        </>
                    }

                    <li style={{ marginBottom: '4px' }}>
                        A Escola Nova <b>{escolaNova.nmEscola} ({escolaNova.cdMecEscola})</b> será 
                        marcada como <b>INVÁLIDA</b>{escolaNova.isInscrita && <>, terá sua Inscrição suspensa e Perderá seu Acesso ao Sistema</>}.
                    </li>
                </ul>
            </Typography>
        </Alert>
    )
}

export default AlertaOcorrenciaEscolaNovaTrocandoINEP;