import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { useKeycloak } from '@react-keycloak/web';

import { makeStyles } from '@material-ui/core/styles';

import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

import Pagination from './Pagination';

import AuthorizedFunction from '../../security/AuthorizedFunction';
import {
    __ADMINISTRADOR,
    __APOIO_SECRETARIAL,
    __CENTRAL,
    __COORDENADOR,
} from '../../security/RoleConfiguration';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const useStyles = makeStyles( 
    (theme) => (
        {
            banner: {
                marginTop: theme.spacing(3),
                width: '100%',
                ['@media (max-width:600px)']: { // eslint-disable-line no-useless-computed-key
                    marginTop: theme.spacing(4),  
                },
            },
            pointer: {
                cursor: 'pointer',
            }
        }
    ) 
);

export const Slider = ({ banners }) => {
    const classes = useStyles();
    const history = useHistory();

    const [keycloak, ] = useKeycloak();
    const handleSubmitSignIn = () => keycloak.login({ redirectUri: `${process.env.REACT_APP_PUBLIC_URL}/admin/inicial` });
    const handleRestrictArea = () => history.push(`/admin/${AuthorizedFunction([ __ADMINISTRADOR, __CENTRAL, __COORDENADOR, __APOIO_SECRETARIAL ]) ? `inicial` : `inicio`}`);

	const [index, setIndex] = useState(0);

    const funcLink = (e, link, toLogin) => {
        e.preventDefault();
        
        if (toLogin) {
            if (!keycloak.authenticated) 
                handleSubmitSignIn();
            else
                handleRestrictArea();            
        } else if (link) {
            if (isInternal(link)) {
                if (link.includes("/inscricao"))
                    keycloak.logout({ redirectUri: `${process.env.REACT_APP_PUBLIC_URL}/inscricao` });
                else
                    history.push({ pathname: cleanURL(link) });
            } else {
                window.location.href = verifyHTTP(link);
            }
        }
    }
    
    const url = process.env.REACT_APP_PUBLIC_URL;
    const isInternal = (link) => link.includes(url) || link.includes(url.substring(7)) || link.includes(url.substring(8)) ? true : false;
    const cleanURL = (link) => link.includes(url) ? link.replace(url, '') : link.includes(url.substring(7)) ? link.replace(url.substring(7), '') : link.replace(url.substring(8), '');
    const verifyHTTP = (link) => !link.includes("http") ? "http://" + link : link;

    return (
        <div style={{ position: 'relative' }}>
            <AutoPlaySwipeableViews
                index={index}
                onChangeIndex={(i) => setIndex(i)}
                enableMouseEvents
                interval={5000}
            >
                {banners.map( b => 
                    <a key={b.id} href="onclick" rel="noopener noreferrer" className={classes.pointer} onClick={(e) => funcLink(e, b.link, b.login)}>
                        <picture>
                            <source media="(max-width: 650px)" srcSet={`${process.env.REACT_APP_URL_PUBLICA_BANNERS}/${b.mobile}`}/>
                            <source media="(max-width: 980px)" srcSet={`${process.env.REACT_APP_URL_PUBLICA_BANNERS}/${b.desktop}`}/>
                            <img src={`${process.env.REACT_APP_URL_PUBLICA_BANNERS}/${b.desktop}`} className={classes.banner} alt={process.env.REACT_APP_NAME_GENERAL} />
                        </picture>
                    </a>    
                )}
            </AutoPlaySwipeableViews>
            { banners.length > 1 && <Pagination dots={banners.length} index={index} onChangeIndex={(i) => setIndex(i)} /> }
        </div>
    );
}

export default Slider;
