import React from 'react'

import Grid from '@material-ui/core/Grid';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import { amber, blueGrey } from '@material-ui/core/colors';

import Title from '../../components/Title/Title';
import { LinkTargetBlank } from '../../components/Link/Link';

import useStyles from './Style';

export const SegundaOlimpiadaMirim = () => {
  const classes = useStyles();

  return (
    <Grid container spacing={3} >
      <Grid item xs={12} sm={12} lg={6} xl={5} >
        <div >
          <Title variant="h6">
            FASE 1
          </Title>
          <div className={classes.file}>
            <InsertDriveFileOutlinedIcon style={{ color: amber[500] }} fontSize="large" />
            <LinkTargetBlank link="https://drive.google.com/file/d/1qOExhUNEc42sfNFo5_8CwZsDrkh6SWrg/view">PROVA - MIRIM 1</LinkTargetBlank>
          </div>

          <div className={classes.file}>
            <InsertDriveFileOutlinedIcon style={{ color: blueGrey[600] }} fontSize="large" />
            <LinkTargetBlank link="https://drive.google.com/file/d/1X-y9ery4DhthT7vziMCtKq1E39oz0gFo/view">PROVA - MIRIM 2</LinkTargetBlank>
          </div>

          <div className={classes.file}>
            <InsertDriveFileOutlinedIcon style={{ color: amber[500] }} fontSize="large" />
            <LinkTargetBlank link="https://drive.google.com/file/d/1LlYxRwl3FGx35nqwIawYOVzTbXiizs8I/view">SOLUÇÕES - MIRIM 1</LinkTargetBlank>
          </div>

          <div className={classes.file}>
            <InsertDriveFileOutlinedIcon style={{ color: blueGrey[600] }} fontSize="large" />
            <LinkTargetBlank link="https://drive.google.com/file/d/1WQDbufgEPRy6OuCLO4wqedVJg6SlLYph/view">SOLUÇÕES - MIRIM 2</LinkTargetBlank>
          </div>
        </div>
      </Grid>

      <Grid item xs={12} sm={12} lg={6} xl={5} >
        <div >
          <Title variant="h6">
            FASE 2
          </Title>

          <div className={classes.file}>
            <InsertDriveFileOutlinedIcon style={{ color: amber[500] }} fontSize="large" />
            <LinkTargetBlank link="https://drive.google.com/file/d/1DggNOWqHJ7Pzjz3Bv8k5xkO19Si468Zz/view">PROVA - MIRIM 1</LinkTargetBlank>
          </div>

          <div className={classes.file}>
            <InsertDriveFileOutlinedIcon style={{ color: blueGrey[600] }} fontSize="large" />
            <LinkTargetBlank link="https://drive.google.com/file/d/1wSTt0DjtLDKVMDfHygu_RyarZL0kVpcB/view">PROVA - MIRIM 2</LinkTargetBlank>
          </div>

          <div className={classes.file}>
            <InsertDriveFileOutlinedIcon style={{ color: amber[500] }} fontSize="large" />
            <LinkTargetBlank link="https://drive.google.com/file/d/1EdHr2VFeJp7rhk5COkWdyc6sqQiGQvqp/view">SOLUÇÕES - MIRIM 1</LinkTargetBlank>
          </div>

          <div className={classes.file}>
            <InsertDriveFileOutlinedIcon style={{ color: blueGrey[600] }} fontSize="large" />
            <LinkTargetBlank link="https://drive.google.com/file/d/1m6nm69b7Up0D-VXVgdt9wd5YOAxXKpOY/view">SOLUÇÕES - MIRIM 2</LinkTargetBlank>
          </div>
        </div>
      </Grid> 
    </Grid>
  )
}

export default SegundaOlimpiadaMirim;
