// TIPOS DE VARIAVEIS DE CONTEXTO
export const optionsType = [
    { value: 'BOOLEAN', label: 'Verdadeiro ou Falso' },
    { value: 'LONG', label: 'Numérico' },
    { value: 'TEXTO', label: 'Texto' },
    { value: 'DATA', label: 'Data' },
];

export const returnLabel = value => {
    return value === 'BOOLEAN' ? 'Verdadeiro ou Falso'
    : value === 'LONG' ? 'Numérico'
    : value === 'DATA' ? 'Data' : 'Texto';            
}

export const optionsGrupoExibicao = [
    { value: 'GERAL', label: 'Geral' },
    { value: 'INSCRICAO', label: 'Inscrições' },
    { value: 'PAGAMENTO', label: 'Pagamento' },
    { value: 'FASE_1', label: '1ª Fase' },
    { value: 'FASE_2', label: '2ª Fase' },
    { value: 'PREMIACAO', label: 'Premiação' },
    { value: 'INTEGRACAO', label: 'Integracões' },
];

export const patternLabelGrupoExibicao = value => {
    return value === 'GERAL' ? 'Geral'
        : value === 'INSCRICAO' ? 'Inscrições'
        : value === 'PAGAMENTO' ? 'Pagamento'
        : value === 'FASE_1' ? '1ª Fase'
        : value === 'FASE_2' ? '2ª Fase'
        : value === 'PREMIACAO' ? 'Premiação'
        : value === 'INTEGRACAO' ? 'Integrações' : 'Sem Grupo';
}

export const patternContext = contexts => {
    let array = [];
    contexts.forEach((context) => {
        array.push({
            id: context.cdVariavel,
            name: context.nmVariavel.replace(/ /g, '').concat(context.cdVariavel),
            type: context.nmTipo,
            label: context.nmVariavel,
            value: context.nmValor ? context.nmValor : '',
            ordem: context.nmOrdemExibicao,
            legenda: context.nmLegenda,
            grupoExibicao: context.nmAgrupamento,
        });
    })
    return array;
}