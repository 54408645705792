import React from 'react';

import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';

import EscolaOcorrencia from '../../forms/EscolaOcorrencia/EscolaOcorrencia';

export const Ocorrencia = ({ escola }) => {
    return (
        <Grid item sm={12} lg={12} >
            {escola.ocorrencias.length > 0 ? 
                escola.ocorrencias.map(ocorrencia => <EscolaOcorrencia key={ocorrencia.id} escola={escola} ocorrencia={ocorrencia} />)
            : 
                <Typography variant='body2'>Não Existem Ocorrências para esta Escola.</Typography>
            }
        </Grid>
    )
};

export default Ocorrencia;