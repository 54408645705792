export const optionsConvenio = [
    { value: 'PARCIAL', label: 'Parte da rede pública e parte da rede particular' },
    { value: 'TOTAL', label: 'Todos da rede pública' },
];

export const optionsTipoConvenio = [
    { value: 'MUNICIPAL', label: 'Municipal' },
    { value: 'ESTADUAL', label: 'Estadual' },
    { value: 'MUNICIPAL_E_ESTADUAL', label: 'Municipal e Estadual' },
]

export const patternAdminDepConvenio = dep => {
    return dep === "TOTAL" ? "Todos da Rede Pública": dep === "PARCIAL" ? "Parte da Rede Pública e parte da Rede Particular" : "";
}

export const patternAdminDepConvenioAlunos = dep => {
    return dep === "MUNICIPAL" ? "Municipal": dep === "ESTADUAL" ? "Estadual" : dep === "MUNICIPAL_E_ESTADUAL" ? "Municipal e Estadual" : "";
}

export const patternPossuiConvenio = dep => {
    return dep ? 'Sim' : 'Não';
}