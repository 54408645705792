import API, { API as APIService } from './API';

const path = `coordenadores`;

const CoordenadorService= {
    // FILTROS
    filters: async filters => await API.filters(path, filters),

    // EXPORTAR
    export: async filters => await API.export(path, filters),

    // LISTAR TODOS
    list: async () => await API.list(path),

    // ATUALIZAR SENHA
    atualizarSenha: async (id, coord) => await APIService.put(`${path}/${id}/senha`, coord),

    buscarMaterialExtra: async (id) => await API.list(`${path}/${id}/materiais-extras`),
    atualizarMaterialExtra: async (objeto) => await APIService.put(`${path}/materiais-extras`, objeto),
}

export default CoordenadorService;