import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';

import Dialog from '../../components/Dialog/Dialog';
import { ButtonDefault } from '../../components/Button/Button';

import EmailViewer from '../../forms/Email/EmailViewer';

export class DialogPreviewEmail extends Component {
    constructor(props) {
        super(props);
        props.parentRef(this);
        this.state = {
            dialog: {
                open: false,
                title: null,
                to: null,
                subject: null,
                message: null,
            },
        }
    }

    // AÇÕES MODAL
    actions = () => {
        return (
            <ButtonDefault 
                onClick={this.close}
                startIcon={<ClearOutlinedIcon />}
                style={{ margin: 4 }}
                name="Fechar"
            />
        )
    }

    // FECHAR MODAL
    close = () => {
        this.setState({ 
            dialog: { 
                open: false, 
                title: null,
                to: null,
                subject: null,
                message: null,
            }
        })
    }

    render() {
        return (
            <>
                <Dialog
                    openDialog={this.state.dialog.open}
                    title={this.state.dialog.title}
                    closeDialog={this.close}
                    actions={this.actions()}
                    fullWidth={true}
                    maxWidth={"md"}
                >
                    <Grid item sm={12} lg={12}>
                        <Paper elevation={1} style={{ padding: 24 }} >
                            <Typography variant="body2"><b>Para:</b> {this.state.dialog.to}</Typography>
                        </Paper>
                    </Grid>
                    <EmailViewer
                        subject={this.state.dialog.subject}
                        message={this.state.dialog.message}
                    />
                </Dialog>
            </>
        )
    }
}

export default DialogPreviewEmail;