import React from 'react';

import Typography from '@material-ui/core/Typography';

import Title from '../../components/Title/Title';

import useStyles from './Style';

const MaterialDigitalFase1 = () => {
    const classes = useStyles();
    return (
        <>
            <Title id="anchor-08" className={classes.title}>
                8. DA DISPONIBILIZAÇÃO DIGITAL DO MATERIAL DE PROVA DA 1ª FASE
            </Title>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>8.1</span> O IMPA é responsável pela elaboração e disponibilização do Material de Prova para as escolas inscritas na área restrita da escola de forma 
                digital. O acesso à área restrita pelas escolas inscritas deve ser realizado por meio do site da Olimpíada Mirim - OBMEP (<span className={classes.underline}>www.olimpiadamirim.obmep.org.br</span>).
            </Typography>

            <Typography className={classes.text} component={'div'} variant="body2">
                <span className={classes.item}>8.2</span> O Material de Prova da 1ª Fase é digital, sendo composto de:

                <ul className={classes.list}>
                    <li><span className={classes.item}>{`a)`}</span> Manual de Instruções para Aplicação da Prova da 1ª Fase;</li>
                    <li><span className={classes.item}>{`b)`}</span> Provas da 1ª Fase da Olimpíada Mirim - OBMEP, disponíveis de acordo com o(s) nível(is) dos alunos que a escola inscreveu;</li>
                    <li><span className={classes.item}>{`c)`}</span> Máscara de Correção;</li>
                    <li><span className={classes.item}>{`d)`}</span> Soluções das Provas;</li>
                    <li><span className={classes.item}>{`e)`}</span> Cartaz de Divulgação.</li>
                </ul>
            </Typography>
            
            <Typography className={classes.text} component={'div'} variant="body2">
                <span className={classes.item}>8.3</span> <span className={classes.bolder}>O Material de Prova descrito no item 8.2 deste Regulamento será disponibilizado para download no site 
                www.olimpiadamirim.obmep.org.br, durante o prazo estipulado no Calendário Oficial da 3ª Olimpíada Mirim - OBMEP (Anexo I);</span>
            
                <ul className={classes.list}>
                    <li><span className={classes.item}>8.3.1</span> Este material será disponibilizado exclusivamente no formato “.pdf”, sendo certo que em nenhuma hipótese o IMPA enviará o material em forma impressa na 1ª Fase;</li>
                    <li><span className={classes.item}>8.3.2</span> A impressão do Material de Prova é de responsabilidade da escola inscrita ou da secretaria de educação responsável pela inscrição da escola;</li>
                    <li><span className={classes.item}>8.3.3</span> As regras deste Regulamento e as instruções do Manual para Aplicação da 1ª Fase deverão ser rigorosamente seguidas pelas escolas.</li>
                </ul>
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>8.4</span> O acesso ao site da Olimpíada Mirim - OBMEP será feito <span className={classes.bolder}>exclusivamente</span> pelo responsável pela escola inscrita/secretaria, por meio de login e senha, cadastrados no momento 
                da inscrição (vide itens 3.6 e 3.9).
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>8.5</span> As escolas inscritas ou as secretarias de educação são responsáveis pela impressão do material da 1ª Fase e pela manutenção do sigilo da prova e do gabarito durante todo o processo, 
                bem como por instruir neste sentido quaisquer funcionários e/ou colaboradores eventualmente envolvidos no processo.
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>8.6</span> O IMPA se isenta de qualquer responsabilidade em caso de divulgação indevida das provas.
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>8.7</span> A escola inscrita ou secretaria de educação que quebrar o sigilo das questões das provas e de seus respectivos gabaritos poderá ser punida e até mesmo desclassificada, 
                inexistindo responsabilidade do IMPA.
            </Typography>
        </>
    )
}

export default MaterialDigitalFase1;