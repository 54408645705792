import React from 'react'

import Grid from '@material-ui/core/Grid';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import { amber, blueGrey } from '@material-ui/core/colors';

import Title from '../../components/Title/Title';
import { LinkTargetBlank } from '../../components/Link/Link';

import useStyles from './Style';

export const TerceiraOlimpiadaMirim = () => {
  const classes = useStyles();

  return (
    <Grid container spacing={3} >
      <Grid item xs={12} sm={12} lg={6} xl={5} >
        <div >
          <Title variant="h6">
            FASE 1
          </Title>
          <div className={classes.file}>
            <InsertDriveFileOutlinedIcon style={{ color: amber[500] }} fontSize="large" />
            <LinkTargetBlank link="https://drive.google.com/file/d/1wuuYb0KjGeR-y2_AwFowjWwXsxmmouf2/view">PROVA - MIRIM 1</LinkTargetBlank>
          </div>

          <div className={classes.file}>
            <InsertDriveFileOutlinedIcon style={{ color: blueGrey[600] }} fontSize="large" />
            <LinkTargetBlank link="https://drive.google.com/file/d/1ZEIBhLQ-mWY7WuGWxhBGGmlHGnjDORe_/view">PROVA - MIRIM 2</LinkTargetBlank>
          </div>

          <div className={classes.file}>
            <InsertDriveFileOutlinedIcon style={{ color: amber[500] }} fontSize="large" />
            <LinkTargetBlank link="https://drive.google.com/file/d/1StMEh_bCXv2BONLY6A1guSzZtwEZZwSU/view">SOLUÇÕES - MIRIM 1</LinkTargetBlank>
          </div>

          <div className={classes.file}>
            <InsertDriveFileOutlinedIcon style={{ color: blueGrey[600] }} fontSize="large" />
            <LinkTargetBlank link="https://drive.google.com/file/d/1RGAMgf2SA4PAN-rvjyKZFjj6O1Nm1lKP/view">SOLUÇÕES - MIRIM 2</LinkTargetBlank>
          </div>
        </div>
      </Grid>
    </Grid>
  )
}

export default TerceiraOlimpiadaMirim;
