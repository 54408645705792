import API from './API';

const path = `municipios`;

const CidadeService = {
    // LISTAR TODOS
    list: async () => await API.list(path),

    // FILTRAR REGIÕES
    filterRegions: async filters => await API.filters(`${path}/regioes`, filters),

    // FILTROS
    filters: async filters => await API.filters(path, filters),
}

export default CidadeService;