import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadContext } from '../../store/actions/Contexto';
import { isAfterDataFimInscricaoEDataFimPagamento } from '../../helper/ContextHelper';

import Grid from '@material-ui/core/Grid';

import Table, { DataTableLineTotals as Totals } from '../../components/Table/Table';

import { percentMask } from '../../helper/MaskHelper';

export class DadosInscricaoUF extends Component {
    constructor (props) {
        super(props)
        props.parentRef(this);
        this.state = {
            ufs: [],
        }
    }

    sumUfs = () => this.state.ufs.length;
    sumEscolas = () => this.state.ufs.reduce((total, uf) => total + uf.qtdEscolasTotal, 0);
    sumEscolasInscritas = () => this.state.ufs.reduce((total, uf) => total + uf.qtdEscolasInscritasTotal, 0);
    sumEscolasAguardandoPagamento = () => this.state.ufs.reduce((total, uf) => total + uf.qtdEscolasAguardandoPagamentoTotal, 0);
    sumEscolasInscritasPorcentagem = () => this.state.ufs.reduce((total, uf) => total + uf.qtdEscolasInscritasPorcentagemTotal, 0);
    sumMunicipios = () => this.state.ufs.reduce((total, uf) => total + uf.qtdMunicipiosTotal, 0);
    sumMunicipiosInscritos = () => this.state.ufs.reduce((total, uf) => total + uf.qtdMunicipiosInscritosTotal, 0);
    sumMunicipiosInscritosPorcentagem = () => this.state.ufs.reduce((total, uf) => total + uf.qtdMunicipiosInscritosPorcentagemTotal, 0);
    sumAlunosPrevistos = () => this.state.ufs.reduce((total, uf) => total + uf.qtdAlunosPrevistosTotal, 0);
    sumAlunosPrevistosMirim1 = () => this.state.ufs.reduce((total, uf) => total + uf.qtdAlunosPrevistosMirim1, 0);
    sumAlunosPrevistosMirim2 = () => this.state.ufs.reduce((total, uf) => total + uf.qtdAlunosPrevistosMirim2, 0);

    render () {
        const columns = [
            { label: 'UF', name: 'nmUf', func: null, key: false, width: '9', },
            { label: 'Total de Escolas', name: 'qtdEscolasTotal', func: null, key: false, width: '9', },
            { label: 'Total de Escolas Inscritas', name: 'qtdEscolasInscritasTotal', func: null, key: false, width: '9', },
            { label: 'Total de Escolas Aguardando Pagamento', name: 'qtdEscolasAguardandoPagamentoTotal', func: null, key: false, width: '9', hidden: isAfterDataFimInscricaoEDataFimPagamento(this.props.contexts), },
            { label: '% de Escolas Inscritas', name: 'qtdEscolasInscritasPorcentagemTotal', func: percentMask, key: false, width: '9', },
            { label: 'Total de Municipios', name: 'qtdMunicipiosTotal', func: null, key: false, width: '9', },
            { label: 'Total de Municipios Inscritos', name: 'qtdMunicipiosInscritosTotal', func: null, key: false, width: '9', },
            { label: '% de Municipios Inscritos', name: 'qtdMunicipiosInscritosPorcentagemTotal', func: percentMask, key: false, width: '9', },
            { label: 'Mirim 1 - Alunos Inscritos', name: 'qtdAlunosPrevistosMirim1', func: null, key: false, width: '9', },
            { label: 'Mirim 2 - Alunos Inscritos', name: 'qtdAlunosPrevistosMirim2', func: null, key: false, width: '9', },
            { label: 'Total de Alunos Inscritos', name: 'qtdAlunosPrevistosTotal', func: null, key: false, width: '9', },
        ];
        
        const actions = [];

        const totals = [
            { column: 0, value: this.sumUfs(), width: '9', },
            { column: 1, value: this.sumEscolas(), width: '9', },
            { column: 2, value: this.sumEscolasInscritas(), width: '9', },
            { column: 3, value: this.sumEscolasAguardandoPagamento(), width: '9', hidden: isAfterDataFimInscricaoEDataFimPagamento(this.props.contexts), },
            { column: 4, value: percentMask(this.sumEscolasInscritasPorcentagem() / this.sumUfs()), width: '9', },
            { column: 5, value: this.sumMunicipios(), width: '9', },
            { column: 6, value: this.sumMunicipiosInscritos(), width: '9', },
            { column: 7, value: percentMask(this.sumMunicipiosInscritosPorcentagem() / this.sumUfs()), width: '9', },
            { column: 8, value: this.sumAlunosPrevistosMirim1(), width: '9', },
            { column: 9, value: this.sumAlunosPrevistosMirim2(), width: '9', },
            { column: 10, value: this.sumAlunosPrevistos(), width: '9', },
        ];

        return (
            <Grid container spacing={3} style={{ marginTop: this.props.marginTop ?? 8 }}>
                <Grid item xs={12}>
                    <Table              
                        columns={columns}
                        tableData={this.state.ufs}
                        actions={actions}
                        page={null}
                        rowsPerPage={null}
                        sort={null}
                        fixedHeader={true}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Totals lineData={totals} columns={columns} />
                </Grid>
            </Grid>
        )
    }
}


const mapStateToProps = state => ({ contexts: state.contexts });
const mapDispatchToProps = dispatch => (bindActionCreators({ loadContext }, dispatch));

export default connect(mapStateToProps, mapDispatchToProps)(DadosInscricaoUF);