import React from 'react';

import Typography from '@material-ui/core/Typography';

import Title from '../../components/Title/Title';

import useStyles from './Style';

const AplicacaoProvaFase1 = () => {
    const classes = useStyles();
    return (
        <>
            <Title id="anchor-09" className={classes.title}>
                9. DA APLICAÇÃO DA PROVA DA 1ª FASE
            </Title>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>9.1</span> É de responsabilidade da escola inscrita ou da secretaria de educação responsável pela inscrição da escola, a impressão, a aplicação e a correção da prova em concordância 
                com as diretrizes deste Regulamento e do Manual para Aplicação da 1ª Fase.
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>9.2</span> As provas da Primeira Fase serão aplicadas por cada escola nas suas próprias dependências, as quais serão integralmente responsáveis por sua aplicação e correção, 
                de acordo com as instruções enviadas pelo IMPA junto com o material de prova.
            </Typography>
            
            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>9.3</span> A aplicação da prova deverá ser realizada exclusivamente no dia <span className={classes.bolder}>27 de agosto de 2024</span>, conforme indicado no Calendário Oficial da 3ª Olimpíada Mirim – OBMEP (Anexo I), 
                podendo ser aplicada em todos os turnos da escola.
            </Typography>

            <Typography className={classes.text} component={'div'} variant="body2">
                <span className={classes.item}>9.4</span> A prova terá duração de 1h30min (uma hora e trinta minutos).

                <ul className={classes.list}>
                    <li><span className={classes.item}>9.4.1</span> Para alunos(as) com necessidades especiais deverá ser oferecida uma hora adicional para a realização da prova.</li>
                </ul>
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>9.5</span> É de responsabilidade da escola inscrita ou da secretaria de educação, a identificação e o atendimento das necessidades especiais dos(as) alunos(as).
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>9.6</span> A prova não deverá ser reaplicada para alunos(as) que tenham estado ausentes por quaisquer motivos no dia da aplicação da prova, uma vez que, a prova da 1ª Fase é aplicada 
                em um único dia em todo o território brasileiro.
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>9.7</span> É de responsabilidade da escola instruir seus(suas) alunos(as) sobre a manutenção do sigilo, bem como coibir o uso de aparelhos eletrônicos e outros meios de consulta durante 
                a realização das provas.
            </Typography>
        </>
    )
}

export default AplicacaoProvaFase1;