import API from './API';

const path = `relatorios`;

const RelatorioService = {

    // RELATÓRIO DE ESCOLAS
    reportAllSchools: async () => await API.list(`${path}/escolas`),
    filtersReportAllSchools: async filters => await API.filters(`${path}/escolas`, filters),
    exportReportAllSchools: async filters => await API.export(`${path}/escolas`, filters),

    // RELATÓRIO DE DEPENDENCIAS ADMINISTRATIVAS
    reportDependenciasAdministrativas: async () => await API.list(`${path}/dependencias-administrativas`),
    filtersReportDependenciasAdministrativas: async filters => await API.filters(`${path}/dependencias-administrativas`, filters),
    exportReportDependenciasAdministrativas: async filters => await API.export(`${path}/dependencias-administrativas`, filters),

    // RELATÓRIO DE MUNICÍPIOS
    reportMunicipios: async () => await API.list(`${path}/municipios`),
    filtersReportMunicipios: async filters => await API.filters(`${path}/municipios`, filters),
    exportReportMunicipios: async filters => await API.export(`${path}/municipios`, filters),

    // RELATÓRIO DE UF'S
    reportUfs: async () => await API.list(`${path}/ufs`),
    filtersReportUfs: async filters => await API.filters(`${path}/ufs`, filters),
    exportReportUfs: async filters => await API.export(`${path}/ufs`, filters),

    // RELATÓRIO DE MEDALHAS
    reportMedalhas: async () => await API.list(`${path}/medalhas`),
    filtersReportMedalhas: async filters => await API.filters(`${path}/medalhas`, filters),
    exportReportMedalhas: async filters => await API.export(`${path}/medalhas`, filters),

    // RELATÓRIO DE PAGAMENTOS
    reportPagamentos: async () => await API.list(`${path}/pagamentos`),
    filtersReportPagamentos: async filters => await API.filters(`${path}/pagamentos`, filters),
    exportReportPagamentos: async filters => await API.export(`${path}/pagamentos`, filters),

    // RELATÓRIO DE INSCRIÇÕES POR DATA
    reportInscricoesPorData: async () => await API.list(`${path}/inscricoes/por-data`),
    filtersReportInscricoesPorData: async filters => await API.filters(`${path}/inscricoes/por-data`, filters),
    exportReportInscricoesPorData: async filters => await API.export(`${path}/inscricoes/por-data`, filters),

    // RELATÓRIO PÚBLICO DE ESCOLAS
    reportAllSchoolsPublic: async () => await API.list(`${path}/publico/escolas`),
    filtersReportAllSchoolsPublic: async filters => await API.filters(`${path}/publico/escolas`, filters),
    exportReportAllSchoolsPublic: async filters => await API.export(`${path}/publico/escolas`, filters),

    // RELATÓRIO DE PROVAS ESPECIAIS
    reportProvasEspeciais: async () => await API.list(`${path}/provas-especiais`),
    filtersReportProvasEspeciais: async filters => await API.filters(`${path}/provas-especiais`, filters),
    exportReportProvasEspeciais: async filters => await API.export(`${path}/provas-especiais`, filters),

    // RELATÓRIO DE INSCRIÇÕES POR UF
    reportInscricoesPorUF: async () => await API.list(`${path}/inscricoes/por-uf`),
    filtersReportInscricoesPorUF: async filters => await API.filters(`${path}/inscricoes/por-uf`, filters),
    exportReportInscricoesPorUF: async filters => await API.export(`${path}/inscricoes/por-uf`, filters),

    // RELATÓRIO DE INSCRIÇÕES POR EDICAO
    reportInscricoesPorEdicao: async () => await API.list(`${path}/inscricoes/por-edicao`),
    filtersReportInscricoesPorEdicao: async filters => await API.filters(`${path}/inscricoes/por-edicao`, filters),
    exportReportInscricoesPorEdicao: async filters => await API.export(`${path}/inscricoes/por-edicao`, filters),

    // RELATÓRIO DE INSCRIÇÕES POR MUNICIPIO
    reportInscricoesPorMunicipio: async () => await API.list(`${path}/inscricoes/por-municipio`),
    filtersReportInscricoesPorMunicipio: async filters => await API.filters(`${path}/inscricoes/por-municipio`, filters),
    exportReportInscricoesPorMunicipio: async filters => await API.export(`${path}/inscricoes/por-municipio`, filters),

    // RELATÓRIO DE GESTAO POR EDICAO
    reportGestaoPorEdicao: async () => await API.list(`${path}/gestao/por-edicao`),
    filtersReportGestaoPorEdicao: async filters => await API.filters(`${path}/gestao/por-edicao`, filters),
    exportReportGestaoPorEdicao: async filters => await API.export(`${path}/gestao/por-edicao`, filters),

    // RELATÓRIO DE GESTAO POR ALUNOS
    reportGestaoPorAlunos: async () => await API.list(`${path}/gestao/por-alunos`),
    filtersReportGestaoPorAlunos: async filters => await API.filters(`${path}/gestao/por-alunos`, filters),
    exportReportGestaoPorAlunos: async filters => await API.export(`${path}/gestao/por-alunos`, filters),

    // RELATÓRIO DE GESTAO POR ESCOLAS
    reportGestaoPorEscolas: async () => await API.list(`${path}/gestao/por-escolas`),
    filtersReportGestaoPorEscolas: async filters => await API.filters(`${path}/gestao/por-escolas`, filters),
    exportReportGestaoPorEscolas: async filters => await API.export(`${path}/gestao/por-escolas`, filters),
}

export default RelatorioService;