import React from 'react';
import clsx from 'clsx';

import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';

import Title from '../../components/Title/Title';

import useStyles from './Style';

const Inscricao = () => {
    const classes = useStyles();
    return (
        <>
            <Title id="anchor-03" className={classes.title}>
                3. DA INSCRIÇÃO
            </Title>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>3.1</span> A inscrição das <span className={classes.bolder}>ESCOLAS PÚBLICAS</span> e das <span className={classes.bolder}>ESCOLAS PRIVADAS</span> na 3ª Olimpíada Mirim – OBMEP deverá ser realizada, exclusivamente no site da 3ª Olimpíada Mirim 
                – OBMEP, <span className={clsx(classes.bolder, classes.underline)}>www.olimpiadamirim.obmep.org.br</span>, obrigatoriamente, dentro do Período de Inscrições conforme estipulado no Calendário Oficial da 3ª Olimpíada Mirim – OBMEP, Anexo I deste Regulamento.
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>3.2</span> A inscrição das <span className={classes.bolder}>ESCOLAS PÚBLICAS</span> na 3ª Olimpíada Mirim – OBMEP é gratuita.
            </Typography>
            
            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>3.3</span> A inscrição das <span className={classes.bolder}>ESCOLAS PRIVADAS</span> está condicionada à emissão, ao pagamento e à compensação do pagamento da taxa de inscrição.
            </Typography>

            <Typography className={classes.text} component={'div'} variant="body2">
                <span className={classes.item}>3.4</span> A inscrição das <span className={classes.bolder}>ESCOLAS PÚBLICAS</span> na 3ª Olimpíada Mirim – OBMEP é gratuita e poderá ser realizada nas duas modalidades distintas e excludentes, a saber (conferir Tabela A):

                <Grid 
                    container 
                    align='center' 
                    direction="row" 
                    justify="center"
                    alignItems="center"
                    className={classes.wrapper}
                >
                    <Grid item xs={12} sm={8} lg={6} xl={6} >
                        <TableContainer component="div">
                            <Table className={classes.table} size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.bolder} width="31%">Escolas Públicas</TableCell>
                                        <TableCell className={classes.bolder} align="center">Inscrição</TableCell>
                                        <TableCell className={classes.bolder} align="center">Premiação</TableCell>
                                        <TableCell className={classes.bolder} align="center">Pagamento</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell className={classes.bolder} width="31%" component="th" scope="row">MODALIDADE 1</TableCell>
                                        <TableCell align="center">Gratuita</TableCell>
                                        <TableCell align="center">Não</TableCell>
                                        <TableCell align="center">Isenta</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className={classes.bolder} width="31%" component="th" scope="row">MODALIDADE 2</TableCell>
                                        <TableCell align="center">Gratuita</TableCell>
                                        <TableCell align="center">Sim</TableCell>
                                        <TableCell align="center">Valores detalhados na Tabela C (Item 3.10 deste Regulamento)</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                            <small style={{ fontSize: '0.85em' }}>Tabela A - Escolas Públicas – Modalidade de Inscrição</small>
                        </TableContainer>
                    </Grid>
                </Grid>
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>3.5</span> No momento da inscrição na 3ª Olimpíada Mirim – OBMEP, a <span className={classes.bolder}>ESCOLA PÚBLICA</span> deverá escolher a MODALIDADE DE INSCRIÇÃO de sua preferência, 
                MODALIDADE 1 (INSCRIÇÃO SEM PREMIAÇÃO) e MODALIDADE 2 (INSCRIÇÃO COM PREMIAÇÃO).
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>3.6</span> Nas duas MODALIDADES DE INSCRIÇÃO, as <span className={classes.bolder}>ESCOLAS PÚBLICAS</span> que, exclusivamente no site da 3ª Olimpíada Mirim – OBMEP, <span className={clsx(classes.bolder, classes.underline)}>www.olimpiadamirim.obmep.org.br</span>, obrigatoriamente, dentro do 
                Período de Inscrições conforme estipulado no Calendário Oficial da 3ª Olimpíada Mirim – OBMEP, Anexo I deste Regulamento, preencherem completamente a ficha de inscrição serão consideradas 
                INSCRITAS na 3ª Olimpíada Mirim – OBMEP.
            </Typography>

            <Typography className={classes.text} component={'div'} variant="body2">
                <span className={classes.item}>3.7</span> A <span className={classes.bolder}>MODALIDADE 1</span> (INSCRIÇÃO SEM PREMIAÇÃO) é oferecida exclusivamente para as <span className={classes.bolder}>ESCOLAS PÚBLICAS</span> e garante a inscrição da escola na 3ª Olimpíada Mirim – OBMEP, nas duas Fases (1ª e 2ª Fases) 
                incluindo premiação digital - certificados digitais para os alunos(as) que obtiverem o melhor desempenho na 2ª Fase da olimpíada, de acordo com o número de inscritos pela escola, por Nível 
                (MIRIM 1 – alunos(as) de 2º e 3º anos do Ensino Fundamental e MIRIM 2 – alunos(as) de 4º e 5º anos do Ensino Fundamental), conforme indicado na Tabela B, abaixo:<br /><br />

                <Grid 
                    container 
                    align='center' 
                    direction="row" 
                    justify="center"
                    alignItems="center"
                    className={classes.wrapper}
                >
                    <Grid item sm={10} lg={10}>
                        <TableContainer component="div">
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center" style={{ backgroundColor: '#CACACA' }}>Nível</TableCell>
                                        <TableCell align="center" style={{ backgroundColor: '#CACACA' }}>Quantidade de Alunos Inscritos</TableCell>
                                        <TableCell align="center" className={classes.bolder} style={{ backgroundColor: '#B68D4A', color: '#FFFFFF' }}>Certificado Digital - OURO</TableCell>
                                        <TableCell align="center" className={classes.bolder} style={{ backgroundColor: '#A3A2A0', color: '#FFFFFF' }}>Certificado Digital - PRATA</TableCell>
                                        <TableCell align="center" className={classes.bolder} style={{ backgroundColor: '#A46628', color: '#FFFFFF' }}>Certificado Digital - BRONZE</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow style={{ border: 1 }}>
                                        <TableCell align="center" component="th" scope="row" rowSpan={4} className={classes.bolder} style={{ backgroundColor: '#FFE699' }}>MIRIM 1</TableCell>
                                        <TableCell align="center" className={classes.bolder}>1 a 60</TableCell>
                                        <TableCell align="center">1</TableCell>
                                        <TableCell align="center">3</TableCell>
                                        <TableCell align="center">6</TableCell>
                                    </TableRow>
                                    <TableRow style={{ border: 1 }}>
                                        <TableCell align="center" className={classes.bolder}>61 a 120</TableCell>
                                        <TableCell align="center">2</TableCell>
                                        <TableCell align="center">6</TableCell>
                                        <TableCell align="center">12</TableCell>
                                    </TableRow>
                                    <TableRow style={{ border: 1 }}>
                                        <TableCell align="center" className={classes.bolder}>121 a 180</TableCell>
                                        <TableCell align="center">3</TableCell>
                                        <TableCell align="center">9</TableCell>
                                        <TableCell align="center">18</TableCell>
                                    </TableRow>
                                    <TableRow style={{ border: 1 }}>
                                        <TableCell align="center" className={classes.bolder}>181 ou +</TableCell>
                                        <TableCell align="center">4</TableCell>
                                        <TableCell align="center">12</TableCell>
                                        <TableCell align="center">24</TableCell>
                                    </TableRow>
                                    <TableRow style={{ border: 1 }}>
                                        <TableCell align="center" component="th" scope="row" rowSpan={4} className={classes.bolder} style={{ backgroundColor: '#FFCCFF' }}>MIRIM 2</TableCell>
                                        <TableCell align="center">1 a 60</TableCell>
                                        <TableCell align="center">1</TableCell>
                                        <TableCell align="center">3</TableCell>
                                        <TableCell align="center">6</TableCell>
                                    </TableRow>
                                    <TableRow style={{ border: 1 }}>
                                        <TableCell align="center" className={classes.bolder}>61 a 120</TableCell>
                                        <TableCell align="center">2</TableCell>
                                        <TableCell align="center">6</TableCell>
                                        <TableCell align="center">12</TableCell>
                                    </TableRow>
                                    <TableRow style={{ border: 1 }}>
                                        <TableCell align="center" className={classes.bolder}>121 a 180</TableCell>
                                        <TableCell align="center">3</TableCell>
                                        <TableCell align="center">9</TableCell>
                                        <TableCell align="center">18</TableCell>
                                    </TableRow>
                                    <TableRow style={{ border: 1 }}>
                                        <TableCell align="center" className={classes.bolder}>181 ou +</TableCell>
                                        <TableCell align="center">4</TableCell>
                                        <TableCell align="center">12</TableCell>
                                        <TableCell align="center">24</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                            <small style={{ fontSize: '0.85em' }}>Tabela B - Escolas Públicas – Quantidade de premiados com certificado digital por nível</small>
                        </TableContainer>
                    </Grid>
                </Grid>
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>3.8</span> Os certificados serão disponibilizados na página restrita da escola na 3ª Olimpíada Mirim – OBMEP (<span className={classes.underline}>www.olimpiadamirim.obmep.org.br</span>), tão logo a escola informe as notas dos alunos na 2ª Fase.
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>3.9</span> A <span className={classes.bolder}>MODALIDADE 2</span> (INSCRIÇÃO COM PREMIAÇÃO) é oferecida exclusivamente para as <span className={classes.bolder}>ESCOLAS PÚBLICAS</span> e garante a inscrição da escola na 3ª Olimpíada Mirim – OBMEP, nas duas Fases (1ª e 2ª Fases), 
                com premiação digital - certificados digitais para os alunos(as) que obtiverem o melhor desempenho na 2ª Fase da olimpíada, de acordo com o número de inscritos pela escola por Nível 
                (MIRIM 1 – alunos(as) de 2º e 3º anos do Ensino Fundamental e MIRIM 2 – alunos(as) de 4º e 5º anos do Ensino Fundamental) e o envio de medalhas de ouro, prata e bronze em quantidade proporcional 
                a de alunos(as) inscritos pela escola.
            </Typography>

            <Typography className={classes.text} component={'div'} variant="body2">
                <span className={classes.item}>3.10</span> A opção da <span className={classes.bolder}>ESCOLA PÚBLICA</span> pela <span className={classes.bolder}>MODALIDADE 2</span> (INSCRIÇÃO COM PREMIAÇÃO) está condicionada 
                à emissão, ao pagamento e à compensação do pagamento de taxa. Ao optar pela <span className={classes.bolder}>MODALIDADE 2</span> (INSCRIÇÃO COM PREMIAÇÃO), a <span className={classes.bolder}>ESCOLA PÚBLICA</span> inscrita deverá, dentro do prazo estipulado no Calendário Oficial da 3ª Olimpíada 
                Mirim – OBMEP, “PERÍODO PARA EMISSÃO E PAGAMENTO DE BOLETO”, acessar a página da 3ª Olimpíada Mirim – OBMEP, www.olimpiadamirim.obmep.org.br , e promover a emissão do boleto bancário que deverá ser pago, obrigatoriamente 
                até a data do vencimento. O valor da taxa da <span className={classes.bolder}>MODALIDADE 2</span> (INSCRIÇÃO COM PREMIAÇÃO) varia de acordo com a quantidade de alunos inscritos pela escola em cada nível (MIRIM 1 – 2º e 3º anos do Ensino Fundamental e 
                MIRIM 2 – 4º e 5º anos do Ensino Fundamental), conforme indicado na Tabela C, abaixo:<br /><br />

                <Grid 
                    container 
                    align='center' 
                    direction="row" 
                    justify="center"
                    alignItems="center"
                    className={classes.wrapper}
                >
                    <Grid item sm={10} lg={10}>
                        <TableContainer component="div">
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                    <TableCell align="center" style={{ backgroundColor: '#CACACA' }}>Nível</TableCell>
                                        <TableCell align="center" style={{ backgroundColor: '#CACACA' }}>Quantidade de Alunos Inscritos - ESCOLA PÚBLICA – MODALIDADE 2</TableCell>
                                        <TableCell align="center" className={classes.bolder} style={{ backgroundColor: '#B68D4A', color: '#FFFFFF' }}>Medalha - OURO</TableCell>
                                        <TableCell align="center" className={classes.bolder} style={{ backgroundColor: '#A3A2A0', color: '#FFFFFF' }}>Medalha - PRATA</TableCell>
                                        <TableCell align="center" className={classes.bolder} style={{ backgroundColor: '#A46628', color: '#FFFFFF' }}>Medalha - BRONZE</TableCell>
                                        <TableCell align="center" style={{ backgroundColor: '#CACACA' }}>Valor da inscrição com premiação <span className={classes.bolder}>POR NÍVEL</span></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow style={{ border: 1 }}>
                                    <TableCell align="center" component="th" scope="row" rowSpan={4} style={{ backgroundColor: '#FFE699' }}>MIRIM 1</TableCell>
                                        <TableCell align="center" className={classes.bolder}>1 a 60</TableCell>
                                        <TableCell align="center">1</TableCell>
                                        <TableCell align="center">3</TableCell>
                                        <TableCell align="center">6</TableCell>
                                        <TableCell align="center" className={classes.bolder}>R$ 200,00</TableCell>
                                    </TableRow>
                                    <TableRow style={{ border: 1 }}>
                                        <TableCell align="center" className={classes.bolder}>61 a 120</TableCell>
                                        <TableCell align="center">2</TableCell>
                                        <TableCell align="center">6</TableCell>
                                        <TableCell align="center">12</TableCell>
                                        <TableCell align="center" className={classes.bolder}>R$ 400,00</TableCell>
                                    </TableRow>
                                    <TableRow style={{ border: 1 }}>
                                        <TableCell align="center" className={classes.bolder}>121 a 180</TableCell>
                                        <TableCell align="center">3</TableCell>
                                        <TableCell align="center">9</TableCell>
                                        <TableCell align="center">18</TableCell>
                                        <TableCell align="center" className={classes.bolder}>R$ 600,00</TableCell>
                                    </TableRow>
                                    <TableRow style={{ border: 1 }}>
                                        <TableCell align="center" className={classes.bolder}>181 ou +</TableCell>
                                        <TableCell align="center">4</TableCell>
                                        <TableCell align="center">12</TableCell>
                                        <TableCell align="center">24</TableCell>
                                        <TableCell align="center" className={classes.bolder}>R$ 800,00</TableCell>
                                    </TableRow>
                                    <TableRow style={{ border: 1 }}>
                                        <TableCell align="center" component="th" scope="row" rowSpan={4} style={{ backgroundColor: '#FFCCFF' }}>MIRIM 2</TableCell>
                                        <TableCell align="center" className={classes.bolder}>1 a 60</TableCell>
                                        <TableCell align="center">1</TableCell>
                                        <TableCell align="center">3</TableCell>
                                        <TableCell align="center">6</TableCell>
                                        <TableCell align="center" className={classes.bolder}>R$ 200,00</TableCell>
                                    </TableRow>
                                    <TableRow style={{ border: 1 }}>
                                        <TableCell align="center" className={classes.bolder}>61 a 120</TableCell>
                                        <TableCell align="center">2</TableCell>
                                        <TableCell align="center">6</TableCell>
                                        <TableCell align="center">12</TableCell>
                                        <TableCell align="center" className={classes.bolder}>R$ 400,00</TableCell>
                                    </TableRow>
                                    <TableRow style={{ border: 1 }}>
                                        <TableCell align="center" className={classes.bolder}>121 a 180</TableCell>
                                        <TableCell align="center">3</TableCell>
                                        <TableCell align="center">9</TableCell>
                                        <TableCell align="center">18</TableCell>
                                        <TableCell align="center" className={classes.bolder}>R$ 600,00</TableCell>
                                    </TableRow>
                                    <TableRow style={{ border: 1 }}>
                                        <TableCell align="center" className={classes.bolder}>181 ou +</TableCell>
                                        <TableCell align="center">4</TableCell>
                                        <TableCell align="center">12</TableCell>
                                        <TableCell align="center">24</TableCell>
                                        <TableCell align="center" className={classes.bolder}>R$ 800,00</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                            <small style={{ fontSize: '0.85em' }}>Tabela C - Escolas Públicas – MODALIDADE 2 (INSCRIÇÃO COM PREMIAÇÃO)</small>
                        </TableContainer>
                    </Grid>
                </Grid>
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>3.11</span> A inscrição das <span className={classes.bolder}>ESCOLAS PÚBLICAS</span> na 3ª Olimpíada Mirim – OBMEP é gratuita nas duas modalidades e depende exclusivamente do preenchimento completo da ficha de inscrição, dentro do prazo estipulado no 
                Calendário Oficial da 3ª Olimpíada Mirim (Anexo I). A <span className={classes.bolder}>ESCOLA PÚBLICA</span> que optar pela <span className={classes.bolder}>MODALIDADE 2</span> (INSCRIÇÃO COM PREMIAÇÃO), e NÃO efetuar a emissão e o pagamento do boleto bancário correspondente à taxa, dentro 
                do prazo estipulado no Calendário Oficial da 3ª Olimpíada Mirim (Anexo I), terá a inscrição mantida, ou seja, permanecerá inscrita na olimpíada, porém a modalidade de inscrição será automaticamente convertida para 
                a MODALIDADE 1 (INSCRIÇÃO SEM PREMIAÇÃO).
            </Typography>

            <Typography className={classes.text} component={'div'} variant="body2">
                <span className={classes.item}>3.12</span> A inscrição das <span className={classes.bolder}>ESCOLAS PRIVADAS</span> está condicionada à emissão, ao pagamento e à compensação do pagamento da taxa de inscrição e poderá ser realizada nas duas modalidades distintas e excludentes, a saber (Tabela D):

                <Grid 
                    container 
                    align='center' 
                    direction="row" 
                    justify="center"
                    alignItems="center"
                    className={classes.wrapper}
                >
                    <Grid item xs={12} sm={8} lg={6} xl={6} >
                        <TableContainer component="div">
                            <Table className={classes.table} size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.bolder} width="31%">Escolas Privadas</TableCell>
                                        <TableCell className={classes.bolder} align="center">Inscrição</TableCell>
                                        <TableCell className={classes.bolder} align="center">Premiação</TableCell>
                                        <TableCell className={classes.bolder} align="center">Pagamento</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell className={classes.bolder} width="31%" component="th" scope="row">MODALIDADE 3</TableCell>
                                        <TableCell align="center">Paga</TableCell>
                                        <TableCell align="center">Não</TableCell>
                                        <TableCell align="center">R$ 150,00</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className={classes.bolder} width="31%" component="th" scope="row">MODALIDADE 4</TableCell>
                                        <TableCell align="center">Paga</TableCell>
                                        <TableCell align="center">Sim</TableCell>
                                        <TableCell align="center">R$ 150,00 + Valores Tabela F (Item 3.17 deste Regulamento)</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                            <small style={{ fontSize: '0.85em' }}>Tabela D - Escolas Privadas – MODALIDADES DE INSCRIÇÃO</small>
                        </TableContainer>
                    </Grid>
                </Grid>
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>3.13</span> No momento da inscrição na 3ª Olimpíada Mirim – OBMEP, a <span className={classes.bolder}>ESCOLA PRIVADA</span> deverá escolher a MODALIDADE DE INSCRIÇÃO de sua preferência, <span className={classes.bolder}>MODALIDADE 3</span> (INSCRIÇÃO SEM PREMIAÇÃO) e <span className={classes.bolder}>MODALIDADE 4</span> (INSCRIÇÃO COM PREMIAÇÃO).
            </Typography>

            <Typography className={classes.text} component={'div'} variant="body2">
                <span className={classes.item}>3.14</span> A <span className={classes.bolder}>MODALIDADE 3</span> (INSCRIÇÃO SEM PREMIAÇÃO) é oferecida exclusivamente para as <span className={classes.bolder}>ESCOLAS PRIVADAS</span> e garante a inscrição da escola na 3ª Olimpíada Mirim – OBMEP, nas duas Fases (1ª e 2ª Fases), com premiação digital - 
                certificados digitais para os(as) alunos(as) que obtiverem o melhor desempenho na 2ª Fase da olimpíada, de acordo com o número de inscritos pela escola por Nível (MIRIM 1 – alunos(as) de 2º e 3º anos do Ensino Fundamental 
                e MIRIM 2 – alunos(as) de 4º e 5º anos do Ensino Fundamental), de acordo com o número de inscritos pela escola, conforme Tabela E, abaixo:<br /><br />

                <Grid 
                    container 
                    align='center' 
                    direction="row" 
                    justify="center"
                    alignItems="center"
                    className={classes.wrapper}
                >
                    <Grid item sm={10} lg={10}>
                        <TableContainer component="div">
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center" style={{ backgroundColor: '#CACACA' }}>Nível</TableCell>
                                        <TableCell align="center" style={{ backgroundColor: '#CACACA' }}>Quantidade de Alunos Inscritos</TableCell>
                                        <TableCell align="center" className={classes.bolder} style={{ backgroundColor: '#B68D4A', color: '#FFFFFF' }}>Certificado Digital - OURO</TableCell>
                                        <TableCell align="center" className={classes.bolder} style={{ backgroundColor: '#A3A2A0', color: '#FFFFFF' }}>Certificado Digital - PRATA</TableCell>
                                        <TableCell align="center" className={classes.bolder} style={{ backgroundColor: '#A46628', color: '#FFFFFF' }}>Certificado Digital - BRONZE</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow style={{ border: 1 }}>
                                        <TableCell align="center" component="th" scope="row" rowSpan={4} style={{ backgroundColor: '#FFE699' }}>MIRIM 1</TableCell>
                                        <TableCell align="center" className={classes.bolder}>1 a 60</TableCell>
                                        <TableCell align="center">1</TableCell>
                                        <TableCell align="center">3</TableCell>
                                        <TableCell align="center">6</TableCell>
                                    </TableRow>
                                    <TableRow style={{ border: 1 }}>
                                        <TableCell align="center" className={classes.bolder}>61 a 120</TableCell>
                                        <TableCell align="center">2</TableCell>
                                        <TableCell align="center">6</TableCell>
                                        <TableCell align="center">12</TableCell>
                                    </TableRow>
                                    <TableRow style={{ border: 1 }}>
                                        <TableCell align="center" className={classes.bolder}>121 a 180</TableCell>
                                        <TableCell align="center">3</TableCell>
                                        <TableCell align="center">9</TableCell>
                                        <TableCell align="center">18</TableCell>
                                    </TableRow>
                                    <TableRow style={{ border: 1 }}>
                                        <TableCell align="center" className={classes.bolder}>181 ou +</TableCell>
                                        <TableCell align="center">4</TableCell>
                                        <TableCell align="center">12</TableCell>
                                        <TableCell align="center">24</TableCell>
                                    </TableRow>
                                    <TableRow style={{ border: 1 }}>
                                        <TableCell align="center" component="th" scope="row" rowSpan={4} style={{ backgroundColor: '#FFCCFF' }}>MIRIM 2</TableCell>
                                        <TableCell align="center" className={classes.bolder}>1 a 60</TableCell>
                                        <TableCell align="center">1</TableCell>
                                        <TableCell align="center">3</TableCell>
                                        <TableCell align="center">6</TableCell>
                                    </TableRow>
                                    <TableRow style={{ border: 1 }}>
                                        <TableCell align="center" className={classes.bolder}>61 a 120</TableCell>
                                        <TableCell align="center">2</TableCell>
                                        <TableCell align="center">6</TableCell>
                                        <TableCell align="center">12</TableCell>
                                    </TableRow>
                                    <TableRow style={{ border: 1 }}>
                                        <TableCell align="center" className={classes.bolder}>121 a 180</TableCell>
                                        <TableCell align="center">3</TableCell>
                                        <TableCell align="center">9</TableCell>
                                        <TableCell align="center">18</TableCell>
                                    </TableRow>
                                    <TableRow style={{ border: 1 }}>
                                        <TableCell align="center" className={classes.bolder}>181 ou +</TableCell>
                                        <TableCell align="center">4</TableCell>
                                        <TableCell align="center">12</TableCell>
                                        <TableCell align="center">24</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                            <small style={{ fontSize: '0.85em' }}>Tabela E - Escolas Privadas – Quantidade de premiados por nível</small>
                        </TableContainer>
                    </Grid>
                </Grid>
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>3.15</span> Para as <span className={classes.bolder}>ESCOLAS PRIVADAS</span> que optarem pela <span className={classes.bolder}>MODALIDADE 3 (INSCRIÇÃO SEM PREMIAÇÃO)</span>, o valor da taxa de inscrição é de <span className={clsx(classes.bolder, classes.underline)}>R$ 150,00</span>, independentemente da quantidade de alunos(as) inscritos por nível.
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>3.16</span> Os certificados serão disponibilizados na página restrita da escola na 3ª Olimpíada Mirim – OBMEP (<span className={classes.underline}>www.olimpiadamirim.obmep.org.br</span>), tão logo a escola informe as notas dos alunos na 3ª Fase.
            </Typography>

            <Typography className={classes.text} component={'div'} variant="body2">
                <span className={classes.item}>3.17</span> A <span className={classes.bolder}>MODALIDADE 4 (INSCRIÇÃO COM PREMIAÇÃO)</span> é oferecida exclusivamente às <span className={classes.bolder}>ESCOLAS PRIVADAS</span> e garante a inscrição da escola na 3ª Olimpíada Mirim – OBMEP, nas duas Fase (1ª e 2ª Fases), com premiação digital - 
                certificados digitais para os(as) alunos(as) que obtiverem o melhor desempenho na 2ª Fase da olimpíada, de acordo com o número de inscritos pela escola por Nível (MIRIM 1 – alunos(as) de 2º e 3º anos do Ensino 
                Fundamental e MIRIM 2 – alunos(as) de 4º e 5º anos do Ensino Fundamental) e o envio de medalhas de ouro, prata e bronze em quantidade proporcional a de alunos(as) inscritos pela escola, conforme Tabela F, abaixo:<br /><br />

                <Grid 
                    container 
                    align='center' 
                    direction="row" 
                    justify="center"
                    alignItems="center"
                    className={classes.wrapper}
                >
                    <Grid item sm={10} lg={10}>
                        <TableContainer component="div">
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center" style={{ backgroundColor: '#CACACA' }}>Nível</TableCell>
                                        <TableCell align="center" style={{ backgroundColor: '#CACACA' }}>Quantidade de Alunos Inscritos</TableCell>
                                        <TableCell align="center" className={classes.bolder} style={{ backgroundColor: '#B68D4A', color: '#FFFFFF' }}>Medalha - OURO</TableCell>
                                        <TableCell align="center" className={classes.bolder} style={{ backgroundColor: '#A3A2A0', color: '#FFFFFF' }}>Medalha - PRATA</TableCell>
                                        <TableCell align="center" className={classes.bolder} style={{ backgroundColor: '#A46628', color: '#FFFFFF' }}>Medalha - BRONZE</TableCell>
                                        <TableCell align="center" className={classes.bolder} style={{ backgroundColor: '#CACACA' }}>R$150,00 + </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow style={{ border: 1 }}>
                                        <TableCell align="center" component="th" scope="row" rowSpan={4} style={{ backgroundColor: '#FFE699' }}>MIRIM 1</TableCell>
                                        <TableCell align="center" className={classes.bolder}>1 a 60</TableCell>
                                        <TableCell align="center">1</TableCell>
                                        <TableCell align="center">3</TableCell>
                                        <TableCell align="center">6</TableCell>
                                        <TableCell align="center" className={classes.bolder}>R$ 200,00</TableCell>
                                    </TableRow>
                                    <TableRow style={{ border: 1 }}>
                                        <TableCell align="center" className={classes.bolder}>61 a 120</TableCell>
                                        <TableCell align="center">2</TableCell>
                                        <TableCell align="center">6</TableCell>
                                        <TableCell align="center">12</TableCell>
                                        <TableCell align="center" className={classes.bolder}>R$ 400,00</TableCell>
                                    </TableRow>
                                    <TableRow style={{ border: 1 }}>
                                        <TableCell align="center" className={classes.bolder}>121 a 180</TableCell>
                                        <TableCell align="center">3</TableCell>
                                        <TableCell align="center">9</TableCell>
                                        <TableCell align="center">18</TableCell>
                                        <TableCell align="center" className={classes.bolder}>R$ 600,00</TableCell>
                                    </TableRow>
                                    <TableRow style={{ border: 1 }}>
                                        <TableCell align="center" className={classes.bolder}>181 ou +</TableCell>
                                        <TableCell align="center">4</TableCell>
                                        <TableCell align="center">12</TableCell>
                                        <TableCell align="center">24</TableCell>
                                        <TableCell align="center" className={classes.bolder}>R$ 800,00</TableCell>
                                    </TableRow>
                                    <TableRow style={{ border: 1 }}>
                                        <TableCell align="center" component="th" scope="row" rowSpan={4} style={{ backgroundColor: '#FFCCFF' }}>MIRIM 2</TableCell>
                                        <TableCell align="center" className={classes.bolder}>1 a 60</TableCell>
                                        <TableCell align="center">1</TableCell>
                                        <TableCell align="center">3</TableCell>
                                        <TableCell align="center">6</TableCell>
                                        <TableCell align="center" className={classes.bolder}>R$ 200,00</TableCell>
                                    </TableRow>
                                    <TableRow style={{ border: 1 }}>
                                        <TableCell align="center" className={classes.bolder}>61 a 120</TableCell>
                                        <TableCell align="center">2</TableCell>
                                        <TableCell align="center">6</TableCell>
                                        <TableCell align="center">12</TableCell>
                                        <TableCell align="center" className={classes.bolder}>R$ 400,00</TableCell>
                                    </TableRow>
                                    <TableRow style={{ border: 1 }}>
                                        <TableCell align="center" className={classes.bolder}>121 a 180</TableCell>
                                        <TableCell align="center">3</TableCell>
                                        <TableCell align="center">9</TableCell>
                                        <TableCell align="center">18</TableCell>
                                        <TableCell align="center" className={classes.bolder}>R$ 600,00</TableCell>
                                    </TableRow>
                                    <TableRow style={{ border: 1 }}>
                                        <TableCell align="center" className={classes.bolder}>181 ou +</TableCell>
                                        <TableCell align="center">4</TableCell>
                                        <TableCell align="center">12</TableCell>
                                        <TableCell align="center">24</TableCell>
                                        <TableCell align="center" className={classes.bolder}>R$ 800,00</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                            <small style={{ fontSize: '0.85em' }}>Tabela F - Escolas Privadas – MODALIDADE 4 (INSCRIÇÃO COM PREMIAÇÃO)</small>                            
                        </TableContainer>
                    </Grid>
                </Grid>
            </Typography>

            <Typography className={classes.text} variant="body2">
                A opção da <span className={classes.bolder}>ESCOLA PRIVADA</span> pela <span className={classes.bolder}>MODALIDADE 4 (INSCRIÇÃO COM PREMIAÇÃO)</span> está condicionada ao preenchimento completo da ficha de inscrição, à emissão, dentro do prazo estipulado no Calendário 
                Oficial da 3ª Olimpíada Mirim – OBMEP, ao pagamento e à compensação do pagamento da taxa de inscrição. Ao optar pela <span className={classes.bolder}>MODALIDADE 4 (INSCRIÇÃO COM PREMIAÇÃO)</span>, a <span className={classes.bolder}>ESCOLA PRIVADA</span> deverá, dentro do prazo estipulado 
                no Calendário Oficial da 3ª Olimpíada Mirim – OBMEP, “PERÍODO PARA EMISSÃO E PAGAMENTO DE BOLETO”, acessar a página da 3ª Olimpíada Mirim – OBMEP, <span className={classes.underline}>www.olimpiadamirim.obmep.org.br</span>, e promover a emissão do boleto 
                bancário que deverá ser pago, obrigatoriamente até a data de vencimento. O valor da taxa de inscrição da <span className={classes.bolder}>ESCOLA PRIVADA</span> na <span className={classes.bolder}>MODALIDADE 4 (INSCRIÇÃO COM PREMIAÇÃO)</span> varia de acordo com a quantidade de alunos inscritos pela 
                escola em cada nível (MIRIM 1 – 2º e 3º anos do Ensino Fundamental e MIRIM 2 – 4º e 5º anos do Ensino Fundamental).
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.bolder}>PARÁGRAFO ÚNICO:</span><br />
                A premiação das escolas públicas e privadas inscritas na 3ª Olimpíada Mirim – OBMEP está diretamente relacionada à quantidade de alunos(as) inscritos(as) pela escola em cada nível (Mirim 1 – Alunos de 2º e 3º anos e Mirim 2 – Alunos de 4º e 5º anos do ensino fundamental), 
                conforme indicado nas Tabelas B, C, E, F, H, I e J dos itens 3.7, 3.10, 3.14, 3.17, 4.3, 4.5 e 16.1 e no Anexo II, <span className={clsx(classes.bolder, classes.underline)}>EXCETO</span> quando a quantidade de alunos(as) classificados(as), por nível, for inferior à quantidade de premiados(as). Nestes casos, o número de 
                certificados/medalhas de bronze será reduzido até que se alcance a quantidade de classificados(as). Excluídos(as) todos(as) os certificados/medalhas de bronze, caso a quantidade de classificados(as) ainda permaneça maior do que a quantidade de premiados(as) 
                prevista, a quantidade de certificados/medalhas de prata será reduzida até que o número de classificados(as) seja igual ao número de premiados(as).            
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>3.18</span> A <span className={classes.bolder}>ESCOLA PRIVADA</span> que não realizar a emissão de boleto, dentro do prazo estipulado no Calendário Oficial da 3ª Olimpíada Mirim – OBMEP, bem como não realizar o pagamento e não for verificada a consequente 
                compensação do pagamento de taxa de inscrição estará automaticamente excluída da 3ª Olimpíada Mirim – OBMEP.
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>3.19</span> Não serão concedidos às Escolas Públicas ou Privadas novos prazos de pagamento, senão o previsto no Calendário Oficial da 3ª Olimpíada Mirim – OBMEP (Anexo I).
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>3.20</span> A definição dos premiados, com base no disposto no item 16. DOS CRITÉRIOS DE PREMIAÇÃO, deste Regulamento se dará por ordenamento decrescente de notas, sendo a escola responsável por validar cada um de 
                seus medalhistas, sobretudo nos casos de empate para os quais a escola deverá antecipadamente definir critérios de desempate.
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>3.21</span> A escola, antes de realizar a sua inscrição, deverá ler atentamente este regulamento e seus anexos, para se certificar de que aceita todas as condições nele estabelecidas e de que preenche todos 
                os requisitos exigidos para a participação na 3ª Olimpíada Mirim - OBMEP.
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>3.22</span> As inscrições, independentemente da modalidade escolhida, deverão ser efetuadas dentro do prazo estipulado no Calendário Oficial da 3ª Olimpíada Mirim - OBMEP (Anexo I), por meio do preenchimento 
                da Ficha de Inscrição disponível exclusivamente no site <span className={classes.underline}>www.olimpiadamirim.obmep.org.br</span>.
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>3.23</span> As inscrições para participação dos(as) alunos(as) na 3ª Olimpíada Mirim - OBMEP deverão ser realizadas <span className={classes.bolder}>exclusivamente</span> pelas escolas ou secretarias de educação.
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>3.24</span> <span className={classes.bolder}>A ESCOLA PÚBLICA, A ESCOLAS PRIVADA E A SECRETARIA DE EDUCAÇÃO MUNICIPAL OU ESTADUAL, AO REALIZAR A INSCRIÇÃO, CONCORDAM INTEGRALMENTE COM TODAS AS CONDIÇÕES E REGRAS PREVISTAS NO PRESENTE REGULAMENTO, 
                NÃO PODENDO ALEGAR DESCONHECIMENTO POSTERIOR, BEM COMO COMPROMETEM-SE A DAR PUBLICIDADE AO PRESENTE REGULAMENTO, NA FORMA DO ITEM 3.35.</span> 
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>3.25</span> Para se inscrever na 3ª Olimpíada Mirim - OBMEP, a escola deverá informar obrigatoriamente o respectivo código MEC/INEP, o qual será considerado como o número de identificação na Olimpíada Mirim em 
                todo o processo e preencher todos os campos do formulário de inscrição.
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>3.26</span> O acesso à área restrita pelas escolas inscritas deve ser realizado por meio do site da Olimpíada Mirim - OBMEP (<span className={classes.underline}>www.olimpiadamirim.obmep.org.br</span>).
            </Typography>

            <Typography className={classes.text} component={'div'} variant="body2">
                <span className={classes.item}>3.27</span> O login da escola ou da secretaria, para acesso ao sistema, será sempre o código MEC/INEP (de cada escola), e a senha será aquela cadastrada pela escola/secretaria no ato do processo de inscrição. 
                Essas informações deverão ser utilizadas para acesso ao sistema em todas as etapas da Olimpíada Mirim - OBMEP.

                <ul className={classes.list}>
                    <li><span className={classes.item}>3.27.1</span> Não serão aceitas inscrições enviadas por meio de mensagens eletrônicas, telefone ou por meio postal.</li>
                    <li><span className={classes.item}>3.27.2</span> Não serão aceitas inscrições incompletas. A escola é responsável por informar corretamente todos os dados no ato da inscrição, especialmente seu endereço e contatos, inclusive e-mail 
                        e números de telefone. O IMPA não se responsabilizará por qualquer problema na participação das escolas decorrente de informações equivocadas ou incompletas, como em caso de impossibilidade de entrega 
                        do material de provas em razão de endereços errados ou incompletos.</li>
                    <li><span className={classes.item}>3.27.3</span> Não serão aceitas inscrições fora do prazo. O IMPA não se responsabilizará por inscrições não realizadas por motivos de ordem técnica dos computadores, falhas de comunicação, erro, 
                        falta de energia, bem como outros fatores que impossibilitem a transferência de dados.</li>
                </ul>
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>3.28</span> O responsável pela inscrição deverá informar corretamente todos os dados solicitados na Ficha de Inscrição.
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>3.29</span> O responsável pela inscrição deverá informar, na Ficha de Inscrição, apenas o total de alunos(as) participantes por nível (MIRIM 1 – 2º e 3º anos e MIRIM 2 – 4º e 5º anos), não sendo necessária 
                a inscrição nominal de alunos(as).
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>3.30</span> Exclusivamente durante o período de inscrição, as escolas poderão realizar alterações e correções no formulário de inscrição como a alteração da modalidade de inscrição, do número de participantes 
                ou a correção de dados cadastrais, sendo certo que, <span className={clsx(classes.bolder, classes.underline)}>a partir do dia 22/06/2024 não será permitida a realização de quaisquer alterações na inscrição ou a realização de novas inscrições.</span>
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>3.31</span> É de responsabilidade do representante de escola ou da secretaria a conservação do recibo gerado ao final do processo de inscrição, o qual constitui comprovante de inscrição da(s) escola(s) sob 
                sua responsabilidade. Caso o recibo não seja gerado, o responsável pela inscrição deverá entrar em contato com a central de atendimento da Olimpíada Mirim - OBMEP pelo telefone (21) 2529-5084, em horário comercial 
                (horário de Brasília), ou por meio de mensagem para <span className={classes.underline}>olimpiadamirim@obmep.org.br</span>.
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>3.32</span> É de responsabilidade de cada escola ou secretaria divulgar amplamente a participação na 3ª Olimpíada Mirim - OBMEP e, também, o teor deste Regulamento e o material de apoio, ambos disponíveis para 
                consulta no site <span className={classes.underline}>www.olimpiadamirim.obmep.org.br</span>, para os seus alunos e seus respectivos representantes legais, além de professores e demais profissionais da instituição.
            </Typography>
        </>
    )
}

export default Inscricao;