import React, { useState } from 'react';

import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
import SyncOutlinedIcon from '@material-ui/icons/SyncOutlined';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import ObjetoPostalService from '../../services/ObjetoPostal';

import { patternConteudo } from '../../variables/Enums/ObjetoPostal';

export const AlertaObjetoPostal = ({ objetos, handleAtualizarObjetoPostal }) => {

    const [loading, setLoading] = useState(false);

    const handleAtualizar = (numero) => {
        setLoading(true);

        ObjetoPostalService.atualizar(numero)
            .then(res => {
                handleAtualizarObjetoPostal(res.data);
            })
            .finally(() => setLoading(false));
    }

    return (
        <Grid item xs={12} spacing={1}>
            <Alert severity="info" style={{ marginTop: '10px' }} >
                <Grid container >
                    <Grid item sm={12} lg={12}>
                        <Typography variant="body1">
                            Objetos Postais Enviados para a Escola: 
                            <br />

                            <ul style={{ paddingLeft: '30px' }}>
                                {objetos.map(objeto => 
                                    <li key={objeto.numero}>
                                        {patternConteudo(objeto.conteudo)}: <b>{objeto.numero} {objeto.status && ` - ${objeto.status}`}</b>
                                        <Tooltip title={`Atualizar Status do Objeto ${objeto.numero}`} aria-label={`Atualizar Status do Objeto ${objeto.numero}`}>
                                            <IconButton
                                                color="primary"
                                                size="small"
                                                aria-label={`Atualizar Status do Objeto ${objeto.numero}`}
                                                onClick={() => handleAtualizar(objeto.numero)}
                                                disabled={loading}
                                            >
                                                {loading ? <CircularProgress style={{ marginLeft: 4 }} size={14} /> : <SyncOutlinedIcon fontSize="small" />}
                                            </IconButton>
                                        </Tooltip>
                                    </li>
                                )}
                            </ul>
                        </Typography>
                    </Grid>
                </Grid>          
            </Alert>
        </Grid>
    )
}

export default AlertaObjetoPostal;