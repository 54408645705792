import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Table from '../../components/Table/Table';
import ArrowUpwardOutlinedIcon from '@material-ui/icons/ArrowUpwardOutlined';
import ArrowDownwardOutlinedIcon from '@material-ui/icons/ArrowDownwardOutlined';
import RemoveCircleOutlineOutlinedIcon from '@material-ui/icons/RemoveCircleOutlineOutlined';

import BannerService from '../../services/Banner';
import { errors } from '../../services/API';

import Dialog from '../../components/Dialog/Dialog';
import Toast from '../../components/Toast/Toast';

import { ButtonCancel } from '../../forms/Buttons/ButtonsForm';
import Loading from '../../components/Loading/Loading';

export class DialogPosicoes extends Component {
    constructor(props) {
        super(props);
        props.parentRef(this);
        this.state = {
            open: false,
            loading: false,
            banners: [],
        }
    }

    // DECLARAÇÕES DE REFERENCIA DOS COMPONENTES
    setToast = t => this.Toast = t;

    open = () => this.setState({ ...this.state, open: true }, () => this.loadData());

    // FECHAR MODAL
    handleClose = () => {
        this.setState({
            open: false,
            loading: false,
            banners: [],
        }, () => this.props.loadData());
    }

    // AÇÕES MODAL
    buttons = () =>
        <>
            <ButtonCancel 
                onBack={this.handleClose} 
                nameOnBack="Fechar"
            />
        </>

    // CARREGAR DADOS
    loadData = () => {
        this.setState({ loading: true });

        BannerService.paginaInicial()
            .then(res => {
                this.setState({
                    banners: [...res.data],
                    loading: false,
                });
            })
            .catch(error => {
                const e = errors(error);
                this.Toast.setState({
                    message: {
                        message: e.message,
                        type: e.type,
                        open: true
                    }
                })

                this.setState({ loading: false });
            })
    }

    disabledUp = id => {
        const banner = this.state.banners.find(b => b.cdBanner === id);
        return banner.posicao === 1;
    }

    disabledDown = id => {
        const banner = this.state.banners.find(b => b.cdBanner === id);
        return banner.posicao === this.state.banners.length;
    }

    handleSubirClick = id => this.alterarPosicao(id, "UP");
    handleDescerClick = id => this.alterarPosicao(id, "DOWN");
    handleRemoveClick = id => this.alterarPosicao(id, "REMOVE");

    // ALTERAR POSICOES
    alterarPosicao = (id, acao) => {
        this.setState({ loading: true });

        BannerService.alterarPosicao(id, acao)
            .then(res => {
                this.setState({ loading: false, }, () => { this.loadData(); this.props.loadData(); });
            })
            .catch(error => {
                const e = errors(error);
                this.Toast.setState({
                    message: {
                        message: e.message,
                        type: e.type,
                        open: true
                    }
                })

                this.setState({ loading: false });
            })
    }

    render() {
        const columns = [
            { label: '#', name: 'cdBanner', func: null, key: true, hidden: true },
            { label: 'Posição', name: 'posicao', func: null, key: false },
            { label: 'Título', name: 'titulo', func: null, key: false },
        ];
        
        const actions = [
            { 
              name: () => "Subir Posição",
              func: this.handleSubirClick,
              icon: <ArrowUpwardOutlinedIcon />,
              disabled: this.disabledUp,
            },
            { 
              name: () => "Descer Posição",
              func: this.handleDescerClick,
              icon: <ArrowDownwardOutlinedIcon />,
              disabled: this.disabledDown,
            },
            { 
              name: () => "Remover da Página Inicial",
              func: this.handleRemoveClick,
              icon: <RemoveCircleOutlineOutlinedIcon />,
              disabled: () => false,
              color: "secondary",
            },
        ];

        return (
            <>
                <Toast parentRef={this.setToast} />
                <Dialog
                    maxWidth={"lg"}
                    fullWidth={true}
                    closeDialog={this.handleClose}
                    openDialog={this.state.open}
                    title={"Posições de Banners na Página Inicial"}
                    actions={this.buttons()}
                >
                    <Grid container spacing={3} alignItems='center'>
                        <Grid item sm={12} lg={12}>
                            { this.state.loading ?
                                <Loading height={10} />
                            :
                                this.state.banners.length > 0 ?
                                    <Table
                                        loading={this.state.loading}         
                                        columns={columns}
                                        tableData={this.state.banners}
                                        page={null}
                                        sort={null}
                                        rowsPerPage={null}
                                        actions={actions}
                                    />
                                : 
                                    <Typography variant="body2" >
                                        Adicione Banners a Página Inicial através da listagem para ordená-los aqui.
                                    </Typography>
                            }
                        </Grid>
                    </Grid>
                </Dialog>
            </>
        )
    }
}

export default DialogPosicoes;