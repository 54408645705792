import React, { Component } from 'react'

import Grid from '@material-ui/core/Grid'
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";

import { errors } from '../../services/API'

import Dialog from '../../components/Dialog/Dialog'
import Text from '../../components/Inputs/Text/Text'
import Toast from '../../components/Toast/Toast'
import SelectOption from "../../components/Inputs/Select/Select";

import { ButtonsForm } from '../../forms/Buttons/ButtonsForm'

import CoordenadorService from "../../services/Coordenador";

import {isBlank, isBlankHelperText} from "../../helper/ValidationHelper";

export class DialogMaterialExtra extends Component {
    constructor(props) {
        super(props);
        props.parentRef(this);
        this.state = {
            open: false,
            coordenadores: [],
            tipos: [],
            material: "",
            quantidade: 0,
            errors: {
                material: false,
            },
            helpers: {
                material: null,
            },
            loadingButtonSave: false,
        }
    }

    // DECLARAÇÕES DE REFERENCIA DOS COMPONENTES
    setToast = t => this.Toast = t;

    handleChange = e => {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value,
            errors: {...this.state.errors, [e.target.name]: false },
            helpers: {...this.state.helpers, [e.target.name]: null }
        });
    };

    // AÇÕES MODAL
    buttons = (id) =>
        <ButtonsForm
            onClick={this.handleSubmit}
            onBack={() => this.handleClose()}
            idFocus={`saveButton`}
            loading={this.state.loadingButtonSave}
        />

    // FECHAR MODAL
    handleClose = () => {
        this.setState({
            open: false,
            coordenadores: [],
            tipos: [],
            material: "",
            quantidade: 0,
            errors: {
                material: false,
            },
            helpers: {
                material: null,
            },
            loadingButtonSave: false,
        });

        this.props.handleClose();
    }

    // VALIDAÇÃO
    isValid = () => {
        if (isBlank(this.state.material)) {
            this.setState({
                errors: {
                    material: isBlank(this.state.material) ? true : false,
                },
                helpers: {
                    material: isBlank(this.state.material) ? isBlankHelperText() : null,
                },
            });
            return false
        }
        return true
    }

    // SUBMIT
    handleSubmit = () => {
        this.setState({ loadingButtonSave: true });

        if (!this.isValid()) {
            this.setState({ loadingButtonSave: false });
            return
        }

        const objeto = {
            coordenadores: [
                ...this.state.coordenadores.map(c => c.id)
            ],
            materiais: [
                {
                    cdTipoDocumento: this.state.material,
                    qtdDocumento: this.state.quantidade ?? 0
                }
            ],
        };

        CoordenadorService.atualizarMaterialExtra(objeto)
            .then(res => {
                this.Toast.setState({
                    message: {
                        message: "Quantidade de Material Extra Atualizada Com Sucesso.",
                        type: "success",
                        open: true
                    }
                })

                this.setState({ loadingButtonSave: false });
                setTimeout(() => this.handleClose(), 500);
            })
            .catch(err => {
                const e = errors(err);
                this.Toast.setState({
                    message: {
                        message: e.message,
                        type: e.type,
                        open: true
                    }
                })
            })
            .finally(() => this.setState({ loadingButtonSave: false }));
    }

    render() {
        return (
            <>  
                <Toast parentRef={this.setToast} />
                <Dialog
                    maxWidth={"sm"}
                    fullWidth={true}
                    closeDialog={this.handleClose}
                    openDialog={this.state.open}
                    title={"Atualizar Quantidade de Material Extra para os Coordenadores"}
                    actions={this.buttons()}
                >
                    <Grid container alignItems='center'>
                        <Grid item sm={12} lg={12} >
                            <Paper elevation={1} style={{ padding: 16 }} >
                                <Typography component="p" variant="overline" >
                                    {this.state.coordenadores.length > 1 ? "Coordenadores Selecionados" : "Coordenador Selecionado"}:
                                </Typography>

                                {this.state.coordenadores.map((c) =>
                                    <div key={c.id} style={{ margin: "4px", display: "inline-block" }}>
                                        <Chip
                                            color="primary"
                                            label={`${c.login.toUpperCase()} - ${c.nome} - ${c.regiao}`}
                                        />
                                    </div>
                                )}
                            </Paper>
                        </Grid>

                        <Grid item sm={12} >
                            <SelectOption
                                name="material"
                                label="Tipo de Material"
                                onChange={this.handleChange}
                                value={this.state.material}
                                options={this.state.tipos.map(t => { return { label: `#${t.codigoDocumento} - ${t.nomeTipoDocumento}`, value: t.codigoDocumento }; })}
                                hiddenblank={true}
                                error={this.state.errors.material}
                                helperText={this.state.material ? `Envelope: ${this.state.tipos.find(m => this.state.material === m.codigoDocumento).nomeEnvelope}` : this.state.helpers.material}
                            />
                        </Grid>

                        {this.state.material &&
                            <Grid item sm={12} >
                                <Text
                                    name="quantidade"
                                    label="Quantidade"
                                    onChange={this.handleChange}
                                    value={this.state.quantidade}
                                    error={this.state.quantidade || this.state.quantidade === 0}
                                />
                            </Grid>
                        }
                    </Grid>
                </Dialog>
            </>
        )
    }
}

export default DialogMaterialExtra;