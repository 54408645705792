export const patternMateriais = (tipos, materiais) => {
    let array = [];

    materiais.forEach((material) => {
        array.push({
            id: material.cdTipoDocumento,
            value: material.qtdDocumento,
        });
    });

    let idsMateriaisCheios = materiais.map(material => material.cdTipoDocumento);
    let tiposVazios = tipos.filter(tipo => !idsMateriaisCheios.includes(tipo.codigoDocumento));

    tiposVazios.forEach((tipo) => {
        array.push({
            id: tipo.codigoDocumento,
            value: 0,
        });
    });

    return array;
}