import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
    (theme) => (
        {
            container: {
                marginTop: theme.spacing(12),
                marginBottom: theme.spacing(8),
            },
            subtitle: {
                textIndent: theme.spacing(2),
            },
            text: {
                marginTop: theme.spacing(2),
                textIndent: theme.spacing(4),
                textAlign: 'justify',
            },
            bolder: {
                fontWeight: 'bold',
            },
        }
    )
);

export default useStyles;