import React from 'react';
import clsx from 'clsx';

import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';

import Title from '../../components/Title/Title';

import useStyles from './Style';

const Premiacao = () => {
    const classes = useStyles();
    return (
        <>
            <Title id="anchor-16" className={classes.title}>
                16. DOS CRITÉRIOS DE PREMIAÇÃO
            </Title>

            <Typography className={classes.text} component={'div'} variant="body2">
                <span className={classes.item}>16.1</span> 
                A premiação das escolas públicas e privadas inscritas na 3ª Olimpíada Mirim – OBMEP está diretamente relacionada à quantidade de alunos(as) inscritos(as) pela escola em cada nível (Mirim 1 – Alunos de 2º e 3º anos e Mirim 2 – 
                Alunos de 4º e 5º anos do ensino fundamental), conforme indicado nas Tabelas B, C, E, F, H, I e J dos itens 3.7, 3.10, 3.14, 3.17, 4.3, 4.5, no Anexo II, e na Tabela J, abaixo, <span className={clsx(classes.bolder, classes.underline)}>EXCETO</span> quando a 
                quantidade de alunos(as) classificados(as), por nível, for inferior à quantidade de premiados(as). Nestes casos, o número de certificados/medalhas de bronze será reduzido até que se alcance a quantidade de classificados(as). Excluídos(as) todos(as) os 
                certificados/medalhas de bronze, caso a quantidade de classificados(as) ainda permaneça maior do que a quantidade de premiados(as) prevista, a quantidade de certificados/medalhas de prata será reduzida até que o número de classificados(as) seja igual ao número 
                de premiados(as).<br /><br />

                <Grid 
                    container 
                    align='center' 
                    direction="row" 
                    justify="center"
                    alignItems="center"
                    className={classes.wrapper}
                >
                    <Grid item sm={10} lg={10}>
                        <TableContainer component="div">
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center" style={{ backgroundColor: '#CACACA' }}>Nível</TableCell>
                                        <TableCell align="center" style={{ backgroundColor: '#CACACA' }}>Quantidade de Alunos Inscritos</TableCell>
                                        <TableCell align="center" className={classes.bolder} style={{ backgroundColor: '#B68D4A', color: '#FFFFFF' }}>OURO</TableCell>
                                        <TableCell align="center" className={classes.bolder} style={{ backgroundColor: '#A3A2A0', color: '#FFFFFF' }}>PRATA</TableCell>
                                        <TableCell align="center" className={classes.bolder} style={{ backgroundColor: '#A46628', color: '#FFFFFF' }}>BRONZE</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow style={{ border: 1 }}>
                                        <TableCell align="center" component="th" scope="row" rowSpan={4} style={{ backgroundColor: '#FFE699' }}>MIRIM 1</TableCell>
                                        <TableCell align="center" className={classes.bolder}>1 a 60</TableCell>
                                        <TableCell align="center">1</TableCell>
                                        <TableCell align="center">3</TableCell>
                                        <TableCell align="center">6</TableCell>
                                    </TableRow>
                                    <TableRow style={{ border: 1 }}>
                                        <TableCell align="center" className={classes.bolder}>61 a 120</TableCell>
                                        <TableCell align="center">2</TableCell>
                                        <TableCell align="center">6</TableCell>
                                        <TableCell align="center">12</TableCell>
                                    </TableRow>
                                    <TableRow style={{ border: 1 }}>
                                        <TableCell align="center" className={classes.bolder}>121 a 180</TableCell>
                                        <TableCell align="center">3</TableCell>
                                        <TableCell align="center">9</TableCell>
                                        <TableCell align="center">18</TableCell>
                                    </TableRow>
                                    <TableRow style={{ border: 1 }}>
                                        <TableCell align="center" className={classes.bolder}>181 ou +</TableCell>
                                        <TableCell align="center">4</TableCell>
                                        <TableCell align="center">12</TableCell>
                                        <TableCell align="center">24</TableCell>
                                    </TableRow>
                                    <TableRow style={{ border: 1 }}>
                                        <TableCell align="center" component="th" scope="row" rowSpan={4} style={{ backgroundColor: '#FFCCFF' }}>MIRIM 2</TableCell>
                                        <TableCell align="center" className={classes.bolder}>1 a 60</TableCell>
                                        <TableCell align="center">1</TableCell>
                                        <TableCell align="center">3</TableCell>
                                        <TableCell align="center">6</TableCell>
                                    </TableRow>
                                    <TableRow style={{ border: 1 }}>
                                        <TableCell align="center" className={classes.bolder}>61 a 120</TableCell>
                                        <TableCell align="center">2</TableCell>
                                        <TableCell align="center">6</TableCell>
                                        <TableCell align="center">12</TableCell>
                                    </TableRow>
                                    <TableRow style={{ border: 1 }}>
                                        <TableCell align="center" className={classes.bolder}>121 a 180</TableCell>
                                        <TableCell align="center">3</TableCell>
                                        <TableCell align="center">9</TableCell>
                                        <TableCell align="center">18</TableCell>
                                    </TableRow>
                                    <TableRow style={{ border: 1 }}>
                                        <TableCell align="center" className={classes.bolder}>181 ou +</TableCell>
                                        <TableCell align="center">4</TableCell>
                                        <TableCell align="center">12</TableCell>
                                        <TableCell align="center">24</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                            <small style={{ fontSize: '0.85em' }}>Tabela J - Quantidade de premiações de acordo com a quantidade de alunos(as) inscritos(as) por nível</small>
                        </TableContainer>
                    </Grid>
                </Grid>
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>16.2</span> A todas as escolas inscritas serão disponibilizados <span className={classes.bolder}>CERTIFICADOS DIGITAIS</span> correspondentes a medalhas de ouro, prata e bronze para condecorar os(as) alunos(as) com os melhores 
                desempenhos na 2ª Fase da olimpíada, considerando a quantidade de alunos(as) inscritos(as) por nível (MIRIM 1 e MIRIM2), conforme quantitativos previstos na Tabela J, tão logo a escola 
                indique, na página restrita da escola na Olimpíada Mirim – OBMEP, www.olimpiadamirim.obmep.org.br, dentro do prazo estipulado no Calendário Oficial da 3ª Olimpíada Mirim – OBMEP, a nota 
                da cada um(a) dos(as) alunos(as) na prova da 2ª Fase.
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>16.3</span> As medalhas físicas de ouro, prata e bronze serão enviadas somente para os endereços declarados pelas <span className={classes.bolder}>ESCOLAS PÚBLICAS</span> com inscrição validada na <span className={classes.bolder}>MODALIDADE 2</span> 
                (INSCRIÇÃO COM PREMIAÇÃO) e pelas <span className={classes.bolder}>ESCOLAS PRIVADAS</span> com inscrição validada na <span className={classes.bolder}>MODALIDADE 4</span> (INSCRIÇÃO COM PREMIAÇÃO), serão enviadas para os endereços declarados pelas escolas na ficha de inscrição, 
                no ano subsequente à realização da olimpíada, ou seja, no caso da 3ª Olimpíada Mirim – OBMEP 2024, as medalhas serão enviadas no ano de 2025, podendo ser enviadas antes, caso o IMPA assim o decida..
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>16.4</span> A quantidade de medalhas a ser enviada para as <span className={classes.bolder}>ESCOLAS PÚBLICAS</span> optantes pela <span className={classes.bolder}>MODALIDADE 2</span> de inscrição (INSCRIÇÃO COM PREMIAÇÃO) e para as <span className={classes.bolder}>ESCOLAS PRIVADAS</span> inscritas na 
                <span className={classes.bolder}>MODALIDADE 4</span> (INSCRIÇÃO COM PREMIAÇÃO) seguirá rigorosamente os critérios expostos na Tabela J, disponível no item 16.1 deste regulamento.
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>16.5</span> A quantidade de medalhas a ser enviada para as para as <span className={classes.bolder}>ESCOLAS PÚBLICAS</span> optantes pela <span className={classes.bolder}>MODALIDADE 2</span> de inscrição (INSCRIÇÃO COM PREMIAÇÃO) e para as <span className={classes.bolder}>ESCOLAS PRIVADAS</span> inscritas 
                na <span className={classes.bolder}>MODALIDADE 4</span> (INSCRIÇÃO COM PREMIAÇÃO) está diretamente relacionada ao número de alunos(as), por nível, declarado na ficha de inscrição. Não será permitido às escolas inscritas na <span className={classes.bolder}>MODALIDADE 2</span> 
                ou na <span className={classes.bolder}>MODALIDADE 4</span>, receber quantidade de medalhas maior ou menor do que a determinada na Tabela J, disponível no item 16.1 deste regulamento. Mesmo em caso de desistência, após a quitação do 
                boleto correspondente à taxa, o valor não será devolvido para a escola sob nenhuma hipótese. A modalidade, a quantidade de alunos inscritos pela escola por nível e, por consequência, a quantidade 
                de alunos a classificar e a quantidade de premiados somente poderá ser alterada durante o período de inscrições, de 02 de maio a 21 de junho, sendo vedada qualquer alteração após este período.
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>16.6</span> É de responsabilidade da escola, independente da modalidade inscrita, caso julgue necessário, promover cerimônias de premiação, estando o IMPA isento de qualquer relação com as 
                eventuais cerimônias realizadas pela escola ou secretaria, independente da opção de modalidade de inscrição.
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>16.7</span> As <span className={classes.bolder}>ESCOLAS PÚBLICAS</span> inscritas na MODALIDADE 1 (INSCRIÇÃO SEM PREMIAÇÃO) e às <span className={classes.bolder}>ESCOLAS PRIVADAS</span> inscritas na MODALIDADE 3 (INSCRIÇÃO SEM PREMIAÇÕES) <span className={classes.bolder}>NÃO</span> receberão medalhas físicas, 
                possuindo direito, tão somente, ao recebimento de <span className={classes.bolder}>CERTIFICADOS DIGITAIS</span>, conforme disposto no item 16.2. deste regulamento.
            </Typography>
        </>
    )
}

export default Premiacao;
