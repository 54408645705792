import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';

import Cache from './Cache';

import RelatorioService from '../../services/Relatorio';
import { errors } from '../../services/API';

import Title from '../../components/Title/Title';
import Toast from '../../components/Toast/Toast';
import Loading from '../../components/Loading/Loading';

import DadosGestaoEscola from '../../forms/Relatorio/DadosGestaoEscola';
import { ButtonExport } from '../../forms/Buttons/ButtonsExport';

import AuthorizedElement from '../../security/AuthorizedElement';
import { __RELATORIO_GESTAO_EXPORTAR } from '../../security/RoleConfiguration';

import { download } from '../../helper/FileHelper';

export class GestaoPorEscola extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ultimaChamada: '',
            loading: true,
        }
    }

    // DECLARAÇÃO DE REFERÊNCIA DOS COMPONENTES
    setToast = t => this.Toast = t;
    setDadosGestaoEscola = d => this.DadosGestaoEscola = d;

    // EXPORTAÇÃO
    handleExportChange = () => {       
        const f = [];

        f.push({ field: "forceRefresh", value: true })
        f.push({ field: 'exportarPlanilha', value: true });
        
        this.Toast.setState({
            message: {
                message: "A Planilha está sendo gerada para Exportação.",
                type: 'info',
                open: true,
                loading: true,
                autoHide: false,
            }
        })

        RelatorioService.exportReportGestaoPorEscolas(f)
            .then(res => download(res))
    }

    // FILTROS
    ApplyFilters = (forceRefresh = false) => {
        this.setState({ loading: true });

        const f = [];
        f.push({ field: "forceRefresh", value: forceRefresh })

        RelatorioService.filtersReportGestaoPorEscolas(f)
            .then(res => {
                this.setState({ 
                    ultimaChamada: res.data.ultimaChamada,
                    loading: false,
                });

                this.DadosGestaoEscola.setState({ 
                    ufs: res.data.cache
                });
            })
            .catch(error => {                
                const e = errors(error);
                
                this.setState({ loading: false });
                
                this.Toast.setState({
                    message: {
                        message: e.message,
                        type: e.type,
                        open: true
                    }
                })
            });
    }

    componentDidMount() {
        this.ApplyFilters();
    }

    render() {
        const pageName = 'Escolas';

        return(
            <>
                <Toast parentRef={this.setToast} />
                <Title>
                    {pageName}
                    { this.state.ultimaChamada && 
                        <Cache ultimaAtualizacao={this.state.ultimaChamada} action={() => this.ApplyFilters(true)} /> 
                    }
                </Title>
                
                <Grid container >
                    <AuthorizedElement roles={[__RELATORIO_GESTAO_EXPORTAR]}>
                        <ButtonExport
                            title="Exportar Planilha"
                            onClick={this.handleExportChange}
                        />
                    </AuthorizedElement>
                </Grid>

                { this.state.loading ? 
                    <Loading />
                :
                    <DadosGestaoEscola parentRef={this.setDadosGestaoEscola} />
                }
            </>
        )
    }
}

export default GestaoPorEscola;