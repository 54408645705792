import React from 'react';
import clsx from 'clsx';

import Typography from '@material-ui/core/Typography';
import Title from '../../components/Title/Title';

import useStyles from './Style';

const QuemSomos = () => {
    const classes = useStyles();
    return (
        <>
            <Title id="anchor-1" variant="h6" className={classes.subtitle}>
                <span className={classes.item}>1.</span> QUEM SOMOS?
            </Title>

            <Typography className={classes.text} variant="body2">
                O Instituto de Matemática Pura e Aplicada “IMPA” é associação de ensino e pesquisa sem fins lucrativos, qualificada como Organização Social e vinculada ao
                <a href="http://www.mctic.gov.br/" target="_blank" rel="noopener noreferrer" className={clsx(classes.pointer, classes.bolder)}> Ministério da Ciência, Tecnologia e Inovação (MCTI)</a> e ao
                <a href="https://www.mec.gov.br/" target="_blank" rel="noopener noreferrer" className={clsx(classes.pointer, classes.bolder)}> Ministério da Educação (MEC)</a>.
            </Typography>
            <Typography className={classes.text} variant="body2">
                Sendo assim, o IMPA trabalha em constante colaboração com poderes públicos e instituições de ensino no Brasil e no exterior, oferecendo programas de formação
                acadêmica e capacitação profissional, obras e eventos científicos, programas de ensino e aprendizado, dentre outras oportunidades no setor educacional.
            </Typography>
            <Typography className={classes.text} variant="body2">
                Dentre os programas de ensino, insere-se as Olimpíadas Brasileiras de Matemática das Escolas Públicas – OBMEP, concurso cultural realizado pelo IMPA com apoio da Sociedade Brasileira de Matemática
                (SBM), promovida majoritariamente com recursos oriundos do contrato de gestão firmado pelo IMPA com o Ministério da Ciência, Tecnologia e Inovação (MCTI) e com o Ministério da Educação (MEC).
            </Typography>
            <Typography className={classes.text} variant="body2">
                Para realização da OBMEP, o IMPA realiza o tratamento de dados pessoais dos participantes, cujas finalidades e maiores informações serão listadas no presente Aviso.
            </Typography>
        </>
    )
}

export default QuemSomos;