import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';

import Filters from '../../components/Filters/Filters';
import Text from '../../components/Inputs/Text/Text';
import SelectOption from "../../components/Inputs/Select/Select";

import EnderecoFilter from '../Endereco/EnderecoFilter';

import { optionsStatusMateriais, optionsTipoCoordenador } from "../../variables/Enums/Coordenador";

import { download } from '../../helper/FileHelper';
import { removePagingAndSorting } from '../../helper/PaginationHelper';

export class CoordenadorFilter extends Component {
    constructor(props) {
        super(props);
        props.parentRef(this);
        this.state = {
            filters: {
                nmCoordenador: '',
                nmEmail: '',
                tipo: '',
                statusMateriais: '',
            },
            exportPlan: false,
            count: 0,
        }
    }

    // INPUTS
    handleChange = e => {
        this.setState({
            filters: { ...this.state.filters, [e.target.name]: e.target.value }
        });
    };

    // DECLARAÇÃO DE REFERÊNCIA DOS COMPONENTES TIPO FORM
    setEnderecoFilter = form => this.EnderecoFilter = form;

    // APLICAR PERSISTENCIA
    handleFilterPersistence = (filters) => {
        filters = removePagingAndSorting(filters);

        this.setState({
            filters: {
                nmCoordenador: filters.find(f => f.field === 'nmCoordenador') ? filters.find(f => f.field === 'nmCoordenador').value : "",
                nmEmail: filters.find(f => f.field === 'nmEmail') ? filters.find(f => f.field === 'nmEmail').value : "",
                tipo: filters.find(f => f.field === 'tipo') ? filters.find(f => f.field === 'tipo').value : "",
                statusMateriais: filters.find(f => f.field === 'statusMateriais') ? filters.find(f => f.field === 'statusMateriais').value : "",
            },
            count: filters.reduce((total, f) => f.value !== '' ? total + 1 : total, 0),
        })

        this.EnderecoFilter.setState({
            filters: {
                UF: filters.find(f => f.field === 'uf') ? filters.find(f => f.field === 'uf').value : "",
                city: filters.find(f => f.field === 'municipio') ? filters.find(f => f.field === 'municipio').value : "",
                region: filters.find(f => f.field === 'regiao') ? filters.find(f => f.field === 'regiao').value : "",
            }
        })
    }

    // FILTROS
    handleFilterApply = () => {
        const f = [];
        const { filters, exportPlan } = this.state;
        const filtersEndereco = this.EnderecoFilter.state.filters;
        
        if (filters.nmCoordenador) f.push({ field: 'nmCoordenador', value: filters.nmCoordenador })
        if (filters.nmEmail) f.push({ field: 'nmEmail', value: filters.nmEmail })
        if (filters.tipo) f.push({ field: 'tipo', value: filters.tipo })
        if (filters.statusMateriais) f.push({ field: 'statusMateriais', value: filters.statusMateriais })

        if (filtersEndereco.UF) f.push({field: 'uf', value: filtersEndereco.UF})
        if (filtersEndereco.city) f.push({field: 'municipio', value: filtersEndereco.city})
        if (filtersEndereco.region) f.push({field: 'regiao', value: filtersEndereco.region})

        if (exportPlan) {
            f.push({ field: 'exportarPlanilha', value: exportPlan });

            this.props.export(f)
                .then(res => download(res))
                .finally(() => this.setState({ exportPlan: false }));
        } else {
            this.props.handleFilterChange(f);
        };
    }

    // LIMPAR FILTROS
    handleFilterClear = () => {
        this.setState({
            filters: {
                nmCoordenador: '',
                nmEmail: '',
                tipo: '',
                statusMateriais: '',
            },
            exportPlan: false,
            count: 0,
        })

        this.EnderecoFilter.limparBuscas();

        this.props.handleFilterChange([], false);
    }

    // CONTAGEM DE FILTROS ATIVOS
    handleCountFilters = () => this.state.count;

    render() {
        return (
            <Filters
                handleFilter={this.handleFilterApply}
                handleFilterClear={this.handleFilterClear}
                countFilters={this.handleCountFilters}
            >
                <Grid item container spacing={4}>
                    <Grid item sm={12} lg={4}>
                        <Text
                            label="Nome"
                            name='nmCoordenador'
                            value={this.state.filters.nmCoordenador}
                            onChange={(e) => this.handleChange(e)}
                        />
                    </Grid>
                    <Grid item sm={12} lg={3}>
                        <Text
                            label="Email"
                            name='nmEmail'
                            value={this.state.filters.nmEmail}
                            onChange={(e) => this.handleChange(e)}
                        />
                    </Grid>
                    <Grid item sm={12} lg={2}>
                        <SelectOption
                            label="Tipo"
                            name='tipo'
                            value={this.state.filters.tipo}
                            onChange={(e) => this.handleChange(e)}
                            options={optionsTipoCoordenador}
                            disabled={true}
                        />
                    </Grid>
                    {!this.props.disabledStatusMateriais &&
                        <Grid item sm={12} lg={3}>
                            <SelectOption
                                label="Materiais Extras"
                                name='statusMateriais'
                                value={this.state.filters.statusMateriais}
                                onChange={(e) => this.handleChange(e)}
                                options={optionsStatusMateriais}
                            />
                        </Grid>
                    }
                </Grid>
                <Grid item container spacing={4}>
                    <EnderecoFilter
                        parentRef={this.setEnderecoFilter}
                        withRegion={true}
                        disabledCities={true}
                    />
                </Grid>
            </Filters>
        )
    }
}

export default CoordenadorFilter;