import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';

import Text from '../../components/Inputs/Text/Text';

import { __ADMINISTRADOR, __CENTRAL } from '../../security/RoleConfiguration';
import AuthorizedFunction from '../../security/AuthorizedFunction';

import {
    isBlank,
    isBlankHelperText,
    isEmailsDiffHelperText,
    isMailInvalid,
    isMailHelperText,
} from '../../helper/ValidationHelper';

export class Email extends Component {
    constructor(props) {
        super(props);
        props.parentRef(this);
        this.state = {
            email: '',
            emailRepeat: '',
            emailOriginal: '',
            errors: {
                email: false,
                emailRepeat: false,
            },
            helpers: {
                email: null,
                emailRepeat: null,
            }
        }
        this.isValid = this.isValid.bind(this);
    }

    // INPUTS
    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
            errors: { ...this.state.errors, [e.target.name]: false },
            helpers: { ...this.state.helpers, [e.target.name]: null }
        })
    }

    // VALIDAÇÕES
    isPrecisaValidarEmail = () => !this.state.emailOriginal || (this.state.emailOriginal && this.state.email !== this.state.emailOriginal);

    isValid = async () => {
        if (isBlank(this.state.email) || isMailInvalid(this.state.email) || (this.isPrecisaValidarEmail() && (isBlank(this.state.emailRepeat) || isMailInvalid(this.state.emailRepeat)))) {
            this.setState({
                errors: {
                    email: isBlank(this.state.email) || isMailInvalid(this.state.email) ? true : false,
                    emailRepeat: this.isPrecisaValidarEmail() ? isBlank(this.state.emailRepeat) || isMailInvalid(this.state.emailRepeat) ? true : false : false,
                },
                helpers: {
                    email: isBlank(this.state.email) ? isBlankHelperText() : isMailInvalid(this.state.email) ? isMailHelperText() : null,
                    emailRepeat: this.isPrecisaValidarEmail() ? isBlank(this.state.emailRepeat) ? isBlankHelperText() : isMailInvalid(this.state.emailRepeat) ? isMailHelperText() : null : null,
                },
            });
            return false;

        } else if (this.state.email !== this.state.emailRepeat && this.isPrecisaValidarEmail()) {
            this.setState({
                errors: {
                    email: true,
                    emailRepeat: true,
                },
                helpers: {
                    emailRepeat: isEmailsDiffHelperText(),
                },
            });
            return false;
        }
        return true;
    }

    isAdmin = () => AuthorizedFunction([__ADMINISTRADOR, __CENTRAL]);

    render() {
        return (
            <Grid container spacing={3} alignItems='center'>
                <Grid item sm={12} lg={4}>
                    <Text
                        required
                        name="email"
                        label={this.props.name ?? "Email"}
                        value={this.state.email ?? ""}
                        error={this.state.errors.email}
                        onChange={this.handleChange}
                        helperText={this.state.helpers.email}
                        onCut={(e) => !this.isAdmin() ? e.preventDefault() : null }
                        onCopy={(e) => !this.isAdmin() ?  e.preventDefault() : null }
                        onPaste={(e) => !this.isAdmin() ?  e.preventDefault() : null }
                    />
                </Grid>
                {this.isPrecisaValidarEmail() &&
                    <Grid item sm={12} lg={4}>
                        <Text
                            required
                            name="emailRepeat"
                            label={`Repetir ${this.props.name ?? "Email"}`}
                            value={this.state.emailRepeat}
                            error={this.state.errors.emailRepeat}
                            onChange={this.handleChange}
                            helperText={this.state.helpers.emailRepeat}
                            onCut={(e) => !this.isAdmin() ? e.preventDefault() : null }
                            onCopy={(e) => !this.isAdmin() ?  e.preventDefault() : null }
                            onPaste={(e) => !this.isAdmin() ?  e.preventDefault() : null }
                        />
                    </Grid>
                }
            </Grid>
        )
    }
}

export default Email;