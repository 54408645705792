import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import SendOutlinedIcon from '@material-ui/icons/SendOutlined';

import MaterialExtraService from '../../services/MaterialExtra';
import { errors } from '../../services/API';

import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Title from '../../components/Title/Title';
import Table from '../../components/Table/Table';
import Toast from '../../components/Toast/Toast';
import { ButtonPrimary } from "../../components/Button/Button";

import { ButtonExport } from '../../forms/Buttons/ButtonsExport';

import { download } from '../../helper/FileHelper';

import AuthorizedElement from '../../security/AuthorizedElement';
import { 
  __OBJETO_POSTAL_POR_STATUS_EXPORTAR, 
  __OBJETO_POSTAL_EXPORTAR,
  __EMAIL_ENVIAR, 
} from '../../security/RoleConfiguration';
import AuthorizedFunction from '../../security/AuthorizedFunction';

import { Layout } from '../../layouts/private/Private';

export class MaterialExtraPorStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: [],
      sendToStatus: [],
      loading: true,
    }
  }

  // FECHAR TOAST
  setToast = t => this.Toast = t;

  // EXPORTAÇÂO
  handleExportPlan = () => {
    this.Toast.setState({
      message: {
        message: "A Planilha está sendo gerada para Exportação.",
        type: 'info',
        open: true,
        loading: true, 
        autohide: false,
      }
    })

    MaterialExtraService.porStatusExport([{ field: 'exportarPlanilha', value: true }])
      .then(res => download(res))
  }

  // EXPORTAÇÂO
  labelExportPlanStatus = id => !this.disabledVerObjetosPostais(id) ? "Exportar Listagem de Coordenadores neste Status" : "Você não tem permissão para fazer isso";
  handleExportPlanStatus = (status) => {    
    const f = [];

    f.push({ field: 'status', value: status })
    f.push({ field: 'exportarPlanilha', value: true });

    this.Toast.setState({
        message: {
            message: "A Planilha está sendo gerada para Exportação.",
            type: 'info',
            open: true,
            loading: true,
            autohide: false,
        }
    })

    MaterialExtraService.export(f).then(res => download(res));
  }

  labelVerObjetosPostais = id => !this.disabledVerObjetosPostais(id) ? "Visualizar Materiais Extras" : "Você não tem permissão para fazer isso";
  disabledVerObjetosPostais = id => !AuthorizedFunction([__OBJETO_POSTAL_EXPORTAR]);
  handleVerObjetosPostais = id => this.props.history.push(`/admin/materiaisExtras?status=${id}`);

  labelMailStatus = id => !this.disabledMailStatus(id) ? "Enviar Email para Coordenadores neste Status" : "Você não tem permissão para fazer isso";
  disabledMailStatus = id => !AuthorizedFunction([__EMAIL_ENVIAR]);
  handleMailStatus = id => this.props.history.push(`/admin/materiaisExtras/email?status=${id}`);
  
  handleChangeStatus = keys => {
    const status = this.state.status.filter( s => keys.find(k => k === s.id));
    this.setState({
      sendToStatus: [...status]
    })
  }
  handleSendMailStatusBatch = () => this.props.history.push(`/admin/materiaisExtras/email?${this.state.sendToStatus.map(status => `status=${status.id}`).join('&')}`);
  
  loadData = () => {
    this.setState({ loading: true, });

    MaterialExtraService.porStatus([])
      .then(res => {
        this.setState({ status: [...res.data], loading: false, });
      })
      .catch(error => {
        const e = errors(error);
        this.Toast.setState({
          message: {
            message: e.message,
            type: e.type,
            open: true
          }
        })

        this.setState({ loading: false });
      })
  }

  // COMPONENT DID MOUNT
  componentDidMount() { this.loadData(); }

  render() {
    const pageName = 'Materiais Extras Por Status';
    const links = [
      {
        path: null,
        name: 'Correios'
      },
      {
        path: null,
        name: 'Materiais Extras'
      }
    ];

    const columns = [
      { label: '', name: 'id', func: null, key: false, item: 'checkbox' },
      { label: '#', name: 'id', func: null, key: true },
      { label: 'Status', name: 'status', func: null, key: false },
      { label: 'Objetos Postais', name: 'objetosPostais', func: null, key: false },
    ];

    const actions = [
      {
        name: this.labelMailStatus,
        func: this.handleMailStatus,
        icon: <SendOutlinedIcon />,
        disabled: this.disabledMailStatus,
      },
      {
        name: this.labelExportPlanStatus,
        func: this.handleExportPlanStatus,
        icon: <DescriptionOutlinedIcon />,
        disabled: this.disabledVerObjetosPostais,
      },
      {
        name: this.labelVerObjetosPostais,
        func: this.handleVerObjetosPostais,
        icon: <OpenInNewIcon />,
        disabled: this.disabledVerObjetosPostais,
      },
    ];

    return (
      <Layout>
        <Toast parentRef={this.setToast} />
        <Grid container >
          <Grid item xs={12}>
            <Breadcrumbs links={links} active={pageName} />
          </Grid>
        </Grid>
        <Title>{pageName}</Title>

        <Grid container >
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <AuthorizedElement roles={[__OBJETO_POSTAL_POR_STATUS_EXPORTAR]}>
                <ButtonExport
                  title="Exportar Planilha"
                  onClick={this.handleExportPlan}
                />
              </AuthorizedElement>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Table
              columns={columns}
              tableData={this.state.status}
              actions={actions}
              page={null}
              sort={null}
              rowsPerPage={null}
              loading={this.state.loading}
              handleChecks={this.handleChangeStatus}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item sm={12} lg={4} >
            <ButtonPrimary
              startIcon={<SendOutlinedIcon />}
              onClick={this.handleSendMailStatusBatch}
              name="Enviar Email para Coordenadores nos Status Selecionados"
              disabled={this.state.sendToStatus.length === 0}
            />
          </Grid>
        </Grid>
      </Layout>
    )
  }
}

export default withRouter(MaterialExtraPorStatus);