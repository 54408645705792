import React from 'react';

import Typography from '@material-ui/core/Typography';

import Title from '../../components/Title/Title';

import useStyles from './Style';

const ProvaFase2 = () => {
    const classes = useStyles();
    return (
        <>
            <Title id="anchor-12" className={classes.title}>
                12. DA PROVA DA 2ª FASE
            </Title>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>12.1</span> As provas da 2ª Fase serão impressas e enviadas pelo IMPA para as escolas, nos endereços indicados no ato da inscrição. É de inteira responsabilidade da escola inscrita ou da secretaria de educação 
                responsável pela inscrição da escola, o preenchimento correto das informações, estando o IMPA isento de qualquer responsabilidade pelo envio para o endereço incorreto, não havendo reenvio ou nova data 
                de aplicação de prova.
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>12.2</span> A escola inscrita é responsável pela aplicação da prova em concordância com as diretrizes deste Regulamento e do Manual de Instruções.
            </Typography>
            
            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>12.3</span> As provas da 2ª Fase serão aplicadas por cada escola nas suas próprias dependências, as quais serão integralmente responsáveis por sua aplicação e correção, de acordo com as instruções enviadas 
                pelo IMPA junto com o material de prova.
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>12.4</span> A aplicação das provas da 2ª Fase deverá ser realizada exclusivamente no dia <span className={classes.bolder}>12 de novembro de 2024</span>, conforme indicado no Calendário Oficial Olimpíada Mirim - OBMEP (Anexo I), podendo ser aplicada 
                em todos os turnos da escola.
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>12.5</span> A prova da 2ª Fase é composta de <span className={classes.bolder}>15 (quinze) questões objetivas</span> (múltipla escolha) que totalizam 15 (quinze) pontos, sendo atribuído um ponto para cada questão, sendo que cada questão dispõe 
                de 5 (cinco) opções de resposta (A, B, C, D e E), dentre as quais apenas uma delas é a correta.
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>12.6</span> As questões propostas na prova da 2ª Fase apresentam conteúdos previstos na Base Nacional Comum Curricular (BNCC) para alunos(as) de 2º, 3º, 4º e 5º anos do Ensino Fundamental.
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>12.7</span> A prova terá duração de 1h30min (uma hora e trinta minutos).
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>12.8</span> Para alunos (as) com necessidades especiais deverá ser oferecida uma hora adicional para a realização da prova.
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>12.9</span> É de responsabilidade da escola inscrita ou da secretaria de educação, a identificação e o atendimento das necessidades especiais dos(as) alunos(as), conforme disposto no 
                item 11.9 deste Regulamento.
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>12.10</span> A prova não deverá ser reaplicada para alunos(as) que tenham estado ausentes por quaisquer motivos no dia da aplicação da prova, uma vez que, a prova da 2ª Fase é aplicada em um 
                único dia em todo o Brasil.
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>12.11</span> É de responsabilidade da escola instruir seus alunos(as) sobre a manutenção do sigilo, bem como coibir o uso de aparelhos eletrônicos e outros meios de consulta durante a realização das provas. 
            </Typography>
        </>
    )
}

export default ProvaFase2;