import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import { Card, CardContent } from "@material-ui/core"

import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Title from '../../components/Title/Title';

import { Layout } from '../../layouts/private/Private';

import DependenciaAdministrativaFilter from '../../forms/DependenciaAdministrativa/DependenciaAdministrativaFilter';
import Filters from '../../components/Filters/Filters';

import InscricoesPorEdicao from './InscricoesPorEdicao';
import InscricoesPorMunicipio from './InscricoesPorMunicipio';
import InscricoesPorUF from './InscricoesPorUF';
import InscricoesPorData from './InscricoesPorData';

export class QuantitativoInscricoes extends Component {
    constructor(props){
        super(props);
        this.state = {
            count: 0,
        }
    }

    setDependenciaAdministrativaFilter = d => this.DependenciaAdministrativaFilter = d;
    setInscricoesPorEdicao = e => this.InscricoesPorEdicao = e;
    setInscricoesPorUF = u => this.InscricoesPorUF = u;
    setInscricoesPorMunicipio = m => this.InscricoesPorMunicipio = m;
    setInscricoesPorData = d => this.InscricoesPorData = d;

    // FILTROS
    ApplyFilters = () => {
        const filtersDepAdm = this.DependenciaAdministrativaFilter.state.filters;
        
        this.InscricoesPorEdicao.setState({
            filters: {
                adminDep: filtersDepAdm.adminDep ?? '',
            }
        }, () => this.InscricoesPorEdicao.ApplyFilters(true))
        
        this.InscricoesPorUF.setState({
            filters: {
                adminDep: filtersDepAdm.adminDep ?? '',
            }
        }, () => this.InscricoesPorUF.ApplyFilters(true))
        
        this.InscricoesPorMunicipio.setState({
            filters: {
                adminDep: filtersDepAdm.adminDep ?? '',
            }
        }, () => this.InscricoesPorMunicipio.ApplyFilters(true))
        
        this.InscricoesPorData.setState({
            filters: {
                adminDep: filtersDepAdm.adminDep ?? '',
            }
        }, () => this.InscricoesPorData.ApplyFilters(true))

        this.setState({ 
            count: filtersDepAdm.adminDep ? this.state.count + 1 : this.state.count, 
        })
    }
    
    // LIMPAR FILTROS
    handleFilterClear = () => {
        this.DependenciaAdministrativaFilter.setState({
            filters: {
                adminDep: '',
            }
        })
    }

    handleCountFilters = () => this.state.count;

    render() {
        const pageName = 'Inscrições';
        const links = [
            { 
                path: null, 
                name: 'Relatórios' 
            }
        ];

        return(
            <Layout>
                <Grid container >
                    <Grid item xs={12}>
                        <Breadcrumbs links={links} active={pageName} />
                    </Grid>
                </Grid>

                <Title>{pageName}</Title>

                <Filters
                    handleFilter={this.ApplyFilters}
                    handleFilterClear={this.handleFilterClear}
                    countFilters={this.handleCountFilters}
                >
                    <Grid item container spacing={3}>
                        <DependenciaAdministrativaFilter
                            parentRef={this.setDependenciaAdministrativaFilter}
                            withPublicGroup={true}
                        />
                    </Grid>
                </Filters>

                <Card style={{ marginTop: 20, marginBottom: 20 }}>
                    <CardContent style={{ padding: 20, fontSize: 14, borderBottom: "1px solid #ccc", backgroundColor: "#f5f5f5" }}>
                        <InscricoesPorEdicao parentRef={this.setInscricoesPorEdicao} />
                    </CardContent>
                </Card>

                <Card style={{ marginTop: 20, marginBottom: 20 }}>
                    <CardContent style={{ padding: 20, fontSize: 14, borderBottom: "1px solid #ccc", backgroundColor: "#f5f5f5" }}>
                        <InscricoesPorMunicipio parentRef={this.setInscricoesPorMunicipio} />
                    </CardContent>
                </Card>
                
                <Card style={{ marginTop: 20, marginBottom: 20 }}>
                    <CardContent style={{ padding: 20, fontSize: 14, borderBottom: "1px solid #ccc", backgroundColor: "#f5f5f5" }}>
                        <InscricoesPorUF parentRef={this.setInscricoesPorUF} />
                    </CardContent>
                </Card>

                <Card style={{ marginTop: 20, marginBottom: 20 }}>
                    <CardContent style={{ padding: 20, fontSize: 14, borderBottom: "1px solid #ccc", backgroundColor: "#f5f5f5" }}>
                        <InscricoesPorData parentRef={this.setInscricoesPorData} />
                    </CardContent>
                </Card>
            </Layout>
        )
    }
}

export default withRouter(QuantitativoInscricoes);