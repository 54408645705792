import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadContext } from '../../store/actions/Contexto';
import { isAfterDataFimInscricaoEDataFimPagamento } from '../../helper/ContextHelper';

import Grid from '@material-ui/core/Grid';

import Table, { DataTableLineTotals as Totals } from '../../components/Table/Table';

import { percentMask } from '../../helper/MaskHelper';

export class DadosInscricoes extends Component {
    constructor(props) {
        super(props)
        props.parentRef(this);
        this.state = {
            ufs: [],
        }
    }

    sumUfs = () => this.state.ufs.length;
    sumEscolasInscritas = () => this.state.ufs.reduce((total, i) => total + i.qtdEscolasInscritas, 0);
    sumEscolasAguardandoPagamento = () => this.state.ufs.reduce((total, i) => total + i.qtdEscolasAguardandoPagamento, 0);
    sumEscolas = () => this.state.ufs.reduce((total, i) => total + i.qtdEscolasTotal, 0);
    sumEscolasInscritasPorcentagem = () => this.state.ufs.reduce((total, i) => total + i.qtdEscolasInscritasPorcentagem, 0);
    sumMunicipiosInscritos = () => this.state.ufs.reduce((total, i) => total + i.qtdMunicipiosInscritos, 0);
    sumMunicipios = () => this.state.ufs.reduce((total, i) => total + i.qtdMunicipiosTotal, 0);
    sumMunicipiosInscritosPorcentagem = () => this.state.ufs.reduce((total, i) => total + i.qtdMunicipiosInscritosPorcentagem, 0);
    sumAlunos = () => this.state.ufs.reduce((total, i) => total + i.qtdAlunosPrevistosTotal, 0);

    render() {
        const columns = [
            { label: 'UF', name: 'nmUf', func: null, key: false, width: '10', },
            { label: 'Escolas Inscritas', name: 'qtdEscolasInscritas', func: null, key: false, width: '10', },
            { label: 'Escolas Aguardando Pagamento', name: 'qtdEscolasAguardandoPagamento', func: null, key: false, width: '10', hidden: isAfterDataFimInscricaoEDataFimPagamento(this.props.contexts), },
            { label: 'Total de Escolas', name: 'qtdEscolasTotal', func: null, key: false, width: '10', },
            { label: '% de Escolas Inscritas', name: 'qtdEscolasInscritasPorcentagem', func: percentMask, key: false, width: '12', },
            { label: 'Municípios Inscritos', name: 'qtdMunicipiosInscritos', func: null, key: false, width: '12', },
            { label: 'Total de Municípios', name: 'qtdMunicipiosTotal', func: null, key: false, width: '12', },
            { label: '% de Municípios Inscritos', name: 'qtdMunicipiosInscritosPorcentagem', func: percentMask, key: false, width: '12', },
            { label: 'Total de Alunos Previstos', name: 'qtdAlunosPrevistosTotal', func: null, key: false, width: '12', },
        ];

        const totals = [
            { column: 0, value: "Total", width: '10', },
            { column: 1, value: this.sumEscolasInscritas(), width: '10', },
            { column: 2, value: this.sumEscolasAguardandoPagamento(), width: '10', hidden: isAfterDataFimInscricaoEDataFimPagamento(this.props.contexts), },
            { column: 3, value: this.sumEscolas(), width: '10', },
            { column: 4, value: percentMask(this.sumEscolasInscritasPorcentagem() / this.sumUfs()), width: '12', },
            { column: 5, value: this.sumMunicipiosInscritos(), width: '12', },
            { column: 6, value: this.sumMunicipios(), width: '12', },
            { column: 7, value: percentMask(this.sumMunicipiosInscritosPorcentagem() / this.sumUfs()), width: '12', },
            { column: 8, value: this.sumAlunos(), width: '12', },
        ];

        return (
            <Grid container spacing={3} style={{ marginTop: this.props.marginTop ?? 8 }}>
                <Grid item xs={12}>
                    <Table
                        columns={columns}
                        tableData={this.state.ufs}
                        actions={[]}
                        page={null}
                        rowsPerPage={null}
                        sort={null}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Totals lineData={totals} columns={columns} />
                </Grid>
            </Grid>
        )
    }
}

const mapStateToProps = state => ({ contexts: state.contexts });
const mapDispatchToProps = dispatch => (bindActionCreators({ loadContext }, dispatch));

export default connect(mapStateToProps, mapDispatchToProps)(DadosInscricoes);