export const sizeFiles = size => size / 1000 >= 1024 ? Math.round((size / 1000000 + Number.EPSILON) * 100) / 100 + " mb" : Math.round((size / 1000 + Number.EPSILON) * 100) / 100 + " kb";

export const getFilenameResponse = response => {
    const headers = response.headers['content-disposition'];
    return headers.substring(headers.indexOf('=') + 1);
}

export const getContentTypeResponse = response => {
    return response.headers['content-type'];
}

export function download(response) {
    const blobUrl = URL.createObjectURL(response.data);

    const link = document.createElement("a");
    link.href = blobUrl;
    link.download = getFilenameResponse(response);

    document.body.appendChild(link);

    link.dispatchEvent(
        new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window
        })
    );

    document.body.removeChild(link);
}

export function json(data, filename) {
    const json = JSON.stringify(data, null, 2)
    const blobUrl = URL.createObjectURL(new Blob([ json ], { type: "application/json" }));

    const link = document.createElement("a");
    link.href = blobUrl;
    link.download = `${filename}_${new Date().getTime()}.json`;

    document.body.appendChild(link);

    link.dispatchEvent(
        new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window
        })
    );

    document.body.removeChild(link);
}