import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';

import Filters from '../../components/Filters/Filters';
import Text from '../../components/Inputs/Text/Text';
import Boolean from '../../components/Inputs/Boolean/Boolean';
import { DatePicker } from '../../components/Inputs/DatePicker/DatePicker';

import { toDate, parseDate } from '../../helper/DateHelper';
import { removePagingAndSorting } from '../../helper/PaginationHelper';

export class BannerFilter extends Component {
    constructor(props) {
        super(props);
        props.parentRef(this);
        this.state = {
            filters: {
                titulo: '',
                ativo: '',
                dataInicio: '',
                dataFim: '',
                comPosicao: '',
            },
            count: 0,
        }
    }  

    // INPUTS
    handleChange = e => {
        this.setState({
            filters: {...this.state.filters, [e.target.name]: e.target.value }
        });
    };

    // INPUTS DATE PICKER
    handleChangeDate = (e, name) => {
        this.setState({
            filters: { ...this.state.filters, [name]: e },
        });
    }

    // APLICAR PERSISTENCIA
    handleFilterPersistence = (filters) => {
        filters = removePagingAndSorting(filters);

        this.setState({
            filters: {
                titulo: filters.find(f =>  f.field === 'titulo') ? filters.find(f =>  f.field === 'titulo').value : "",
                ativo: filters.find(f =>  f.field === 'ativo') ? filters.find(f =>  f.field === 'ativo').value : "",
                dataInicio: filters.find(f =>  f.field === 'dataInicio') ? parseDate(filters.find(f =>  f.field === 'dataInicio').value) : "",
                dataFim: filters.find(f =>  f.field === 'dataFim') ? parseDate(filters.find(f =>  f.field === 'dataFim').value) : "",
                comPosicao: filters.find(f =>  f.field === 'comPosicao') ? filters.find(f =>  f.field === 'comPosicao').value : "",
            },
            count: filters.reduce((total, f) => f.value !== '' ? total + 1 : total, 0),
        })
    }

    // FILTROS
    handleFilterApply = () => {
        let f = [];
        const { filters } = this.state;

        if (filters.titulo) f.push({ field: 'titulo', value: filters.titulo });
        if (filters.ativo) f.push({ field: 'ativo', value: filters.ativo });
        if (filters.dataInicio) f.push({ field: 'dataInicio', value: toDate(filters.dataInicio)});
        if (filters.dataFim) f.push({ field: 'dataFim', value: toDate(filters.dataFim) });
        if (filters.comPosicao) f.push({ field: 'comPosicao', value: filters.comPosicao });

        this.props.handleFilterChange(f);
    }

    // LIMPAR FILTROS
    handleFilterClear = () => {
        this.setState({
            filters: {
                titulo: '',
                ativo: '',
                dataInicio: '',
                dataFim: '',
                comPosicao: '',
            },
            count: 0,
        })
        this.props.handleFilterChange([], false);
    }

    // CONTAGEM DE FILTROS ATIVOS
    handleCountFilters = () => this.state.count;

    render () {
        return (
            <Filters
                handleFilter={this.handleFilterApply}
                handleFilterClear={this.handleFilterClear}
                countFilters={this.handleCountFilters}
            >
                <Grid item container spacing={3}>
                    <Grid item sm={12} lg={4}>
                        <Text
                            label='Título'
                            value={this.state.filters.titulo}
                            name='titulo'
                            onChange={this.handleChange}
                        />
                    </Grid>
                    <Grid item sm={6} lg={1}>
                        <Boolean
                            label="Ativo?"
                            name='ativo'
                            value={this.state.filters.ativo}
                            onChange={this.handleChange}
                        />
                    </Grid>
                    <Grid item sm={12} lg={2}>
                        <DatePicker
                            clearable
                            label="Data de Início"
                            name='dataInicio'
                            value={this.state.filters.dataInicio}
                            onChange={(e) => this.handleChangeDate(e, 'dataInicio')}
                        />
                    </Grid>
                    <Grid item sm={12} lg={2}>
                        <DatePicker
                            clearable
                            label="Data de Fim"
                            name='dataFim'
                            value={this.state.filters.dataFim}
                            onChange={(e) => this.handleChangeDate(e, 'dataFim')}
                        />
                    </Grid>
                    <Grid item sm={6} lg={1}>
                        <Boolean
                            label="Com Posição?"
                            name='comPosicao'
                            value={this.state.filters.comPosicao}
                            onChange={this.handleChange}
                        />
                    </Grid>
                </Grid>
            </Filters>
        )
    }
}

export default BannerFilter;