import React, { Component } from 'react'
import * as XLSX from 'xlsx';

import Grid from '@material-ui/core/Grid'
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import Alert from "@material-ui/lab/Alert";
import TableBody from "@material-ui/core/TableBody";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import ClearOutlinedIcon from "@material-ui/icons/ClearOutlined";
import DoneAllOutlinedIcon from "@material-ui/icons/DoneAllOutlined";
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import Tab from "@material-ui/core/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";

import { green, red } from "@material-ui/core/colors";

import EscolaService from "../../services/Escola";
import { errors } from '../../services/API'

import Dialog from '../../components/Dialog/Dialog'
import Toast from '../../components/Toast/Toast'
import Text from "../../components/Inputs/Text/Text";
import Arquivo from "../../components/Inputs/File/File";

import { ButtonsForm } from '../../forms/Buttons/ButtonsForm'

import { patternSchool } from "../../variables/Enums/Escola";

import { mecCodeMask } from "../../helper/MaskHelper";

const __MECCODE_LENGTH = 8;

export class DialogIgnorarEscola extends Component {
    constructor(props) {
        super(props);
        props.parentRef(this);
        this.state = {
            open: false,
            tab: '0',
            mec: '',
            loadingSearchMec: false,
            loadingImportMecs: false,
            errors: {
                mec: false,
            },
            helpers: {
                mec: null,
            },
            schools: [],
            schoolsComplete: [],
            schoolsErrors: [],
            loadingButtonSave: false,
        }
    }

    // DECLARAÇÕES DE REFERENCIA DOS COMPONENTES
    setToast = t => this.Toast = t;
    setArquivo = f => this.Arquivo = f;

    // INPUTS
    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.name === "mec" ? mecCodeMask(e.target.value) : e.target.value,
            errors: {...this.state.errors, [e.target.name]: false },
            helpers: {...this.state.helpers, [e.target.name]: null }
        });
    }

    handleTab = (e, newTab) => {
        this.setState({
            tab: newTab,
            mec: '',
            errors: {...this.state.errors, mec: false },
            helpers: {...this.state.helpers, mec: null },
        });
    }

    // AÇÕES MODAL
    buttons = (id) =>
        <ButtonsForm
            onClick={this.handleSubmit}
            onBack={() => this.handleClose()}
            idFocus={`saveButton`}
            loading={this.state.loadingButtonSave}
            saveDisabled={this.state.schools.length === 0}
        />

    // FECHAR MODAL
    handleClose = () => {
        this.setState({
            open: false,
            tab: '0',
            mec: '',
            loadingSearchMec: false,
            loadingImportMecs: false,
            errors: {
                mec: false,
            },
            helpers: {
                mec: null,
            },
            schools: [],
            schoolsComplete: [],
            schoolsErrors: [],
            loadingButtonSave: false,
        });

        if (this.Arquivo)
            this.Arquivo.setState({ file: null });

        this.props.handleClose();
    }

    // SUBMIT
    handleSubmit = () => {
        this.setState({
            loadingSearchMec: true,
            loadingImportMecs: true,
            loadingButtonSave: true,
        });

        const escolasParaIgnorar = this.state.schoolsComplete.length > 0 ? this.state.schools.filter(school => !this.state.schoolsComplete.includes(school.cdMecEscola)) : this.state.schools;

        Promise.all(escolasParaIgnorar.map(async school => {
            return await EscolaService.ignorarParaMateriais(school.cdMecEscola, true)
                .then(() => this.setState({
                    schoolsComplete: [...this.state.schoolsComplete, school.cdMecEscola],
                    schoolsErrors: [...this.state.schoolsErrors.filter(mec => mec !== school.cdMecEscola) ],
                }))
                .catch(() => this.setState({ schoolsErrors: [...this.state.schoolsErrors, school.cdMecEscola ] }));
        }))
            .then(res => {
                if (this.state.schoolsErrors.length > 0 && this.state.schoolsComplete.length > 0) {
                    this.Toast.setState({
                        message: {
                            message: "Ocorreram erros em algumas Escolas ao Ignorá-las para Ações de Impressão. Verifique os erros e Tente Novamente",
                            type: "warning",
                            open: true
                        }
                    });

                    this.setState({
                        loadingSearchMec: false,
                        loadingImportMecs: false,
                        loadingButtonSave: false
                    });
                } else if (this.state.schoolsErrors.length === this.state.schools.length) {
                    this.Toast.setState({
                        message: {
                            message: "Ocorreu um erro ao Ignorar Escolas para Ações de Impressão.",
                            type: "error",
                            open: true
                        }
                    });

                    this.setState({
                        loadingSearchMec: false,
                        loadingImportMecs: false,
                        loadingButtonSave: false
                    });
                } else if (this.state.schoolsComplete.length === this.state.schools.length) {
                    this.Toast.setState({
                        message: {
                            message: "Escolas Ignoradas para Ações de Impressão com Sucesso.",
                            type: "success",
                            open: true
                        }
                    });

                    setTimeout(() => this.handleClose(), 1500);
                }
            })
    }

    // POR INEP
    handleSearchEscola = () => {
        this.setState({ loadingSearchMec: true, loadingImportMecs: true, loadingButtonSave: true })

        if (this.state.mec.length !== __MECCODE_LENGTH) {
            this.setState({
                errors: { mec: true, },
                helpers: { mec: this.state.mec.length !== __MECCODE_LENGTH ? "Preencha o campo com um Código INEP de 8 Dígitos" : "" },
                loadingSearchMec: false,
                loadingImportMecs: false,
                loadingButtonSave: false,
            });
            return
        }

        EscolaService.find(this.state.mec)
            .then(res => {
                if (res.status === 200) {
                    const school = res.data

                    if (school.enviarGrafica === false) {
                        this.setState({
                            errors: { mec: true, },
                            helpers: { mec: "A Escola já esta marcada para ser Ignorada para Impressão.", },
                        });
                    } else {
                        this.setState({
                            mec: "",
                            schools: [...this.state.schools, patternSchool(school) ],
                            errors: { mec: false, },
                            helpers: { mec: null, },
                        });
                    }
                } else {
                    this.Toast.setState({
                        message: {
                            message: "Escola Não Encontrada",
                            type: "error",
                            open: true
                        }
                    });

                    this.setState({
                        errors: { mec: true, },
                        helpers: { mec: "Escola Não Encontrada", },
                        loadingSearchMec: false,
                        loadingImportMecs: false,
                        loadingButtonSave: false,
                    });
                }
            })
            .catch(error => {
                const e = errors(error);
                this.Toast.setState({
                    message: {
                        message: e.message,
                        type: e.type,
                        open: true
                    }
                })
            })
            .finally(() => this.setState({ loadingSearchMec: false, loadingImportMecs: false, loadingButtonSave: false }));
    }

    // EM LOTE
    handleImportarEscolas = () => {
        this.setState({ loadingSearchMec: true, loadingImportMecs: true, loadingButtonSave: true });

        const file = this.Arquivo.state.file;
        const reader = new FileReader();

        reader.onerror = (event) => {
            reader.abort();
            this.setState({ loadingSearchMec: false, loadingImportMecs: false, loadingButtonSave: false });
            this.Toast.setState({
                message: {
                    message: "Ocorreu um Erro ao Importar a Planilha",
                    type: "error",
                    open: true
                }
            })
        }

        reader.onload = (event) => {
            const workbook = XLSX.read(event.target.result, {type: 'binary'});
            const worksheet = workbook.Sheets[workbook.SheetNames[0]];
            const data = XLSX.utils.sheet_to_json(worksheet, {header: 1});

            const mecs = [...data.map(json => json[0])];

            Promise.all(mecs.map(async mec => {
                return await EscolaService.find(mec)
                    .then(res => {
                        if (res.status === 200) {
                            const school = res.data

                            if (school.enviarGrafica === true) {
                                this.setState({
                                    schools: [...this.state.schools, patternSchool(school)],
                                    loadingSearchMec: true,
                                    loadingImportMecs: true,
                                    loadingButtonSave: true,
                                });
                            }
                        }
                    })
                })
            ).finally(() => {
                this.setState({
                    loadingSearchMec: false,
                    loadingImportMecs: false,
                    loadingButtonSave: false
                });
            })
        };

        reader.readAsBinaryString(file);
    };

    // REMOVER DA LISTA
    handleRemoverEscola = (mec) => {
        this.setState({
            schools: [...this.state.schools.filter(school => school.cdMecEscola !== mec)],
            schoolsErrors: [...this.state.schoolsErrors.filter(school => school !== mec)],
        })
    }

    render() {
        return (
            <>  
                <Toast parentRef={this.setToast} />
                <Dialog
                    maxWidth={"md"}
                    fullWidth={true}
                    closeDialog={this.handleClose}
                    openDialog={this.state.open}
                    title="Adicionar Escolas para Ignorar Ações de Impressão"
                    actions={this.buttons()}
                >
                    <Grid container alignItems='center' >
                        <Grid item sm={12} style={{ marginBottom: "16px" }} >
                            <Alert severity="warning" >
                                <Typography component={'div'} variant="body2">
                                    As adições serão realizadas após o botão <b>SALVAR</b> ser clicado.
                                </Typography>
                            </Alert>
                        </Grid>

                        <Grid item sm={12} >
                            <TabContext value={this.state.tab}>
                                <TabList onChange={this.handleTab} indicatorColor="primary" textColor="primary" variant="fullWidth">
                                    <Tab label="Por INEP" value="0" />
                                    <Tab label="Em Lote" value="1" />
                                </TabList>

                                <TabPanel value="0">
                                    <Grid container justify="center" >
                                        <Grid item sm={10} lg={10} >
                                            <Text
                                                autoFocus
                                                name="mec"
                                                label="INEP da Escola"
                                                value={this.state.mec}
                                                onChange={this.handleChange}
                                                error={this.state.errors.mec}
                                                helperText={this.state.helpers.mec}
                                                inputProps={{ maxLength: __MECCODE_LENGTH }}
                                                InputProps={{
                                                    endAdornment:
                                                        <InputAdornment position="end">
                                                            {this.state.loadingSearchMec ?
                                                                <CircularProgress size={16} />
                                                                : this.state.mec &&
                                                                <Tooltip title="Buscar Escola" TransitionComponent={Zoom}>
                                                                    <IconButton
                                                                        size="small"
                                                                        color="primary"
                                                                        aria-label="Buscar Escola"
                                                                        onClick={this.handleSearchEscola}
                                                                    >
                                                                        <SearchIcon />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            }
                                                        </InputAdornment>,
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </TabPanel>

                                <TabPanel value="1">
                                    <Grid container >
                                        <Grid item sm={12} lg={12} >
                                            <Typography component="div" variant="body2">
                                                <b>Informações para Importação:</b>

                                                <ul style={{ marginTop: 16 }}>
                                                    <li>A planilha deve estar no formato <b>.xlsx</b> com os INEP`s das Escolas que serão Ignoradas nas Ações de Impressão.</li>
                                                    <li>A planilha deve obrigatoriamente conter o INEP da Escola que será marcada como ignorada na <b>Coluna 1 (Ex.: 12345678)</b>, para que seja importada corretamente.</li>
                                                    <li>A importação irá considerar como válidos, <b>INEP`s</b> com <b>8</b> dígitos que tiverem Escolas dentro do sistema. Os casos que <b>NÃO</b> se enquadram irão ser <b>DESCARTADOS</b> na importação.</li>
                                                    <li>Não serão importadas Escolas que já foram marcadas como ignoradas anteriormente.</li>
                                                </ul>
                                            </Typography>
                                        </Grid>

                                        <Grid item sm={10} lg={10} style={{ display: 'flex', alignSelf: 'center', marginTop: '16px' }}>
                                            <Arquivo
                                                parentRef={this.setArquivo}
                                                accept=".xlsx"
                                                handleChange={this.handleImportarEscolas}
                                            />
                                        </Grid>

                                        { this.state.loadingImportMecs &&
                                            <Grid item sm={1} lg={1} style={{ display: 'flex', alignSelf: 'center', marginTop: '16px' }}>
                                                <CircularProgress size={16} />
                                            </Grid>
                                        }
                                    </Grid>
                                </TabPanel>
                            </TabContext>
                        </Grid>

                        {this.state.schools.length > 0 &&
                            <Grid item sm={12} lg={12} style={{ marginTop: 16 }}>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell><b>INEP</b></TableCell>
                                            <TableCell><b>Nome</b></TableCell>
                                            <TableCell align="center"><b>Status</b></TableCell>
                                            <TableCell align="center"><b>Remover</b></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.schools.map(school => (
                                            <TableRow key={school.cdMecEscola} hover>
                                                <TableCell>{school.cdMecEscola}</TableCell>
                                                <TableCell>{school.nmEscola}</TableCell>
                                                <TableCell align="center">
                                                    { this.state.schoolsErrors.length > 0 && this.state.schoolsErrors.includes(school.cdMecEscola) ?
                                                        <Tooltip title="Erro" TransitionComponent={Zoom}>
                                                            <IconButton size="small" component="div" style={{ color: red[800]} }><ClearOutlinedIcon /></IconButton>
                                                        </Tooltip>
                                                    : this.state.schoolsComplete.length > 0 && this.state.schoolsComplete.includes(school.cdMecEscola) ?
                                                        <Tooltip title="Sucesso" TransitionComponent={Zoom}>
                                                            <IconButton size="small" component="div" style={{ color: green[600]} }><DoneAllOutlinedIcon /></IconButton>
                                                        </Tooltip>
                                                    :
                                                        <Tooltip title="Aguardando" TransitionComponent={Zoom}>
                                                            <IconButton size="small" component="div" ><CheckOutlinedIcon /></IconButton>
                                                        </Tooltip>
                                                    }
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Tooltip title="Remover" TransitionComponent={Zoom}>
                                                        <IconButton
                                                            size="small"
                                                            component="div"
                                                            style={this.state.schoolsComplete.length > 0 && this.state.schoolsComplete.includes(school.cdMecEscola) ? {} : { color: red[800] }}
                                                            onClick={() => this.handleRemoverEscola(school.cdMecEscola)}
                                                            disabled={this.state.schoolsComplete.length > 0 && this.state.schoolsComplete.includes(school.cdMecEscola)}
                                                        >
                                                            <DeleteOutlinedIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Grid>
                        }
                    </Grid>
                </Dialog>
            </>
        )
    }
}

export default DialogIgnorarEscola;