import Keycloak from 'keycloak-js';

export const keycloakConfig = {
    realm: process.env.REACT_APP_KEYCLOAK_REALM,
    url: process.env.REACT_APP_KEYCLOAK_URL,
    sslRequired: "none",
    clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
    publicClient: true,
    confidentialPort: 0,
    enableCors:true,
    disableTrustManager: true,
}

export const keycloak = new Keycloak(keycloakConfig);

export const updateToken = (successCallback) => {
    return keycloak.updateToken(300)
        .then(successCallback)
        .catch(keycloak.login)
};

export const isAuthenticated = ({keycloak, keycloakInitialized}) => {
    return keycloakInitialized && keycloak.authenticated;
}

export const isAutherized = (roles) => {
    if (keycloak && roles) {
        return roles.some(r => {
            const realm =  keycloak.hasRealmRole(r);
            const resource = keycloak.hasResourceRole(r);
            return realm || resource;
        });
    }
    return roles !== null ? false : true;
}

const keycloakInfoAccess = () => keycloak && keycloak.tokenParsed;

export const getUsername = () => keycloakInfoAccess() ? keycloak.tokenParsed.preferred_username : '';
export const getFirstName = () => keycloakInfoAccess() ? keycloak.tokenParsed.given_name : '';
export const getLastName = () => keycloakInfoAccess() ? keycloak.tokenParsed.family_name : '';
export const getFullName = () => keycloakInfoAccess() ? keycloak.tokenParsed.name : '';

export const getCoordenadorUsername = () => keycloakInfoAccess() ? keycloak.tokenParsed.preferred_username.replace("subcr_", "").toUpperCase() : '';
export const getCoordenadorUF = () => getCoordenadorUsername().substring(0, 2);
export const getCoordenadorRegiao = () => getCoordenadorUsername();

export const getApoioSecretarialUsername = () => keycloakInfoAccess() ? keycloak.tokenParsed.preferred_username.substring(0, getUsername().indexOf("_")).toUpperCase() : '';
export const getApoioSecretarialUF = () => getApoioSecretarialUsername().substring(0, 2);
export const getApoioSecretarialRegiao = () => getApoioSecretarialUsername();

export default keycloak;