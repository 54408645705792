import React from 'react';
import clsx from 'clsx';

import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';

import Title from '../../components/Title/Title';

import useStyles from './Style';

const Anexo2 = () => {
    const classes = useStyles();
    return (
        <>
            <Title id="anchor-a2" className={classes.title}>
                ANEXO II - NORMAS DE PAGAMENTO PARA TAXA DE INSCRIÇÃO
            </Title>

            <Typography className={classes.text} variant="body2">
                O pagamento da taxa de inscrição é aplicado apenas às <span className={classes.bolder}>ESCOLAS PÚBLICAS</span> optantes pela <span className={classes.bolder}>MODALIDADE 2</span> (INSCRIÇÃO COM PREMIAÇÃO) e a <span className={classes.bolder}>TODAS AS ESCOLAS PRIVADAS</span>, independentemente da modalidade de 
                inscrição escolhida (<span className={classes.bolder}>MODALIDADE 3</span> – INSCRIÇÃO SEM PREMIAÇÃO e <span className={classes.bolder}>MODALIDADE 4</span> – INSCRIÇÃO COM PREMIAÇÃO) observando-se as regras abaixo:
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>1</span> A inscrição deverá ser realizada no site da 3ª Olimpíada Mirim - OBMEP exclusivamente entre <span className={classes.bolder}>02 de maio e 21 de junho de 2024</span>, sendo possível, somente neste período, alterar o número 
                de participantes.
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>2</span> Durante esse período não será possível realizar a emissão do boleto para pagamento da taxa de inscrição. A partir do dia <span className={classes.bolder}>22 de junho de 2024</span> não será mais possível realizar 
                alterações na inscrição.
            </Typography>
            
            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>3</span> Os boletos para pagamento da taxa de inscrição só poderão ser emitidos e quitados no período de <span className={classes.bolder}>22 de junho a 28 de junho de 2024</span>. Este prazo não será prorrogado em hipótese alguma.
            </Typography>

            <Typography className={classes.text} component={'div'} variant="body2">
                <span className={classes.item}>4</span> O processo de inscrição das <span className={classes.bolder}>ESCOLAS PÚBLICAS</span> que optaram pela <span className={classes.bolder}>MODALIDADE 2</span> (INSCRIÇÃO COM PREMIAÇÃO) varia de acordo com a quantidade de alunos(as) inscritos pela escola, 
                POR NÍVEL, conforme tabela abaixo:<br /><br />

                <Grid 
                    container 
                    align='center' 
                    direction="row" 
                    justify="center"
                    alignItems="center"
                    className={classes.wrapper}
                >
                    <Grid item sm={10} lg={10}>
                        <TableContainer component="div">
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                    <TableCell align="center" style={{ backgroundColor: '#CACACA' }}>Nível</TableCell>
                                        <TableCell align="center" style={{ backgroundColor: '#CACACA' }}>Quantidade de Alunos Inscritos</TableCell>
                                        <TableCell align="center" className={classes.bolder} style={{ backgroundColor: '#B68D4A', color: '#FFFFFF' }}>Medalha - OURO</TableCell>
                                        <TableCell align="center" className={classes.bolder} style={{ backgroundColor: '#A3A2A0', color: '#FFFFFF' }}>Medalha - PRATA</TableCell>
                                        <TableCell align="center" className={classes.bolder} style={{ backgroundColor: '#A46628', color: '#FFFFFF' }}>Medalha - BRONZE</TableCell>
                                        <TableCell align="center" style={{ backgroundColor: '#CACACA' }}>Valor da inscrição <span className={classes.bolder}>POR NÍVEL</span></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow style={{ border: 1 }}>
                                    <TableCell align="center" component="th" scope="row" rowSpan={4} style={{ backgroundColor: '#FFE699' }}>MIRIM 1</TableCell>
                                        <TableCell align="center" className={classes.bolder}>1 a 60</TableCell>
                                        <TableCell align="center">1</TableCell>
                                        <TableCell align="center">3</TableCell>
                                        <TableCell align="center">6</TableCell>
                                        <TableCell align="center" className={classes.bolder}>R$ 200,00</TableCell>
                                    </TableRow>
                                    <TableRow style={{ border: 1 }}>
                                        <TableCell align="center" className={classes.bolder}>61 a 120</TableCell>
                                        <TableCell align="center">2</TableCell>
                                        <TableCell align="center">6</TableCell>
                                        <TableCell align="center">12</TableCell>
                                        <TableCell align="center" className={classes.bolder}>R$ 400,00</TableCell>
                                    </TableRow>
                                    <TableRow style={{ border: 1 }}>
                                        <TableCell align="center" className={classes.bolder}>121 a 180</TableCell>
                                        <TableCell align="center">3</TableCell>
                                        <TableCell align="center">9</TableCell>
                                        <TableCell align="center">18</TableCell>
                                        <TableCell align="center" className={classes.bolder}>R$ 600,00</TableCell>
                                    </TableRow>
                                    <TableRow style={{ border: 1 }}>
                                        <TableCell align="center" className={classes.bolder}>181 ou +</TableCell>
                                        <TableCell align="center">4</TableCell>
                                        <TableCell align="center">12</TableCell>
                                        <TableCell align="center">24</TableCell>
                                        <TableCell align="center" className={classes.bolder}>R$ 800,00</TableCell>
                                    </TableRow>
                                    <TableRow style={{ border: 1 }}>
                                        <TableCell align="center" component="th" scope="row" rowSpan={4} style={{ backgroundColor: '#FFCCFF' }}>MIRIM 2</TableCell>
                                        <TableCell align="center" className={classes.bolder}>1 a 60</TableCell>
                                        <TableCell align="center">1</TableCell>
                                        <TableCell align="center">3</TableCell>
                                        <TableCell align="center">6</TableCell>
                                        <TableCell align="center" className={classes.bolder}>R$ 200,00</TableCell>
                                    </TableRow>
                                    <TableRow style={{ border: 1 }}>
                                        <TableCell align="center" className={classes.bolder}>61 a 120</TableCell>
                                        <TableCell align="center">2</TableCell>
                                        <TableCell align="center">6</TableCell>
                                        <TableCell align="center">12</TableCell>
                                        <TableCell align="center" className={classes.bolder}>R$ 400,00</TableCell>
                                    </TableRow>
                                    <TableRow style={{ border: 1 }}>
                                        <TableCell align="center" className={classes.bolder}>121 a 180</TableCell>
                                        <TableCell align="center">3</TableCell>
                                        <TableCell align="center">9</TableCell>
                                        <TableCell align="center">18</TableCell>
                                        <TableCell align="center" className={classes.bolder}>R$ 600,00</TableCell>
                                    </TableRow>
                                    <TableRow style={{ border: 1 }}>
                                        <TableCell align="center" className={classes.bolder}>181 ou +</TableCell>
                                        <TableCell align="center">4</TableCell>
                                        <TableCell align="center">12</TableCell>
                                        <TableCell align="center">24</TableCell>
                                        <TableCell align="center" className={classes.bolder}>R$ 800,00</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                            <small style={{ fontSize: '0.85em' }}>Escolas Públicas – MODALIDADE 2 (INSCRIÇÃO COM PREMIAÇÃO)</small>
                        </TableContainer>
                    </Grid>
                </Grid>

                EXEMPLOS de Inscrição de Escola Pública na MODALIDADE 2 (INSCRIÇÃO COM PREMIAÇÃO):

                <ul className={classes.list}>
                    <li><span className={classes.item}>{"a)"}</span> Se a <span className={classes.bolder}>ESCOLA PÚBLICA</span> optou pela <span className={classes.bolder}>MODALIDADE 2</span> e inscreveu 57 alunos no MIRIM 1 e 45 alunos no MIRIM 2, o valor da inscrição será de R$ 400,00 (R$ 200,00 pelo MIRIM 1 e R$ 200,00 pelo MIRIM 2);</li>
                    <li><span className={classes.item}>{"b)"}</span> Se a <span className={classes.bolder}>ESCOLA PÚBLICA</span> optou pela <span className={classes.bolder}>MODALIDADE 2</span> e inscreveu 49 alunos no MIRIM 1 e 87 alunos no MIRIM 2, o valor da inscrição será de R$ 600,00 (R$ 200,00 pelo MIRIM 1 e R$ 400,00 pelo MIRIM 2);</li>
                    <li><span className={classes.item}>{"c)"}</span> Se a <span className={classes.bolder}>ESCOLA PÚBLICA</span> optou pela <span className={classes.bolder}>MODALIDADE 2</span> e inscreveu 180 alunos no MIRIM 1 e 245 alunos no MIRIM 2, o valor da inscrição será de R$ 1400,00 (R$ 600,00 pelo MIRIM 1 e R$ 800,00 pelo MIRIM 2).</li>
                </ul>
            </Typography>

            <Typography className={classes.text} component={'div'} variant="body2">
                <span className={classes.item}>5</span> O processo de inscrição das <span className={classes.bolder}>ESCOLA PÚBLICA</span> que optaram pela <span className={classes.bolder}>MODALIDADE 2</span> – INSCRIÇÃO COM PREMIAÇÃO) deve seguir as etapas, a saber:

                <ul className={classes.list}>
                    <li><span className={classes.item}>{"a)"}</span> Preenchimento da Ficha de Inscrição para a 3ª Olimpíada Mirim – OBMEP, entre <span className={classes.bolder}>02 de maio e 21 de junho</span>, em <span className={classes.underline}>www.olimpiadamirim.obmep.org.br</span>;</li>
                    <li><span className={classes.item}>{"b)"}</span> Emissão do boleto de pagamento da taxa de inscrição, correspondente ao valor da MODALIDADE escolhida, entre <span className={classes.bolder}>22 de junho e 28 de junho de 2024</span>, exclusivamente em www.olimpiadamirim.obmep.org.br;</li>
                    <li><span className={classes.item}>{"c)"}</span> Pagamento da taxa de inscrição, correspondente ao valor da MODALIDADE de inscrição escolhida, entre <span className={classes.bolder}>22 de junho e 28 de junho de 2024</span>;</li>
                    <li><span className={classes.item}>{"d)"}</span> Processamento do pagamento e compensação bancária da taxa de inscrição.</li>
                </ul>

                <span className={classes.bolder}>ATENÇÃO ESCOLA PÚBLICA!</span> A inscrição das <span className={classes.bolder}>ESCOLAS PÚBLICAS</span> na 3ª Olimpíada Mirim – OBMEP é <span className={classes.bolder}>GRATUITA</span> nas duas modalidades e depende exclusivamente do preenchimento completo da ficha de inscrição, 
                dentro do prazo estipulado no Calendário Oficial da 3ª Olimpíada Mirim (Anexo I). A <span className={classes.bolder}>ESCOLA PÚBLICA</span> que optar pela <span className={classes.bolder}>MODALIDADE 2</span> (INSCRIÇÃO COM PREMIAÇÃO), e NÃO efetuar a emissão e o pagamento do 
                boleto bancário correspondente à taxa, dentro do prazo estipulado no Calendário Oficial da 3ª Olimpíada Mirim (Anexo I), terá a inscrição mantida, ou seja, permanecerá inscrita na olimpíada, porém a 
                modalidade de inscrição será automaticamente convertida para a MODALIDADE 1 (INSCRIÇÃO SEM PREMIAÇÃO).
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>6</span> O valor da taxa de inscrição das <span className={classes.bolder}>ESCOLAS PRIVADAS</span> que optarem pela <span className={classes.bolder}>MODALIDADE 3</span> de inscrição (INSCRIÇÃO SEM PREMIAÇÃO) é de R$ 150,00 (cento e cinquenta reais) por escola inscrita.
            </Typography>

            <Typography className={classes.text} component={'div'} variant="body2">
                <span className={classes.item}>7</span> O processo de inscrição das <span className={classes.bolder}>ESCOLAS PRIVADAS</span> que optaram pela <span className={classes.bolder}>MODALIDADE 4</span> – INSCRIÇÃO COM PREMIAÇÃO varia de acordo com a quantidade de alunos(as) inscritos pela escola, POR NÍVEL, 
                conforme tabela abaixo:<br /><br />
                
                <Grid 
                    container 
                    align='center' 
                    direction="row" 
                    justify="center"
                    alignItems="center"
                    className={classes.wrapper}
                >
                    <Grid item sm={10} lg={10}>
                        <TableContainer component="div">
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center" style={{ backgroundColor: '#CACACA' }}>Nível</TableCell>
                                        <TableCell align="center" style={{ backgroundColor: '#CACACA' }}>Quantidade de Alunos Inscritos</TableCell>
                                        <TableCell align="center" className={classes.bolder} style={{ backgroundColor: '#B68D4A', color: '#FFFFFF' }}>OURO</TableCell>
                                        <TableCell align="center" className={classes.bolder} style={{ backgroundColor: '#A3A2A0', color: '#FFFFFF' }}>PRATA</TableCell>
                                        <TableCell align="center" className={classes.bolder} style={{ backgroundColor: '#A46628', color: '#FFFFFF' }}>BRONZE</TableCell>
                                        <TableCell align="center" className={classes.bolder} style={{ backgroundColor: '#CACACA' }}>R$150,00 + </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow style={{ border: 1 }}>
                                        <TableCell align="center" component="th" scope="row" rowSpan={4} style={{ backgroundColor: '#FFE699' }}>MIRIM 1</TableCell>
                                        <TableCell align="center" className={classes.bolder}>1 a 60</TableCell>
                                        <TableCell align="center">1</TableCell>
                                        <TableCell align="center">3</TableCell>
                                        <TableCell align="center">6</TableCell>
                                        <TableCell align="center" className={classes.bolder}>R$ 200,00</TableCell>
                                    </TableRow>
                                    <TableRow style={{ border: 1 }}>
                                        <TableCell align="center" className={classes.bolder}>61 a 120</TableCell>
                                        <TableCell align="center">2</TableCell>
                                        <TableCell align="center">6</TableCell>
                                        <TableCell align="center">12</TableCell>
                                        <TableCell align="center" className={classes.bolder}>R$ 400,00</TableCell>
                                    </TableRow>
                                    <TableRow style={{ border: 1 }}>
                                        <TableCell align="center" className={classes.bolder}>121 a 180</TableCell>
                                        <TableCell align="center">3</TableCell>
                                        <TableCell align="center">9</TableCell>
                                        <TableCell align="center">18</TableCell>
                                        <TableCell align="center" className={classes.bolder}>R$ 600,00</TableCell>
                                    </TableRow>
                                    <TableRow style={{ border: 1 }}>
                                        <TableCell align="center" className={classes.bolder}>181 ou +</TableCell>
                                        <TableCell align="center">4</TableCell>
                                        <TableCell align="center">12</TableCell>
                                        <TableCell align="center">24</TableCell>
                                        <TableCell align="center" className={classes.bolder}>R$ 800,00</TableCell>
                                    </TableRow>
                                    <TableRow style={{ border: 1 }}>
                                        <TableCell align="center" component="th" scope="row" rowSpan={4} style={{ backgroundColor: '#FFCCFF' }}>MIRIM 2</TableCell>
                                        <TableCell align="center" className={classes.bolder}>1 a 60</TableCell>
                                        <TableCell align="center">1</TableCell>
                                        <TableCell align="center">3</TableCell>
                                        <TableCell align="center">6</TableCell>
                                        <TableCell align="center" className={classes.bolder}>R$ 200,00</TableCell>
                                    </TableRow>
                                    <TableRow style={{ border: 1 }}>
                                        <TableCell align="center" className={classes.bolder}>61 a 120</TableCell>
                                        <TableCell align="center">2</TableCell>
                                        <TableCell align="center">6</TableCell>
                                        <TableCell align="center">12</TableCell>
                                        <TableCell align="center" className={classes.bolder}>R$ 400,00</TableCell>
                                    </TableRow>
                                    <TableRow style={{ border: 1 }}>
                                        <TableCell align="center" className={classes.bolder}>121 a 180</TableCell>
                                        <TableCell align="center">3</TableCell>
                                        <TableCell align="center">9</TableCell>
                                        <TableCell align="center">18</TableCell>
                                        <TableCell align="center" className={classes.bolder}>R$ 600,00</TableCell>
                                    </TableRow>
                                    <TableRow style={{ border: 1 }}>
                                        <TableCell align="center" className={classes.bolder}>181 ou +</TableCell>
                                        <TableCell align="center">4</TableCell>
                                        <TableCell align="center">12</TableCell>
                                        <TableCell align="center">24</TableCell>
                                        <TableCell align="center" className={classes.bolder}>R$ 800,00</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                            <small style={{ fontSize: '0.85em' }}>Escolas Privadas – MODALIDADE 4 (INSCRIÇÃO COM PREMIAÇÃO)</small>
                        </TableContainer>
                    </Grid>
                </Grid>

                EXEMPLOS de Inscrição de Escola Privada na MODALIDADE 4 (INSCRIÇÃO COM PREMIAÇÃO):

                <ul className={classes.list}>
                    <li><span className={classes.item}>{"a)"}</span> Se a <span className={classes.bolder}>ESCOLA PRIVADA</span> optou pela MODALIDADE 4 e inscreveu 57 alunos no MIRIM 1 e 45 alunos no MIRIM 2, o valor da inscrição será de R$ 150,00 + R$ 400,00 (R$ 200,00 pelo MIRIM 1 e R$ 200,00 pelo MIRIM 2), logo, o valor da taxa de inscrição para esta escola será de R$ 550,00;</li>
                    <li><span className={classes.item}>{"b)"}</span> Se a <span className={classes.bolder}>ESCOLA PRIVADA</span> optou pela MODALIDADE 4 e inscreveu 49 alunos no MIRIM 1 e 87 alunos no MIRIM 2, o valor da inscrição será de R$ 150,00 + R$ 600,00 (R$ 200,00 pelo MIRIM 1 e R$ 400,00 pelo MIRIM 2), logo, o valor da taxa de inscrição para esta escola será de R$ 750,00;</li>
                    <li><span className={classes.item}>{"c)"}</span> Se a <span className={classes.bolder}>ESCOLA PRIVADA</span> optou pela MODALIDADE 4 e inscreveu 180 alunos no MIRIM 1 e 245 alunos no MIRIM 2, o valor da inscrição será de R$ 150,00 + R$1400,00 (R$ 600,00 pelo MIRIM 1 e R$ 800,00 pelo MIRIM 2), logo, o valor da taxa de inscrição para esta escola será de R$ 1550,00.</li>
                </ul>
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.bolder}>PARÁGRAFO ÚNICO:</span> A premiação das escolas públicas e privadas inscritas na 3ª Olimpíada Mirim – OBMEP está diretamente relacionada à quantidade de alunos(as) inscritos(as) pela escola em cada nível (Mirim 1 – Alunos de 2º e 3º anos e Mirim 2 – Alunos de 4º e 
                5º anos do ensino fundamental), conforme indicado nas tabelas dos itens (4) e (7) deste anexo, <span className={clsx(classes.bolder, classes.underline)}>EXCETO</span> quando a quantidade de alunos(as) classificados(as), por nível, for inferior à quantidade de premiados(as). Nestes casos, o número de
                certificados/medalhas de bronze será reduzido até que se alcance a quantidade de classificados(as). Excluídos(as) todos(as) os certificados/medalhas de bronze, caso a quantidade de classificados(as) ainda permaneça maior do que a quantidade de 
                premiados(as) prevista, a quantidade de certificados/medalhas de prata será reduzida até que o número de classificados(as) seja igual ao número de premiados(as).
            </Typography>

            <Typography className={classes.text} component={'div'} variant="body2">
                <span className={classes.item}>8</span> O processo de inscrição das <span className={classes.bolder}>ESCOLAS PRIVADAS</span>, independentemente da modalidade de inscrição escolhida (MODALIDADE 3 – INSCRIÇÃO SEM PREMIAÇÃO e MODALIDADE 4 – INSCRIÇÃO COM PREMIAÇÃO) 
                deve seguir as etapas, a saber:

                <ul className={classes.list}>
                    <li><span className={classes.item}>{"a)"}</span> Preenchimento da Ficha de Inscrição para a 3ª Olimpíada Mirim – OBMEP, entre <span className={classes.bolder}>02 de maio e 21 de junho</span>, em <span className={classes.underline}>www.olimpiadamirim.obmep.org.br</span>;</li>
                    <li><span className={classes.item}>{"b)"}</span> Emissão do boleto de pagamento da taxa de inscrição, correspondente ao valor da MODALIDADE escolhida, entre <span className={classes.bolder}>22 de junho e 28 de junho de 2024</span>, exclusivamente em <span className={classes.underline}>www.olimpiadamirim.obmep.org.br</span>;</li>
                    <li><span className={classes.item}>{"c)"}</span> Pagamento da taxa de inscrição, correspondente ao valor da MODALIDADE de inscrição escolhida, entre <span className={classes.bolder}>22 de junho e 28 de junho de 2024</span>;</li>
                    <li><span className={classes.item}>{"d)"}</span> Processamento do pagamento e compensação bancária da taxa de inscrição.</li>
                </ul>
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>9</span> O pagamento da taxa de inscrição deverá ser realizado, exclusivamente, por meio de boleto bancário e deverá ser efetuado em qualquer agência bancária, casa lotérica ou agência dos correios, 
                obedecendo aos critérios estabelecidos nesses correspondentes bancários.
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>10</span> Sob nenhuma hipótese, o pagamento do valor correspondente à taxa de inscrição na 3ª Olimpíada Mirim – OBMEP, será aceito via outros meios (transferência bancária, pix etc.), que 
                não o boleto bancário.
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>11</span> O IMPA não se responsabilizará por solicitação de inscrição ou pagamento não recebidos por motivos de ordem técnica dos computadores, de falhas de comunicação, de congestionamento das 
                linhas de comunicação, por erro ou atraso dos bancos ou entidades conveniadas no que se refere ao processamento da taxa de inscrição, emissão e pagamento de boletos, bem como outros fatores que 
                impossibilitem a transferência de dados.
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>12</span> A nota fiscal relativa à taxa de inscrição paga será emitida de acordo com os dados preenchidos no momento da emissão do boleto, no sistema da 3ª Olimpíada Mirim - OBMEP. Ao prosseguir 
                com o pagamento, os dados da fatura serão automaticamente enviados ao SisPag - Sistema de Pagamentos do IMPA. Após a finalização do seu pedido e, dentro do prazo estipulado no calendário 
                Oficial (Anexo I), a escola estará apta à emissão do boleto bancário, que poderá ser feito a qualquer momento acessando o SisPag (https://sispag.impa.br). <span className={classes.bolder}>ATENÇÃO! Não será possível alterar dados 
                da nota fiscal após sua emissão.</span>
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>13</span> O prazo para emissão de nota fiscal é de até 30 (trinta) dias úteis após a compensação do pagamento do boleto e sua disponibilização será feita na página do SisPag - Sistema de 
                Pagamentos do IMPA, no endereço https://sispag.impa.br.
            </Typography>
        </>
    )
}

export default Anexo2;