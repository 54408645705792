import React from 'react';

import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import Header from '../../components/Header/Header';
import { Download } from '../../components/Download/Download';
import Title from '../../components/Title/Title';

import Navbar from './NavbarRegulamento';
import Preliminares from './Preliminares';
import Participantes from './Participantes';
import Inscricao from './Inscricao';
import Taxas from './Taxas';
import Confirmacao from './Confirmacao';
import Fases from './Fases';
import ProvaFase1 from './ProvaFase1';
import MaterialDigitalFase1 from './MaterialDigitalFase1';
import AplicacaoProvaFase1 from './AplicacaoProvaFase1';
import CorrecaoProvaFase1 from './CorrecaoProvaFase1';
import ClassificacaoFase2 from './ClassificacaoFase2';
import ProvaFase2 from './ProvaFase2';
import MaterialDigitalFase2 from './MaterialDigitalFase2';
import CorrecaoProvaFase2 from './CorrecaoProvaFase2';
import Desempate from './Desempate';
import Premiacao from './Premiacao';
import DisposicoesGerais from './DisposicoesGerais';
import Anexo1 from './Anexo1';
import Anexo2 from './Anexo2';

import pdfRegulamento from '../../assets/docs/regulamento.pdf';

import useStyles from './Style';

import Layout from '../../layouts/public/Public';

const Regulamento = () => {
    const classes = useStyles();
    return (
        <Layout>
            <Header />
            
            <Container maxWidth="xl" className={classes.container} >                
                <Grid container spacing={6} >
                    <Grid item xs={12} sm={12} lg={3} xl={3} >
                        <Navbar />
                    </Grid>

                    <Grid item xs={12} sm={12} lg={9} xl={9} >
                        
                        <Title variant="h4">
                            REGULAMENTO DA 3ª OLIMPÍADA MIRIM – OBMEP
                        </Title>
                        <Typography className={classes.textInitial} variant="body2">
                            A Associação Instituto Nacional de Matemática Pura e Aplicada (IMPA), associação privada sem fins lucrativos qualificada como organização social pelo Decreto 3.605/00, 
                            vinculada ao Ministério da Ciência, Tecnologia e Inovação (MCTI) e ao Ministério da Educação (MEC), vem, por meio deste instrumento, tornar pública a realização da 3ª Olimpíada Mirim - OBMEP, 
                            mediante as condições estabelecidas neste Regulamento.
                        </Typography>

                        <Preliminares />
                        <Participantes />
                        <Inscricao />                        
                        <Taxas />                        
                        <Confirmacao />                        
                        <Fases />                        
                        <ProvaFase1 />                        
                        <MaterialDigitalFase1 />                        
                        <AplicacaoProvaFase1 />                        
                        <CorrecaoProvaFase1 />                        
                        <ClassificacaoFase2 />                        
                        <ProvaFase2 />                        
                        <MaterialDigitalFase2 />                        
                        <CorrecaoProvaFase2 />                        
                        <Desempate />                        
                        <Premiacao />                        
                        <DisposicoesGerais />

                        <Anexo1 />
                        <Anexo2 />
                    </Grid>
                </Grid>
            </Container>
            
            <Download type="Regulamento" file={pdfRegulamento} />
        </Layout>
    )
}

export default Regulamento;