import React from 'react';

import Typography from '@material-ui/core/Typography';

import Title from '../../components/Title/Title';

import useStyles from './Style';

const CorrecaoProvaFase1 = () => {
    const classes = useStyles();
    return (
        <>
            <Title id="anchor-10" className={classes.title}>
                10. DA CORREÇÃO DA PROVA DA 1ª FASE 
            </Title>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>10.1</span> É de responsabilidade da escola inscrita realizar a correção das provas, respeitando as instruções deste Regulamento e do Manual para Aplicação da 1ª Fase 
                e o prazo estipulado no Calendário Oficial da Olimpíada Mirim - OBMEP (Anexo I).
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>10.2</span> A correção das provas deve ser realizada pelos professores das próprias escolas, seguindo rigorosamente as instruções e fazendo uso das máscaras de correção 
                e soluções elaboradas pelo IMPA para este fim.
            </Typography>
            
            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>10.3</span> As máscaras para correção das provas, bem como o manual para aplicação da 1ª Fase e as soluções são parte integrantes do Material de Prova, conforme item 8.2 deste Regulamento. 
                O Material de Prova, máscaras de correção e soluções, inclusive, será disponibilizado digitalmente na área restrita da escola inscrita na Olimpíada Mirim - OBMEP, no prazo estipulado no 
                Calendário Oficial da Olimpíada Mirim - OBMEP (Anexo I).
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>10.4</span> É responsabilidade das escolas participantes divulgar o resultado da 1ª Fase aos alunos participantes, bem como conceder a vista pedagógica de prova àqueles que assim o requererem.

            </Typography>
        </>
    )
}

export default CorrecaoProvaFase1;