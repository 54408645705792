import React from 'react';

import Typography from '@material-ui/core/Typography';

import Title from '../../components/Title/Title';

import useStyles from './Style';

const ClassificacaoFase2 = () => {
    const classes = useStyles();
    return (
        <>
            <Title id="anchor-11" className={classes.title}>
                11. DA CLASSIFICAÇÃO DOS ALUNOS PARA A 2ª FASE
            </Title>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>11.1</span> Serão classificados para a 2ª Fase os alunos que obtiverem as maiores notas na prova da 1ª Fase, selecionados em ordem decrescente de nota, até que se preencha o total de vagas disponível 
                para cada escola, por cada nível (<span className={classes.bolder}>MIRIM 1</span> – 2º e 3º anos do fundamental e <span className={classes.bolder}>MIRIM 2</span> – 4º e 5º anos do fundamental), conforme os critérios descritos neste Regulamento.
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>11.2</span> <span className={classes.bolder}>A escola inscrita terá direito a classificar para a 2ª Fase da 3ª Olimpíada Mirim – OBMEP, 20% (vinte por cento) do total de alunos inscritos, por nível: 20% dos inscritos no Mirim 1 – 2º e 
                3º anos do fundamental e 20% dos inscritos no Mirim 2 – 4º e 5º anos do fundamental.</span>
            </Typography>
            
            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>11.3</span> Caso o número equivalente a 20% (vinte por cento) do total de alunos inscritos na 1ª Fase não represente um número inteiro, ele deverá ser aproximado para o número inteiro imediatamente subsequente.
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>11.4</span> Ao ordenar as provas dos alunos de modo decrescente de notas para classificação, a escola deverá considerar alunos de todos os turnos, inclusive em turmas de modalidade EJA, separados apenas por 
                nível (<span className={classes.bolder}>MIRIM 1</span> – 2º e 3º anos do fundamental e <span className={classes.bolder}>MIRIM 2</span> – 4º e 5º anos do fundamental).
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>11.5</span> Os alunos com nota 0 (zero) serão automaticamente desclassificados, ainda que haja vaga disponível para a 2ª Fase.
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>11.6</span> Para classificar os alunos e receber o material da 2ª Fase, a escola deve, exclusivamente dentro do prazo estipulado no <span className={classes.bolder}>Calendário Oficial (Anexo I)</span>, informar, na área restrita da escola na Olimpíada Mirim - OBMEP,  
                <span className={classes.underline}>www.olimpiadamirim.obmep.org.br</span>, NOME DO(A) ALUNO(A), SEXO DO(A) ALUNO(A), RAÇA DO(A) ALUNO(A), DATA DE NASCIMENTO DO(A) ALUNO(A), ANO LETIVO DO(A) ALUNO(A), NOTA DA PROVA DO(A) ALUNO(A) NA 1ª FASE.
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>11.7</span> Para acessar a área restrita da escola no site, www.olimpiadamirim.obmep.org.br, o login é o Código INEP/MEC da escola e a senha é a mesma cadastrada no ato da inscrição.
            </Typography>

            <Typography className={classes.text} component={'div'} variant="body2">
                <span className={classes.item}>11.8</span> Deverão ser informados <span className={classes.bolder}>APENAS os dados dos alunos classificados</span>. A escola poderá classificar 20% (vinte por cento) da quantidade de alunos inscritos por nível, conforme item 11.2.

                <ul className={classes.list}>
                    <li><span className={classes.item}>EXEMPLO:</span></li><br />
                    <li> A escola inscreveu 20 alunos no Mirim 1, portanto 04 alunos serão classificados;</li>
                    <li> A escola inscreveu 35 alunos no Mirim 2, portanto 07 alunos serão classificados;</li>
                    <li> A escola inscreveu 42 alunos no Mirim 1, portanto 09 alunos (8,4 deve ser arredondado para o número inteiro imediatamente subsequente, conforme estipulado no item 11.3 deste regulamento);</li>
                    <li> A escola inscreveu 120 alunos no Mirim 1, portanto 24 alunos serão classificados.</li>
                </ul>
            </Typography>

            <Typography className={classes.text} component={'div'} variant="body2">
                <span className={classes.item}>11.9</span> A escola deverá indicar também o tipo de prova que o aluno classificado deverá receber para a 2ª fase:
                
                <ul className={classes.list}>
                    <li><span className={classes.item}>PROVA REGULAR</span> – Alunos sem deficiência visual;</li>
                    <li><span className={classes.item}>PROVA EM BRAILLE</span> – Alunos cegos ledores de Braille;</li>
                    <li><span className={classes.item}>PROVA AMPLIADA {"(Fonte 24)"}</span> – Alunos com baixa visão.</li>
                </ul>
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>11.10</span> É de responsabilidade da escola comunicar aos alunos que foram classificados para a 2ª Fase e a seus respectivos representantes legais, sobre a classificação e a data da prova da 2ª Fase.
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>11.11</span> Caso a escola deixe de informar, em sua área restrita na Olimpíada Mirim - OBMEP, os dados dos alunos classificados para a 2ª Fase, dentro do prazo estipulado no Calendário Oficial (Anexo I), 
                estará automaticamente desclassificada.
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>11.12</span> Após o prazo estipulado para as escolas informarem, na área restrita da Olimpíada Mirim - OBMEP, www.olimpiadamirim.obmep.org.br, conforme Calendário Oficial (Anexo I), <span className={classes.bolder}>não será permitida 
                qualquer alteração nos dados informados</span>.
            </Typography>
        </>
    )
}

export default ClassificacaoFase2;