import ContextoService from '../../services/Contexto';

export const GET_LIST_CONTEXT = 'SET_LIST_CONTEXT';

export function setListContext(contexts){
    return {
        type: GET_LIST_CONTEXT,
        contexts
    }
}

export function loadContext() {
    return async dispatch => {
        let f = [];
        f.push({ field: 'pageable', value: false });

        return await ContextoService.filters(f)
                .then( res => {
                    dispatch ( setListContext(res.data.content) );
                });
    }
}