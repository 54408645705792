import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';

import ArquivoService from '../../services/Arquivo';
import { errors } from '../../services/API';

import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Title from '../../components/Title/Title';
import Text from '../../components/Inputs/Text/Text';
import SelectOption from '../../components/Inputs/Select/Select';
import Arquivo from '../../components/Inputs/File/File';
import Toast from "../../components/Toast/Toast";
import { DateTimePicker } from '../../components/Inputs/DateTimePicker/DateTimePicker';
import Loading from '../../components/Loading/Loading';
import CheckBox from '../../components/Inputs/CheckBox/CheckBox';

import ButtonsForm from '../../forms/Buttons/ButtonsForm';

import { formatInTimeZone, parseDateTime } from '../../helper/DateHelper';

import { optionsFile, optionsLocalExibicao } from '../../variables/Enums/Arquivo.jsx';

import { Layout } from '../../layouts/private/Private';

import {
    isBlank,
 isDateTimeValid,
    isBlankHelperText,
isDateTimeHelperText
} from '../../helper/ValidationHelper';

class Form extends Component {
    constructor(props) {
        super(props);
        this.state = {
            files: [],
            file: {
                id: !isNaN(this.props.match.params.id) ? this.props.match.params.id : '',
                name: '',
                type: '',
                description: '',
                localExibicao: '',
                dataInicioDownload: '',
                dataFimDownload: '',
                exibirParaAlunosMirim1: true,
                exibirParaAlunosMirim2: true,
            },
            errors: {
                name: false,
                type: false,
                description: false,
                localExibicao: false,
                dataInicioDownload: false,
                dataFimDownload: false,
            },
            helpers: {
                name: null,
                type: null,
                description: null,
                localExibicao: null,
                dataInicioDownload: null,
                dataFimDownload: null,
            },
            loading: this.props.match.params.id ? true : false,
            loadingButtonSave: false,
        };
    }

    // INPUTS
    handleChange = (e, callback = null) => {
        this.setState({
            file: { 
                ...this.state.file, 
                [e.target.name]: e.target.name === 'exibirParaAlunosMirim1' || e.target.name === 'exibirParaAlunosMirim2' ? e.target.checked : e.target.value, 
            },
            errors: { ...this.state.errors, [e.target.name]: false },
            helpers: { ...this.state.helpers, [e.target.name]: null },
        }, () => callback);
    };

    handleChangeDate = (e, name) => {
        this.setState({
            file: { ...this.state.file, [name]: e },
            errors: { ...this.state.errors, [name]: false },
            helpers: { ...this.state.helpers, [name]: null },
        });
    }

    // DECLARAÇÃO DE REFERÊNCIA DOS COMPONENTES
    setToast = t => this.Toast = t;
    setArquivo = f => this.Arquivo = f;

    // VALIDAÇÕES
    isValid = () => {
        if (isBlank(this.state.file.name) || isBlank(this.state.file.type) || isBlank(this.state.file.description) || isBlank(this.state.file.localExibicao)
            || isBlank(this.state.file.dataInicioDownload) || !isDateTimeValid(this.state.file.dataInicioDownload) || isBlank(this.state.file.dataFimDownload) || !isDateTimeValid(this.state.file.dataFimDownload)) {
            this.setState({
                errors: {
                    name: isBlank(this.state.file.name) ? true : false,
                    type: isBlank(this.state.file.type) ? true : false,
                    description: isBlank(this.state.file.description) ? true : false,
                    localExibicao: isBlank(this.state.file.localExibicao) ? true : false,
                    dataInicioDownload: isBlank(this.state.file.dataInicioDownload) ? true : (!isDateTimeValid(this.state.file.dataInicioDownload) ? true : false),
                    dataFimDownload: isBlank(this.state.file.dataFimDownload) ? true : (!isDateTimeValid(this.state.file.dataFimDownload) ? true : false),
                },
                helpers: {
                    name: isBlank(this.state.file.name) ? isBlankHelperText() : null,
                    type: isBlank(this.state.file.type) ? isBlankHelperText() : null,
                    description: isBlank(this.state.file.description) ? isBlankHelperText() : null,
                    localExibicao: isBlank(this.state.file.localExibicao) ? isBlankHelperText() : null,
                    dataInicioDownload: isBlank(this.state.file.dataInicioDownload) ? isBlankHelperText() : (!isDateTimeValid(this.state.file.dataInicioDownload) ? isDateTimeHelperText() : null),
                    dataFimDownload: isBlank(this.state.file.dataFimDownload) ? isBlankHelperText() : (!isDateTimeValid(this.state.file.dataFimDownload) ? isDateTimeHelperText() : null),
                },
            });
            return false;
        }
        return true;
    }

    // SALVAR
    handleSubmit = () => {
        this.setState({ loadingButtonSave: true });

        const formIsValid = this.isValid();
        const formArquivoIsValid = this.state.file.id || this.Arquivo.isValid();

        if (!formIsValid || !formArquivoIsValid) {
            this.setState({ loadingButtonSave: false });
            return
        }

        const config = { headers: { 'content-type': 'multipart/form-data' } }

        const file = new FormData();
        file.append('nomeArquivo', this.state.file.name)
        file.append('tipoArquivo', this.state.file.type)
        file.append('localExibicao', this.state.file.localExibicao)
        file.append('dataInicio', formatInTimeZone(this.state.file.dataInicioDownload))
        file.append('dataFim', formatInTimeZone(this.state.file.dataFimDownload))
        file.append('descricao', this.state.file.description)
        file.append('exibirParaAlunosMirim1', this.state.file.exibirParaAlunosMirim1)
        file.append('exibirParaAlunosMirim2', this.state.file.exibirParaAlunosMirim2)

        if (!this.state.file.id)
            file.append('arquivo', this.Arquivo.state.file)

        let msgSuccess = '';
        let request = '';

        if (this.state.file.id) {
            request = ArquivoService.edit(this.state.file.id, file, config)
            msgSuccess = "Arquivo Atualizado com Sucesso.";
        } else {
            msgSuccess = `Arquivo Criado com Sucesso.`;
            request = ArquivoService.add(file, config)
        }

        request
            .then(res => {
                this.Toast.setState({
                    message: {
                        message: msgSuccess,
                        type: "success",
                        open: true
                    }
                });

                setTimeout(() => this.props.history.push(`/admin/arquivo`), 1000);
            })
            .catch(error => {
                const e = errors(error);
                this.Toast.setState({
                    message: {
                        message: e.message,
                        type: e.type,
                        open: true
                    }
                })
                this.setState({ loadingButtonSave: false });
            })
    }

    componentDidMount() {
        if (this.state.file.id) {
            ArquivoService.find(this.state.file.id)
                .then(res => {
                    this.setState({
                        ...this.state,
                        file: {
                            id: res.data.id,
                            name: res.data.nomeArquivo,
                            type: res.data.tipo,
                            description: res.data.descricao,
                            localExibicao: res.data.localExibicao,
                            dataInicioDownload: parseDateTime(res.data.dataInicioDownload),
                            dataFimDownload: parseDateTime(res.data.dataFimDownload),
                            exibirParaAlunosMirim1: res.data.exibirParaAlunosMirim1,
                            exibirParaAlunosMirim2: res.data.exibirParaAlunosMirim2,
                        },
                        loading: false,
                    });
                })
                .catch(err => {
                    this.Toast.setState({
                        message: {
                            message: "Não Foi Possível Buscar o Arquivo para Edição.",
                            type: 'error',
                            open: true
                        }
                    })
                    this.setState({ loading: false })
                })
        }

        ArquivoService.list()
            .then(res => {
                this.setState({
                    files: [...this.state.files, ...res.data.content]
                });
            })
            .catch(error => {
                const e = errors(error);
                this.Toast.setState({
                    message: {
                        message: e.message,
                        type: e.type,
                        open: true
                    }
                })
            })
        }

        render() {
            const page = this.state.file.id ? 'Editar Arquivo' : 'Adicionar Arquivo';
            const links = [
                {
                    path: null,
                    name: 'Configurações'
                },
                {
                    path: '/admin/arquivo',
                    name: 'Arquivos'
                },
            ];

            return (
                <Layout>
                    <Toast parentRef={this.setToast} />
                    <Grid container >
                        <Grid item xs={12}>
                            <Breadcrumbs links={links} active={page} />
                        </Grid>
                    </Grid>

                    <Title>{page}</Title>

                    {this.state.loading ?
                        <Loading />
                    :
                        <>
                            <Grid container spacing={3} alignItems='center'>
                                <Grid item sm={12} lg={3}>
                                    <Text
                                        required
                                        name="name"
                                        label="Nome do Arquivo conforme o Usuário lerá"
                                        value={this.state.file.name}
                                        error={this.state.errors.name}
                                        onChange={this.handleChange}
                                        helperText={this.state.helpers.name}
                                    />
                                </Grid>
                                <Grid item sm={12} lg={2}>
                                    <SelectOption
                                        required
                                        name="localExibicao"
                                        label="Local de Exibição no Dashboard"
                                        options={optionsLocalExibicao}
                                        value={this.state.file.localExibicao}
                                        error={this.state.errors.localExibicao}
                                        onChange={this.handleChange}
                                        hiddenblank="true"
                                        helperText={this.state.helpers.localExibicao}
                                    />
                                </Grid>
                                <Grid item sm={12} lg={3}>
                                    <SelectOption
                                        required
                                        name="type"
                                        label="Tipo"
                                        options={optionsFile}
                                        value={this.state.file.type}
                                        error={this.state.errors.type}
                                        onChange={this.handleChange}
                                        hiddenblank="true"
                                        helperText={this.state.helpers.type}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={3} alignItems='center'>
                                <Grid item sm={12} lg={2}>
                                    <DateTimePicker
                                        required
                                        label="Data Inicio de Download"
                                        name='dataInicioDownload'
                                        value={this.state.file.dataInicioDownload}
                                        onChange={(e) => this.handleChangeDate(e, 'dataInicioDownload')}
                                        error={this.state.errors.dataInicioDownload}
                                        helperText={this.state.helpers.dataInicioDownload}
                                    />
                                </Grid>
                                <Grid item sm={12} lg={2}>
                                    <DateTimePicker
                                        required
                                        label="Data Fim de Download"
                                        name='dataFimDownload'
                                        value={this.state.file.dataFimDownload}
                                        onChange={(e) => this.handleChangeDate(e, 'dataFimDownload')}
                                        error={this.state.errors.dataFimDownload}
                                        helperText={this.state.helpers.dataFimDownload}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={3} alignItems='center'>
                                <Grid item sm={12} lg={4}>
                                    <CheckBox
                                        name="exibirParaAlunosMirim1"
                                        label="Exibir Para Escolas com Alunos no Nível Mirim 1"
                                        checked={this.state.file.exibirParaAlunosMirim1}
                                        onChange={this.handleChange}
                                    />
                                </Grid>
                                <Grid item sm={12} lg={4}>
                                    <CheckBox
                                        name="exibirParaAlunosMirim2"
                                        label="Exibir Para Escolas com Alunos no Nível Mirim 2"
                                        checked={this.state.file.exibirParaAlunosMirim2}
                                        onChange={this.handleChange}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={3} alignItems='center'>
                                {!this.state.file.id && 
                                    <Arquivo parentRef={this.setArquivo} />
                                }
                                <Grid item sm={12} lg={10}>
                                    <Text
                                        required
                                        label="Descrição"
                                        name="description"
                                        value={this.state.file.description}
                                        error={this.state.errors.description}
                                        onChange={this.handleChange}
                                        helperText={this.state.helpers.description}
                                        fullWidth
                                    />
                                </Grid>
                                <ButtonsForm onClick={this.handleSubmit} idFocus={`saveButton`} loading={this.state.loadingButtonSave} />
                            </Grid>
                        </>
                    }
                </Layout>
            )
        }
    }

export default withRouter(Form);