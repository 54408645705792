import Form from '../../views/Coordenador/MaterialExtraForm';
import {
    __COORDENADOR_EDITAR_MATERIAL_EXTRA,
} from '../../security/RoleConfiguration';

export const path = '/admin';

export const listRoutes = [
    {
        path: path + '/coordenadores/materiaisExtras',
        component: Form,
        roles: [ __COORDENADOR_EDITAR_MATERIAL_EXTRA ],
    },
];

export default listRoutes;