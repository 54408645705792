import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { format } from 'date-fns';
import brLocale from "date-fns/locale/pt-BR";

import Grid from '@material-ui/core/Grid';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import Arquivo from '../../components/Inputs/File/File';
import Toast from '../../components/Toast/Toast';
import { ButtonPrimary } from "../../components/Button/Button";
import { DatePicker } from '../../components/Inputs/DatePicker/DatePicker';

import TarefaService from '../../services/Tarefa';
import { errors } from '../../services/API';

import {
    isBlank,
    isBlankHelperText,
    isDateTimeHelperText,
    isDateTimeInvalid
} from '../../helper/ValidationHelper';
import { formatInDate } from '../../helper/DateHelper';

import InformacoesUltimaExecucao from './InformacoesUltimaExecucao';
import BarraProgressoTarefa from './BarraProgressoTarefa';

import {
    __TAREFA_IMPORTACAO_OBJETOS_POSTAIS_INICIAR,
    __TAREFA_IMPORTACAO_OBJETOS_POSTAIS_ACOMPANHAR,
} from '../../security/RoleConfiguration';
import AuthorizedFunction from '../../security/AuthorizedFunction';

export class ImportacaoPlanilhaMaterialExtra extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tarefa: {
                id: null,
                inicio: null,
                termino: null,
                usuarioQueIniciou: '',
                tarefaExecutada: '',
                tipo: '',
                status: '',
                registrosParaProcessar: 0,
                registrosProcessados: 0,
                inicioProcessamento: null,
            },
            possuiTarefa: false,
            loadingImportarObjetosPostais: false,
            importacaoObjetosPostaisJaIniciada: true,
            planilha: {
                dataEnvio: '',
            },  
            errors: {
                dataEnvio: false,
            },
            helpers: {
                dataEnvio: null,                
            },
        }
    }

    // FECHAR TOAST
    setToast = t => this.Toast = t;
    setArquivo = f => this.Arquivo = f;

     // INPUTS DATE PICKER
     handleChangeDate = (e, name) => {
        this.setState({
            ...this.state,
            planilha: {
                ...this.state.planilha,
                [name]: e,
            },
            errors: { ...this.state.errors, [name]: false },
            helpers: { ...this.state.helpers, [name]: null }
        });
    }

    // VALIDAÇÕES
    isValid = () => {
        if (isBlank(this.state.planilha.dataEnvio) || isDateTimeInvalid(this.state.planilha.dataEnvio)) {
            this.setState({
                errors: {
                    dataEnvio: isBlank(this.state.planilha.dataEnvio) || isDateTimeInvalid(this.state.planilha.dataEnvio) ? true : false,
                },
                helpers: {
                    dataEnvio: isBlank(this.state.planilha.dataEnvio) ? isBlankHelperText() : isDateTimeInvalid(this.state.planilha.dataEnvio) ? isDateTimeHelperText() : null,
                },
            });
            return false;
        }
        return true;
    }

    handleImportarObjetosPostais = () => {
        this.setState({ loadingImportarObjetosPostais: true });

        const formIsValid = this.isValid();
        const formArquivoIsValid = this.Arquivo.isValid();

        if (!formIsValid || !formArquivoIsValid) {
            this.setState({ loadingImportarObjetosPostais: false });
            return
        }
        
        this.setState({
            ...this.state,
            possuiTarefa: false,
            loadingImportarObjetosPostais: true,
            importacaoObjetosPostaisJaIniciada: true,
        });
        
        const config = { headers: { 'content-type': 'multipart/form-data' } }

        const file = new FormData();
        file.append('dataEnvio', formatInDate(this.state.planilha.dataEnvio))
        file.append('objetosPostais', this.Arquivo.state.file)

        TarefaService.importarPlanilhaMaterialExtra(file, config)
            .then(res => {
                this.setState({
                    ...this.state,
                    loadingImportarObjetosPostais: false,
                    importacaoObjetosPostaisJaIniciada: true,
                    planilha: {
                        dataEnvio: '',
                    },
                });

                this.Arquivo.setState({
                    file: null,
                })

                this.initObserverAcompanhamento();

                this.Toast.setState({
                    message: {
                        message: "Importação Iniciada com Sucesso. Em breve aparecerão informações na tela, sobre o progresso desta Tarefa.",
                        type: "success",
                        open: true
                    }
                });
            })
            .catch(error => {
                this.setState({
                    ...this.state,
                    possuiTarefa: true,
                    loadingImportarObjetosPostais: false,
                    importacaoObjetosPostaisJaIniciada: false,
                });

                const e = errors(error);
                this.Toast.setState({
                    message: {
                        message: "Não foi Possível Iniciar a Importação. " + e.message,
                        type: e.type,
                        open: true
                    }
                });
            });
    }

    initObserverAcompanhamento = () => setTimeout(() => this.loadData(true), 5000);

    loadData = (observer = false) => {
        TarefaService.acompanharTarefaImportacaoPlanilhaMaterialExtra()
            .then(res => {
                if (res.status === 200) {
                    this.setState({
                        tarefa: {
                            id: res.data.id,
                            inicio: res.data.inicio,
                            termino: res.data.termino,
                            usuarioQueIniciou: res.data.usuarioQueIniciou,
                            tarefaExecutada: res.data.tarefaExecutada,
                            tipo: res.data.tipo,
                            status: res.data.status,
                            registrosParaProcessar: res.data.registrosParaProcessar,
                            registrosProcessados: res.data.registrosProcessados,
                            inicioProcessamento: res.data.inicioProcessamento,
                        },
                        possuiTarefa: true,
                        importacaoObjetosPostaisJaIniciada: res.data.status === "EM_ANDAMENTO" ? true : false,
                    })

                    if (res.data.status === "EM_ANDAMENTO")
                        this.initObserverAcompanhamento();

                } else if (res.status === 204) {
                    this.setState({
                        ...this.state,
                        possuiTarefa: false,
                        importacaoObjetosPostaisJaIniciada: false,
                    });
                } else {
                    this.setState({
                        ...this.state,
                        possuiTarefa: false,
                        importacaoObjetosPostaisJaIniciada: true,
                    });
                }
            })
            .catch(error => {
                if (!observer) {
                    const e = errors(error);
                    this.Toast.setState({
                        message: {
                            message: "Não foi Possível Realizar o Acompanhamento da Importação. " + e.message,
                            type: e.type,
                            open: true
                        }
                    });
                }
            })
    }

    // COMPONENT DID MOUNT
    componentDidMount() { this.loadData(); }

    render() {
        return (
            <>
                <Toast parentRef={this.setToast} />
                {(AuthorizedFunction([__TAREFA_IMPORTACAO_OBJETOS_POSTAIS_INICIAR, __TAREFA_IMPORTACAO_OBJETOS_POSTAIS_ACOMPANHAR])) &&
                    <Grid container spacing={2} style={{ marginTop: '16px', marginBottom: '16px' }}>
                        
                        {AuthorizedFunction([__TAREFA_IMPORTACAO_OBJETOS_POSTAIS_ACOMPANHAR]) && this.state.possuiTarefa &&
                            <Grid item sm={12} lg={12} style={{ marginBottom: '24px' }}>
                                <InformacoesUltimaExecucao
                                    inicio={this.state.tarefa.inicio}
                                    termino={this.state.tarefa.termino}
                                    usuario={this.state.tarefa.usuarioQueIniciou}
                                    status={this.state.tarefa.status}
                                    tipo={this.state.tarefa.tipo}
                                    paraProcessar={this.state.tarefa.registrosParaProcessar}
                                    processados={this.state.tarefa.registrosProcessados}
                                />

                                {this.state.importacaoObjetosPostaisJaIniciada &&
                                    <BarraProgressoTarefa 
                                        inicioProcessamento={this.state.tarefa.inicioProcessamento}
                                        paraProcessar={this.state.tarefa.registrosParaProcessar}
                                        processados={this.state.tarefa.registrosProcessados}
                                    />
                                }

                            </Grid>
                        }

                        {AuthorizedFunction([__TAREFA_IMPORTACAO_OBJETOS_POSTAIS_INICIAR]) &&
                            <>
                                <Grid item sm={12} lg={12} >
                                    <Typography variant="body2">
                                        <b>Informações para Importação:</b>

                                        <ul style={{ marginTop: 16, paddingLeft: 36 }}>
                                            <li>A planilha deve estar no formato <b>.xlsx</b> com os dados de Materiais Extras para os Coordenadores.</li>
                                            <li>A planilha deve obrigatoriamente conter o Identificador Único (ID) do Coordenador que receberá o Material Extra na <b>Coluna 1 (Ex.: 231)</b> e o Código Sedex na <b>Coluna 4 (Ex.: BR123456789BR)</b>, para que as informações sejam importadas corretamente.</li>
                                            <li>A importação irá considerar como válidos, <b>Códigos SEDEX</b> com <b>13</b> dígitos. Os casos que <b>NÃO</b> se enquadram irão ser <b>IGNORADOS</b> na importação.</li>
                                            <li>Obrigatoriamente também, deve ser informada uma <b>Data de Envio</b> para os Materiais Extras contidos na planilha no momento da importação, para seu uso nos registros no sistema, acompanhamentos dos objetos postais e relatórios com data de envio.</li>
                                            <li>Não serão importados Objetos Postais que já foram importados anteriormente.</li>
                                            <li>Caso haja algum erro na importação da planilha, estes serão mostrados na listagem de <b>Erros de Importaçao</b> no menu principal do sistema.</li>
                                        </ul>                                
                                    </Typography>
                                </Grid>
                                
                                <Grid item sm={12} lg={5} style={{ display: 'flex', alignSelf: 'center' }}>
                                    <Arquivo 
                                        parentRef={this.setArquivo} 
                                        accept=".xlsx"
                                    />
                                </Grid>
    
                                <Grid item sm={12} lg={2}>
                                    <DatePicker
                                        required
                                        disableFuture
                                        maxDate={format(new Date().setYear(new Date().getYear()), 'MM/dd/yy', { locale: brLocale })}
                                        minDate={format(new Date().setYear(1920), 'MM/dd/yyyy', { locale: brLocale })}
                                        label="Data de Envio"
                                        name='dataEnvio'
                                        value={this.state.planilha.dataEnvio}
                                        onChange={(e) => this.handleChangeDate(e, 'dataEnvio')}
                                        error={this.state.errors.dataEnvio}
                                        helperText={this.state.helpers.dataEnvio}
                                    />
                                </Grid>

                                <Grid item sm={12} lg={12}>
                                    <ButtonPrimary
                                        startIcon={this.state.loadingImportarObjetosPostais ? <CircularProgress size={16} /> : <PlayCircleOutlineIcon />}
                                        onClick={this.handleImportarObjetosPostais}
                                        name="Importar Planilha de Materiais Extras"
                                        disabled={this.state.importacaoObjetosPostaisJaIniciada}
                                    />
                                </Grid>
                            </>
                        }
                    </Grid>
                }
            </>
        )
    }
}

export default withRouter(ImportacaoPlanilhaMaterialExtra);