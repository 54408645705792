import React, { Component } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

import CircularProgress from '@material-ui/core/CircularProgress';

export const ToastDesign = ({ open, handleClose, children, severity, loading, autoHide }) => (
    <Snackbar className="no-print" open={open} autoHideDuration={autoHide ? 6000 : null} onClose={handleClose}>
        {loading ?
            <Alert onClose={handleClose} variant="filled" severity={severity} icon={<CircularProgress style={{ color: '#fff' }} size={20} />}>
                {children}
            </Alert>
        :
            <Alert onClose={handleClose} variant="filled" severity={severity} >
                {children}
            </Alert>
        }
    </Snackbar>
)

export class Toast extends Component {
    constructor(props) {
        super(props);
        props.parentRef(this);
        this.state = {
            message: {
                message: '',
                type: 'error',
                open: false,
                loading: false,
                autoHide: true,
            },
        }
    }

    // FECHAR TOAST
    handleCloseToast = e => {
        this.setState({ 
            message: {
                message: '',
                type: 'error',
                open: false,
                loading: false,
                autoHide: true,
            },
        });
    }

    render () {
        return (
            <ToastDesign
                open={this.state.message.open}
                handleClose={this.handleCloseToast}
                severity={this.state.message.type}
                loading={this.state.message.loading}
                autoHide={this.state.message.autoHide}
            >
                <ul dangerouslySetInnerHTML={{__html:this.state.message.message}}></ul>
            </ToastDesign>
        )
    }
}

export default Toast;