import Form from '../../views/ObjetoPostal/ObjetoPostalEmail';
import { __EMAIL_ENVIAR } from '../../security/RoleConfiguration';

export const path = '/admin/objetosPostais';

export const listRoutes = [
    {
        path: `${path}/email`,
        component: Form,
        roles: [ __EMAIL_ENVIAR ],
    }
];

export default listRoutes;