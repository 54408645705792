export const optionsTipoCoordenador = [
    { value: 'COORDENADOR', label: 'Coordenador' },
    { value: 'SUBCOORDENADOR', label: 'Sub-Coordenador' },
    { value: 'APOIO_SECRETARIAL', label: 'Apoio Secretarial' },
];

export const patternCoordenadores = coordenadores => {
    let array = [];
    coordenadores.forEach((coordenador) => array.push(patternCoordenador(coordenador)));
    return array;
}

export const patternCoordenador = coordenador => {
    return {
        id: coordenador.cdCoordenador,
        nome: coordenador.nmCoordenador,
        email: coordenador.nmEmail,
        login: coordenador.login,
        tipo: coordenador.tipo,
        regiao: coordenador.regiao.cdRegiao,
        uf: coordenador.regiao.uf.cdUf,
        materiais: [...coordenador.materiaisExtras],
    };
}

export const optionsStatusMateriais = [
    { value: 'NENHUM', label: 'Sem Nenhum Material Atribuído' },
    { value: 'PARCIAL', label: 'Com Materiais Parcialmente Atribuídos' },
    { value: 'TODOS', label: 'Com Todos os Materiais Atribuídos' },
];