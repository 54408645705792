import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Paper from "@material-ui/core/Paper";
import { Tab } from "@material-ui/core";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";

import TipoDocumentoService from "../../services/TipoDocumento";

import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Title from "../../components/Title/Title";

import { Layout } from '../../layouts/private/Private';

import Coordenador from "./Coordenador";
import SubCoordenador from "./SubCoordenador";
import ApoioSecretarial from "./ApoioSecretarial";

export class Listagem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tipos: [],
            tab: "0",
        }
    }

    handleTab = (e, newTab) => {
        this.setState({ tab: newTab });
    }

    loadData = () => {
        TipoDocumentoService.filters([{ field: "materialExtra", value: true }])
            .then(res => this.setState({ tipos: [...res.data]}));
    }

    // COMPONENT DID MOUNT
    componentDidMount() { this.loadData(); }

    render() {
        const pageName = 'Coordenadores';
        const links = [
            {
                path: null,
                name: 'Administração'
            }
        ];

        return (
            <Layout>
                <Grid container >
                    <Grid item xs={12}>
                        <Breadcrumbs links={links} active={pageName} />
                    </Grid>
                </Grid>
                <Title>{pageName}</Title>

                <Grid container spacing={3} >
                    <Grid item xs={12}>
                        <Paper style={{ padding: '24px' }} elevation={1}>
                            <TabContext value={this.state.tab}>
                                <TabList onChange={this.handleTab} indicatorColor="primary" textColor="primary" variant="fullWidth">
                                    <Tab label="Coordenadores" value="0" />
                                    <Tab label="Sub-Coordenadores" value="1" />
                                    <Tab label="Apoio Secretarial" value="2" />
                                </TabList>

                                <TabPanel value="0">
                                    <Coordenador tipos={this.state.tipos} />
                                </TabPanel>
                                <TabPanel value="1">
                                    <SubCoordenador tipos={this.state.tipos} />
                                </TabPanel>
                                <TabPanel value="2">
                                    <ApoioSecretarial />
                                </TabPanel>
                            </TabContext>
                        </Paper>
                    </Grid>
                </Grid>
            </Layout>
        )
    }
}

export default withRouter(Listagem);