import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';

import Text from '../../components/Inputs/Text/Text';

import { isBlank, isBlankHelperText, isZeroParaInscricao } from '../../helper/ValidationHelper';
import { numberMask } from '../../helper/MaskHelper';
import { Link } from 'react-router-dom';
import { Alert } from '@material-ui/lab';
import { Typography } from '@material-ui/core';
import InscricaoService from '../../services/Inscricao';

export class Fase1 extends Component {
    constructor(props) {
        super(props);
        props.parentRef(this);
        this.state = {
            possuiConvenio: "false",
            adminDep: '',
            dependenciaConvenio: '',
            modalidade: 'MODALIDADE_UM',
            inscricaoMirim1QtdAlunosPrevistos: '',
            inscricaoMirim2QtdAlunosPrevistos: '',
            valorPrevisto : "--",
            errors: {
                inscricaoMirim1QtdAlunosPrevistos: false,
                inscricaoMirim2QtdAlunosPrevistos: false,
            },
            helpers: {
                inscricaoMirim1QtdAlunosPrevistos: null,
                inscricaoMirim2QtdAlunosPrevistos: null,
            },
            disabled: {
                inscricaoMirim1QtdAlunosPrevistos: false,
                inscricaoMirim2QtdAlunosPrevistos: false,
            },
        }
        this.isValid = this.isValid.bind(this);
    }

    // INPUTS
    handleChange = e => {
        this.setState({
            [e.target.name]: numberMask(e.target.value),
            errors: {
                inscricaoMirim1QtdAlunosPrevistos: false,
                inscricaoMirim2QtdAlunosPrevistos: false,
            },
            helpers: {
                inscricaoMirim1QtdAlunosPrevistos: null,
                inscricaoMirim2QtdAlunosPrevistos: null,
            },
        }, () => this.calcularValorPrevisto());
    }

    changeDepAdmin = (state) => {
        this.setState({
            ...this.state,
            possuiConvenio : state.possuiConvenio,
            adminDep: state.adminDep,
            dependenciaConvenio: state.depConvenio,
        }, () => this.calcularValorPrevisto());
    }

    changeModalidade = (state) => {
        this.setState({
            ...this.state,
            modalidade: state.modalidade,
            dependenciaConvenio : state.dependenciaConvenio,
        }, () => this.calcularValorPrevisto());
    }

    calcularValorPrevisto = () => {
        let f = []

        f.push({ field: 'depAdmin', value: this.state.adminDep })
        f.push({ field: 'isConveniadaTotal', value: this.state.dependenciaConvenio === "TOTAL" })
        f.push({ field: 'modalidade', value: this.state.modalidade })
        f.push({ field: 'qtdMirim1', value: this.state.inscricaoMirim1QtdAlunosPrevistos === '' ? 0 : this.state.inscricaoMirim1QtdAlunosPrevistos })
        f.push({ field: 'qtdMirim2', value: this.state.inscricaoMirim2QtdAlunosPrevistos === '' ? 0 : this.state.inscricaoMirim2QtdAlunosPrevistos })

        InscricaoService.calcularValorPagamento(f).then(resultado => this.setState({ valorPrevisto: resultado.data }));
    }

    // VALIDAÇÕES
    isValid = () => {
        const isAmbosZerados = (this.state.inscricaoMirim1QtdAlunosPrevistos === 0 || this.state.inscricaoMirim1QtdAlunosPrevistos === '0') && (this.state.inscricaoMirim2QtdAlunosPrevistos === 0 || this.state.inscricaoMirim2QtdAlunosPrevistos === '0');

        if (isBlank(this.state.inscricaoMirim1QtdAlunosPrevistos) || isBlank(this.state.inscricaoMirim2QtdAlunosPrevistos) || isAmbosZerados) {
            this.setState({
                errors: {
                    inscricaoMirim1QtdAlunosPrevistos: isBlank(this.state.inscricaoMirim1QtdAlunosPrevistos) ? true : isAmbosZerados ? true : false,
                    inscricaoMirim2QtdAlunosPrevistos: isBlank(this.state.inscricaoMirim2QtdAlunosPrevistos) ? true : isAmbosZerados ? true : false,
                },
                helpers: {
                    inscricaoMirim1QtdAlunosPrevistos: isBlank(this.state.inscricaoMirim1QtdAlunosPrevistos) ? isBlankHelperText() : isAmbosZerados ? isZeroParaInscricao() : null,
                    inscricaoMirim2QtdAlunosPrevistos: isBlank(this.state.inscricaoMirim2QtdAlunosPrevistos) ? isBlankHelperText() : isAmbosZerados ? isZeroParaInscricao() : null,
                },
            });
            return false;
        }
        return true;
    }

    render() {
        return (
            <>
                <Grid container spacing={3} alignItems='center'>
                    <Grid item sm={4} lg={4}>
                        <Text
                            required
                            name="inscricaoMirim1QtdAlunosPrevistos"
                            label="Quantidade de Alunos Mirim 1 (2º e 3º Anos)"
                            value={this.state.inscricaoMirim1QtdAlunosPrevistos}
                            onChange={this.handleChange}
                            error={this.state.errors.inscricaoMirim1QtdAlunosPrevistos}
                            helperText={this.state.helpers.inscricaoMirim1QtdAlunosPrevistos}
                            inputProps={{ maxLength:"4" }}
                            disabled={this.state.disabled.inscricaoMirim1QtdAlunosPrevistos}
                        />
                    </Grid>
                    <Grid item sm={4} lg={4}>
                        <Text
                            required
                            name="inscricaoMirim2QtdAlunosPrevistos"
                            label="Quantidade de Alunos Mirim 2 (4º e 5º Anos)"
                            value={this.state.inscricaoMirim2QtdAlunosPrevistos}
                            onChange={this.handleChange}
                            error={this.state.errors.inscricaoMirim2QtdAlunosPrevistos}
                            helperText={this.state.helpers.inscricaoMirim2QtdAlunosPrevistos}
                            inputProps={{ maxLength:"4" }}
                            disabled={this.state.disabled.inscricaoMirim2QtdAlunosPrevistos}
                        />
                    </Grid>
                </Grid>

                { this.state.modalidade && this.state.modalidade !== "MODALIDADE_UM" &&
                    <Grid container spacing={3} alignItems='center'>
                        <Grid item sm={6} lg={2}>
                            <Text
                                readOnly
                                name="valorPrevisto"
                                label="Valor Previsto do Pagamento"
                                value={this.state.valorPrevisto}
                                id="valorPrevisto"
                            />
                        </Grid>
                        <Grid item sm={12} lg={4}>
                            <Alert severity="warning" style={{ marginTop: '10px' }}>
                                <Typography variant="body1">Tem dúvidas sobre o valor cobrado? <Link target="_blank" to={{ pathname: `/regulamento#pgto` }} label="Clique Aqui">Clique aqui</Link>  e entenda melhor. </Typography>
                            </Alert>                            
                        </Grid>
                    </Grid> 
                }
            </>
        )
    }
}

export default Fase1;