import React from 'react';

import Typography from '@material-ui/core/Typography';

import Title from '../../components/Title/Title';

import useStyles from './Style';

const CorrecaoProvaFase2 = () => {
    const classes = useStyles();
    return (
        <>
            <Title id="anchor-14" className={classes.title}>
                14. DA CORREÇÃO DA PROVA DA 2ª FASE
            </Title>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>14.1</span> É de responsabilidade da escola inscrita realizar a correção das provas da 2ª Fase da Olimpíada Mirim - OBMEP, respeitando as instruções deste Regulamento e do Manual de Instruções 
                para Aplicação da Prova da 2ª Fase e o prazo estipulado no Calendário Oficial (Anexo I).
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>14.2</span> A correção das provas deve ser realizada pelos professores das próprias escolas, seguindo rigorosamente as instruções e fazendo uso das máscaras de correção e soluções elaboradas 
                pelo IMPA para este fim.
            </Typography>
            
            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>14.3</span> As máscaras para correção das provas, bem como suas soluções são parte integrantes do Material de Prova.
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>14.4</span> Após a correção das provas, a escola inscrita deverá, dentro do prazo estipulado no Calendário Oficial da Olimpíada Mirim - OBMEP (Anexo I), informar, na área restrita da escola, 
                as notas dos alunos nas provas da 2ª Fase da 3ª Olimpíada Mirim - OBMEP.
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>14.5</span> Os certificados de participação digitais dos alunos na 2ª Fase da 3ª Olimpíada Mirim - OBMEP somente estarão disponíveis, na área restrita da escola, www.olimpiadamirim.obmep.org.br, 
                após a informação, por parte da escola inscrita, dentro do prazo estipulado no Calendário Oficial (Anexo I), das notas dos alunos na prova da 2ª Fase.
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>14.6</span> O IMPA não fornecerá atestados, certificados ou certidões relativas à classificação, participação ou à nota dos participantes na 3ª Olimpíada Mirim - OBMEP, exceto aqueles previstos 
                no item 14.5 deste Regulamento.
            </Typography>
            
            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>14.7</span> A utilização dos resultados da Olimpíada Mirim - OBMEP para fins de seleção, classificação e/ou premiação que não conste neste Regulamento não será de responsabilidade do IMPA 
                em qualquer hipótese.
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>14.8</span> Somente após a informação das notas dos alunos na área restrita da escola na Olimpíada Mirim - OBMEP, conforme descrito item 14.4, a escola poderá realizar a divulgação do 
                resultado da prova da segunda fase para os alunos.
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>14.9</span> Eventuais dúvidas, esclarecimentos e/ou solicitações deverão ser enviadas para o e-mail <span className={classes.underline}>olimpiadamirim@obmep.org.br</span>.
            </Typography>
        </>
    )
}

export default CorrecaoProvaFase2;