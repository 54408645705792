import React from 'react';

import Typography from '@material-ui/core/Typography';
import Title from '../../components/Title/Title';

import useStyles from './Style';

const Atualizacao = () => {
    const classes = useStyles();
    return (
        <>
            <Title id="anchor-8" variant="h6" className={classes.subtitle}>
                <span className={classes.item}>8.</span> ATUALIZAÇÕES DESTE AVISO DE PRIVACIDADE
            </Title>

            <Typography className={classes.text} variant="body2">
                Este aviso de privacidade foi atualizado pela última vez em 15 de dezembro de 2023.
            </Typography>
        </>
    )
}

export default Atualizacao;