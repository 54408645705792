import React from 'react';

import Typography from '@material-ui/core/Typography';

import Title from '../../components/Title/Title';

import useStyles from './Style';

const Confirmacao = () => {
    const classes = useStyles();
    return (
        <>
            <Title id="anchor-05" className={classes.title}>
                5. DA CONFIRMAÇÃO DE INSCRIÇÃO E DO ACESSO AO SISTEMA
            </Title>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>5.1</span> Ao final da inscrição, a escola deverá <span className={classes.bolder}>obrigatoriamente</span> guardar o recibo de inscrição, o qual servirá de comprovação da mesma.
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>5.2</span> Caso o recibo não seja gerado, a inscrição não terá sido concluída, devendo a escola entrar em contato com a central de atendimento Olimpíada Mirim - 
                OBMEP por meio do telefone (21) 2529-5084 ou pelo e-mail <span className={classes.bolder}>olimpiadamirim@obmep.org.br</span>, exclusivamente durante o período das inscrições. Após o decurso do prazo para contato, 
                não será mais possível o atendimento de qualquer solicitação referente a problemas relacionados aos recibos não gerados corretamente.
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>5.3</span> Não serão aceitas inscrições enviadas por meio diverso do estipulado neste Regulamento, tais como mensagens eletrônicas, fax, telefone, e-mails ou por meio postal.
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>5.4</span> Não serão aceitas inscrições incompletas, sendo as escolas responsáveis pela finalização, dentro do prazo, de todos os passos da inscrição.
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>5.5</span> O IMPA não se responsabilizará por qualquer solicitação de inscrição não recebida por motivos de ordem técnica dos computadores e/ou de conexão com a internet, falhas 
                de qualquer natureza, congestionamento das linhas de comunicação, indisponibilidade ou erro de instituições financeiras e entidades conveniadas no que se refere ao processamento 
                do pagamento da taxa de inscrição, bem como em relação a quaisquer outros fatores que impossibilitem a finalização da inscrição.
            </Typography>
        </>
    )
}

export default Confirmacao;
