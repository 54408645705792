import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';

import ObjetoPostalService from '../../services/ObjetoPostal';
import { errors } from '../../services/API';

import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Title from '../../components/Title/Title';
import Table from '../../components/Table/Table';
import Toast from '../../components/Toast/Toast';

import { ButtonExport } from '../../forms/Buttons/ButtonsExport';

import { download } from '../../helper/FileHelper';

import AuthorizedElement from '../../security/AuthorizedElement';
import { 
  __OBJETO_POSTAL_POR_DATA_EXPORTAR,
  __OBJETO_POSTAL_EXPORTAR,
} from '../../security/RoleConfiguration';
import AuthorizedFunction from '../../security/AuthorizedFunction';

import { Layout } from '../../layouts/private/Private';

export class ObjetoPostalPorData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datas: [],
      loading: true,
    }
  }

  // FECHAR TOAST
  setToast = t => this.Toast = t;

  // EXPORTAÇÂO
  handleExportPlan = () => {
    this.Toast.setState({
      message: {
        message: "A Planilha está sendo gerada para Exportação.",
        type: 'info',
        open: true,
        loading: true, 
        autohide: false,
      }
    })

    ObjetoPostalService.porDataExport([{ field: 'exportarPlanilha', value: true }])
      .then(res => download(res))
  }

  // EXPORTAÇÂO
  labelExportPlanData = id => !this.disabledVerObjetosPostais(id) ? "Exportar Listagem de Escolas nesta Data de Envio" : "Você não tem permissão para fazer isso";
  handleExportPlanData = (dataEnvio) => {
    const f = [];

    f.push({ field: 'dataEnvio', value: dataEnvio })
    f.push({ field: 'exportarPlanilha', value: true });

    this.Toast.setState({
        message: {
            message: "A Planilha está sendo gerada para Exportação.",
            type: 'info',
            open: true,
            loading: true,
            autohide: false,
        }
    })

    ObjetoPostalService.export(f).then(res => download(res));
  }

  // LABELS
  labelVerObjetosPostais = id => !this.disabledVerObjetosPostais(id) ? "Visualizar Objetos Postais" : "Você não tem permissão para fazer isso";

  // DISABLES
  disabledVerObjetosPostais = id => !AuthorizedFunction([__OBJETO_POSTAL_EXPORTAR]);

  // EDIÇÃO
  handleVerObjetosPostais = id => this.props.history.push(`/admin/objetosPostais?dataEnvio=${id}`);

  loadData = () => {
    this.setState({ loading: true, });

    ObjetoPostalService.porData([])
      .then(res => {
        this.setState({ datas: [...res.data], loading: false, });
      })
      .catch(error => {
        const e = errors(error);
        this.Toast.setState({
          message: {
            message: e.message,
            type: e.type,
            open: true
          }
        })

        this.setState({ loading: false });
      })
  }

  // COMPONENT DID MOUNT
  componentDidMount() { this.loadData(); }

  render() {
    const pageName = 'Objetos Postais Por Data';
    const links = [
      {
        path: null,
        name: 'Correios'
      },
      {
        path: null,
        name: 'Objetos Postais'
      }
    ];

    const columns = [
      { label: 'Data de Envio', name: 'dataEnvio', func: null, key: true },
      { label: 'Objetos Postais', name: 'objetosPostais', func: null, key: false },
    ];

    const actions = [
      {
        name: this.labelExportPlanData,
        func: this.handleExportPlanData,
        icon: <DescriptionOutlinedIcon />,
        disabled: this.disabledVerObjetosPostais,
      },
      {
        name: this.labelVerObjetosPostais,
        func: this.handleVerObjetosPostais,
        icon: <OpenInNewIcon />,
        disabled: this.disabledVerObjetosPostais,
      },
    ];

    return (
      <Layout>
        <Toast parentRef={this.setToast} />
        <Grid container >
          <Grid item xs={12}>
            <Breadcrumbs links={links} active={pageName} />
          </Grid>
        </Grid>
        <Title>{pageName}</Title>

        <Grid container >
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <AuthorizedElement roles={[__OBJETO_POSTAL_POR_DATA_EXPORTAR]}>
                <ButtonExport
                  title="Exportar Planilha"
                  onClick={this.handleExportPlan}
                />
              </AuthorizedElement>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Table
              columns={columns}
              tableData={this.state.datas}
              actions={actions}
              page={null}
              sort={null}
              rowsPerPage={null}
              loading={this.state.loading}
            />
          </Grid>
        </Grid>
      </Layout>
    )
  }
}

export default withRouter(ObjetoPostalPorData);