import API, { API as Instance } from './API';

const path = `arquivos`;

const FileService = {
    // FILTROS
    filters: async filters => await API.filters(path, filters),

    // LISTAR TODOS
    list: async () => await API.list(path),

    // LISTAR UM
    find: async id => await API.find(path, id),
    
    // ADICIONAR
    add: async (arquivo, config) => await Instance.post(path, arquivo, config),

    // ADICIONAR
    edit: async (id, arquivo, config) => await Instance.put(`${path}/${id}`, arquivo, config),

    // REMOVER
    remove: async id => await API.remove(path, id),

    // DOWNLOAD
    download: async id => await Instance.get(`${path}/${id}/download`, { responseType: 'blob' }),

    // CERTIFICADO
    certificado: async (inep) => await API.download(`${path}/${inep}/certificado`, ''),
}

export default FileService;