import API, { API as Instance } from './API';

const path = `aluno`;

const StudentService= {
    // FILTROS
    filters: async filters => await API.filters(path, filters),

    // EXPORTAR
    export: async filters => await API.export(path, filters),

    // LISTAR TODOS
    list: async () => await API.list(path),

    // LISTAR UM
    find: async id => await API.find(path, id),

    // ADICIONAR
    add: async student => await API.add(path, student),

    // EDITAR
    edit: async (id, student) => await API.edit(path, id, student),
    
    // REMOVER
    remove: async id => await API.remove(path, id),

    // TERMO DE AUTORIZAÇÃO
    buscarTermos: async (id) => await API.list(`${path}/${id}/termo`),
    enviarTermo: async (id, arquivo, config) => await Instance.post(`${path}/${id}/termo`, arquivo, config),
    downloadTermo: async (id, cdTermo) => await Instance.get(`${path}/${id}/termo/${cdTermo}/download` , { responseType: 'blob' }),
    atualizarStatusTermo: async (id, cdTermo, termo) => await Instance.put(`${path}/${id}/termo/${cdTermo}/validar`, termo),
    verificarSePossuiTermoValido: async id => await API.list(`${path}/${id}/termo/possui-valido`),
    downloadTermoValidoAluno: async (id) => await Instance.get(`${path}/${id}/termo/valido/download` , { responseType: 'blob' }),

    // ATUALIZAR NOTA FASE 2
    atualizarNotaFase2: async (id, nota) => await Instance.put(`${path}/${id}/nota-fase2/${nota}`),

    // ATUALIZAR NOME
    atualizarNome: async (id, student) => await Instance.put(`${path}/${id}/nome`, student),

    downloadCertificado: async (id) => await Instance.get(`${path}/${id}/download-certificado` , { responseType: 'blob' }),
}

export default StudentService;