import React from 'react';
import clsx from 'clsx';

import Typography from '@material-ui/core/Typography';

import Title from '../../components/Title/Title';

import useStyles from './Style';

const DisposicoesGerais = () => {
    const classes = useStyles();
    return (
        <>
            <Title id="anchor-17" className={classes.title}>
                17. DISPOSIÇÕES GERAIS
            </Title>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>17.1</span> A inscrição na 3ª Olimpíada Mirim - OBMEP implicará na tácita aceitação das normas e condições estabelecidas neste Regulamento, incluindo seus 
                anexos e eventuais retificações, das quais as escolas inscritas, alunos participantes e respectivos responsáveis não poderão alegar desconhecimento.
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>17.2</span> A divulgação de dados específicos e não estatísticos da OBMEP fica condicionada à autorização do Ministério da Ciência, Tecnologia e Inovação (MCTI) 
                e do Ministério da Educação (MEC), bem como ao cumprimento da legislação de proteção de dados à época vigente, de modo a garantir a privacidade dos participantes.
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>17.3</span> O IMPA se obriga a tratar os dados pessoais coletados no momento do recebimento destes de acordo com a legislação vigente aplicável, incluindo, mas não se 
                limitando à Lei nº 12.965, de 23 de abril de 2014 e Decreto nº 8.771, de 11 de maio de 2016 (“Marco Civil da Internet”) e Lei nº 13.709, de 14 de agosto de 2018 (“Lei Geral de Proteção de Dados” ou “LGPD”), 
                no que couber e for aplicável. Mais detalhes podem ser consultados no Aviso Externo de Privacidade disponível em <span className={clsx(classes.bolder, classes.underline)}>https://impa.br/sobre/acesso-a-informacao/aviso-de-privacidade/</span>.
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>17.4</span> Finalidades dos tratamentos: O tratamento dos dados pessoais ora coletados possui como finalidades: (i) identificar o participante; (ii) viabilizar a divulgação e a publicação do andamento da 
                Olimpíada Mirim - OBMEP (iii) realização/entrega de premiações e convites; (iv) publicação do resultado final da Olimpíada Mirim - OBMEP (v) viabilizar o envio de comunicados diversos ao candidato; (vi) permitir acesso aos 
                sistemas e às ferramentas disponibilizados pelo IMPA no âmbito da Olimpíada Mirim - OBMEP; (vii) permitir o exercício regular de direitos assegurados ao IMPA, e (viii) realização de estudos estatísticos.
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>17.5</span> O dado pessoal sensível correspondente à raça somente será usado para a elaboração de estudos estatísticos, pelo próprio IMPA, sendo compartilhado apenas de forma totalmente anonimizada.
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>17.6</span> Os dados pessoais tratados pelo IMPA são mantidos apenas por período indispensável para alcance das finalidades apresentadas nesse aviso de privacidade e por eventual período adicional 
                quando necessário para cumprimento de obrigações legais.
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>17.7</span> Mais detalhes sobre as medidas de segurança adotadas pela Instituição podem ser encontrados na Política de Segurança da Informação disponível no 
                link <span className={clsx(classes.bolder, classes.underline)}>https://olimpiadamirim.obmep.org.br/privacidade</span>.
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>17.8</span> Os dados pessoais coletados poderão ser compartilhados com terceiros que sejam contratados pelo IMPA para apoio na realização da Olimpíada Mirim - OBMEP e, ainda, com parceiros para realização de estudos, 
                comprometendo-se a Instituição a realizar o compartilhamento no exato limite e tempo necessários à realização das atividades
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>17.9</span> Para o saneamento de dúvidas ou exercício dos direitos assegurados pela LGPD, o candidato poderá entrar em contato através do e-mail <span className={clsx(classes.bolder, classes.underline)}>privacidade@impa.br</span>.
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>17.10</span> Os casos omissos e as eventuais dúvidas referentes a este Regulamento serão decididos e esclarecidos pelo IMPA.
            </Typography>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>17.11</span> Os casos omissos e as eventuais dúvidas referentes a este Regulamento serão decididos e esclarecidos pelo IMPA.
            </Typography>
        </>
    )
}

export default DisposicoesGerais;