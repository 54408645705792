import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import HomeIcon from '@material-ui/icons/Home';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import Grid from '@material-ui/core/Grid';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';

import { makeStyles } from '@material-ui/core/styles';

import header from '../../assets/img/header.png';
import headerMobile from '../../assets/img/header-mobile.png';

const useStyles = makeStyles(
    (theme) => (
        {
            menu: {
                backgroundColor: '#E60480',
                marginTop: theme.spacing(-1),
                position: 'absolute',
            },
            menuNormal:{
                flex: 10,
                display: 'inline-flex',
                ['@media (max-width:920px)']: { // eslint-disable-line no-useless-computed-key
                    display: 'none',
                },
            },
            menuColapsado: {
                display: 'none',
                ['@media (max-width:920px)']: { // eslint-disable-line no-useless-computed-key
                    display: 'inline-flex'
                },
            },
            buttonMenu: {
                ['@media (max-width:1080px)']: { // eslint-disable-line no-useless-computed-key
                    fontSize: '10px'
                },
            }
        }
    )
);

export const BannerHeader = () => {
    return (
        <picture>
            <source media="(max-width: 650px)" srcSet={headerMobile}/>
            <source media="(max-width: 980px)" srcSet={header}/>
            <img src={header} alt={process.env.REACT_APP_NAME_GENERAL} width="100%"/>
        </picture>
    )
}

export const MenuMirim = ({ children }) => {
    const classes = useStyles();

    const history = useHistory();
    const handleInicio = () => {
        history.push({ pathname: `/` });
    }

    return (
        <AppBar
            position="static"
            className={classes.menu}
        >
            <Toolbar>
                <Grid item container xs={11} sm={11} lg={1} xl={1} spacing={1} style={{flex: 2}}>
                    <Tooltip title="Início" TransitionComponent={Zoom}>
                        <IconButton
                            size="small"
                            component="div"
                            aria-label="Início"
                            onClick={handleInicio}
                        >
                            <HomeIcon style={{ color: 'white', fontSize: 32 }} />
                        </IconButton>
                    </Tooltip>
                </Grid>
                {children}
            </Toolbar>
        </AppBar>
    )
}

export const ButtonAppBarCollapse = ({children, className}) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleMenu = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <>
            <div className={className} >
                <Grid container item xs={1} spacing={1}>
                    <IconButton onClick={handleMenu}>
                        <MenuIcon style={{ color: 'white', fontSize: 32 }} />
                    </IconButton>
                </Grid>
                <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    transformOrigin={{ vertical: "top", horizontal: "right" }}
                    open={open}
                    onClose={handleClose}
                >
                    {children}
                </Menu>
            </div>
        </>
    );
}

export const Title = (props) => {
    return (
        <Typography component="h1" variant="h5">
            {props.children}
        </Typography>
    )
}

export default useStyles;
