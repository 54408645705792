import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
    (theme) => (
        {
            medal: {
                marginTop: theme.spacing(1),
                marginBottom: theme.spacing(1),
                width: '10%',
            },
        }
    )
);

export default useStyles;