import API, { API as Instance } from './API';

const path = `banners`;

const BannerService = {
    // FILTROS
    filters: async filters => await API.filters(path, filters),

    // LISTAR TODOS
    list: async () => await API.list(path),

    // LISTAR UM
    find: async id => await API.find(path, id),

    // ADICIONAR
    add: async (banner, config) => await Instance.post(path, banner, config),

    // EDITAR
    edit: async (id, banner, config) => await Instance.put(`${path}/${id}`, banner, config),

    // REMOVER
    remove: async id => await API.remove(path, id),

    paginaInicial: async () => await API.list(`${path}/inicial`),

    adicionarNoInicio: async id => await Instance.put(`${path}/inicial/${id}/adicionar`),

    alterarPosicao: async (id, acao) => await Instance.put(`${path}/inicial/${id}/atualizar/${acao}`),
}

export default BannerService;