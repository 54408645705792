import React, { useState, useEffect } from 'react';

import Loading from '../../components/Loading/Loading';
import Slider from '../../components/Slider/Slider';

import BannerService from '../../services/Banner';

export const Banner = () => {
    const [ banners, setBanners ] = useState([]);
    const [ loading, setLoading ] = useState(true);

    useEffect(() => {
        BannerService.paginaInicial()
            .then(res => {
                setBanners([...res.data.map(b => { return { id: b.cdBanner, desktop: b.token, mobile: b.tokenResponsivo, link: b.link, login: b.login }; }) ]);
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);
            })
    }, []);

    return loading ? <Loading /> : banners.length > 0 ? <Slider banners={banners} /> : <></>
}

export default Banner;