import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles((theme) => ({
    divider: {
        margin: theme.spacing(4, 0, 4),
    },
}));

export const Divisor = ({ id, children, variant, className }) => {
  const classes = useStyles();
  return (
      <div className={classes.divider}>
          <Divider />
      </div>
  );
}

export default Divisor;