import { API as APIService } from './API';

const path = `tarefas`;

const TarefaService = {

    // OBJETO POSTAL
    iniciarTarefaImportacaoObjetoPostal: async () => await APIService.post(`${path}/objetos-postais`),
    acompanharTarefaImportacaoObjetoPostal: async () => await APIService.get(`${path}/objetos-postais`),

    // STATUS SEDEX
    iniciarTarefaAtualizacaoStatusSedex: async () => await APIService.post(`${path}/status-sedex`),
    acompanharTarefaAtualizacaoStatusSedex: async () => await APIService.get(`${path}/status-sedex`),

    // PLANILHA OBJETO POSTAL
    importarPlanilhaObjetoPostal: async (arquivo, config) => await APIService.post(`${path}/objetos-postais/planilha`, arquivo, config),
    acompanharTarefaImportacaoPlanilhaObjetoPostal: async () => await APIService.get(`${path}/objetos-postais/planilha`),

    // PLANILHA MATERIAL EXTRA
    importarPlanilhaMaterialExtra: async (arquivo, config) => await APIService.post(`${path}/materiais-extras/planilha`, arquivo, config),
    acompanharTarefaImportacaoPlanilhaMaterialExtra: async () => await APIService.get(`${path}/materiais-extras/planilha`),
}

export default TarefaService;