import Form from '../../views/MaterialExtra/MaterialExtraEmail';
import { __EMAIL_ENVIAR } from '../../security/RoleConfiguration';

export const path = '/admin/materiaisExtras';

export const listRoutes = [
    {
        path: `${path}/email`,
        component: Form,
        roles: [ __EMAIL_ENVIAR ],
    }
];

export default listRoutes;