import React from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';

import Title from '../../components/Title/Title';

import useStyles from './Style';

const Anexo1 = () => {
    const classes = useStyles();
    return (
        <>
            <Title id="anchor-a1" className={classes.title}>
                ANEXO I
            </Title>

            <Grid 
                container 
                align='center' 
                direction="row" 
                justify="center"
                alignItems="center"
            >
                <Grid item >
                    <TableContainer component="div">
                        <Table className={classes.table} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center" colSpan={2} className={classes.purple}><span className={classes.item}>Calendário Oficial da 3ª Olimpíada Mirim - OBMEP</span></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell align="center" width='35%' component="th" scope="row" className={classes.blue}><span className={classes.item}>02 DE MAIO a 21 DE JUNHO</span></TableCell>
                                    <TableCell align="center">Período de inscrições (exclusivamente em www.olimpiadamirim.obmep.org.br)</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="center" width='35%' component="th" scope="row" className={classes.blue}><span className={classes.item}>22 DE JUNHO a 28 DE JUNHO</span></TableCell>
                                    <TableCell align="center">Período de emissão e pagamento dos boletos (somente escolas privadas, exclusivamente em www.olimpiadamirim.obmep.org.br)</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="center" width='35%' component="th" scope="row" className={classes.blue}><span className={classes.item}>01 DE AGOSTO a 27 DE AGOSTO</span></TableCell>
                                    <TableCell align="center">Disponibilização do material de provas (exclusivamente em www.olimpiadamirim.obmep.org.br)</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="center" width='35%' component="th" scope="row" className={classes.blue}><span className={classes.item}>27 DE AGOSTO</span></TableCell>
                                    <TableCell align="center" className={classes.orange}>PROVA DA 1ª FASE</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="center" width='35%' component="th" scope="row" className={classes.blue}><span className={classes.item}>28 DE AGOSTO a 13 DE SETEMBRO</span></TableCell>
                                    <TableCell align="center">Período para a correção das provas da 1ª Fase pelas escolas</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="center" width='35%' component="th" scope="row" className={classes.blue}><span className={classes.item}>28 DE AGOSTO a 13 DE SETEMBRO</span></TableCell>
                                    <TableCell align="center">
                                        Período para as escolas informarem os dados dos alunos classificados para a 2ª Fase, inclusive notas. <br />
                                        Período para solicitação de especiais - Braille e Ampliada <br />
                                        (exclusivamente em www.olimpiadamirim.obmep.com.br)
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="center" width='35%' component="th" scope="row" className={classes.blue}><span className={classes.item}>12 DE NOVEMBRO</span></TableCell>
                                    <TableCell align="center" className={classes.orange}>PROVA DA 2ª FASE</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="center" width='35%' component="th" scope="row" className={classes.blue}><span className={classes.item}>13 DE NOVEMBRO a 22 DE NOVEMBRO</span></TableCell>
                                    <TableCell align="center">Período para a correção das provas da 2ª Fase pelas escolas</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="center" width='35%' component="th" scope="row" className={classes.blue}><span className={classes.item}>13 DE NOVEMBRO a 02 DE DEZEMBRO</span></TableCell>
                                    <TableCell align="center">
                                        Período para as escolas informarem os dados e notas dos alunos (exclusivamente em www.olimpiadamirim.obmep.org.br)
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </>
    )
}

export default Anexo1;