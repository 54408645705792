import Chip from "@material-ui/core/Chip";
import React from "react";

export const optionsRange = [
    { value: '>=', label: 'Maior ou Igual' },
    { value: '<=', label: 'Menor ou Igual' },
];

export const patternBoolean = value => value ? 'Sim' : 'Não';

export const patternBooleanWithChip = value => {
    const color = value ? "#013214" : "#8B0000";
    const label = value ? "Sim" : "Não";
    return <Chip size="small" label={label} style={{ backgroundColor: color, fontWeight: '600', color: "white" }} />
}
