import React from 'react';

import Typography from '@material-ui/core/Typography';

import Title from '../../components/Title/Title';

import useStyles from './Style';

const Preliminares = () => {
    const classes = useStyles();
    return (
        <>
            <Title id="anchor-01" className={classes.title}>
                1. DISPOSIÇÕES PRELIMINARES
            </Title>

            <Title variant="h6" className={classes.subtitle} >
                <span className={classes.item}>1.1</span> Da Natureza
            </Title>

            <Typography className={classes.text} variant="body2">
                A 3ª Olimpíada Mirim - OBMEP é uma ação exclusivamente cultural e recreativa, sendo a participação absolutamente voluntária e desvinculada à aquisição de qualquer bem, serviço e/ou direito.
            </Typography>

            <Title variant="h6" className={classes.subtitle} >
                <span className={classes.item}>1.2</span> Da Realização
            </Title>
            <Typography className={classes.text} variant="body2">
                A 3ª Olimpíada Mirim – OBMEP é uma realização da Associação Instituto Nacional de Matemática Pura e Aplicada (IMPA), com apoio da B3 Social e da Sociedade Brasileira de Matemática (SBM), 
                promovida também com recursos oriundos do contrato de gestão firmado pelo IMPA com o Ministério da Ciência, Tecnologia e Inovação (MCTI) e com o Ministério da Educação (MEC). 
            </Typography>
            
            <Title variant="h6" className={classes.subtitle} >
                <span className={classes.item}>1.3</span> Da Abrangência
            </Title>
            <Typography className={classes.text} variant="body2">
                A 3ª Olimpíada Mirim - OBMEP é dirigida aos(as) alunos(as) dos 2º, 3º, 4º e 5º anos do Ensino Fundamental de escolas públicas municipais, estaduais e federais e de escolas privadas localizadas no território brasileiro.
            </Typography>

            <Title variant="h6" className={classes.subtitle} >
                <span className={classes.item}>1.4</span> Dos Objetivos
            </Title>
            <Typography className={classes.text} component={'div'} variant="body2">
                São objetivos da Olimpíada Mirim - OBMEP:

                <ul className={classes.list}>
                    <li><span className={classes.item}>1.4.1</span> Estimular e promover o estudo da Matemática no Brasil;</li>
                    <li><span className={classes.item}>1.4.2</span> Contribuir para a melhoria da qualidade da educação básica, possibilitando que um maior número de alunos(as) brasileiros(as) possa ter acesso a material didático de qualidade;</li>
                    <li><span className={classes.item}>1.4.3</span> Promover a difusão da cultura matemática;</li>
                    <li><span className={classes.item}>1.4.4</span> Identificar jovens talentos;</li>
                    <li><span className={classes.item}>1.4.5</span> Incentivar o aperfeiçoamento dos professores das escolas públicas, contribuindo para a sua valorização profissional; e</li>
                    <li><span className={classes.item}>1.4.6</span> Promover a inclusão social por meio da difusão do conhecimento.</li>
                </ul>
            </Typography>
        </>
    )
}

export default Preliminares;