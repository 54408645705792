export const locationEscolaParse = (location, f = []) => {
    const parseState = location ? location.state : null;

    if (parseState && parseState.escola) {
        f = f.filter(f => f.field !== 'id');
        f.push({ field: 'id', value: parseState.escola.id });
    }

    return f;
}

export const verifyLocationEscolaParse = location => {
    const parseState = location ? location.state : null;

    if (parseState && parseState.escola) return true;
    
    return false;
}

export const filterPersistence = (parseLocation) => {
    if (parseLocation.state && parseLocation.state.history)
        return { path: parseLocation.state.history.path, state: parseLocation.state.history.state };
    
    return null;
}

export const locationStateParse = (location) => {
    return location ? location.state : null;
}