import API from './API';

const path = `tipos-documento`;

const TipoDocumentoService= {
    // FILTROS
    filters: async (filters) => await API.filters(path, filters),

    atualizar: async (id, objeto) => await API.edit(path, id, objeto),
}

export default TipoDocumentoService;