import Form from '../../views/Materiais/IgnorarMaterial';
import {
    __CONTEXTO_CALCULAR_MATERIAIS,
    __CONTEXTO_ORDENAR_IMPRESSAO,
} from '../../security/RoleConfiguration';

export const path = '/admin';

export const listRoutes = [
    {
        path: path + '/materiais/escolas-ignorar',
        component: Form,
        roles: [
            __CONTEXTO_CALCULAR_MATERIAIS,
            __CONTEXTO_ORDENAR_IMPRESSAO,
        ],
    },
];

export default listRoutes;