import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadContext } from '../../store/actions/Contexto';

import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Chip from '@material-ui/core/Chip';
import Checkbox from '@material-ui/core/Checkbox';
import { Paper } from '@material-ui/core';
import { amber, green } from "@material-ui/core/colors";

import { ButtonPrimary } from '../../components/Button/Button';
import Dialog from '../../components/Dialog/Dialog';
import { ButtonDefault } from '../../components/Button/Button';
import Toast from "../../components/Toast/Toast";
import Loading from '../../components/Loading/Loading';

import { ButtonSave } from '../../forms/Buttons/ButtonsForm';
import { ButtonListagemPremiados } from '../Dashboard/Dashboard';

import InscricaoService from '../../services/Inscricao';
import { errors } from '../../services/API';

import { 
    patternNivel, 
    patternPremio,
    patternAnoLetivo,
    patternSexo,
} from '../../variables/Enums/Aluno';

import { 
    isPeriodoInserirNotaFase2,
    isPeriodoResolverProblemasEmpatesPremiacao,
    getDataFimResolverProblemasEmpatesPremiacao 
} from '../../helper/ContextHelper';

export class Desempate extends Component {
    constructor(props) {
        super(props);
        props.parentRef(this);
        this.state = {
            mec: this.props.mec,
            statusPremiacao: this.props.statusPremiacao,
            dialog: {
                open: false,
                loading: false,
                saveLoading: false,
            },
            selected: [],
            alunos: [],
            nivel: null,
            premioEmDisputa: null,
            proximoPremio: null,
            qtdPremiosDisponiveis: 0,
        }

        if (this.props.openModal)
            this.openModal();
    }

    setToast = t => this.Toast = t;

    isSelected = (id) => this.state.selected.indexOf(id) !== -1;

    isDisabled = (id) => this.state.selected.length === this.state.qtdPremiosDisponiveis && this.state.selected.indexOf(id) === -1;

    handleChange = (event, id) => { 
        if (this.state.selected.length <= this.state.qtdPremiosDisponiveis) {
            const selectedIndex = this.state.selected.indexOf(id);
            let newSelected = [];
        
            if (selectedIndex === -1) {
                newSelected = newSelected.concat(this.state.selected, id);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(this.state.selected.slice(1));
            } else if (selectedIndex === this.state.selected.length - 1) {
                newSelected = newSelected.concat(this.state.selected.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(this.state.selected.slice(0, selectedIndex), this.state.selected.slice(selectedIndex + 1));
            }

            this.setState({ ...this.state, selected: newSelected }) 
        }
    };

    close = () => {
        this.props.handleClose();
        this.setState({
            ...this.state,
            dialog: {
                open: false,
                loading: false,
                saveLoading: false,
            },
            selected: [],
            alunos: [],
            nivel: null,
            premioEmDisputa: null,
            proximoPremio: null,
            qtdPremiosDisponiveis: 0,
        });
    }

    openModal = async () => {
        await this.props.loadContext();

        this.setState({
            dialog: {
                ...this.state.dialog,
                open: true,
                loading: true,
            },
        }, () => this.buscarProximoEmpate());
    }

    buscarProximoEmpate = (refresh = false) => {
        InscricaoService.buscarProximoEmpate(this.props.mec)
            .then(res => {
                if (res.status === 200) {
                    this.setState({ 
                        ...this.state,
                        selected: [],
                        alunos: [...res.data.alunos],
                        nivel: res.data.nivel,
                        premioEmDisputa: res.data.premio,
                        proximoPremio: res.data.proximoPremio,
                        qtdPremiosDisponiveis: res.data.qtdPremiosDisponiveis,
                    });

                    if (refresh)
                        this.props.handleClose();

                } else if (res.status === 204) {
                    this.close();
                }
            })
            .catch(error => {
                const e = errors(error);
                this.Toast.setState({
                    message: {
                        message: e.message,
                        type: e.type,
                        open: true
                    }
                })
            }).finally(() => this.setState({ dialog: { ...this.state.dialog, loading: false }, }))
    }

    handleSubmit = () => {
        this.setState({ ...this.state, dialog: { ...this.state.dialog, saveLoading: true } });

        if (this.state.selected.length === 0 || this.state.selected.length > this.state.qtdPremiosDisponiveis)
            return;

        const alunos = new FormData();
        alunos.append('alunos', this.state.selected);
        
        InscricaoService.resolverEmpate(this.props.mec, alunos)
            .then(res => {
                this.setState({ ...this.state, dialog: { ...this.state.dialog, loading: true } });
                this.buscarProximoEmpate(true);
            })
            .catch(error => {
                const e = errors(error);
                this.Toast.setState({
                    message: {
                        message: e.message,
                        type: e.type,
                        open: true
                    }
                })
            }).finally(() => this.setState({ ...this.state, dialog: { ...this.state.dialog, saveLoading: false } }));        
    }

    actions = () => {
        return (
            <>
                <ButtonSave
                    name={`Resolver Empates Selecionados`}
                    onClick={this.handleSubmit}
                    loading={this.state.dialog.saveLoading}
                    style={{ backgroundColor: green[800]}}
                    saveDisabled={(this.state.selected.length === 0 || this.state.selected.length > this.state.qtdPremiosDisponiveis)}
                />
                <ButtonDefault
                    onClick={this.close}
                    startIcon={<ClearOutlinedIcon />}
                    style={{ margin: 4 }}
                    name="Resolver Depois"
                />
            </>
        )
    }

    render() {
        return (
            <>
                <Toast parentRef={this.setToast} />

                <Grid item xs={12} spacing={1}>
                    {this.props.statusPremiacao === 'CONCLUIDA' ?
                        <Alert severity="success" style={{ marginTop: '10px' }} >
                            <Grid container >
                                <Grid item sm={12} lg={12}>
                                    <Typography variant="body1">
                                        A Premiação foi concluída com sucesso.

                                        {(this.props.mostrarBotaoListagemPremiados || !isPeriodoInserirNotaFase2(this.props.contexts)) && 
                                            <>
                                                <br /><br />
                                                Clique no botão abaixo para:
                                                <ul style={{ paddingLeft: '50px' }}>
                                                    {this.props.mostrarBotaoListagemPremiados && <li> Ver a Listagem de Premiados por Nível.</li>}
                                                    {!isPeriodoInserirNotaFase2(this.props.contexts) && <li> Fazer o Download dos Certificados dos Alunos.</li>}
                                                </ul>
                                            </>
                                        }
                                    </Typography>
                                </Grid>
                                
                                {this.props.mostrarBotaoListagemPremiados && 
                                    <ButtonListagemPremiados id={this.state.mec} />
                                }
                            </Grid>          
                        </Alert>
                    : (this.props.statusPremiacao === 'AGUARDANDO_DESEMPATE'|| this.props.statusPremiacao === 'INICIADO') &&
                        <Alert severity="warning" style={{ marginTop: '10px' }} >
                            <Grid container >
                                {isPeriodoResolverProblemasEmpatesPremiacao(this.props.contexts) ? 
                                    <>
                                        <Grid item sm={12} lg={12} >
                                            <Typography variant="body1">
                                                <b>ATENÇÃO: </b>
                                                Existem empates que impactam na realização completa da <b>Premiação</b> dos alunos, e precisam de sua intervenção. 
                                                Clique no botão abaixo e escolha a forma que os alunos que empataram devem ser premiados.<br />
                                            </Typography>
                                        </Grid>
                                        
                                        <Grid item sm={12} lg={4} style={{ marginTop: '10px' }} >
                                            <ButtonPrimary
                                                startIcon={<CheckOutlinedIcon />}
                                                onClick={this.openModal}
                                                name="Tratamento de Empates"
                                                style={{ backgroundColor: amber[800] }}
                                            />
                                        </Grid>    
                                    </>
                                : 
                                    <Grid item sm={12} lg={12} >
                                        <Typography variant="body1">
                                            Empates impactaram na realização completa da <b>Premiação</b> dos alunos, e o período para resolver estas pendências expirou em <b>{getDataFimResolverProblemasEmpatesPremiacao(this.props.contexts)}</b><br />
                                        </Typography>
                                    </Grid>
                                }       
                            </Grid>          
                        </Alert>
                    }
                </Grid>

                {isPeriodoResolverProblemasEmpatesPremiacao(this.props.contexts) &&
                    <Dialog
                        openDialog={this.state.dialog.open}
                        title={`Tratamento de Empates que impactam na distribuição de Prêmios`}
                        closeDialog={this.close}
                        actions={this.actions()}
                        fullWidth={true}
                        maxWidth={"lg"}
                    >   
                        {this.state.dialog.loading ?
                            <Loading />
                        :   
                            <>
                                <Alert severity="warning" style={{ marginTop: '10px', marginBottom: '25px' }} >
                                    <Grid container >
                                        <Grid item sm={12} lg={12} >
                                            <Typography variant="body1">
                                                <b>ATENÇÃO: </b>
                                                Para definir a <b>Premiação</b>, você precisa resolver as situações de <b>EMPATE</b> entre alunos com a mesma nota. 
                                                Usando os critérios de desempate pré-definidos pela escola, clique no botão abaixo e realize o <b>DESEMPATE</b>.<br />
                                            </Typography>
                                        </Grid>
                                    </Grid>          
                                </Alert>
                                <Paper elevation={1} style={{ paddingLeft: '16px', paddingRight: '16px' }}>
                                    <Grid container spacing={3}>
                                        <Grid item lg={12}>
                                            <Typography variant="overline"><b>Informações sobre o Empate</b></Typography>

                                            <Grid container spacing={2} style={{ paddingTop: 2 }}>
                                                <Grid item sm={4} lg={2}>
                                                    <Typography variant="body2">
                                                        <b>Nível:</b> <Chip size="small" label={patternNivel(this.state.nivel)} color="primary" style={{ backgroundColor: amber[800], fontWeight: 800, color: '#fff' }} />
                                                    </Typography>
                                                </Grid>

                                                <Grid item sm={4} lg={2}>
                                                    <Typography variant="body2">
                                                        <b>Prêmio:</b> <Chip size="small" label={patternPremio(this.state.premioEmDisputa)} color="primary" style={{ fontWeight: 800, color: '#fff' }} />
                                                    </Typography>
                                                </Grid>

                                                <Grid item sm={4} lg={4}>
                                                    <Typography variant="body2">
                                                        <b>Quantidade de Prêmios Disponíveis:</b> <Chip size="small" label={this.state.qtdPremiosDisponiveis} style={{ backgroundColor: green[600], fontWeight: 800, color: '#fff' }} />
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Paper>

                                <Paper elevation={1} style={{ marginTop: '16px', paddingLeft: '16px', paddingRight: '16px' }}>
                                    <Grid container spacing={3}>
                                        <Grid item lg={12}>
                                            <Typography variant="overline"><b>Instruções</b></Typography>
                                            <Typography variant="body2">
                                                - Os empates poderão ser resolvidos a qualquer momento até a data de <b>{getDataFimResolverProblemasEmpatesPremiacao(this.props.contexts)}</b>.<br />
                                                - A ação de <b>Resolver Empates</b> só poderá ser executada uma vez para os alunos listados, não sendo possível um novo desempate para as premiações que já foram distribuidas e resolvidas.<br />
                                                - Para resolver os empates deve-se levar em consideração a <b>Quantidade de Prêmios Disponíveis</b>, pois não poderão ser marcados mais alunos do que a quantidade permitida.<br />
                                                - Ao clicar em <b>Resolver Empates</b>, os alunos marcados serão premiados com a <b>Medalha de {patternPremio(this.state.premioEmDisputa)}</b> no Nível <b>{patternNivel(this.state.nivel)}</b>, 
                                                    e os alunos que não forem marcados {this.state.proximoPremio ? <>serão premiados com a <b>Medalha de {patternPremio(this.state.proximoPremio)}</b></> : <>não receberão premiação</>}.<br />
                                                - Caso haja outros empates, após da resolução do empate listado, eles serão mostrados na sequência.<br />
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Paper>

                                <Grid container spacing={3} style={{ marginTop: '24px', marginBottom: '8px' }}>
                                    <Table size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell><b>Nome do Aluno</b></TableCell>
                                                <TableCell><b>Data de Nascimento</b></TableCell>
                                                <TableCell><b>Sexo</b></TableCell>
                                                <TableCell><b>Ano Letivo</b></TableCell>
                                                <TableCell><b>Nota - Fase 2</b></TableCell>
                                                <TableCell align="center"><b>Selecionar</b></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.alunos.length > 0 ?
                                                this.state.alunos.map((aluno) =>
                                                    <TableRow key={aluno.cdAluno} hover>
                                                        <TableCell>{aluno.nmAluno}</TableCell>
                                                        <TableCell>{aluno.dataNascimento}</TableCell>
                                                        <TableCell>{patternSexo(aluno.sexo)}</TableCell>
                                                        <TableCell>{patternAnoLetivo(aluno.anoLetivo)}</TableCell>
                                                        <TableCell width="15%">{aluno.notaFase2}</TableCell>
                                                        <TableCell width="15%" align="center">
                                                            <Checkbox 
                                                                color="primary" 
                                                                checked={this.isSelected(aluno.cdAluno)} 
                                                                onChange={event => this.handleChange(event, aluno.cdAluno)} 
                                                                disabled={this.isDisabled(aluno.cdAluno)} 
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            :
                                                <TableRow style={{ height: 53 }}>
                                                    <TableCell colSpan={6} align="center">
                                                        <Typography variant="body2"><b>Sem Empates para Tratamento</b></Typography>
                                                    </TableCell>
                                                </TableRow>
                                            }
                                        </TableBody>
                                    </Table>
                                </Grid>
                            </>
                        }
                    </Dialog>
                }
            </>
        )
    }
}

const mapStateToProps = state => ({ contexts: state.contexts });
const mapDispatchToProps = dispatch => (bindActionCreators({ loadContext }, dispatch));

export default connect(mapStateToProps, mapDispatchToProps)(Desempate);