import React from 'react';

import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import Title from '../../components/Title/Title';

import useStyles from './Style';

const Direitos = () => {
    const classes = useStyles();
    return (
        <>
            <Title id="anchor-7" variant="h6" className={classes.subtitle}>
                <span className={classes.item}>7.</span> DIREITOS DOS TITULARES
            </Title>

            <Typography className={classes.text} variant="body2">
                Os titulares dos dados pessoais utilizados pelo IMPA possuem direitos assegurados pela LGPD, os quais podem ser exercidos mediante solicitação enviada ao canal de contato disponível nesse aviso. As solicitações podem ser
                feitas pelo próprio titular ou por representante devidamente habilitado.
            </Typography>
            <Typography className={classes.text} variant="body2">
                O exercício desses direitos depende do preenchimento dos critérios compatíveis com a legislação vigente, como a validação da identidade do solicitante e a inexistência de restrições técnicas e/ou jurídicas.
            </Typography>
            <Typography className={classes.text} component={'div'} variant="body2">
                A seguir, apresentamos os direitos garantidos pela LGPD:

                <Grid
                    container
                    align='center'
                    direction="row"
                    justify="center"
                    alignItems="center"
                    className={classes.wrapper}
                >
                    <Grid item xs={12} sm={8} lg={6} xl={6} >
                        <TableContainer component="div">
                            <Table className={classes.table} size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.bolder}>De acordo com a LGPD, é possível:</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>
                                            <ul className={classes.list}>
                                                <li>Solicitar confirmação acerca do tratamento dos seus dados pessoais pelo IMPA;</li>
                                                <li>Solicitar acesso aos dados pessoais tratados pelo IMPA;</li>
                                                <li>Solicitar a correção dos dados pessoais, desde que incorretos, inexatos ou desatualizados;</li>
                                                <li>Solicitar a anonimização, bloqueio ou eliminação dos dados pessoais, desde que desnecessários, excessivos ou tratados em desacordo com a LGPD;</li>
                                                <li>Solicitar a portabilidade dos dados pessoais, de acordo com a regulamentação da Autoridade Nacional de Proteção de Dados;</li>
                                                <li>Solicitar a eliminação dos dados pessoais tratados mediante sua autorização;</li>
                                                <li>Solicitar a revogação de eventual autorização para tratamento dos seus dados pessoais;</li>
                                                <li>Solicitar informações sobre possíveis consequências em caso de não fornecimento de autorização para tratamento de dados;</li>
                                                <li>Solicitar informações sobre compartilhamento de dados pessoais com terceiros;</li>
                                                <li>Solicitar oposição ao tratamento dos dados pessoais realizado em desacordo com a LGPD;</li>
                                                <li>Solicitar a revisão de decisões tomadas exclusivamente de forma automática (sem participação humana) e/ou o fornecimento dos critérios considerados para a tomada dessa decisão; e</li>
                                                <li>Peticionar à Autoridade Nacional de Proteção de Dados em relação ao tratamento de dados pessoais pelo IMPA.</li>
                                            </ul>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </Typography>
        </>
    )
}

export default Direitos;