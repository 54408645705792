export const optionsConteudo = [
    { value: 'MATERIAL_2_FASE', label: 'Material Para 2ª Fase' },
    { value: 'MEDALHAS', label: 'Medalhas para Premiação' },
];

export const optionsConteudoCompleto = [
    { value: 'MATERIAL_2_FASE', label: 'Material Para 2ª Fase' },
    { value: 'MATERIAL_EXTRA_2_FASE', label: 'Material Extra Para 2ª Fase' },
    { value: 'MEDALHAS', label: 'Medalhas para Premiação' },
];

export const patternConteudo = label => {
    return label === "MATERIAL_2_FASE" ? "Material Para 2ª Fase" : label === "MATERIAL_EXTRA_2_FASE" ? "Material Extra Para 2ª Fase" : label === "MEDALHAS" ? "Medalhas para Premiação" : null;
}