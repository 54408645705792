import { API as APIService } from './API';

const path = `importar`;

const ImportacaoService= {
    importarCoordenador: async () => await APIService.post(`${path}/coordenador`),
    importarSubCoordenador: async () => await APIService.post(`${path}/subcoordenador`),
    importarApoioSecretarial: async () => await APIService.post(`${path}/apoio-secretarial`),
}

export default ImportacaoService;