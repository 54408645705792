export const optionsFile = [
    { value: 'PROVA', label: 'Prova' },
    { value: 'ATIVIDADE_COMPLEMENTAR', label: 'Atividade Complementar' },
    { value: 'GABARITO', label: 'Gabarito' },
    { value: 'MASCARA', label: 'Máscara' },
    { value: 'RELATORIO_INFORMATIVO', label: 'Relatório Informativo de Participantes' },
    { value: 'MANUAL', label: 'Manual de Informações Complementares' },
    { value: 'CARTAZ', label: 'Cartaz de Divulgação' },
    { value: 'CERTIFICADO', label: 'Certificado' },
    { value: 'TERMO_ALUNO', label: 'Termo de Consentimento para Publicação de Nome do Aluno' },
    { value: 'OUTRO', label: 'Outro' },
];

export const patternFile = value => 
    value === 'PROVA' ? 'Prova' : value === 'CARTAO' ? 'Cartão Resposta' : value === 'GABARITO' ? 'Gabarito' : value === 'MASCARA' ? 'Máscara' : 
        value === 'RELATORIO_INFORMATIVO' ? 'Relatório Informativo de Participantes' :  value === 'MANUAL' ? 'Manual de Informações Complementares' : value === 'CARTAZ' ? 'Cartaz de Divulgação' :
            value === 'CERTIFICADO' ? 'Certificado' : value === 'TERMO_ALUNO' ? 'Termo de Consentimento para Publicação de Nome do Aluno' : value === 'OUTRO' ? 'Outro' : 
                value === 'ATIVIDADE_COMPLEMENTAR' ? 'Atividade Complementar' : "";

export const optionsLocalExibicao = [
    { value: 'INSCRICAO', label: 'Inscrições' },
    { value: 'FASE_1', label: '1ª Fase' },
    { value: 'FASE_2', label: '2ª Fase' },
    { value: 'RELATORIO', label: 'Premiação' },
];

export const patternLocalExibicao = value =>
    value === 'INSCRICAO' ? 'Inscrições' : value === 'FASE_1' ? '1ª Fase' :
        value === 'FASE_2' ? '2ª Fase' : value === 'RELATORIO' ? 'Premiação' : '';