import React, { Component } from 'react'

import Grid from '@material-ui/core/Grid'
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";

import TipoDocumentoService from "../../services/TipoDocumento";
import { errors } from '../../services/API'

import Dialog from '../../components/Dialog/Dialog'
import Toast from '../../components/Toast/Toast'
import SwitchOption from "../../components/Inputs/Switch/Switch";

import { ButtonsForm } from '../../forms/Buttons/ButtonsForm'

export class DialogTipoDocumento extends Component {
    constructor(props) {
        super(props);
        props.parentRef(this);
        this.state = {
            open: false,
            id: "",
            nome: "",
            regular: false,
            extra: false,
            disabled: {
                regular: false,
                extra: false,
            },
            inicial: {
                extra: false,
                regular: false,
            },
            loadingButtonSave: false,
        }
    }

    // DECLARAÇÕES DE REFERENCIA DOS COMPONENTES
    setToast = t => this.Toast = t;

    handleChangeSwitch = e => {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.checked,
        });
    };

    // AÇÕES MODAL
    buttons = (id) =>
        <ButtonsForm
            onClick={this.handleSubmit}
            onBack={() => this.handleClose()}
            idFocus={`saveButton`}
            loading={this.state.loadingButtonSave}
        />

    // FECHAR MODAL
    handleClose = () => {
        this.setState({
            open: false,
            id: "",
            nome: "",
            regular: false,
            extra: false,
            disabled: {
                regular: false,
                extra: false,
            },
            inicial: {
                extra: false,
                regular: false,
            },
            loadingButtonSave: false,
        });

        this.props.handleClose();
    }

    // SUBMIT
    handleSubmit = () => {
        this.setState({ loadingButtonSave: true });

        const objeto = {
            materialRegular: this.state.regular,
            materialExtra: this.state.extra,
        }

        TipoDocumentoService.atualizar(this.state.id, objeto)
            .then(res => {
                this.Toast.setState({
                    message: {
                        message: "Tipo de Material Atualizado Com Sucesso.",
                        type: "success",
                        open: true
                    }
                })

                this.setState({ loadingButtonSave: false });
                setTimeout(() => this.handleClose(), 500);
            })
            .catch(err => {
                const e = errors(err);
                this.Toast.setState({
                    message: {
                        message: e.message,
                        type: e.type,
                        open: true
                    }
                })
            })
            .finally(() => this.setState({ loadingButtonSave: false }));
    }

    render() {
        return (
            <>  
                <Toast parentRef={this.setToast} />
                <Dialog
                    maxWidth={"sm"}
                    fullWidth={true}
                    closeDialog={this.handleClose}
                    openDialog={this.state.open}
                    title={`Gerenciar Atribuições - #${this.state.id} - ${this.state.nome}`}
                    actions={this.buttons()}
                >
                    <Grid container alignItems='center' >
                        <Grid item sm={12} style={{ marginBottom: "16px" }} >
                            { (this.state.disabled.regular && this.state.disabled.extra) &&
                                <Alert severity="info" >
                                    <Typography variant="body2">
                                        As atribuições não podem ser alteradas neste momento do processo, pois o <b>Consumo do Serviço de Materiais para Impressão às Escolas</b> já foi iniciado pela Gráfica.</Typography>
                                </Alert>
                            }
                        </Grid>

                        <Grid item sm={12} >
                            { !this.state.disabled.regular &&
                                <Alert severity={this.state.inicial.regular ? "warning" : "info"} >
                                    <Typography variant="body2">
                                        { this.state.inicial.regular ?
                                            <>Ao desabilitar este <b>Material Regular</b>, o material <b>#{this.state.id} - {this.state.nome}</b> não estará disponível para a funcionalidade de <b>Cálculo de Materiais para as Escolas</b> na sua próxima execução.</>
                                        :
                                            <>Ao habilitar este <b>Material Regular</b>, o material <b>#{this.state.id} - {this.state.nome}</b> estará disponível para a funcionalidade de <b>Cálculo de Materiais para as Escolas</b> na sua próxima execução.</>
                                        }
                                    </Typography>
                                </Alert>
                            }
                            <Grid container item spacing={3} >
                                <Grid item sm={12} >
                                    <SwitchOption
                                        name="regular"
                                        label="Material Regular"
                                        onChange={this.handleChangeSwitch}
                                        checked={this.state.regular}
                                        disabled={this.state.disabled.regular}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item sm={12} style={{ marginTop: "24px" }} >
                            { !this.state.disabled.extra &&
                                <Alert severity={this.state.inicial.extra ? "warning" : "info"} >
                                    <Typography variant="body2">
                                        { this.state.inicial.extra ?
                                            <>Ao desabilitar este <b>Material Extra</b>, os quantitativos do material <b>#{this.state.id} - {this.state.nome}</b> serão removidos dos Coordenadores, e as quantidades atreladas serão perdidas.</>
                                        :
                                            <>Ao habilitar este <b>Material Extra</b>, o material <b>#{this.state.id} - {this.state.nome}</b> será disponibilizado para ter sua quantidade atribuída na Lista de Coordenadores.</>
                                        }
                                    </Typography>
                                </Alert>
                            }
                            <Grid container item style={{ marginTop: "16px" }} >
                                <Grid item sm={12} >
                                    <SwitchOption
                                        name="extra"
                                        label="Material Extra"
                                        onChange={this.handleChangeSwitch}
                                        checked={this.state.extra}
                                        disabled={this.state.disabled.extra}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Dialog>
            </>
        )
    }
}

export default DialogTipoDocumento;