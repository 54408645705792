import React from 'react';

import Button from '@material-ui/core/Button';

import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';

const ColorSuccessButton = withStyles((theme) => ({
    root: {
      color: theme.palette.getContrastText(green[700]),
      backgroundColor: green[700],
      '&:hover': {
        backgroundColor: green[900],
      },
    },
})) (Button);

export const ButtonPrimary = props => {
    const { name } = props;

    return (
        <Button
            variant="contained"
            size="small"
            color="primary"
            {...props}
        >
            {name}
        </Button>
    )
}

export const ButtonDefault = props => {
    const { name } = props;

    return (
        <Button
            variant="contained"
            size="small"
            color="default"
            {...props}
        >
            {name}
        </Button>
    )
}

export const ButtonSecondary = props => {
    const { name } = props;

    return (
        <Button
            variant="contained"
            size="small"
            color="secondary"
            {...props}
        >
            {name}
        </Button>
    )
}

export const ButtonSuccess = props => {
    const { name } = props;

    return (
        <ColorSuccessButton 
            variant="contained"
            size="small"
            {...props}
        >
            {name}
        </ColorSuccessButton>
    )
}

export default ButtonPrimary;