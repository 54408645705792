import Dashboard from '../../views/Dashboard/Dashboard';
import Contexto from '../../views/Contexto/Contexto';
import Escola from '../../views/Escola/Escola';
import Aluno from '../../views/Aluno/List';
import Email from '../../views/Email/Email';
import Arquivo from '../../views/Arquivo/Arquivo';
import ObjetoPostal from '../../views/ObjetoPostal/ObjetoPostal';
import ObjetoPostalErro from '../../views/ObjetoPostal/ObjetoPostalErro';
import ObjetoPostalPorData from '../../views/ObjetoPostal/ObjetoPostalPorData';
import ObjetoPostalPorStatus from '../../views/ObjetoPostal/ObjetoPostalPorStatus';
import MaterialExtra from '../../views/MaterialExtra/MaterialExtra';
import MaterialExtraPorData from "../../views/MaterialExtra/MaterialExtraPorData";
import MaterialExtraPorStatus from '../../views/MaterialExtra/MaterialExtraPorStatus';
import Tarefa from '../../views/Tarefa/Tarefa';
import RelatorioEscolas from '../../views/Relatorio/Escolas';
import RelatorioMedalhas from '../../views/Relatorio/Medalhas';
import RelatorioPagamentos from '../../views/Relatorio/Pagamentos';
import RelatorioDependenciasAdministrativas from '../../views/Relatorio/DependenciasAdministrativas';
import RelatorioMunicipios from '../../views/Relatorio/Municipios';
import RelatorioUfs from '../../views/Relatorio/Ufs';
import RelatorioInscricoes from '../../views/Relatorio/QuantitativoInscricoes';
import RelatorioProvasEspeciais from '../../views/Relatorio/ProvasEspeciais';
import RelatorioGestao from '../../views/Relatorio/Gestao';
import Banner from '../../views/Banner/Banner';
import Coordenador from '../../views/Coordenador/Listagem';
import TipoDocumento from "../../views/Materiais/TipoDocumento";
import Calculo from "../../views/Materiais/CalculoMaterial";
import OrdemImpressao from "../../views/Materiais/OrdemImpressao";
import Servicos from "../../views/Materiais/Servicos";

import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import SchoolOutlinedIcon from '@material-ui/icons/SchoolOutlined';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import PlaceOutlinedIcon from '@material-ui/icons/PlaceOutlined';
import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined';
import MapOutlinedIcon from '@material-ui/icons/MapOutlined';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import FaceOutlinedIcon from '@material-ui/icons/FaceOutlined';
import MarkunreadMailboxOutlinedIcon from '@material-ui/icons/MarkunreadMailboxOutlined';
import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined';
import AccountTreeOutlinedIcon from '@material-ui/icons/AccountTreeOutlined';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import ScheduleOutlinedIcon from '@material-ui/icons/ScheduleOutlined';
import AccessibleForwardOutlinedIcon from '@material-ui/icons/AccessibleForwardOutlined';
import BusinessOutlinedIcon from '@material-ui/icons/BusinessOutlined';
import ViewCarouselOutlinedIcon from '@material-ui/icons/ViewCarouselOutlined';
import EmojiEventsOutlinedIcon from '@material-ui/icons/EmojiEventsOutlined';
import AttachMoneyOutlinedIcon from '@material-ui/icons/AttachMoneyOutlined';
import Filter1Icon from '@material-ui/icons/Filter1';
import Filter2Icon from '@material-ui/icons/Filter2';
import DnsOutlinedIcon from '@material-ui/icons/DnsOutlined';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import InputOutlinedIcon from '@material-ui/icons/InputOutlined';
import SupervisorAccountOutlinedIcon from '@material-ui/icons/SupervisorAccountOutlined';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import BookmarksOutlinedIcon from '@material-ui/icons/BookmarksOutlined';
import FunctionsIcon from '@material-ui/icons/Functions';
import SwapVertOutlinedIcon from '@material-ui/icons/SwapVertOutlined';
import DynamicFeedOutlinedIcon from '@material-ui/icons/DynamicFeedOutlined';

import {
    __ADMINISTRADOR,
    __CENTRAL,
    __COORDENADOR,
    __DASHBOARD_VISUALIZAR,
    __CONTEXTO_VISUALIZAR_VALOR,
    __EMAIL_VISUALIZAR,
    __ESCOLA_VISUALIZAR,
    __ARQUIVO_VISUALIZAR,
    __OBJETO_POSTAL_VISUALIZAR,
    __OBJETO_POSTAL_ERROS_VISUALIZAR,
    __OBJETO_POSTAL_POR_DATA_VISUALIZAR,
    __OBJETO_POSTAL_POR_STATUS_VISUALIZAR,
    __TAREFA_VISUALIZAR,
    __RELATORIO_ESCOLAS_VISUALIZAR,
    __RELATORIO_DEPENDENCIAS_ADMINISTRATIVAS_VISUALIZAR,
    __RELATORIO_MUNICIPIOS_VISUALIZAR,
    __RELATORIO_UFS_VISUALIZAR,
    __RELATORIO_INSCRICOES_VISUALIZAR,
    __RELATORIO_PROVAS_ESPECIAIS_VISUALIZAR,
    __RELATORIO_GESTAO_VISUALIZAR,
    __BANNER_VISUALIZAR,
    __RELATORIO_MEDALHAS_VISUALIZAR,
    __RELATORIO_PAGAMENTOS_VISUALIZAR,
    __APOIO_SECRETARIAL,
    __COORDENADOR_VISUALIZAR,
    __TIPO_DOCUMENTO_VISUALIZAR,
    __CONTEXTO_CALCULAR_MATERIAIS,
    __CONTEXTO_ORDENAR_IMPRESSAO,
    __SERVICOS_VISUALIZAR,
} from '../../security/RoleConfiguration';

import { Layout } from '../../layouts/private/Private';

export const path = '/admin';

export const mainPage = '/inicio';

export const dashboardMenuList = [
    {
        category: '',
        items: [
            {
                path: `${path}/inicio`,
                name: 'Início',
                icon: HomeOutlinedIcon,
                component: Dashboard,
                collapsed: false,
                submenus: [],
                roles: [ __DASHBOARD_VISUALIZAR ],
            },
            {
                path: `${path}/inicial`,
                name: 'Início',
                icon: HomeOutlinedIcon,
                component: Layout,
                collapsed: false,
                submenus: [],
                roles: [ __ADMINISTRADOR, __CENTRAL, __COORDENADOR, __APOIO_SECRETARIAL ],
            },
        ]
    },
    {
        category: 'Configurações',
        items: [
            {
                path: `${path}/contexto`,
                name: 'Contexto',
                icon: SettingsOutlinedIcon,
                component: Contexto,
                collapsed: false,
                submenus: [],
                roles: [ __CONTEXTO_VISUALIZAR_VALOR ],
            },
            {
                path: `${path}/banners`,
                name: 'Banners',
                icon: ViewCarouselOutlinedIcon,
                component: Banner,
                collapsed: false,
                submenus: [],
                roles: [ __BANNER_VISUALIZAR ],
            },
            {
                path: `${path}/arquivo`,
                name: 'Arquivos',
                icon: CloudDownloadOutlinedIcon,
                component: Arquivo,
                collapsed: false,
                submenus: [],
                roles: [ __ARQUIVO_VISUALIZAR ],
            },
            {
                path: `${path}/`,
                name: 'Materiais',
                icon: BookmarksOutlinedIcon,
                component: null,
                collapsed: true,
                submenus: [
                    {
                        path: `${path}/materiais/tipos`,
                        name: 'Tipos de Materiais',
                        icon: FileCopyOutlinedIcon,
                        component: TipoDocumento,
                        collapsed: false,
                        submenus: [],
                        roles: [ __TIPO_DOCUMENTO_VISUALIZAR ],
                    },
                    {
                        path: `${path}/materiais/calculo`,
                        name: 'Cálculo de Materiais',
                        icon: FunctionsIcon,
                        component: Calculo,
                        collapsed: false,
                        submenus: [],
                        roles: [ __CONTEXTO_CALCULAR_MATERIAIS ],
                    },
                    {
                        path: `${path}/materiais/ordem-impressao`,
                        name: 'Ordem de Impressão',
                        icon: SwapVertOutlinedIcon,
                        component: OrdemImpressao,
                        collapsed: false,
                        submenus: [],
                        roles: [ __CONTEXTO_ORDENAR_IMPRESSAO ],
                    },
                    {
                        path: `${path}/materiais/servicos`,
                        name: 'Serviços para Impressão',
                        icon: DynamicFeedOutlinedIcon,
                        component: Servicos,
                        collapsed: false,
                        submenus: [],
                        roles: [ __SERVICOS_VISUALIZAR ],
                    },
                ],
                roles: [
                    __TIPO_DOCUMENTO_VISUALIZAR,
                    __CONTEXTO_CALCULAR_MATERIAIS,
                    __CONTEXTO_ORDENAR_IMPRESSAO,
                    __SERVICOS_VISUALIZAR,
                ],
            }
        ],
    },
    {
        category: 'Administração',
        items: [
            {
                path: `${path}/coordenadores`,
                name: 'Coordenadores',
                icon: SupervisorAccountOutlinedIcon,
                component: Coordenador,
                collapsed: false,
                submenus: [],
                roles: [ __COORDENADOR_VISUALIZAR ],
            },
            {
                path: `${path}/escola`,
                name: 'Escolas',
                icon: SchoolOutlinedIcon,
                component: Escola,
                collapsed: false,
                submenus: [],
                roles: [ __ESCOLA_VISUALIZAR ],
            },
            {
                path: `${path}/aluno`,
                name: 'Alunos',
                icon: FaceOutlinedIcon,
                component: Aluno,
                collapsed: false,
                submenus: [],
                roles: [ __ADMINISTRADOR, __CENTRAL, __COORDENADOR, __APOIO_SECRETARIAL ],
            },
        ],
    },
    {
        category: 'Correios',
        items: [
            {
                path: `${path}/`,
                name: 'Objetos Postais',
                icon: MarkunreadMailboxOutlinedIcon,
                component: null,
                collapsed: true,
                submenus: [
                    {
                        path: `${path}/objetosPostais`,
                        name: 'Listagem',
                        icon: FormatListBulletedIcon,
                        component: ObjetoPostal,
                        collapsed: false,
                        submenus: [],
                        roles: [ __OBJETO_POSTAL_VISUALIZAR ],
                    },
                    {
                        path: `${path}/objetosPostais/data`,
                        name: 'Por Data',
                        icon: DateRangeOutlinedIcon,
                        component: ObjetoPostalPorData,
                        collapsed: false,
                        submenus: [],
                        roles: [ __OBJETO_POSTAL_POR_DATA_VISUALIZAR ],
                    },
                    {
                        path: `${path}/objetosPostais/status`,
                        name: 'Por Status',
                        icon: AccountTreeOutlinedIcon,
                        component: ObjetoPostalPorStatus,
                        collapsed: false,
                        submenus: [],
                        roles: [ __OBJETO_POSTAL_POR_STATUS_VISUALIZAR ],
                    },

                ],
                roles: [
                    __OBJETO_POSTAL_VISUALIZAR,
                    __OBJETO_POSTAL_POR_DATA_VISUALIZAR,
                    __OBJETO_POSTAL_POR_STATUS_VISUALIZAR,
                ],
            },
            {
                path: `${path}/`,
                name: 'Materiais Extras',
                icon: AllInboxIcon,
                component: null,
                collapsed: true,
                submenus: [
                    {
                        path: `${path}/materiaisExtras`,
                        name: 'Listagem',
                        icon: DnsOutlinedIcon,
                        component: MaterialExtra,
                        collapsed: false,
                        submenus: [],
                        roles: [ __OBJETO_POSTAL_VISUALIZAR ],
                    },
                    {
                        path: `${path}/materiaisExtras/data`,
                        name: 'Por Data',
                        icon: DateRangeOutlinedIcon,
                        component: MaterialExtraPorData,
                        collapsed: false,
                        submenus: [],
                        roles: [ __OBJETO_POSTAL_POR_DATA_VISUALIZAR ],
                    },
                    {
                        path: `${path}/materiaisExtras/status`,
                        name: 'Por Status',
                        icon: AccountTreeOutlinedIcon,
                        component: MaterialExtraPorStatus,
                        collapsed: false,
                        submenus: [],
                        roles: [ __OBJETO_POSTAL_POR_STATUS_VISUALIZAR ],
                    },

                ],
                roles: [
                    __OBJETO_POSTAL_VISUALIZAR,
                    __OBJETO_POSTAL_POR_DATA_VISUALIZAR,
                    __OBJETO_POSTAL_POR_STATUS_VISUALIZAR,
                ],
            },
            {
                path: `${path}/`,
                name: 'Importações',
                icon: InputOutlinedIcon,
                component: null,
                collapsed: true,
                submenus: [
                    {
                        path: `${path}/tarefas`,
                        name: 'Tarefas',
                        icon: ScheduleOutlinedIcon,
                        component: Tarefa,
                        collapsed: false,
                        submenus: [],
                        roles: [ __TAREFA_VISUALIZAR ],
                    },
                    {
                        path: `${path}/objetosPostais/erros`,
                        name: 'Erros de Importação',
                        icon: ReportProblemOutlinedIcon,
                        component: ObjetoPostalErro,
                        collapsed: false,
                        submenus: [],
                        roles: [ __OBJETO_POSTAL_ERROS_VISUALIZAR ],
                    },
                ],
                roles: [
                    __TAREFA_VISUALIZAR,
                    __OBJETO_POSTAL_ERROS_VISUALIZAR,
                ],
            },
        ],
    },
    {
        category: 'Comunicação',
        items: [
            {
                path: `${path}/emails`,
                name: 'Emails',
                icon: EmailOutlinedIcon,
                component: Email,
                collapsed: false,
                submenus: [],
                roles: [ __EMAIL_VISUALIZAR ]
            },
        ],
    },
    {
        category: 'Relatórios',
        items: [
            {
                path: `${path}/relatorio/inscricoes`,
                name: 'Inscrições',
                icon: DateRangeOutlinedIcon,
                component: RelatorioInscricoes,
                collapsed: false,
                submenus: [],
                roles: [ __RELATORIO_INSCRICOES_VISUALIZAR ],
            },
            {
                path: `${path}/relatorio/escolas`,
                name: 'Escolas Inscritas',
                icon: SchoolOutlinedIcon,
                component: RelatorioEscolas,
                collapsed: false,
                submenus: [],
                roles: [ __RELATORIO_ESCOLAS_VISUALIZAR ],
            },
            {
                path: `${path}/relatorio/dependeciasAdministrativas`,
                name: 'Dep. Administrativa',
                icon: LocalOfferOutlinedIcon,
                component: RelatorioDependenciasAdministrativas,
                collapsed: false,
                submenus: [],
                roles: [ __RELATORIO_DEPENDENCIAS_ADMINISTRATIVAS_VISUALIZAR ],
            },
            {
                path: `${path}/relatorio/municipios`,
                name: 'Municípios',
                icon: PlaceOutlinedIcon,
                component: RelatorioMunicipios,
                collapsed: false,
                submenus: [],
                roles: [ __RELATORIO_MUNICIPIOS_VISUALIZAR ],
            },
            {
                path: `${path}/relatorio/ufs`,
                name: "UF's",
                icon: MapOutlinedIcon,
                component: RelatorioUfs,
                collapsed: false,
                submenus: [],
                roles: [ __RELATORIO_UFS_VISUALIZAR ],
            },
            {
                path: `${path}/relatorio/pagamentos`,
                name: 'Pagamentos',
                icon: AttachMoneyOutlinedIcon,
                component: RelatorioPagamentos,
                collapsed: false,
                submenus: [],
                roles: [ __RELATORIO_PAGAMENTOS_VISUALIZAR ],
            },
            {
                path: `${path}/relatorio/medalhas`,
                name: 'Medalhas',
                icon: EmojiEventsOutlinedIcon,
                component: RelatorioMedalhas,
                collapsed: false,
                submenus: [],
                roles: [ __RELATORIO_MEDALHAS_VISUALIZAR ],
            },
            {
                path: `${path}/relatorio/provasEspeciais`,
                name: 'Provas Especiais',
                icon: AccessibleForwardOutlinedIcon,
                component: RelatorioProvasEspeciais,
                collapsed: false,
                submenus: [],
                roles: [ __RELATORIO_PROVAS_ESPECIAIS_VISUALIZAR ],
            },
            {
                path: `${path}/relatorio/gestao`,
                name: 'Gestão',
                icon: BusinessOutlinedIcon,
                component: RelatorioGestao,
                collapsed: false,
                submenus: [],
                roles: [ __RELATORIO_GESTAO_VISUALIZAR ],
            },
        ],
    },
    {
        category: 'Edições Anteriores',
        items: [
            {
                path: `https://1a.olimpiadamirim.obmep.org.br`,
                name: '1ª Edição',
                icon: Filter1Icon,
                component: "a",
                collapsed: false,
                submenus: [],
                roles: [ __ADMINISTRADOR, __CENTRAL ],
            },
            {
                path: `https://2a.olimpiadamirim.obmep.org.br`,
                name: '2ª Edição',
                icon: Filter2Icon,
                component: "a",
                collapsed: false,
                submenus: [],
                roles: [ __ADMINISTRADOR, __CENTRAL ],
            },
        ]
    },
];

export default dashboardMenuList;