import React from 'react';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Title from '../../components/Title/Title';

import useStyles from './Style';

const Compartilhamento = () => {
    const classes = useStyles();
    return (
        <>
            <Title id="anchor-5" variant="h6" className={classes.subtitle}>
                <span className={classes.item}>5.</span> COMPARTILHAMENTO DE DADOS PESSOAIS
            </Title>

            <Typography className={classes.text} component={'div'} variant="body2">
                O IMPA realiza o compartilhamento dos dados pessoais sob sua responsabilidade apenas quando estritamente necessário para atingir as finalidades apontadas nesse aviso de privacidade. Descrevemos
                abaixo quem poderá ter acesso a esses dados, e por quais motivos.

                <Grid
                    container
                    align='center'
                    direction="row"
                    justify="center"
                    alignItems="center"
                    className={classes.wrapper}
                >
                    <Grid item xs={12} sm={8} lg={6} xl={6} >
                        <TableContainer component="div">
                            <Table className={classes.table} size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.bolder} width="40%" align="center">Quem?</TableCell>
                                        <TableCell className={classes.bolder} width="60%" align="center">Por quê?</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell><span className={classes.bolder}>Parceiros em geral</span></TableCell>
                                        <TableCell>Quando necessário para operacionalizar campanhas, programas, cursos, subsidiar pesquisas e iniciativas do IMPA e de seus programas e projetos.</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><span className={classes.bolder}>Autoridades públicas</span></TableCell>
                                        <TableCell>De acordo com a exigência de leis, regulações aplicáveis ao IMPA ou em caso de requisições realizadas por autoridades competentes. </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><span className={classes.bolder}>Fornecedores e prestadores de serviço</span></TableCell>
                                        <TableCell>Quando necessário para executar as finalidades previstas nesse aviso.</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </Typography>
        </>
    )
}

export default Compartilhamento;