import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';

import Filters from '../../components/Filters/Filters';

import DependenciaAdministrativaFilter from '../DependenciaAdministrativa/DependenciaAdministrativaFilter';
import EnderecoFilter from '../Endereco/EnderecoFilter';
import EscolaFilter from './EscolaFilterFields';

import { removePagingAndSorting } from '../../helper/PaginationHelper';
import EscolaService from "../../services/Escola";
import {download} from "../../helper/FileHelper";

export class EscolaMateriaisFilter extends Component {
    constructor(props) {
        super(props);
        props.parentRef(this);
        this.state = {
            exportPlan: false,
            count: 0,
        }
    }

    // DECLARAÇÃO DE REFERÊNCIA DOS COMPONENTES TIPO FORM
    setEnderecoFilter = form => this.EnderecoFilter = form;
    setDependenciaAdministrativaFilter = form => this.DependenciaAdministrativaFilter = form;
    setEscolaFilter = form => this.EscolaFilter = form;

    // APLICAR PERSISTENCIA
    handleFilterPersistence = (filters) => {
        filters = removePagingAndSorting(filters);

        this.setState({
            count: filters.reduce((total, f) => f.value !== '' ? total + 1 : total, 0),
        })

        this.EscolaFilter.setState({
            filters: {
                inepSchool: filters.find(f => f.field === 'cdEscola') ? filters.find(f => f.field === 'cdEscola').value : "",
            }
        })

        this.DependenciaAdministrativaFilter.setState({
            filters: {
                adminDep: filters.find(f => f.field === 'nmDependenciaAdministrativa') ? filters.find(f => f.field === 'nmDependenciaAdministrativa').value : "",
            },
        })

        this.EnderecoFilter.setState({
            filters: {
                UF: filters.find(f => f.field === 'cdUf') ? filters.find(f => f.field === 'cdUf').value : "",
                city: filters.find(f => f.field === 'cdMunicipio') ? filters.find(f => f.field === 'cdMunicipio').value : "",
                region: filters.find(f => f.field === 'cdRegiao') ? filters.find(f => f.field === 'cdRegiao').value : "",
            }
        })
    }

    // FILTROS
    handleFilterApply = () => {
        const f = [];
        const { exportPlan } = this.state;
        const filtersDepAdmin = this.DependenciaAdministrativaFilter.state.filters;
        const filtersEscola = this.EscolaFilter.state.filters;
        const filtersEndereco = this.EnderecoFilter.state.filters;

        if (filtersDepAdmin.adminDep) f.push({ field: 'nmDependenciaAdministrativa', value: filtersDepAdmin.adminDep })

        if (filtersEscola.inepSchool) f.push({ field: 'cdEscola', value: filtersEscola.inepSchool })

        if (filtersEndereco.UF) f.push({ field: 'cdUf', value: filtersEndereco.UF })
        if (filtersEndereco.city) f.push({ field: 'cdMunicipio', value: filtersEndereco.city })
        if (filtersEndereco.region) f.push({ field: 'cdRegiao', value: filtersEndereco.region })

        if (exportPlan) {
            f.push({ field: 'exportarPlanilha', value: exportPlan });

            EscolaService.export(f)
                .then(res => download(res))
                .finally(() => this.setState({ exportPlan: false }))
        } else {
            this.props.handleFilterChange(f);
        }
    }

    // LIMPAR FILTROS
    handleFilterClear = () => {
        this.setState({
            exportPlan: false,
            count: 0,
        })

        this.DependenciaAdministrativaFilter.setState({
            filters: {
                adminDep: '',
            }
        })

        this.EscolaFilter.setState({
            filters: {
                inepSchool: '',
            }
        })

        this.EnderecoFilter.setState({
            filters: {
                city: '',
                UF: '',
                region: '',
            }
        })

        this.props.handleFilterChange([], false);
    }

    // CONTAGEM DE FILTROS ATIVOS
    handleCountFilters = () => this.state.count;

    render() {
        return (
            <Filters
                handleFilter={this.handleFilterApply}
                handleFilterClear={this.handleFilterClear}
                countFilters={this.handleCountFilters}
            >
                <Grid item container spacing={3}>
                    <EscolaFilter
                        parentRef={this.setEscolaFilter}
                        disabledEmail={true}
                        disabledNameSchool={true}
                    />
                    <EnderecoFilter
                        parentRef={this.setEnderecoFilter}
                        withRegion={true}
                    />
                    <DependenciaAdministrativaFilter
                        parentRef={this.setDependenciaAdministrativaFilter}
                        withFederal={true}
                        withPublicGroup={true}
                    />
                </Grid>
            </Filters>
        )
    }
}

export default EscolaMateriaisFilter;