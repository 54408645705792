import React from 'react';

import useStyles from './Style';

const Link = (props) => {
    const classes = useStyles();

    return (
        <a className={classes.link} href={`#anchor-${props.anchor}`} >
            {props.children}
        </a>
    )
}

const NavbarRegulamento = () => {
    const classes = useStyles();

    return (
        <div className={classes.nav}>
            <Link anchor="01">1. DISPOSIÇÕES PRELIMINARES</Link>
            <Link anchor="02">2. DOS PARTICIPANTES</Link>
            <Link anchor="03">3. DA INSCRIÇÃO</Link>
            <Link anchor="04">4. DAS TAXAS DE INSCRIÇÃO </Link>
            <Link anchor="05">5. DA CONFIRMAÇÃO DE INSCRIÇÃO E DO ACESSO AO SISTEMA</Link>
            <Link anchor="06">6. DAS FASES </Link>
            <Link anchor="07">7. DA PROVA DA 1ª FASE</Link>
            <Link anchor="08">8. DA DISPONIBILIZAÇÃO DIGITAL DO MATERIAL DE PROVA DA 1ª FASE</Link>
            <Link anchor="09">9. DA APLICAÇÃO DA PROVA DA 1ª FASE</Link>
            <Link anchor="10">10. DA CORREÇÃO DA PROVA DA 1ª FASE </Link>
            <Link anchor="11">11. DA CLASSIFICAÇÃO DOS ALUNOS PARA A 2ª FASE</Link>
            <Link anchor="12">12. DA PROVA DA 2ª FASE</Link>
            <Link anchor="13">13. DA DISPONIBILIZAÇÃO DO MATERIAL DE PROVA DA 2ª FASE </Link>
            <Link anchor="14">14. DA CORREÇÃO DA PROVA DA 2ª FASE</Link>
            <Link anchor="15">15. DOS CRITÉRIOS DE DESEMPATE</Link>
            <Link anchor="16">16. DOS CRITÉRIOS DE PREMIAÇÃO</Link>
            <Link anchor="17">17. DISPOSIÇÕES GERAIS</Link>

            <Link anchor="a1">ANEXO I - Calendário Oficial – 3ª Olimpíada Mirim – OBMEP</Link>
            <Link anchor="a2">ANEXO II - Normas de Pagamento para Taxa de Inscrição</Link>
        </div>
    )
}

export default NavbarRegulamento;