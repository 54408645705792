import React from 'react';

import Typography from '@material-ui/core/Typography';

import Title from '../../components/Title/Title';

import useStyles from './Style';

const Desempate = () => {
    const classes = useStyles();
    return (
        <>
            <Title id="anchor-15" className={classes.title}>
                15. DOS CRITÉRIOS DE DESEMPATE
            </Title>

            <Typography className={classes.text} variant="body2">
                <span className={classes.item}>15.1</span> Previamente à realização da prova da 1ª Fase, as escolas deverão definir e divulgar amplamente para os seus alunos e professores, os critérios de desempate 
                para classificação para a Segunda Fase, os quais serão aplicados em caso de empate pela última vaga. A definição dos critérios de desempate pelas escolas deverá garantir 
                o tratamento igualitário e ético aos participantes. Previamente à realização da prova da 2ª Fase, as escolas também deverão definir e divulgar amplamente os critérios de 
                desempate para premiação previstos no item 16 do presente Regulamento.
            </Typography>
        </>
    )
}

export default Desempate;