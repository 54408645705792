export const optionsSexo = [
    { value: 'M', label: 'Masculino' },
    { value: 'F', label: 'Feminino' },
];

export const optionsRaca = [
    { value: 'AMARELA', label: 'Amarela' },
    { value: 'BRANCA', label: 'Branca' },
    { value: 'INDIGENA', label: 'Indígena' },
    { value: 'PARDA', label: 'Parda' },
    { value: 'PRETA', label: 'Preta' },
];

export const optionsTipoProva = [
    { value: 'AMPLIADA', label: 'Prova Ampliada' },
    { value: 'BRAILE', label: 'Prova Braile' },
    { value: 'NORMAL', label: 'Prova Normal' },
];

export const optionsNivel = [
    { value: 'MIRIM_1', label: 'Mirim 1' },
    { value: 'MIRIM_2', label: 'Mirim 2' },
];

export const optionsAnoLetivo = [
    { value: 'SEGUNDO', label: 'Segundo Ano' },
    { value: 'TERCEIRO', label: 'Terceiro Ano' },
    { value: 'QUARTO', label: 'Quarto Ano' },
    { value: 'QUINTO', label: 'Quinto Ano' },
];

export const optionsTermo = [
    { value: 'VALIDO', label: 'Válido' },
    { value: 'PENDENTE', label: 'Enviado' },
    { value: 'INVALIDO', label: 'Inválido' },
];

export const optionsPremiacao = [
    { value: 'QUALQUER_PREMIO', label: 'Qualquer Prêmio' },
    { value: 'OURO', label: 'Medalha de Ouro' },
    { value: 'PRATA', label: 'Medalha de Prata' },
    { value: 'BRONZE', label: 'Medalha de Bronze' },
    { value: 'SEM_PREMIO', label: 'Sem Prêmio' },
];

export const patternTipoProva = dep => {
    return dep === "AMPLIADA" ? "Ampliada":
            dep === "BRAILE" ? "Braile" :
            dep === "NORMAL" ? "Normal" : "";
}

export const patternNivel = dep => {
    return dep === "MIRIM_1" ? "Mirim 1":
            dep === "MIRIM_2" ? "Mirim 2" : "";
}

export const patternAnoLetivo = dep => {
    return dep === "SEGUNDO" ? "Segundo Ano":
            dep === "TERCEIRO" ? "Terceiro Ano" :
            dep === "QUARTO" ? "Quarto Ano" :
            dep === "QUINTO" ? "Quinto Ano" : "";
}

export const patternSexo = dep => {
    return dep === "M" ? "Masculino":
            dep === "F" ? "Feminino" : "";
}

export const patternRaca = dep => {
    return dep === "AMARELA" ? "Amarela" : 
            dep === "BRANCA" ? "Branca" :
            dep === "INDIGENA" ? "Indígena" : 
            dep === "PARDA" ? "Parda" : 
            dep === "PRETA" ? "Preta" : "Não Informada";
}

export const patternTermo = termo => {
    return termo === "VALIDO" ? "Válido" :
            termo === "PENDENTE" ? "Enviado" : 
            termo === "INVALIDO" ? "Inválido" : "";
}

export const patternPremio = termo => {
    return termo === "OURO" ? "Ouro" :
            termo === "PRATA" ? "Prata" : 
            termo === "BRONZE" ? "Bronze" : "";
}