import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
    (theme) => (
        {
            container: {
                marginTop: theme.spacing(12),
                marginBottom: theme.spacing(8),
            },
            title: {
                marginTop: theme.spacing(6),
                fontWeight: '600',
            },
            subtitle: {
                textIndent: theme.spacing(2),
            },
            itemText: {
                marginTop: theme.spacing(2),
                textIndent: theme.spacing(4),
                fontWeight: '800',
                fontSize: '1em',
            },
            text: {
                marginTop: theme.spacing(2),
                textIndent: theme.spacing(4),
                textAlign: 'justify',
            },
            list: {
                listStyleType: 'square',
                marginTop: theme.spacing(2),
                marginBottom: theme.spacing(2),
                textIndent: theme.spacing(0),
                paddingLeft: theme.spacing(8),
            },
            table: {
                marginTop: theme.spacing(2),
                marginBottom: theme.spacing(2),
                textIndent: theme.spacing(0),
            },
            item: {
                marginRight: theme.spacing(2),
                fontWeight: 'bold',
            },
            bolder: {
                fontWeight: 'bold',
            },
            pointer: {
                cursor: 'pointer',
            },
            underline: {
                textDecorationLine: 'underline',
            }
        }
    )
);

export default useStyles;