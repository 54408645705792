import React from 'react';

import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';

export const AlertaProcessamento = ({ acao, processado, valido, motivo }) => {
    const tipoAlerta = !processado ? "warning" : valido ? "success" : "error";
    const conteudo = !processado ? `Clique no botão abaixo para Iniciar o Processamento de ${acao} para as Escolas.` : valido ? `Processamento de ${acao} Realizado com Sucesso.` : `${motivo}`;

    return (
        <Grid item sm={12} lg={12}>
            <Alert severity={tipoAlerta} style={{ marginTop: '10px' }} >
                <Grid container >
                    <Grid item sm={12} lg={12}>
                        <Typography variant="body2">
                            <b>{conteudo}</b>
                        </Typography>
                    </Grid>
                </Grid>          
            </Alert>
        </Grid>
    )
}

export default AlertaProcessamento;