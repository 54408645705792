import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

import Grid from '@material-ui/core/Grid';

import MaterialExtraService from '../../services/MaterialExtra';
import { errors } from '../../services/API';

import { removePagingAndSorting } from '../../helper/PaginationHelper';
import { filterPersistence } from '../../helper/ParseStateHelper';
import { parseDate } from '../../helper/DateHelper';

import ObjetoPostalFilter from '../../forms/ObjetoPostal/ObjetoPostalFilter';
import { ButtonExport } from '../../forms/Buttons/ButtonsExport';

import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Title from '../../components/Title/Title';
import Table from '../../components/Table/Table';
import Toast from '../../components/Toast/Toast';
import {
    verifyPagingAndSorting,
    PAGE,
    SIZE,
    ASC,
    TOTAL_ELEMENTS,
    TOTAL_PAGES,
} from '../../components/Table/Utils';

import { __OBJETO_POSTAL_EXPORTAR } from '../../security/RoleConfiguration';
import AuthorizedElement from '../../security/AuthorizedElement';

import { Layout } from '../../layouts/private/Private';

export class MaterialExtra extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: PAGE,
            size: SIZE,
            totalElements: TOTAL_ELEMENTS,
            totalPages: TOTAL_PAGES,
            sort: [{ orderBy: 'numero', order: ASC }],
            defaultOrderBy: 'numero',
            filtered: false,
            filters: [],
            objetosPostais: [],
            loading: true,
            history: {
                path: null,
                state: null
            }
        }
    }

    // FECHAR TOAST
    setToast = t => this.Toast = t;
    setObjetoPostalFilter = e => this.ObjetoPostalFilter = e;

    // EXPORTAÇÂO
    handleExportPlan = () => {
        this.ObjetoPostalFilter.setState({ filter: true, exportPlan: true, }, () => this.ObjetoPostalFilter.handleFilterApply());

        this.Toast.setState({
            message: {
                message: "A Planilha está sendo gerada para Exportação.",
                type: 'info',
                open: true,
                loading: true,
                autohide: false,
            }
        })
    }

    loadData = () => {
        this.setState({ history: filterPersistence(this.props.location) });

        let f = verifyPagingAndSorting(this.state);

        this.setState({
            filters: [...f],
            filtered: true,
            loading: true
        });

        if (f.filter(fi => fi.field === 'dataEnvio' || fi.field === 'status').length === 0) {
            const parseFilters = queryString.parse(this.props.location.search);

            this.ObjetoPostalFilter.setState({
                filters: {
                    ...this.ObjetoPostalFilter.state.filters,
                    dataEnvio: parseFilters.dataEnvio ? parseDate(parseFilters.dataEnvio) : null,
                    status: parseFilters.status ?? '',
                }
            });

            if (parseFilters.dataEnvio) f.push({ field: 'dataEnvio', value: parseFilters.dataEnvio });
            if (parseFilters.status) f.push({ field: 'status', value: parseFilters.status });
        }

        MaterialExtraService.filters(f)
            .then(res => {
                if (res.data.content) {
                    this.setState({
                        filters: f,
                        objetosPostais: [...res.data.content],
                        page: res.data.number,
                        size: res.data.size,
                        totalElements: res.data.totalElements,
                        totalPages: res.data.totalPages,
                        loading: false,
                    });
                } else {
                    this.setState({
                        filters: f,
                        objetosPostais: [],
                        page: res.data.number,
                        size: res.data.size,
                        totalElements: res.data.totalElements,
                        totalPages: res.data.totalPages,
                        loading: false,
                    });
                }
            })
            .catch(error => {
                const e = errors(error);
                this.Toast.setState({
                    message: {
                        message: e.message,
                        type: e.type,
                        open: true
                    }
                })
            })
            .finally(() => this.setState({ loading: false }))
    }

    // FILTROS
    filterData = (filters, isActive = true) => this.setState({ filtered: isActive, filters: [...removePagingAndSorting(filters)] }, () => this.loadData());

    // PÁGINA
    handlePage = page => this.setState({ page: page }, () => this.filterData(this.state.filters));

    // LINHAS POR PÁGINA
    handleRowsPerPage = (size, page) => this.setState({ size: size, page: page }, () => this.filterData(this.state.filters));

    // ORDENAÇÃO
    handleSort = (orderBy, order) => {
        if (this.state.sort.find(s => s.orderBy === orderBy)) {
            let newSort = this.state.sort.filter(s => s.orderBy !== orderBy);
            newSort.splice(this.state.sort.indexOf(this.state.sort.find(s => s.orderBy === orderBy)), 0, { orderBy: orderBy, order: order })
            this.setState({...this.state, sort: [...newSort], filtered: true });
        } else {
            this.setState({ ...this.state, sort: [...this.state.sort.filter(s => s.orderBy !== orderBy), { orderBy: orderBy, order: order }], filtered: true });
        }
    }
    handleClearSort = (orderBy) => {
        this.setState({ ...this.state, filtered: true, sort: orderBy ? [...this.state.sort.filter(s => s.orderBy !== orderBy)] : [{ orderBy: this.state.defaultOrderBy, order: ASC }] });
    };

    // TOTAL DE PAGINAS
    handleTotalPages = () => this.state.totalPages;

    // TOTAL DE ELEMENTOS
    handleTotalElements = () => this.state.totalElements;

    // VERIFICAR PERSISTENCIA NO FORMULARIO DE FILTROS
    handleFilterPersistence = () => this.ObjetoPostalFilter ? this.ObjetoPostalFilter.state ? this.ObjetoPostalFilter.handleFilterPersistence(this.state.filters) : null : null;

    // COMPONENT DID MOUNT
    componentDidMount() { this.loadData(); }

    render() {
        const pageName = 'Listagem de Materiais Extras';
        const links = [
            {
                path: null,
                name: 'Correios'
            },
            {
                path: null,
                name: 'Materiais Extras'
            }
        ];

        const columns = [
            { label: 'Código Sedex', name: 'numero', func: null, key: true },
            { label: 'Data de Envio', name: 'dataEnvioSedex', func: null, key: false },
            { label: 'Região', name: 'regiao', func: null, key: false, ordering: false },
            { label: 'UF', name: 'uf', func: null, key: false, ordering: false },
            { label: 'Status', name: 'status', func: null, key: false, ordering: false },
        ];

        this.handleFilterPersistence();

        return (
            <Layout>
                <Toast parentRef={this.setToast} />
                <Grid container >
                    <Grid item xs={12}>
                        <Breadcrumbs links={links} active={pageName} />
                    </Grid>
                </Grid>
                <Title>{pageName}</Title>

                <Grid container >
                    <Grid item xs={12}>
                        <ObjetoPostalFilter
                            parentRef={this.setObjetoPostalFilter}
                            handleFilterChange={this.filterData}
                            export={MaterialExtraService.export}
                            ocultarConteudo={true}
                            ocultarInep={true}
                            ocultarEndereco={true}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                            <AuthorizedElement roles={[__OBJETO_POSTAL_EXPORTAR]}>
                                {this.state.filters.length > 3 &&
                                    <ButtonExport
                                        title="Exportar Planilha"
                                        onClick={this.handleExportPlan}
                                    />
                                }
                            </AuthorizedElement>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Table
                            columns={columns}
                            tableData={this.state.objetosPostais}
                            actions={[]}
                            page={this.state.page}
                            handlePage={this.handlePage}
                            handleTotalPages={this.handleTotalPages}
                            handleTotalElements={this.handleTotalElements}
                            rowsPerPage={this.state.size}
                            handleRowsPerPage={this.handleRowsPerPage}
                            sort={this.state.sort}
                            handleSort={this.handleSort}
                            handleClearSort={this.handleClearSort}
                            loading={this.state.loading}
                        />
                    </Grid>
                </Grid>
            </Layout >
        )
    }
}

export default withRouter(MaterialExtra);