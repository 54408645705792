import API from './API';

const path = `dashboard`;

const DashboardService = {
    // INICIO
    init: async filters => await API.filters(path, filters),

    getAlertas: async filters => await API.filters(`${path}/alertas`, filters),
}

export default DashboardService;